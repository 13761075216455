import { Box, Link, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RESOURCE_LIST } from 'config';
import { Resource } from 'types/resources';
import useCleanup from '../../hooks/useCleanup';

interface ResouceItemProps {
  resource: Resource;
}

const ResourceItem = ({ resource }: ResouceItemProps) => {
  const { name, link, description } = resource;
  const theme = useTheme();

  return (
    <Box sx={{ padding: theme.spacing(4) }}>
      <Link href={link}>{name}</Link>
      <Typography
        variant="body1"
        sx={{
          fontSize: '12px',
          display: 'block',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export const Resources = () => {
  useCleanup();

  return (
    <Paper
      elevation={8}
      sx={{
        padding: '2em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: 'auto',
          gap: '6px',
        }}
      >
        {RESOURCE_LIST.map(({ type, resources }) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              <Typography
                variant="h2"
                gutterBottom
                color="grey.500"
                sx={{ textTransform: 'capitalize' }}
              >
                {type}
              </Typography>
              <>
                {resources?.map((resource) => (
                  <ResourceItem resource={resource} />
                ))}
              </>
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TopRightQuadrantIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
      <path d="M3 11.5H11V3.5H3V11.5ZM5 5.5H9V9.5H5V5.5Z" />
      <path d="M3 21.5H11V13.5H3V21.5ZM5 15.5H9V19.5H5V15.5Z" />
      <path d="M13 21.5H21V13.5H13V21.5ZM15 15.5H19V19.5H15V15.5Z" />
      <path d="M13 11.5H21V3.5H13V11.5Z" />
    </SvgIcon>
  );
};

export default TopRightQuadrantIcon;

import {
  AlertCategory,
  AllExposedAlertCategories,
  Watchlist,
  alertCategoryDescriptions,
} from '../../../types';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { playAlertAudioState } from '../../../states';
import { difference, uniq } from 'lodash';
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { getDesc } from '../../../types';
import ListAltIcon from '@mui/icons-material/ListAlt';

type EditAlertSettingProps = {
  watchlist: Watchlist;
  changeAlertCategories: (watchlist: Watchlist, strs: string[]) => void;
  changeAlertAudio: (watchlist: Watchlist, enabled: boolean) => void;
  modal: boolean;
};

export const EditAlertSetting = ({
  watchlist,
  changeAlertAudio,
  changeAlertCategories,
  modal,
}: EditAlertSettingProps) => {
  const theme = useTheme();
  const playAlertAudio = useRecoilValue(playAlertAudioState);
  const shouldPlayAlertAudio =
    watchlist.soundDisabled == null ? playAlertAudio : !watchlist.soundDisabled;

  const enabledCategories = difference(
    AllExposedAlertCategories,
    watchlist.disabledAlertCategories ?? [],
  );
  const selectedCategories = uniq(enabledCategories.map((c) => getDesc(c)!));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          marginBottom: '15px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <Typography sx={{ fontSize: '16px' }}>
          {watchlist.id != null && (
            <ListAltIcon
              sx={{ verticalAlign: 'bottom', marginBottom: '2px' }}
              aria-label="Watchlist"
            />
          )}{' '}
          {watchlist.name}
        </Typography>
        <Typography fontSize="9px" color="text.secondary">
          {watchlist.symbols.join(', ')}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: '5px' }}>
        <FormControl
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            width: '50%',
            marginRight: modal ? 0 : '20px',
          }}
        >
          <Select
            multiple
            value={selectedCategories}
            displayEmpty
            style={{
              border: `0.5px solid ${theme.palette.text.secondary}`,
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return 'No alerts enabled';
              } else if (selected.length === 1) {
                return selected[0];
              } else if (
                selected.length ===
                Object.keys(alertCategoryDescriptions).length
              ) {
                return 'All alerts enabled';
              }
              return `${selected.length} of ${
                Object.keys(alertCategoryDescriptions).length
              } alerts enabled`;
            }}
            onChange={(event: SelectChangeEvent<string[]>) => {
              const value = event.target.value;
              const cats = typeof value === 'string' ? value.split(',') : value;
              changeAlertCategories(watchlist, cats);
            }}
          >
            {Object.keys(alertCategoryDescriptions).map((str) => {
              return (
                <MenuItem value={str} key={str}>
                  <Checkbox
                    checked={
                      alertCategoryDescriptions[str].filter(
                        (c: AlertCategory) => !enabledCategories.includes(c),
                      ).length === 0
                    }
                  />
                  <ListItemText>{str}</ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            gap: '5px',
            marginRight: '20px',
            flexGrow: modal ? 1 : undefined,
            justifyContent: modal ? 'flex-end' : 'flex-start',
          }}
        >
          <Typography variant="h4" gutterBottom color="primary">
            Play sound on new alert
          </Typography>
          <Switch
            size="small"
            color="primary"
            checked={shouldPlayAlertAudio}
            onChange={(evt) => {
              changeAlertAudio(watchlist, evt.target.checked);
            }}
            sx={{
              marginTop: '-5px',
              '& .MuiSwitch-track': {
                backgroundColor: shouldPlayAlertAudio
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

import { ThemeProvider } from '@mui/material/styles';
import { themeForMode } from './theme';
import { useRecoilValue } from 'recoil';
import { colorModeState } from './states';
import { ReactNode } from 'react';

type CustomColorModeThemeProviderProps = {
  children: ReactNode;
};
export const CustomColorModeThemeProvider = (
  props: CustomColorModeThemeProviderProps,
) => {
  const colorMode = useRecoilValue(colorModeState);

  return (
    <ThemeProvider theme={themeForMode(colorMode)}>
      {props.children}
    </ThemeProvider>
  );
};

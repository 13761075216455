import { Outlet } from 'react-router-dom';
import {
  Header,
  Sidebar,
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_OPEN,
} from '../components/core';
import Zendesk from 'react-zendesk';
import { ZENDESK_SETTINGS } from 'config';
import { Toast } from 'components/core/Toast';
import { ZendeskModal } from 'components/core/ZendeskModal';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  isSideDrawerExpandedState,
  userDetailsState,
} from 'states';
import { HideSupportStyle } from '../components/shared/HideSupportStyle';
import { chartExpandedState } from '../states';
import { showNoLoginUI } from '../util';

const expandedLeftPadding = SIDEBAR_WIDTH_OPEN + 15;
const collapsedLeftPadding = SIDEBAR_WIDTH + 15;

export const Layout = () => {
  const chartExpanded = useRecoilValue(chartExpandedState);
  const isDrawerExpanded = useRecoilValue(isSideDrawerExpandedState);
  const isMobile = useRecoilValue(isMobileState);
  const userDetails = useRecoilValue(userDetailsState);
  const notShowingSidebar = userDetails == null && showNoLoginUI();

  return (
    <>
      <Sidebar />
      <Box
        component="main"
        sx={{
          paddingRight: isMobile ? '7px' : '25px',
          paddingLeft: notShowingSidebar
            ? undefined
            : isMobile
            ? '7px'
            : isDrawerExpanded
            ? `${expandedLeftPadding}px`
            : `${collapsedLeftPadding}px`, // Adjust based on your sidebar's width
          transition: 'margin 0.3s ease',
          flexGrow: 1,
          overflow: 'auto',
          height: isMobile ? undefined : '100%',
        }}
      >
        <Header />
        <Outlet />
      </Box>

      <Zendesk
        defer
        zendeskKey={process.env.REACT_APP_ZENDESK_API_KEY ?? ''}
        {...ZENDESK_SETTINGS}
      />
      <ZendeskModal />
      <Toast />
      {chartExpanded && <HideSupportStyle />}
    </>
  );
};

import { Watchlist } from '../../types';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  equitySymbolsState,
  UNLOADED_EQUITIES_DEFAULT_LENGTH,
  screenWidthState,
  hiroSymbolsState,
  playAlertAudioState,
} from '../../states';
import { useTheme } from '@mui/material/styles';
import { uniq } from 'lodash';
import { Autocomplete, Box, IconButton, Paper, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type EditWatchlistProps = {
  watchlist: Watchlist;
  onChange: (newWatchlist: Watchlist | null) => void;
  modal?: boolean;
};

export const EditWatchlist = ({
  watchlist,
  onChange,
  modal,
}: EditWatchlistProps) => {
  const [allSymbols, setAllSymbols] = useState<string[]>([]);
  const [watchlistSyms, setWatchlistSyms] = useState(watchlist.symbols);
  const [name, setName] = useState(watchlist.name);
  const screenWidth = useRecoilValue(screenWidthState);
  const [query, setQuery] = useState('');
  const equities = useRecoilValue(equitySymbolsState);
  const hiroSyms = useRecoilValue(hiroSymbolsState);
  const theme = useTheme();
  const playAlertAudio = useRecoilValue(playAlertAudioState);

  useEffect(() => {
    onChange({
      name,
      id: watchlist.id,
      symbols: watchlistSyms,
      soundDisabled: !playAlertAudio,
    });
  }, [name, watchlistSyms, playAlertAudio]);

  useEffect(() => {
    const hiroSymbols = [...hiroSyms.values()];
    const ehSymbols = new Set(equities.map((d) => d.symbol));
    const allSyms = uniq([...hiroSymbols, ...ehSymbols.values()]);

    if (allSyms.length != allSymbols.length) {
      // if both equities and hiroSyms have loaded
      if (
        ehSymbols.size > UNLOADED_EQUITIES_DEFAULT_LENGTH &&
        hiroSyms.size > 0
      ) {
        const filteredWatchlistSyms = watchlistSyms.filter(
          (s) => hiroSyms.has(s) || ehSymbols.has(s),
        );
        if (watchlistSyms.length > filteredWatchlistSyms.length) {
          setWatchlistSyms(filteredWatchlistSyms);
        }
      }
      setAllSymbols(allSyms);
    }
  }, [hiroSyms, equities, watchlistSyms]);

  return (
    <Box
      sx={{
        minHeight: '50px',
        width: modal ? '100%' : 'auto',
        marginBottom: '25px',
        alignItems: 'center',
        m: theme.spacing(3),
        [theme.breakpoints.up('md')]: { display: 'flex' },
      }}
    >
      <Box display="flex" flexDirection="column" width={1}>
        <Box>
          <TextField
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          ></TextField>
          <IconButton
            aria-label={`delete ${name}`}
            onClick={(_event) => onChange(null)}
            color="primary"
            sx={{
              width: '30px',
              height: '30px',
              marginTop: '10px',
              marginLeft: '10px',
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>

        <Autocomplete
          sx={{
            mr: theme.spacing(3),
            width: 1.0,
            minWidth: Math.min(screenWidth * 0.5, 500),
            color: '#ffffff',
            [theme.breakpoints.down('md')]: { width: '100%' },
          }}
          disableClearable={true}
          filterSelectedOptions={true}
          multiple
          value={watchlistSyms}
          options={query.length === 0 ? [] : allSymbols}
          getOptionLabel={(option) => option}
          defaultValue={[]}
          onChange={(
            event: SyntheticEvent<Element, Event>,
            value: string[],
          ) => {
            setWatchlistSyms(value);
          }}
          noOptionsText={
            query.length === 0
              ? 'Start typing to add a symbol...'
              : 'No symbols found'
          }
          onInputChange={(_, val) => setQuery(val.trim())}
          PaperComponent={({ children }) => (
            <Paper
              style={{
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {children}
            </Paper>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ color: '#ffffff' }}
              variant="standard"
              placeholder={
                watchlistSyms.length === 0 ? '+ Add to watchlist' : ' +'
              }
            />
          )}
        />
      </Box>
    </Box>
  );
};

import { SxProps, Theme } from '@mui/material';
import React, { ForwardRefRenderFunction } from 'react';
import SelectedSymbol from './SelectedSymbol';

// size is a % value to indicate the full size of the watermark
// offset sets the position of the watermark on the chart. It's formatted as 'X% Y%'
// We use offsetX to set x-axis offset and offsetY to set y-axis offset
// Use default 25% size and 50% 50% position
interface ChartWatermarkContainerProps {
  size?: number;
  offsetX?: number;
  offsetY?: number;
  sym?: string;
  symStyles?: SxProps<Theme>;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const ChartWatermarkContainer: ForwardRefRenderFunction<
  HTMLDivElement,
  ChartWatermarkContainerProps
> = (
  { sym, symStyles, size = 25, offsetX = 50, offsetY = 50, style, children },
  ref,
) => {
  return (
    <div
      ref={ref}
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        backgroundImage: 'url(/images/spotgamma-logo-15.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${size}%`,
        backgroundPosition: `${offsetX}% ${offsetY}%`,
        ...style, // consumer can override defaults if needed
      }}
    >
      {children}
      {sym && <SelectedSymbol symbol={sym} sx={symStyles} />}
    </div>
  );
};

export default React.forwardRef(ChartWatermarkContainer);

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useRecoilState } from 'recoil';
import { currentToastState } from 'states';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Toast = () => {
  const [toast, setToast] = useRecoilState(currentToastState);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(undefined);
  };

  return (
    <Snackbar
      open={toast !== undefined}
      autoHideDuration={toast?.duration || 6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={toast?.type || 'info'}
        sx={{ width: '100%', fontSize: '16px' }}
      >
        {toast?.message}
      </Alert>
    </Snackbar>
  );
};

import { Box, SxProps, Theme } from '@mui/material';

interface StockIconProps {
  symbol: string;
  sx?: SxProps<Theme>;
}

const StockIcon = ({ symbol, sx }: StockIconProps) => {
  return (
    <Box
      sx={{
        backgroundImage: `url("images/hiro-logos/${symbol}.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        width: 20,
        height: 20,
        borderRadius: '50%',
        ...sx,
      }}
    />
  );
};

export default StockIcon;

import { useRecoilValue, useRecoilState } from 'recoil';
import {
  playAlertAudioState,
  userSettingsState,
  watchlistsState,
} from '../../../states';
import { Box } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import {
  Watchlist,
  alertCategoryDescriptions,
  AllExposedAlertCategories,
  ToastData,
} from '../../../types';
import { arraySpliceCopy } from '../../../util';
import useAlerts from '../../../hooks/alerts/useAlerts';
import { difference } from 'lodash';
import { EditAlertSetting } from './EditAlertSetting';
import { SGModal } from '../../shared/SGModal';

type EditAlertsSettingsProps = {
  onClose?: () => void;
  open?: boolean;
  modal: boolean;
  onChange?: (watchlists: Watchlist[]) => void;
  openToast?: (toastData: ToastData) => void;
};

export const EditAlertsSettings = ({
  onClose,
  onChange,
  open = false,
  modal,
  openToast = (_) => {}, // preferences page does not need to show toastsg
}: EditAlertsSettingsProps) => {
  const playAlertAudio = useRecoilValue(playAlertAudioState);
  const [globalWatchlists, setGlobalWatchlists] =
    useRecoilState(watchlistsState);
  const [watchlists, setWatchlists] = useState(globalWatchlists ?? []);
  const [settingsSaveLoading, setSettingsSaveLoading] = useState(false);
  const { saveAlertsSettings } = useAlerts();
  const settings = useRecoilValue(userSettingsState);
  const defaultNullWatchlist = {
    id: null,
    disabledAlertCategories: settings.disabledAlertCategories ?? [],
    soundDisabled: !playAlertAudio,
    name: 'All other symbols',
    symbols: [],
  };
  const [nullWatchlist, setNullWatchlist] =
    useState<Watchlist>(defaultNullWatchlist);

  const save = useCallback(async () => {
    if (settingsSaveLoading) {
      return;
    }

    setSettingsSaveLoading(true);
    const saveResponse = await saveAlertsSettings([
      ...watchlists,
      nullWatchlist,
    ]);

    setSettingsSaveLoading(false);

    if (saveResponse.error) {
      openToast({
        message: `Error saving alerts settings. Please try again.`,
        type: 'error',
      });
    } else {
      if (Array.isArray(saveResponse.watchlists)) {
        setGlobalWatchlists(saveResponse.watchlists);
      }
      openToast({
        message: `Successfully saved alerts settings.`,
        type: 'success',
      });
      if (onClose != null) {
        onClose();
      }
    }
  }, [watchlists, settingsSaveLoading, globalWatchlists, nullWatchlist]);

  const onCloseModal = useCallback(() => {
    setWatchlists(globalWatchlists ?? []);
    if (onClose != null) {
      onClose();
    }
  }, [globalWatchlists]);

  useEffect(() => {
    if (onChange != null) {
      onChange([...watchlists, nullWatchlist]);
    }
  }, [watchlists, nullWatchlist]);

  useEffect(() => {
    if (modal && !open && globalWatchlists != null) {
      setWatchlists(globalWatchlists);
    }
  }, [modal, open, globalWatchlists]);

  const changeAlertAudio = (
    watchlist: Watchlist,
    index: number,
    playAudio: boolean,
  ) => {
    if (watchlist.id === nullWatchlist.id) {
      return setNullWatchlist({
        ...watchlist,
        soundDisabled: !playAudio,
      });
    }

    setWatchlists(
      arraySpliceCopy(watchlists, index, {
        ...watchlist,
        soundDisabled: !playAudio,
      }),
    );
  };

  const changeAlertCategories = (
    watchlist: Watchlist,
    index: number,
    alertCategoriesStrs: string[],
  ) => {
    const alertCategories = alertCategoriesStrs.flatMap(
      (str) => alertCategoryDescriptions[str] ?? [],
    );

    if (watchlist.id === nullWatchlist.id) {
      return setNullWatchlist({
        ...watchlist,
        disabledAlertCategories: difference(
          AllExposedAlertCategories,
          alertCategories,
        ),
      });
    }

    setWatchlists(
      arraySpliceCopy(watchlists, index, {
        ...watchlist,
        disabledAlertCategories: difference(
          AllExposedAlertCategories,
          alertCategories,
        ),
      }),
    );
  };

  const body = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        padding: '10px 20px',
      }}
    >
      {watchlists.map((watchlist, i) => (
        <EditAlertSetting
          key={`${watchlist.name}-${i}`}
          modal={modal}
          watchlist={watchlist}
          changeAlertCategories={(watchlist, strs) =>
            changeAlertCategories(watchlist, i, strs)
          }
          changeAlertAudio={(watchlist, enabled) =>
            changeAlertAudio(watchlist, i, enabled)
          }
        />
      ))}
      <EditAlertSetting
        modal={modal}
        watchlist={nullWatchlist}
        changeAlertCategories={(watchlist, strs) =>
          changeAlertCategories(watchlist, -1, strs)
        }
        changeAlertAudio={(watchlist, enabled) =>
          changeAlertAudio(watchlist, -1, enabled)
        }
      />
    </Box>
  );

  return modal ? (
    <SGModal
      onClose={onCloseModal}
      open={open}
      onSave={save}
      saveLoading={settingsSaveLoading}
      title="Edit Alerts Settings"
      description="Modify your alerts settings to show alerts and play alerts sounds for some or all of your watchlists."
    >
      {body}
    </SGModal>
  ) : (
    <Box sx={{ maxWidth: '650px', margin: 'auto' }}>{body}</Box>
  );
};

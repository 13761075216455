import { alpha, useTheme } from '@mui/material/styles';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
  Brush,
  ComposedChart,
  Area,
} from 'recharts';
import { ErrorContent, Loader } from '../../shared';
import { DEFAULT_CHART_MARGINS, DEFAULT_Y_AXIS_STYLES } from 'config';
import {
  currentStatsLookbackState,
  currentStatsState,
  iVolErrorState,
  iVolLoadingState,
  showEconomicEventsState,
  showForwardIVsState,
  showTermStructureIVStatsState,
  termStructureChartDataState,
  termStructureChartZoomConfigState,
  termStructureDataState,
  termStructureDisplayTypeState,
  termStructureSelectedDailyGreeksState,
  termStructureUsedLineColorsState,
  termStructureVisibleChartLinesState,
  usEconomicEventsState,
} from 'states/iVol';
import {
  DAY_IN_MS,
  businessDaysAdd,
  formatAsPercentage,
  getFormattedDateFromUTC,
  getQueryDate,
  roundToStartOfDay,
} from 'util/shared';
import poll from 'util/poll';
import {
  DailyGreeks,
  ExpirationsDisplayType,
  RawGreeksDataMap,
  RawStatsDataMap,
  TermStructure,
} from 'types/impliedVol';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import {
  closestExpirationIdx,
  extractTermStructureData,
  getFilteredGreeksData,
  getNextAvailableColor,
  getSnapshotTime,
  getTermStructureKeyFromLabel,
  getTradeDateDisplayText,
  mergeObjectLists,
} from 'util/iVol';
import { isMobileState, workerState } from 'states/shared';
import dayjs from 'dayjs';
import useBrushZoom from 'hooks/useBrushZoom';
import { DTE_TICK_CONFIG } from 'util/shared/chart';
import { TIMESTAMP_TICK_CONFIG } from 'util/shared/chart';
import { getTicksBrushed } from 'util/shared/chart';
import { getZoomConfigRefArea } from 'util/shared/chart';
import { updateBrushZoomConfig } from 'util/shared/chart';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import { timezoneState } from 'states';
import { Paper, Stack, Typography } from '@mui/material';
import { ETFSymbols, Earnings, EconomicCalendarData, ProductType } from 'types';
import useHomeContent from 'hooks/home/useHomeContent';
import useMacroCalendar from 'hooks/home/useMacroCalendar';
import useAuth from 'hooks/auth/useAuth';
import { getOverrideHeader } from 'util/shared/fetch';

interface TermStructureChartProps {
  selectedSym: string;
}

const TermStructureChart: ForwardRefRenderFunction<
  HTMLDivElement,
  TermStructureChartProps
> = ({ selectedSym }, ref) => {
  const chartRef = useRef(null);
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const { getMacroCalendar } = useMacroCalendar();
  const { getEarningsForSyms } = useHomeContent();
  const worker = useRecoilValue(workerState);
  const loading = useRecoilValue(iVolLoadingState);
  const error = useRecoilValue(iVolErrorState);
  const [zoomConfig, setZoomConfig] = useRecoilState(
    termStructureChartZoomConfigState,
  );
  const [
    termStructureSelectedDailyGreeks,
    setTermStructureSelectedDailyGreeks,
  ] = useRecoilState(termStructureSelectedDailyGreeksState);
  const selectedDisplayType = useRecoilValue(termStructureDisplayTypeState);
  const currentTimezone = useRecoilValue(timezoneState);
  const [visibleChartLines, setVisibleChartLines] = useRecoilState(
    termStructureVisibleChartLinesState,
  );
  const [termStructureChartData, setTermStructureChartData] = useRecoilState(
    termStructureChartDataState,
  );
  const showTermStructureIVStats = useRecoilValue(
    showTermStructureIVStatsState,
  );

  const [usEconomicEvents, setUsEconomicEvents] = useRecoilState(
    usEconomicEventsState,
  );

  const visibleGreeks = useMemo(() => {
    return termStructureSelectedDailyGreeks?.filter((dg: DailyGreeks) =>
      visibleChartLines.includes(dg.key),
    );
  }, [visibleChartLines, termStructureSelectedDailyGreeks]);

  const showForwardIVs = useRecoilValue(showForwardIVsState);

  const [termStructureData, setTermStructureData] = useRecoilState(
    termStructureDataState,
  );

  const availableColors = Object.values(theme.palette.iVol.termStructure);
  const [termStructureUsedLineColors, setTermStructureUsedLineColors] =
    useRecoilState(termStructureUsedLineColorsState);

  const showEconomicEvents = useRecoilValue(showEconomicEventsState);
  const { hasAccessToProduct } = useAuth();
  const hasIVolAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);

  const { getCurrentGreeksData, getDailyGreeksData, getStatisticsData } =
    useImpliedVolatility();

  const [currentStats, setCurrentStats] = useRecoilState(currentStatsState);

  const termStructureSharedKey: 'daysToExpiry' | 'expirationDate' = useMemo(
    () => getTermStructureKeyFromLabel(selectedDisplayType),
    [selectedDisplayType],
  );

  const currentStatsLookback = useRecoilValue(currentStatsLookbackState);

  const { zoomChartConfig } = useBrushZoom<TermStructure>(
    zoomConfig,
    setZoomConfig,
    termStructureSharedKey,
    termStructureChartData,
  );

  useEffect(() => {
    let finalData: TermStructure[] = [];

    if (currentStats) {
      termStructureSelectedDailyGreeks.forEach((datedGreeks) => {
        const updatedVolSkewData: TermStructure[] = extractTermStructureData(
          datedGreeks.data,
          datedGreeks.tradeDate,
          datedGreeks.key,
          currentStats[currentStatsLookback],
        );

        if (visibleChartLines.includes(datedGreeks.key)) {
          finalData = mergeObjectLists(
            finalData,
            updatedVolSkewData,
            termStructureSharedKey,
          );
        }
      });

      setTermStructureData(finalData);
    }
  }, [termStructureSelectedDailyGreeks, visibleChartLines]);

  const handleResponse = useCallback(
    async (response: { data: RawGreeksDataMap }) => {
      if (response?.data != null) {
        const filteredGreeks = getFilteredGreeksData(response?.data);
        const tradeDate = getQueryDate(true);
        const key = tradeDate.format('YYYY-MM-DD');
        setTermStructureSelectedDailyGreeks((currentItems: DailyGreeks[]) => {
          // Find the index of the item with today's key
          const index = currentItems.findIndex((item) => item.key === key);

          // If the item is not found, return the original array
          if (index === -1) {
            return currentItems;
          }

          // Create a new array with the updated item
          const newItem = {
            ...currentItems[index],
            tradeDate,
            data: filteredGreeks,
          };

          // Return a new array with the updated item
          return currentItems.map((item, idx) =>
            idx === index ? newItem : item,
          );
        });
      }
    },
    [setTermStructureSelectedDailyGreeks],
  );

  useEffect(() => {
    return poll(
      worker,
      {
        url: `v1/${
          hasIVolAccess ? 'current_greeks' : 'free_current_greeks'
        }?sym=${encodeURIComponent(selectedSym)}`,
        interval: 10_000,
        buffer: true,
        msgpack: true,
        onResponse: handleResponse,
      },
      getOverrideHeader(),
    );
  }, [worker, hasIVolAccess, handleResponse, selectedSym]);

  // this effect is meant to initialize the chart data with previously added time-frames
  useEffect(() => {
    const fetchData = async () => {
      let finalData: TermStructure[] = [];
      let visibleLines: string[] = [];
      let updatedSelectedDailyGreeks: DailyGreeks[] = [];
      let usedColors: string[] = [];
      const queryDate = getQueryDate(true, currentTimezone);

      // create separate promises to be resolved in parallel for fetching greeks and stats
      const rawGreeksPromises: Promise<RawGreeksDataMap | null>[] =
        termStructureSelectedDailyGreeks.map((dg: DailyGreeks) => {
          const tradeDate = dg.tradeDate.isSame(queryDate, 'day')
            ? queryDate
            : dg.tradeDate;
          const key: string = tradeDate.format('YYYY-MM-DD');

          return tradeDate.isSame(getQueryDate(true, currentTimezone), 'day')
            ? getCurrentGreeksData(selectedSym)
            : getDailyGreeksData(key, selectedSym);
        });

      // grab the resolved greeks and stats data
      const [stats, ...greeks]: [
        RawStatsDataMap | null,
        ...(RawGreeksDataMap | null)[],
      ] = await Promise.all([
        getStatisticsData(selectedSym),
        ...rawGreeksPromises,
      ]);

      // handle UI data states
      termStructureSelectedDailyGreeks.forEach(
        (dg: DailyGreeks, index: number) => {
          // access updated greeks from the previously resolved promises
          const updatedRawGreeks: RawGreeksDataMap | null = greeks[index];
          const tradeDate = dg.tradeDate.isSame(queryDate, 'day')
            ? queryDate
            : dg.tradeDate;
          const key: string = tradeDate.format('YYYY-MM-DD');

          if (updatedRawGreeks && stats) {
            const transformedTermStructureData: TermStructure[] =
              extractTermStructureData(
                updatedRawGreeks,
                tradeDate,
                key,
                stats[currentStatsLookback],
              );

            const color =
              dg.color !== ''
                ? dg.color
                : getNextAvailableColor(
                    termStructureUsedLineColors,
                    availableColors,
                  ) ?? theme.palette.primary.main;

            if (
              visibleChartLines.length === 0 ||
              visibleChartLines.includes(key)
            ) {
              finalData = mergeObjectLists(
                finalData,
                transformedTermStructureData,
                termStructureSharedKey,
              );
              visibleLines = [...visibleLines, key];
            }

            usedColors = [...usedColors, color];
            updatedSelectedDailyGreeks = [
              ...updatedSelectedDailyGreeks,
              {
                tradeDate,
                key,
                color,
                data: updatedRawGreeks,
              },
            ];
          }
        },
      );

      setCurrentStats(stats);
      setVisibleChartLines(visibleLines);
      setTermStructureUsedLineColors(usedColors);
      setTermStructureSelectedDailyGreeks(updatedSelectedDailyGreeks);
      setTermStructureData(finalData);
    };

    fetchData();
  }, [
    getCurrentGreeksData,
    getDailyGreeksData,
    getStatisticsData,
    selectedSym,
  ]);

  useEffect(() => {
    const getCalendarData = async () => {
      let calendarMap: Map<string, string[]> = new Map();

      if (visibleGreeks?.length > 0) {
        const minSnapshotTime = Math.min(
          ...visibleGreeks.map((tsGreeks: DailyGreeks) =>
            getSnapshotTime(tsGreeks.data),
          ),
        );
        if (ETFSymbols.has(selectedSym)) {
          const macroCalendarData: EconomicCalendarData[] =
            await getMacroCalendar(
              dayjs(minSnapshotTime).subtract(1, 'day').format('YYYY-MM-DD'),
              dayjs(minSnapshotTime).add(30, 'day').format('YYYY-MM-DD'),
            );
          const highPriUSData = macroCalendarData.filter(
            (calendarData: any) =>
              calendarData.impact === 'High' && calendarData.country === 'US',
          );
          calendarMap = highPriUSData.reduce((acc, obj) => {
            const dateStr = obj.date.split(' ')[0];
            const existingEvents = acc.get(dateStr) ?? [];
            acc.set(dateStr, existingEvents.concat([obj.event]));

            return acc;
          }, new Map<string, string[]>());
        } else {
          const startDate = dayjs(minSnapshotTime);
          const endDate = businessDaysAdd(getQueryDate(true), 365);
          const earningsCalendarIVol: Earnings[] = await getEarningsForSyms(
            [selectedSym],
            startDate,
            endDate,
          );

          earningsCalendarIVol.forEach((earningsObj: Earnings) => {
            const dateStr = dayjs(earningsObj.day).format('YYYY-MM-DD');
            const existingEvents: string[] = calendarMap.get(dateStr) ?? [];
            // unshift is done here since term structure currently picks the "first" event of the day,
            // so if earnings isn't the first event, it will not get displayed
            existingEvents.unshift(`${earningsObj.sym} Earnings`);
            calendarMap.set(dateStr, existingEvents);
          });
        }
      }

      setUsEconomicEvents(calendarMap);
    };

    getCalendarData();
  }, [
    getEarningsForSyms,
    getMacroCalendar,
    selectedSym,
    termStructureSelectedDailyGreeks,
    visibleGreeks,
    setUsEconomicEvents,
  ]);

  useEffect(() => {
    if (termStructureData) {
      const sortedData: TermStructure[] = [...termStructureData]
        .map((ts: TermStructure) => ({
          ...ts,
          expirationDate: roundToStartOfDay(ts.expirationDate),
        }))
        .sort(
          (ts1: TermStructure, ts2: TermStructure) =>
            ts1[termStructureSharedKey] - ts2[termStructureSharedKey],
        )
        // filter any potential duplicates just in case
        .filter(
          (
            termStructure: TermStructure,
            index: number,
            self: TermStructure[],
          ) =>
            self[index - 1]?.[termStructureSharedKey] !==
            termStructure[termStructureSharedKey],
        );

      const minSnapshotTime = Math.min(
        ...visibleGreeks.map((tsGreeks: DailyGreeks) =>
          getSnapshotTime(tsGreeks.data),
        ),
      );
      const econDates: string[] = Array.from(usEconomicEvents?.keys() ?? [])
        .filter((date) =>
          isWithinSnapshotToFirstExp(minSnapshotTime, date, sortedData ?? []),
        )
        .sort();

      if (
        sortedData.length > 0 &&
        termStructureSharedKey === 'expirationDate' &&
        showEconomicEvents &&
        econDates.length > 0
      ) {
        const firstExp = dayjs(sortedData[0].expirationDate);
        const diffDays = firstExp.diff(econDates[0], 'day');

        sortedData.unshift({
          expirationDate:
            sortedData[0][termStructureSharedKey] - (diffDays + 1) * DAY_IN_MS,
          daysToExpiry: 0, // this number is irrelevant and only set to satisfy typescript, otherwise this entry is not included on "DTE" view
        });
      }

      setTermStructureChartData(sortedData);

      const initialRightIdx =
        !zoomConfig.data || zoomConfig.data.length === 0 || !zoomConfig.rightIdx
          ? closestExpirationIdx(
              sortedData,
              termStructureSelectedDailyGreeks,
              90,
            )
          : undefined;
      updateBrushZoomConfig(
        zoomConfig,
        sortedData,
        setZoomConfig,
        initialRightIdx,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    termStructureSelectedDailyGreeks,
    setTermStructureChartData,
    setZoomConfig,
    termStructureData,
    termStructureSharedKey,
    showEconomicEvents,
    usEconomicEvents,
  ]);

  const isWithinSnapshotToFirstExp = (
    snapshotTime: number,
    eventDate: string,
    sortedData: TermStructure[],
  ): boolean => {
    if (sortedData.length === 0) {
      return false;
    }
    const eventDateObj = dayjs(eventDate);

    const snapshot = dayjs(snapshotTime);
    const firstExp = dayjs(sortedData[0].expirationDate);

    return eventDateObj.isAfter(snapshot) && eventDateObj.isBefore(firstExp);
  };

  const isWithinChartRange = (date: string): boolean => {
    if (termStructureChartData.length === 0) {
      return false;
    }
    const dateObj = dayjs(date);
    const minDate = dayjs(termStructureChartData[0].expirationDate);
    const maxDate = dayjs(
      termStructureChartData[termStructureChartData.length - 1].expirationDate,
    );
    return (
      (dateObj.isSame(minDate, 'day') || dateObj.isAfter(minDate, 'day')) &&
      (dateObj.isSame(maxDate, 'day') || dateObj.isBefore(maxDate, 'day'))
    );
  };

  const ticks = useMemo(() => {
    return getTicksBrushed(
      termStructureChartData.map(
        (termStructure: TermStructure) => termStructure[termStructureSharedKey],
      ),
      zoomConfig,
      termStructureSharedKey === 'expirationDate'
        ? TIMESTAMP_TICK_CONFIG
        : DTE_TICK_CONFIG,
    )?.map((tick: number) =>
      termStructureSharedKey === 'expirationDate'
        ? roundToStartOfDay(tick)
        : tick,
    );
  }, [termStructureChartData, termStructureSharedKey, zoomConfig]);

  const visibleSelectedDailyGreeks = useMemo(
    () =>
      termStructureSelectedDailyGreeks.filter((greeks: DailyGreeks) =>
        visibleChartLines.includes(greeks.key),
      ),
    [termStructureSelectedDailyGreeks, visibleChartLines],
  );

  const chartTooltipShadow = `${theme.palette.background.paper} 1px 1px 4px, ${theme.palette.background.paper} 1px -1px 4px, ${theme.palette.background.paper} -1px 1px 4px, ${theme.palette.background.paper} -1px -1px 4px, ${theme.palette.background.paper} 2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px, ${theme.palette.background.paper} -2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px`;

  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            gap: '6px',
            color: theme.palette.text.primary,
            border: 'none',
            backgroundColor: alpha(theme.palette.background.paper, 0.85),
            boxShadow: theme.palette.shadows.paperBoxShadow,
            width: '275px',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: '12px',
              }}
            >
              {selectedDisplayType}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                ? getFormattedDateFromUTC(label)
                : label}
            </Typography>
          </Stack>
          {selectedDisplayType === ExpirationsDisplayType.ExpirationDate &&
            showEconomicEvents &&
            usEconomicEvents?.has(getFormattedDateFromUTC(label)) && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    width: '50%',
                    color: theme.palette.gray,
                  }}
                >
                  Key Event
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textAlign: 'end',
                    color: theme.palette.gray,
                  }}
                >
                  {usEconomicEvents.get(getFormattedDateFromUTC(label))?.[0]}
                </Typography>
              </Stack>
            )}

          {payload.map((payloadObj: any, index: number) => {
            if (payloadObj.name === 'Bounds') {
              return (
                <Stack
                  width="100%"
                  key={`${payloadObj.value[0]}-${payloadObj.value[1]}-${index}`}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography
                      sx={{
                        color: payloadObj.color,
                        fontSize: '13px',
                        textShadow: chartTooltipShadow,
                      }}
                    >
                      10th pct
                    </Typography>
                    <Typography
                      sx={{
                        color: payloadObj.color,
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textShadow: chartTooltipShadow,
                      }}
                    >
                      {formatAsPercentage(payloadObj.value[0])}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography
                      sx={{
                        color: payloadObj.color,
                        fontSize: '13px',
                        textShadow: chartTooltipShadow,
                      }}
                    >
                      90th pct
                    </Typography>
                    <Typography
                      sx={{
                        color: payloadObj.color,
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textShadow: chartTooltipShadow,
                      }}
                    >
                      {formatAsPercentage(payloadObj.value[1])}
                    </Typography>
                  </Stack>
                </Stack>
              );
            } else {
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  key={`${payloadObj.name}-${payloadObj.value}-${index}`}
                >
                  <Typography
                    sx={{
                      color: payloadObj.color,
                      fontSize: '13px',
                      textShadow: chartTooltipShadow,
                    }}
                  >
                    {payloadObj.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: payloadObj.color,
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textShadow: chartTooltipShadow,
                    }}
                  >
                    {formatAsPercentage(payloadObj.value)}
                  </Typography>
                </Stack>
              );
            }
          })}
        </Paper>
      );
    }

    return null;
  };

  if (!selectedSym || loading) {
    return <Loader isLoading={loading} />;
  }

  if (termStructureChartData.length === 0 && !loading && error != null) {
    return (
      <ErrorContent content="Failed to retrieve the data needed for Term Structure Chart. Please either refresh the page or contact us!" />
    );
  }

  return (
    <ChartWatermarkContainer
      ref={ref ?? null}
      style={{
        flex: 1,
        position: 'relative',
      }}
      size={20}
      offsetX={50}
      offsetY={55}
      sym={selectedSym}
    >
      {!hasIVolAccess && isMobile && (
        <Stack alignItems="center">
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 14,
            }}
          >
            Access limited to <strong>{selectedSym}</strong>
          </Typography>
        </Stack>
      )}

      <ResponsiveContainer>
        <ComposedChart
          ref={chartRef}
          margin={{
            ...DEFAULT_CHART_MARGINS,
            bottom: isMobile ? 40 : 20,
            left: 15,
            right: 30,
          }}
          {...zoomChartConfig}
        >
          <CartesianGrid strokeDasharray="1 10" stroke={theme.palette.gray} />

          <XAxis
            allowDataOverflow
            label={{
              value: selectedDisplayType,
              fontSize: 12,
              offset: 3,
              position: 'insideBottom',
              fontWeight: 600,
            }}
            dataKey={termStructureSharedKey}
            name={selectedDisplayType}
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 10 }}
            type="number"
            tickFormatter={(val) =>
              selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                ? getFormattedDateFromUTC(val)
                : val
            }
            ticks={ticks}
            tickCount={20}
          />
          <Brush
            dataKey={termStructureSharedKey}
            tickFormatter={(val) =>
              selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                ? getFormattedDateFromUTC(val)
                : val
            }
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={25}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          <YAxis
            allowDataOverflow
            yAxisId="left"
            domain={[
              (dataMin: number) => Math.max(dataMin - 0.03, 0),
              (dataMax: number) => dataMax + 0.03,
            ]}
            tick={{ fontSize: 11 }}
            type="number"
            label={{
              ...DEFAULT_Y_AXIS_STYLES,
              offset: 0,
              value: 'Implied Volatility',
            }}
            tickFormatter={(value: number) => formatAsPercentage(value)}
          />

          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            wrapperStyle={{ fontSize: '12px' }}
            height={isMobile ? 60 : 40}
          />

          {visibleSelectedDailyGreeks.length > 0 && (
            <Area
              yAxisId="left"
              legendType="none"
              name="Bounds"
              dataKey={`bounds-${
                visibleSelectedDailyGreeks.reduce((smallest, current) => {
                  return smallest.tradeDate < current.tradeDate
                    ? smallest
                    : current;
                }).key
              }`}
              stroke="none"
              fillOpacity={0.3}
              fill="#8884d8"
              connectNulls
              hide={!showTermStructureIVStats}
            />
          )}

          {visibleSelectedDailyGreeks.map((dailyGreeks: DailyGreeks) => {
            const tradeDate = dailyGreeks.tradeDate;
            const dateStr = dailyGreeks.key;
            const stroke = dailyGreeks.color;
            const fivStroke = alpha(stroke, 0.4);
            const name = getTradeDateDisplayText(tradeDate, currentTimezone);
            return (
              <>
                <Line
                  key={dateStr}
                  yAxisId="left"
                  type="linear"
                  dataKey={dateStr}
                  name={name}
                  stroke={stroke}
                  dot={{ stroke, strokeWidth: 2 }}
                  strokeWidth={3}
                  connectNulls
                />
                {showForwardIVs && (
                  <Line
                    key={`${dateStr} FIV`}
                    yAxisId="left"
                    type="linear"
                    dataKey={`${dateStr}/FIV`}
                    name={`${name} FIV`}
                    stroke={fivStroke}
                    dot={{ stroke, strokeWidth: 2 }}
                    strokeWidth={3}
                    connectNulls
                  />
                )}
              </>
            );
          })}

          {selectedDisplayType === ExpirationsDisplayType.ExpirationDate &&
            showEconomicEvents &&
            Array.from(usEconomicEvents?.entries() ?? []).map(
              ([date, events]: [string, string[]]) => {
                return isWithinChartRange(date) ? (
                  <ReferenceLine
                    x={dayjs(date).valueOf()}
                    isFront
                    stroke="transparent"
                    ifOverflow="visible"
                    yAxisId="left"
                  >
                    <Label
                      position="insideBottom"
                      angle={-90}
                      style={{
                        textAnchor: 'start',
                        fontSize: isMobile ? 10 : 13,
                        fontWeight: 500,
                      }}
                    >
                      {events[0]}
                    </Label>
                  </ReferenceLine>
                ) : null;
              },
            )}
          {getZoomConfigRefArea(zoomConfig, 'left')}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};

export default forwardRef(TermStructureChart);

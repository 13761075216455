import dayjs from 'dayjs';
import * as d3 from 'd3';
import {
  EarningsImpliedMove,
  EarningsPeriodType,
  RawEarningsImpliedMove,
} from 'types/resources';
import { Theme } from '@mui/material/styles/createTheme';

export const getEarningsPeriodLabelText = (period: string) => {
  switch (period) {
    case EarningsPeriodType.BMO:
      return 'Before Market Open';
    case EarningsPeriodType.AMC:
      return 'After Market Close';
    default:
      return 'Unknown';
  }
};

export const getEarningsImpliedMovePercentTicks = (
  elems: EarningsImpliedMove[],
): number[] => {
  const maxIV: number = Math.max(...elems.map((e) => e.implied_move));
  const step = maxIV > 0.05 ? 0.05 : 0.01;
  return d3.range(0, maxIV + step, step);
};

export const getEarningsPeriodFromString = (
  period: string,
): EarningsPeriodType => {
  return (
    EarningsPeriodType[period as keyof typeof EarningsPeriodType] ??
    EarningsPeriodType.OTHER
  );
};

export const getTimeFromEarnings = (
  r: RawEarningsImpliedMove,
  timezone: string,
): string => {
  return r.utc ? dayjs.utc(r.utc).tz(timezone).format('HH:mm') : '';
};

export const getFillColorFromEarningsPeriod = (
  period: EarningsPeriodType,
  theme: Theme,
): string => {
  switch (period) {
    case 'BMO':
      return theme.palette.resources.earnings.bmo;
    case 'AMC':
      return theme.palette.resources.earnings.amc;
    default:
      return theme.palette.resources.earnings.other;
  }
};

import TapeDatagrid from './TapeDatagrid';
import { RecoilState, useRecoilValue } from 'recoil';
import {
  DataGridPremiumProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowScrollEndParams,
  GridSlotsComponentsProps,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import {
  Filter,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedContract,
} from 'types/tape';
import { STREAM_HOST_URL } from 'config';
import { useCallback, useEffect, useState } from 'react';
import poll from 'util/poll';
import useToast from 'hooks/useToast';
import { workerState } from 'states';
import useTapeContracts from 'hooks/optionsFeed/useTapeContracts';
import { TAPE_CONTRACTS_TOTAL_ROWS, TAPE_FETCH_BATCH_SIZE } from 'config/tape';
import {
  dedupeTnsRows,
  getFiltersForPayload,
  getOptionFeedContract,
  getSortedOptionsFeedData,
} from 'util/tape';
import { encodeURIJson, getOverrideHeader } from 'util/shared';

interface ContractDataContainerProps {
  columns: GridColDef[];
  filters: Filter[];
  filterPanelOpenState: RecoilState<boolean>;
  customGridSlotProps?: GridSlotsComponentsProps;
  contractsColumnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  contractsSortModelState: RecoilState<GridSortModel>;
  contractsColumnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  contractsColumnSizingState: RecoilState<OptionsFeedColumnSizes>;
  flowLiveState: RecoilState<boolean>;
}

const ContractDataContainer = ({
  columns,
  filters,
  filterPanelOpenState,
  contractsColumnVisibilityState,
  contractsSortModelState,
  contractsColumnOrderState,
  contractsColumnSizingState,
  flowLiveState,
  customGridSlotProps,
}: ContractDataContainerProps) => {
  const worker = useRecoilValue(workerState);
  const { openToast } = useToast();
  const sortModel = useRecoilValue(contractsSortModelState);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [rowsLimit, setRowsLimit] = useState<number>(TAPE_FETCH_BATCH_SIZE);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [contractData, setContractData] = useState<RawOptionFeedContract[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false); // To prevent multiple concurrent fetches

  const { fetchContracts } = useTapeContracts();

  const handleResponse = useCallback(
    async (response: any) => {
      if (response?.json != null) {
        setContractData((prev) =>
          getSortedOptionsFeedData(
            dedupeTnsRows(response.json.map(getOptionFeedContract), prev),
            sortModel,
          ),
        );
      }
    },
    [sortModel],
  );

  const fetchData = async (
    filters: Filter[],
    sorting: GridSortModel,
    offset: number = 0,
    size: number = TAPE_FETCH_BATCH_SIZE,
  ): Promise<RawOptionFeedContract[]> => {
    let result: RawOptionFeedContract[] = [];
    try {
      setLoading(true);
      setIsFetching(true);

      result = await fetchContracts(filters, sorting, offset, size);
      setHasMore(result.length >= size);
    } catch (err: any) {
      setError(err?.message);
      openToast({
        message: err?.message,
        type: 'error',
        duration: 7000,
      });
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
    return result;
  };

  useEffect(() => {
    const getData = async (filters: Filter[], sorting: GridSortModel) => {
      const results = await fetchData(filters, sorting);
      setContractData(results);
      setRowsLimit(TAPE_FETCH_BATCH_SIZE);
    };

    getData(filters, sortModel); // if filters or sorting changes, refetch from scratch
  }, [filters, sortModel]);

  useEffect(() => {
    return poll(
      worker,
      {
        url: `sg/tns_contracts?filters=${encodeURIJson(
          getFiltersForPayload(filters),
        )}&sorting=${encodeURIJson(sortModel)}&offset=${0}&limit=${rowsLimit}`,
        interval: 30_000, // poll every 30s
        onResponse: handleResponse,
      },
      { host: STREAM_HOST_URL, ...getOverrideHeader() },
    );
  }, [worker, filters, sortModel, rowsLimit, handleResponse]);

  const handleOnRowsScrollEnd = useCallback<
    NonNullable<DataGridPremiumProps['onRowsScrollEnd']>
  >(
    async (_params: GridRowScrollEndParams) => {
      if (
        isFetching ||
        !hasMore ||
        contractData.length >= TAPE_CONTRACTS_TOTAL_ROWS
      ) {
        return; // Prevent multiple fetches or fetching when no more data or exceeding max rows
      }

      const newData = await fetchData(filters, sortModel, dataOffset);
      setContractData((prev: RawOptionFeedContract[]) => {
        return getSortedOptionsFeedData(prev.concat(newData), sortModel);
      });
      setRowsLimit((prevLimit) => Math.min(prevLimit + newData.length, 1000));
      setDataOffset((prevOffset) => prevOffset + newData.length);
    },
    [filters, contractData, sortModel, dataOffset, isFetching, hasMore],
  );

  return (
    <TapeDatagrid
      rows={contractData}
      columns={columns}
      columnSortModelState={contractsSortModelState}
      filterPanelOpenState={filterPanelOpenState}
      customGridSlotProps={customGridSlotProps}
      flowLiveState={flowLiveState}
      columnVisibilityState={contractsColumnVisibilityState}
      columnOrderState={contractsColumnOrderState}
      columnSizingState={contractsColumnSizingState}
      isError={error != null}
      isLoading={loading}
      onRowsScrollEnd={handleOnRowsScrollEnd}
    />
  );
};

export default ContractDataContainer;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import PollingWorker from 'PollingWorker';
import ReactGA from 'react-ga4';

import { CustomColorModeThemeProvider } from './CustomColorModeThemeProvider';
import { RecoilDebugObserver } from './RecoilDebugObserver';
import { isBloomberg, getEnv } from './util';
import { CookiesProvider } from 'react-cookie';

// eslint-disable-next-line import/no-webpack-loader-syntax
const worker = require('workerize-loader!./PollingWorker');
const workerInstance: PollingWorker = worker();
workerInstance.addEventListener('message', (msg) => {
  // console.debug(msg);
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

if (process.env.REACT_APP_GA_TRACKING_ID != null) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {});
}

const body = (
  <RecoilRoot>
    {getEnv() === 'development' &&
      process.env.REACT_APP_RECOIL_DEBUG === 'true' && <RecoilDebugObserver />}
    <CustomColorModeThemeProvider>
      <BrowserRouter>
        <CookiesProvider>
          <App worker={workerInstance} />
        </CookiesProvider>
      </BrowserRouter>
    </CustomColorModeThemeProvider>
  </RecoilRoot>
);

// TODO: Remove when x-data-grid is fixed
const oldConsoleError = console.error;
console.error = (...args: any[]) => {
  for (let i = 0; i < args.length; i++) {
    if (
      typeof args[i] === 'string' &&
      args[i].includes('MUI: The `theme.spacing` array')
    ) {
      return;
    }
  }

  return oldConsoleError(...args);
};

// StrictMode breaks the app when run on bloomberg
root.render(
  isBloomberg() || process.env.REACT_APP_NO_STRICT_MODE === 'true' ? (
    body
  ) : (
    <React.StrictMode>{body}</React.StrictMode>
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Stack, Typography } from '@mui/material';

interface LegendItem {
  name: string;
  color: string;
}

interface SharedPieLegendProps {
  items: LegendItem[];
}

const SharedPieLegend: React.FC<SharedPieLegendProps> = ({ items }) => {
  return (
    <Stack sx={{ gap: 3, justifyContent: 'center' }}>
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {items.map((item) => (
          <li
            key={item.name}
            style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}
          >
            <span
              style={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: item.color,
                marginRight: '8px',
              }}
            ></span>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {item.name}
            </Typography>
          </li>
        ))}
      </ul>
    </Stack>
  );
};

export default SharedPieLegend;

import { useRecoilState } from 'recoil';
import { SxProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { drawerExpandedState } from 'states';
import { ChartSizingButton } from './ChartSizingButton';

export const BottomDrawerButton = ({
  sx,
  withText,
  onClick,
}: {
  sx?: SxProps;
  withText?: boolean;
  onClick?: () => void;
}) => {
  const [chartExpanded, setChartExpanded] = useRecoilState(drawerExpandedState);

  return (
    <ChartSizingButton
      onClick={() => {
        setChartExpanded(!chartExpanded);
        onClick != null && onClick();
      }}
      icon={chartExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      withText={withText}
      title={chartExpanded ? 'Close Bottom Drawer' : 'Open Bottom Drawer'}
      buttonSx={sx}
    />
  );
};

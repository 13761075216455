import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import useSetSym from 'hooks/hiro/useSetSym';
import { useCallback } from 'react';
import { StockScanner } from './components';
import { useRecoilValue } from 'recoil';
import { freeSymbolState } from '../states';
import { StockScannerWithCompass } from './components/StockScannerWithCompass';
import useAuth from '../hooks/auth/useAuth';

export const StockScannerPage = () => {
  const { canViewCompass } = useAuth();
  const render = useCallback(
    () => (canViewCompass ? <StockScannerWithCompass /> : <StockScanner />),
    [canViewCompass],
  );
  const freeSym = useRecoilValue(freeSymbolState);
  const { getSym } = useSetSym();
  const sym = getSym(ProductType.HIRO);

  return (
    <AccessProtectedPage
      productType={ProductType.SCANNERS}
      renderContent={render}
      // override default page upsell if no sym param is present OR sym is already set to free sym of day
      overrideCheck={!sym || sym === freeSym}
    />
  );
};

import { Page } from './shared/Page';
import { Preferences } from './components/Preferences';

export const PreferencesPage = () => {
  return (
    <Page>
      <Preferences />
    </Page>
  );
};

import {
  Stack,
  alpha,
  Typography,
  ButtonGroup,
  Button,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentStatsLookbackState,
  currentStatsState,
  isMobileState,
} from 'states';
import { GreeksStatsLookback, RawStatsData } from 'types';
import { DEFAULT_STATS_LOOKBACK } from 'util/iVol';

interface StatsLookbackSettingsProps {
  onLookbackChange: (newStats: RawStatsData) => void;
}

const StatsLookbackSettings = ({
  onLookbackChange,
}: StatsLookbackSettingsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const currentStats = useRecoilValue(currentStatsState);

  const [currentStatsLookback, setCurrentStatsLookback] = useRecoilState(
    currentStatsLookbackState,
  );

  const onStatsLookbackChange = useCallback(
    async (period: GreeksStatsLookback) => {
      setCurrentStatsLookback(period);
      const newStatsDataObj: RawStatsData | null =
        currentStats &&
        (currentStats[period] ?? currentStats[DEFAULT_STATS_LOOKBACK]);

      if (newStatsDataObj) {
        onLookbackChange(newStatsDataObj);
      }
    },
    [currentStats, setCurrentStatsLookback, onLookbackChange],
  );

  return (
    <Stack
      gap={1}
      sx={{
        backgroundColor: alpha(theme.palette.gray, 0.1),
        borderRadius: 2,
        padding: '10px',
      }}
    >
      <Typography>Statistics Look Back Period</Typography>
      <ButtonGroup aria-label="stats lookback settings">
        {Object.values(GreeksStatsLookback).map(
          (period: GreeksStatsLookback) => (
            <Button
              size="small"
              key={period}
              sx={{
                backgroundColor:
                  currentStatsLookback === period
                    ? alpha(theme.palette.secondary.main, 0.25)
                    : 'transparent',
                '&:hover': {
                  color: alpha(theme.palette.secondary.main, 1),
                  borderColor: alpha(theme.palette.secondary.main, 1),
                },
                fontSize: isMobile ? 12 : 14,
                textTransform: 'none',
                width: '33.3%',
              }}
              onClick={() => {
                onStatsLookbackChange(period);
              }}
            >
              {period} days
            </Button>
          ),
        )}
      </ButtonGroup>
    </Stack>
  );
};

export default StatsLookbackSettings;

import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { currentToastState } from 'states';
import { ToastData } from 'types';

const useToast = () => {
  const [toast, setToast] = useRecoilState(currentToastState);

  const openToast = useCallback(
    (content: ToastData) => {
      setToast(content);
    },
    [setToast],
  );

  return { openToast, toast };
};

export default useToast;

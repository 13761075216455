import { useCallback } from 'react';
import useLog from '../useLog';

const useZeroDTE = () => {
  const { fetchAPIWithLog } = useLog('useZeroDTE');

  const getZeroDTE = useCallback(
    async (sym?: string, isSentiment?: boolean) => {
      return await fetchAPIWithLog(
        `v1/zeroDTE?sym=${isSentiment ? 'Equity' : sym}`,
      );
    },
    [],
  );

  return { getZeroDTE };
};

export default useZeroDTE;

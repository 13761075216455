import { useCallback } from 'react';
import { ProductType } from '../../types';
import useLog from 'hooks/useLog';
import useAuth from 'hooks/auth/useAuth';

const useHistory = () => {
  const { fetchAPIWithLog } = useLog('useHistory');
  const { hasAccessToProduct } = useAuth();

  const getHistory = useCallback(
    async (sym: string) => {
      const url = hasAccessToProduct(ProductType.EQUITYHUB)
        ? 'v4/historical'
        : 'v1/free_historical';
      return await fetchAPIWithLog(`${url}?sym=${sym}`);
    },
    [hasAccessToProduct],
  );

  return { getHistory };
};

export default useHistory;

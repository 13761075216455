import {
  OCCCustomerOptionType,
  OCCTransactionSize,
  SentimentTab,
} from '../types/sentiment';

export const OCC_CUSTOMER_OPTION_NAME_MAPPING: Record<
  OCCCustomerOptionType,
  string
> = {
  [OCCCustomerOptionType.BUY_CLOSE_CONTRACTS]: 'Buy Open Contracts',
  [OCCCustomerOptionType.BUY_OPEN_CONTRACTS]: 'Buy Close Contracts',
  [OCCCustomerOptionType.BUY_OPEN_PREMIUM]: 'Buy Open Premiums',
  [OCCCustomerOptionType.SELL_CLOSE_CONTRACTS]: 'Sell Open Contracts',
  [OCCCustomerOptionType.SELL_OPEN_CONTRACTS]: 'Sell Close Contracts',
  [OCCCustomerOptionType.SELL_OPEN_PREMIUM]: 'Sell Open Premiums',
  [OCCCustomerOptionType.BUY_TOTAL_CONTRACTS]: 'Total Buy Contracts',
  [OCCCustomerOptionType.BUY_TOTAL_PREMIUM]: 'Total Buy Premiums',
  [OCCCustomerOptionType.SELL_TOTAL_CONTRACTS]: 'Total Sell Contracts',
  [OCCCustomerOptionType.SELL_TOTAL_PREMIUM]: 'Total Sell Premiums',
  [OCCCustomerOptionType.NET_CONTRACTS]: 'Total Net Contracts',
  [OCCCustomerOptionType.NET_PREMIUM]: 'Total Net Premiums',
};

export const SENTIMENT_LABEL_MAPPING = new Map([
  [SentimentTab.OCC, 'Options Clearing Corporation'],
  [SentimentTab.ZeroDTE, '0DTE Equity Volume/OI'],
  [SentimentTab.EquityPutCallRatio, 'Equity Put/Call Ratio'],
  [SentimentTab.ExpirationConcentration, 'Expiration Concentration'],
]);

export const OCC_TRANSACTION_SIZE_NAME_MAPPING: Record<
  OCCTransactionSize,
  string
> = {
  [OCCTransactionSize.ALL]: 'All Trades',
  [OCCTransactionSize.INSTITUTIONAL]: 'Institutional',
  [OCCTransactionSize.RETAIL]: 'Retail',
};

export const SENTIMENT_ZENDESK_MAPPING: Record<SentimentTab, string> = {
  [SentimentTab.OCC]: 'OCC',
  [SentimentTab.ZeroDTE]: '0DTE Equity Volume/OI Sentiment Chart',
  [SentimentTab.EquityPutCallRatio]: 'Equity Put/Call Ratios',
  [SentimentTab.ExpirationConcentration]: 'Expiration Concentration Sentiment',
};

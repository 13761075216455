import {
  alpha,
  Divider,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import QuadrantEditorTitle from './QuadrantEditorTitle';
import QuadrantEditorRow from './QuadrantEditorRow';
import { QuadrantId, QuadrantRowAction, QuadrantTab } from 'types';

interface QuadrantEditorContainerProps {
  title: string;
  tabs: QuadrantTab[];
  action: QuadrantRowAction;
  onRemoveTab?: (tabId: string) => void;
  onAddTab?: (quadrantId: QuadrantId, newTab: QuadrantTab) => void;
  onMoveTab?: (tabId: string, newPosition: number) => void;
  onUpdateTabSym?: (tabId: string, sym: string) => void;
  headerIcon?: JSX.Element;
  sx?: SxProps;
}

export const QuadrantEditorContainer = ({
  title,
  tabs,
  action,
  headerIcon,
  onRemoveTab,
  onAddTab,
  onMoveTab,
  onUpdateTabSym,
  sx,
}: QuadrantEditorContainerProps) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: 4,
        borderRadius: 2,
        ...sx,
      }}
      gap={3}
    >
      <Stack gap={3}>
        <QuadrantEditorTitle title={title} icon={headerIcon} />
        <Divider
          sx={{ borderColor: alpha(theme.palette.text.secondary, 0.25) }}
        />
      </Stack>
      <Stack gap={2}>
        {tabs.length === 0 ? (
          <Typography>No tabs available.</Typography>
        ) : (
          tabs.map((t: QuadrantTab, index: number) => (
            <QuadrantEditorRow
              key={t.id}
              tab={t}
              positionIndex={index}
              action={action}
              onAddTab={onAddTab}
              onRemoveTab={onRemoveTab}
              onMoveTab={onMoveTab}
              onUpdateTabSym={onUpdateTabSym}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};

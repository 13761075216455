import {
  Box,
  ClickAwayListener,
  Collapse,
  IconButton,
  Popper,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { expandedLinkGroupIdState, isSideDrawerExpandedState } from 'states';
import { LinkGroupId } from 'types';
import { SGTooltip } from '../SGTooltip';

interface CollapsibleLinkGroupProps {
  groupId: LinkGroupId;
  title: string;
  icon: JSX.Element;
  children: ReactNode;
}

const CollapsibleLinkGroup = ({
  groupId,
  title,
  icon,
  children,
}: CollapsibleLinkGroupProps) => {
  const theme = useTheme();
  const isDrawerExpanded = useRecoilValue(isSideDrawerExpandedState);
  const [expandedLinkGroupId, setExpandedLinkGroupId] = useRecoilState(
    expandedLinkGroupIdState,
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (isDrawerExpanded) {
      setAnchorEl(null);
    }
  }, [isDrawerExpanded]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
    setExpandedLinkGroupId(groupId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setExpandedLinkGroupId(undefined);
  };

  const isExpanded = groupId === expandedLinkGroupId;

  const renderLinkGroup = (sidebarOpen: boolean) => {
    return sidebarOpen ? (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            cursor: 'pointer',
            justifyContent: 'space-between',
            width: '100%',
            borderRadius: 1,
            padding: 2,
            '&:hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.1),
              transform: 'scale(1.02)',
              transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.short,
              }),
            },
          }}
          onClick={() =>
            setExpandedLinkGroupId(isExpanded ? undefined : groupId)
          }
        >
          <Stack
            direction="row"
            gap={3}
            sx={{ color: theme.palette.text.secondary }}
          >
            {icon}
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {title}
            </Typography>
          </Stack>
          {isExpanded ? (
            <ExpandMoreIcon sx={{ color: theme.palette.text.secondary }} />
          ) : (
            <ChevronRightIcon sx={{ color: theme.palette.text.secondary }} />
          )}
        </Stack>
        <Collapse in={isExpanded} timeout="auto">
          <Stack sx={{ paddingLeft: '28px' }}>{children}</Stack>
        </Collapse>
      </>
    ) : (
      <>
        <SGTooltip
          title={isExpanded ? '' : title}
          placement="right"
          enterDelay={300}
        >
          <IconButton
            onClick={(event) =>
              isExpanded ? handlePopoverClose() : handlePopoverOpen(event)
            }
            sx={{ color: theme.palette.text.secondary }}
          >
            {icon}
          </IconButton>
        </SGTooltip>
        <Popper
          open={isExpanded}
          anchorEl={anchorEl}
          placement="right-start"
          style={{ zIndex: 10001 }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handlePopoverClose}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: theme.palette.background.default,
                marginTop: '10px',
                flexDirection: 'column',
                display: 'flex',
                padding: '16px',
                boxShadow: `0px 0px 4px ${theme.palette.background.default}`,
                border: `2px solid ${alpha(theme.palette.text.primary, 0.08)}`,
                borderTopRightRadius: theme.spacing(3),
                borderBottomRightRadius: theme.spacing(3),
                gap: theme.spacing(4),
              }}
            >
              {children}
            </Box>
          </ClickAwayListener>
        </Popper>
      </>
    );
  };

  return <Stack gap={3}>{renderLinkGroup(isDrawerExpanded)}</Stack>;
};

export default CollapsibleLinkGroup;

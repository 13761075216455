import {
  TraceLense,
  TraceStrikeBarType,
  StrikeBarsDailyTrackerEntry,
} from '../types';
import dayjs from 'dayjs';
import { ComboSymbol } from './hiro';

export const TraceFiltersReadable = new Map([
  [TraceLense.GAMMA, 'Gamma'],
  [TraceLense.DELTA, 'Delta'],
  [TraceLense.DELTA_DIRECTIONAL, 'Delta Pressure'],
  [TraceLense.GAMMA_DIRECTIONAL, 'Gamma Pressure'],
  [TraceLense.GAMMA_COLOR, 'Color (Gamma)'],
  [TraceLense.DELTA_CHARM_DIRECTIONAL, 'Charm Pressure'],
  [TraceLense.DELTA_END_DIFF, 'Delta Relative Close'],
]);

export const TraceFiltersAxisLabels = new Map([
  [TraceLense.GAMMA, 'Gamma ($ Notional)'],
  [TraceLense.DELTA, 'Delta ($ Notional)'],
  [TraceLense.DELTA_DIRECTIONAL, 'Delta ($ Notional)'],
  [TraceLense.GAMMA_DIRECTIONAL, 'Gamma ($ Notional)'],
  [TraceLense.GAMMA_COLOR, 'Gamma ($ Notional)'],
  [TraceLense.DELTA_CHARM_DIRECTIONAL, 'Delta ($ Notional)'],
  [TraceLense.DELTA_END_DIFF, 'Delta ($ Notional)'],
]);

export const TraceFiltersToParamKeys = new Map<
  TraceLense | TraceStrikeBarType,
  string
>([
  [TraceLense.GAMMA, 'gamma'],
  [TraceLense.DELTA, 'delta'],
  [TraceLense.GAMMA_COLOR, 'color'],
  [TraceLense.DELTA_CHARM_DIRECTIONAL, 'charm'],
  [TraceStrikeBarType.OI, 'strike_oi'],
  [TraceStrikeBarType.OI_NET, 'strike_oi'],
  [TraceStrikeBarType.GAMMA, 'strike_gex'],
  [TraceLense.DELTA_DIRECTIONAL, 'rel_delta'],
  [TraceLense.GAMMA_DIRECTIONAL, 'rel_gamma'],
]);

export const TraceFiltersStatsInnerKeys = new Map<
  TraceLense | TraceStrikeBarType,
  string
>([
  [TraceStrikeBarType.OI_NET, 'net'],
  [TraceStrikeBarType.GAMMA, 'gamma'],
]);

export const HEATMAP_FIRST_AVAILABLE_DATE = dayjs('2024-06-07');

export const HEATMAP_DEFAULT_SPX_HIRO_SYM = ComboSymbol.SPX_EQUITIES;

export enum TraceShowChartType {
  StrikeBars,
  Heatmap,
  Both,
}

// order here is how they are displayed
export const PROD_GAMMA_LENSES = [
  TraceLense.GAMMA,
  TraceLense.DELTA_DIRECTIONAL,
  TraceLense.DELTA_CHARM_DIRECTIONAL,
];

export const HEATMAP_TRACE_NAME = 'heatmap';
export const PRICE_CANDLES_TRACE_NAME = 'price';
export const HIRO_TRACE_NAME = 'hiro';

export const DEFAULT_MAX_AGE_PADDING_MS = 10_000; // add 10 secs to max age to ensure there is data to poll
export const DEFAULT_MAX_AGE_MS = 10 * 60 * 1_000; // 5 mins
export const LAST_TRACE_UPDATE_HOUR_ET = 18;

export const TRACE_BAR_TITLES = new Map([
  [TraceStrikeBarType.GAMMA, 'GEX by Strike'],
  [TraceStrikeBarType.OI, 'OI by Strike'],
  [TraceStrikeBarType.OI_NET, 'Net OI by Strike'],
]);

export const StrikeBarTypeLabels = new Map([
  [TraceStrikeBarType.GAMMA, `GEX`],
  [TraceStrikeBarType.OI, 'OI'],
  [TraceStrikeBarType.OI_NET, 'Net OI'],
  [TraceStrikeBarType.NONE, 'None'],
]);

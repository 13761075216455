import { gql } from '@apollo/client';

export const getBSSOTokenQuery = {
  query: gql`
    query {
      bssoToken {
        ... on BssoToken {
          accessToken
        }
      }
    }
  `,
};

export const bssoTokenTest = 'this_is_not_a_real_token_hax0rs';

import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { ColorMode, themeForMode } from '../../theme';
import { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { useReactToPrint } from 'react-to-print';

export const PrintButton = ({
  content,
  onPrintTheme,
}: {
  content: any;
  onPrintTheme: (newTheme: Theme | undefined) => void;
}) => {
  const [printTheme, setPrintTheme] = useState<Theme | undefined>();
  const [printAnchorEl, setPrintAnchorEl] = useState<null | HTMLElement>(null);
  const [printLoading, setPrintLoading] = useState(false);

  const printOpen = Boolean(printAnchorEl);

  const theme = useTheme();

  const handlePrintClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPrintAnchorEl(event.currentTarget);
  };
  const handlePrintClose = () => {
    setPrintAnchorEl(null);
  };

  // useeffect wont work here, theme update needs to be instant to work
  const changePrintTheme = (newTheme: Theme | undefined) => {
    onPrintTheme(newTheme);
    setPrintTheme(newTheme);
  };

  const handlePrint = useReactToPrint({
    content: () => content,
    onAfterPrint: () => {
      setPrintLoading(false);
      changePrintTheme(undefined);
    },
  });

  const print = (colorMode: ColorMode) => {
    handlePrintClose();
    setPrintLoading(true);
    changePrintTheme(themeForMode(colorMode));
  };

  useEffect(() => {
    if (printTheme != null) {
      handlePrint();
      changePrintTheme(undefined);
    }
  }, [printTheme]);

  return (
    <>
      <LoadingButton
        variant="outlined"
        sx={{
          textTransform: 'none',
          fontSize: '12px',
          height: '30px',
        }}
        onClick={handlePrintClick}
        loading={printLoading}
        loadingIndicator={<CircularProgress color="primary" size={16} />}
      >
        Print
      </LoadingButton>
      <Menu
        anchorEl={printAnchorEl}
        open={printOpen}
        onClose={handlePrintClose}
      >
        <MenuItem onClick={() => print(ColorMode.LIGHT)}>Print Light</MenuItem>
        <MenuItem
          onClick={() =>
            print(
              theme.colorMode === ColorMode.COLOR_BLIND
                ? ColorMode.COLOR_BLIND
                : ColorMode.DARK,
            )
          }
        >
          Print Dark
        </MenuItem>
      </Menu>
    </>
  );
};

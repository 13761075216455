import { atom, selector } from 'recoil';
import {
  FoundersNotesIdMap,
  FoundersNoteData,
  FoundersNoteReviewData,
} from '../types/index';

export const foundersNotesState = atom<FoundersNotesIdMap | null>({
  key: 'foundersNotes-notesState',
  default: null,
});

export const foundersNotesLatestPageState = atom<FoundersNoteData[] | null>({
  key: 'foundersNotes-notesLatestPageState',
  default: null,
});

export const foundersNotesSelectedNoteState = atom<FoundersNoteData | null>({
  key: 'foundersNotes-selectedNoteState',
  default: null,
});

export const foundersNotesCurrentPageState = atom<number>({
  key: 'foundersNotes-notesCurrentPageState',
  default: 1,
});

export const isReviewingFoundersNoteState = atom<boolean>({
  key: 'foundersNotes-isReviewingFoundersNote',
  default: false,
});

export const foundersNoteReviewContentKeyState = selector<{
  id: string;
  name: string;
}>({
  key: 'foundersNotes-foundersNoteReviewCurrentContentKey',
  get: ({ get }) => {
    const note = get(foundersNotesSelectedNoteState);
    return {
      id: `${note?.id}` || '',
      name: note?.title || '',
    };
  },
});

export const foundersNotesSelectedNoteReviewState = atom<
  FoundersNoteReviewData | undefined
>({
  key: 'foundersNotes-foundersNotesSelectedNoteReviewState',
  default: undefined,
});

import { Box, Link } from '@mui/material';
import useIntegrations from '../../hooks/integrations/useIntegrations';

export const BookmapIntegration = () => {
  const { getIntegrationsLinksBlock } = useIntegrations();

  return (
    <Box>
      <p>
        <em>Cloud Notes files update automatically each day.</em>
      </p>
      {getIntegrationsLinksBlock('bookmap')}
      <br />

      <p>
        Download the Bookmap Cloud Notes Cheat Sheet&nbsp;
        <Link
          href="https://ac-landing-pages-user-uploads-production.s3.amazonaws.com/0000083509/50de8a9b-b733-4ea7-9f1c-9bef0f062091.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          here
        </Link>
        .
      </p>

      <br />

      <p>
        To install the Cloud Notes, right&nbsp;click&nbsp;on Column &gt; Notes
        &gt; Cloud Notes. Then paste the URL of the FILE into the Cloud Notes
        box, as shown below:
      </p>

      <br />

      <img
        decoding="async"
        width="1024"
        height="549"
        src="https://spotgamma.com/wp-content/uploads/2020/05/bmc-1-1024x549.png"
        alt="Bookmap Cloudnotes featuring SpotGamma Options Gamma Levels"
      ></img>

      <h3 style={{ paddingTop: '2em' }}>
        How to add a Cloud Notes column in Bookmap:
      </h3>

      <span>
        <iframe
          title="Columns - Custom Notes and Cloud Notes"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/IxRefo1jihs?feature=oembed"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </span>
    </Box>
  );
};

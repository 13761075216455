import { useCallback } from 'react';
import dayjs from 'dayjs';
import { Tick } from '../../types';
import useLog from 'hooks/useLog';

export const filterLastDay = (values?: any) => {
  if (values == null) {
    return;
  }
  const days = new Set();
  const ticks = values
    .filter((v: any) => v.close != null)
    .map((v: any) => {
      const datetime = dayjs.tz(v.datetime, 'America/New_York');
      days.add(datetime.format('YYYY-MM-DD'));
      return {
        price: parseFloat(v.close),
        epoch_millis: datetime.valueOf(),
      };
    });
  const daytimes: number[] = [...days.values()].map((ymd: any) =>
    dayjs(ymd).valueOf(),
  );
  const lastDay = Math.max(...daytimes);
  return ticks.filter((v: Tick) => v.epoch_millis >= lastDay);
};

const useTimeSeries = () => {
  const { fetchAPIWithLog } = useLog('useTimeSeries');
  const getTimeSeries = useCallback(
    async (instruments: string[], reversed?: boolean) => {
      const symbol = instruments.map(encodeURIComponent).join(',');
      return await fetchAPIWithLog(
        `v1/twelve_series?symbol=${symbol}&interval=1min&outputsize=390${
          reversed ? `&order=ASC` : ''
        }`,
      );
    },
    [],
  );

  const getLastDaysPrices = useCallback(
    async (syms: string[], ascending?: boolean) => {
      const data = await getTimeSeries(syms, ascending);
      const result: Record<string, Tick[]> = {};
      for (const sym in data) {
        result[sym] = filterLastDay(data[sym]?.values);
      }
      return result;
    },
    [],
  );

  return { getLastDaysPrices, getTimeSeries };
};

export default useTimeSeries;

import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedEquityState } from 'states';
import useLog from '../useLog';
import { ProductType } from 'types/shared';
import useAuth from 'hooks/auth/useAuth';

const useRiskReversal = () => {
  const selectedEquity = useRecoilValue(selectedEquityState);
  const { hasAccessToProduct } = useAuth();
  const hasAccess = hasAccessToProduct(ProductType.EQUITYHUB);
  const { fetchAPIWithLog } = useLog('useRiskRiversal');

  const getRiskReversal = useCallback(async () => {
    if (selectedEquity?.sym == null) {
      return null;
    }
    const sym = encodeURIComponent(selectedEquity!.sym);
    return await fetchAPIWithLog(
      `v1/${!hasAccess ? 'free_' : ''}rr?sym=${sym}`,
    );
  }, [hasAccess, selectedEquity]);

  return { getRiskReversal };
};

export default useRiskReversal;

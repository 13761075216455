import { Dayjs } from 'dayjs';
import { Box, Stack, Typography } from '@mui/material';
import {
  getPremarketSymbols,
  getQueryDate,
  isPreMarket,
  isZerohedge,
  shouldShowWaitingForMarketOpen,
  timeToMarketOpenMs,
} from '../../../util';
import { useRecoilValue } from 'recoil';
import { useEffect, useMemo, useState } from 'react';
import { hiroETHState, isMobileState, screenWidthState } from '../../../states';
import { endQueryDateState } from 'states';
import { HIRO_CHART_UPDATE_DELAY } from '../../../config';
import { ProductType } from 'types';
import useAuth from 'hooks/auth/useAuth';
import useSetSym from '../../../hooks/hiro/useSetSym';

export const HiroChartHeader = () => {
  const [timeToOpen, setTimeToOpen] = useState(timeToMarketOpenMs());
  const [timer, setTimer] = useState<NodeJS.Timer | undefined>(undefined);
  const isMobile = useRecoilValue(isMobileState);
  const endDate: Dayjs = useRecoilValue(endQueryDateState);
  const { productsWithAccess } = useAuth();
  const hasHiroAccess = productsWithAccess.has(ProductType.HIRO);
  const includeHiroETH = useRecoilValue(hiroETHState);
  const { getSym } = useSetSym();
  const screenWidth = useRecoilValue(screenWidthState);
  const condensedDateFormat = isZerohedge() || screenWidth < 1100;

  useEffect(() => {
    if (timer == null && shouldShowWaitingForMarketOpen()) {
      setTimer(setInterval(() => setTimeToOpen(timeToMarketOpenMs()), 1000));
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  useEffect(() => {
    if (
      timeToOpen < -1 * HIRO_CHART_UPDATE_DELAY ||
      (endDate.isSame(getQueryDate(true), 'day') && !isPreMarket())
    ) {
      clearInterval(timer);
      setTimer(undefined);
    }
  }, [timeToOpen]);

  const timeDisplay = new Date(timeToOpen).toISOString().substring(11, 19);
  const today = getQueryDate(true);
  const premarketDisclaimer =
    getPremarketSymbols(ProductType.HIRO).has(getSym(ProductType.HIRO)) &&
    !includeHiroETH
      ? 'Extended hours disabled. '
      : '';

  const { stringDisplay, customSubheader } = useMemo(() => {
    let stringDisplay = undefined;
    let customSubheader = undefined;
    if (endDate.isSame(today, 'day') && isPreMarket() && includeHiroETH) {
      customSubheader = 'Showing Pre-Market';
      stringDisplay = !isMobile && `. Open in ${timeDisplay}`;
    } else if (shouldShowWaitingForMarketOpen()) {
      stringDisplay = `${
        isMobile ? 'Open' : `${premarketDisclaimer}Waiting for Market Open`
      } in ${timeDisplay}`;
    } else if (
      !endDate.isSame(today, 'day') &&
      timeToOpen >= -1 * HIRO_CHART_UPDATE_DELAY
    ) {
      const timeToUpdate = Math.floor(
        (timeToOpen + HIRO_CHART_UPDATE_DELAY) / 1000,
      );
      stringDisplay = `${
        isMobile ? 'Updating in' : `Market Open. Updating chart in `
      } ${timeToUpdate}...`;
    } else if (!hasHiroAccess || isZerohedge()) {
      customSubheader = isZerohedge() ? '15 min delay' : 'Showing 15 min delay';
    }
    return { stringDisplay, customSubheader };
  }, [endDate, hasHiroAccess, isMobile, timeDisplay, timeToOpen, today]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '2px',
      }}
    >
      <Stack>
        <Typography
          variant={isZerohedge() ? 'h4' : 'h2'}
          gutterBottom
          color="text.primary"
          sx={{
            display: 'inline-block',
            minWidth: isMobile ? undefined : '150px',
          }}
        >
          {endDate.format(
            condensedDateFormat ? 'ddd, ll' : 'dddd, MMMM DD, YYYY',
          )}
        </Typography>
        <Box display="flex">
          {!isZerohedge() && stringDisplay != null && (
            <Typography
              color="primary"
              variant={isZerohedge() ? 'h4' : 'h3'}
              gutterBottom
              display="inline"
              paddingY="1px"
            >
              {customSubheader}
              {stringDisplay}
            </Typography>
          )}
        </Box>
      </Stack>
    </div>
  );
};

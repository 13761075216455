import { gql } from '@apollo/client';

export const getWorksheetsQuery = {
  query: gql`
    query {
      worksheets {
        ... on WorksheetConnection {
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            ... on WorksheetEdge {
              node {
                ... on Worksheet {
                  id
                  name
                  isOpen
                  securities {
                    edges {
                      ... on WorksheetSecurityEdge {
                        node {
                          ... on WorksheetSecurity {
                            id
                          }
                        }
                      }
                    }
                    pageInfo {
                      startCursor
                      hasNextPage
                      endCursor
                    }
                  }
                }
              }
            }
          }
          itemCount
        }
      }
    }
  `,
};

export const sampleWorksheetsResponse = {
  worksheets: {
    pageInfo: {
      hasNextPage: false,
      startCursor:
        'MgwIRZfIeSXWtXtGiMG21kHhbzhNwu7H47ONrTVmmbFEo0e6Zb0l7uu3coYNOooQ',
      endCursor:
        'MgwIRZfIeSXWtXtGiMG21kHhbzhNwu7H47ONrTVmmbG9yAT3vdkQkwiRRsWQvpD0',
    },
    edges: [
      {
        node: {
          id: 'worksheet-A',
          name: 'Dow Jones Industrials',
          isOpen: false,
          securities: {
            edges: [
              {
                node: {
                  id: 'AAPL US Equity',
                },
              },
              {
                node: {
                  id: 'AMGN US Equity',
                },
              },
              {
                node: {
                  id: 'AXP US Equity',
                },
              },
              {
                node: {
                  id: 'BA US Equity',
                },
              },
              {
                node: {
                  id: 'CAT US Equity',
                },
              },
              {
                node: {
                  id: 'CRM US Equity',
                },
              },
              {
                node: {
                  id: 'CSCO US Equity',
                },
              },
              {
                node: {
                  id: 'CVX US Equity',
                },
              },
              {
                node: {
                  id: 'DIS US Equity',
                },
              },
              {
                node: {
                  id: 'DOW US Equity',
                },
              },
              {
                node: {
                  id: 'GS US Equity',
                },
              },
              {
                node: {
                  id: 'HD US Equity',
                },
              },
              {
                node: {
                  id: 'HON US Equity',
                },
              },
              {
                node: {
                  id: 'IBM US Equity',
                },
              },
              {
                node: {
                  id: 'INTC US Equity',
                },
              },
              {
                node: {
                  id: 'JNJ US Equity',
                },
              },
              {
                node: {
                  id: 'JPM US Equity',
                },
              },
              {
                node: {
                  id: 'KO US Equity',
                },
              },
              {
                node: {
                  id: 'MCD US Equity',
                },
              },
              {
                node: {
                  id: 'MMM US Equity',
                },
              },
              {
                node: {
                  id: 'MRK US Equity',
                },
              },
              {
                node: {
                  id: 'MSFT US Equity',
                },
              },
              {
                node: {
                  id: 'NKE US Equity',
                },
              },
              {
                node: {
                  id: 'PG US Equity',
                },
              },
              {
                node: {
                  id: 'TRV US Equity',
                },
              },
            ],
            pageInfo: {
              startCursor:
                'MgwIRZfIeSXWtXtGiMG21kErR88k4Uhb0KoGVu15ypsbEbAJtCjbjqrDYUhG7JwW',
              hasNextPage: true,
              endCursor:
                'MgwIRZfIeSXWtXtGiMG21rnXOh4TRdBHj7TTUyHhQfKZHQWR2AY3fH+iJTiY2JNP',
            },
          },
        },
      },
      {
        node: {
          id: 'worksheet-B',
          name: 'World Bonds',
          isOpen: false,
          securities: {
            edges: [
              {
                node: {
                  id: 'CTUSD10Y Govt',
                },
              },
              {
                node: {
                  id: 'CTCAD10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTUSDBR10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTUSDMX10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTGBP10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTFRF10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTDEM10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTITL10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTESP10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTPTE10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTSEK10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTNLG10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTCHF10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTGRD10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTJPY10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTAUD10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTNZD10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTKRW10Y Corp',
                },
              },
              {
                node: {
                  id: 'CTCNY10Y Corp',
                },
              },
            ],
            pageInfo: {
              startCursor:
                'MgwIRZfIeSXWtXtGiMG21pqR1soHFsn0Rxl0+xe6RlU97mjqtPrAy3ODH5a2HfjJ',
              hasNextPage: false,
              endCursor:
                'MgwIRZfIeSXWtXtGiMG21oI07IUkfdY1+T1XufRt75iwF6+Iqqbn2Qm3vjWedXWf',
            },
          },
        },
      },
      {
        node: {
          id: 'worksheet-C',
          name: 'World Equity Indices',
          isOpen: false,
          securities: {
            edges: [
              {
                node: {
                  id: 'INDU Index',
                },
              },
              {
                node: {
                  id: 'SPX Index',
                },
              },
              {
                node: {
                  id: 'CCMP Index',
                },
              },
              {
                node: {
                  id: 'SPTSX Index',
                },
              },
              {
                node: {
                  id: 'MEXBOL Index',
                },
              },
              {
                node: {
                  id: 'IBOV Index',
                },
              },
              {
                node: {
                  id: 'SX5E Index',
                },
              },
              {
                node: {
                  id: 'UKX Index',
                },
              },
              {
                node: {
                  id: 'CAC Index',
                },
              },
              {
                node: {
                  id: 'DAX Index',
                },
              },
              {
                node: {
                  id: 'IBEX Index',
                },
              },
              {
                node: {
                  id: 'FTSEMIB Index',
                },
              },
              {
                node: {
                  id: 'OMX Index',
                },
              },
              {
                node: {
                  id: 'SMI Index',
                },
              },
              {
                node: {
                  id: 'NKY Index',
                },
              },
              {
                node: {
                  id: 'ES1 Index',
                },
              },
              {
                node: {
                  id: 'SHSZ300 Index',
                },
              },
              {
                node: {
                  id: 'AS51 Index',
                },
              },
              {
                node: {
                  id: 'INDU Index',
                },
              },
            ],
            pageInfo: {
              startCursor:
                'MgwIRZfIeSXWtXtGiMG21lDP3yZ4gA2abeNUsoyiuAt4Oj1INFC+M2Vgqh0KkRhQ',
              hasNextPage: false,
              endCursor:
                'MgwIRZfIeSXWtXtGiMG21lDP3yZ4gA2abeNUsoyiuAt4Oj1INFC+M2Vgqh0KkRhQ',
            },
          },
        },
      },
    ],
    itemCount: 3,
  },
};

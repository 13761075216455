export interface RawDarkPoolIndicator {
  dpi: string;
  dpi_volume: string;
  high: string;
  low: string;
  px: string;
  sect: string;
  sym: string;
  total_volume: number;
  trade_date: string;
}

export interface DarkPoolIndicator {
  sym: string;
  sector: string;
  trade_date: string;
  total_volume: number;
  avg_dpi_volume?: number;
  dpi_volume?: number;
  px?: number;
  price?: number;
  high52w?: number;
  low52w?: number;
  avg_dpi?: number;
  dpi?: number;
}

export interface DarkPoolSectorAverage {
  type?: string;
  'Capital Goods': number;
  'Consumer Services': number;
  Technology: number;
  'Health Care': number;
  Transportation: number;
  Energy: number;
  'Basic Industries': number;
  Miscellaneous: number;
  Finance: number;
  'Public Utilities': number;
  'Consumer Non-Durables': number;
  'Consumer Durables': number;
}

export enum DarkPoolScanner {
  BULLISH = 'bullish',
  BEARISH = 'bearish',
}

export interface RawOCC {
  buy_close_contracts: number;
  buy_open_contracts: number;
  buy_open_premium: number;
  buy_total_contracts: number;
  buy_total_premium: number;
  category: string;
  net_contracts: number;
  net_premium: number;
  sell_close_contracts: number;
  sell_open_contracts: number;
  sell_open_premium: number;
  sell_total_contracts: number;
  sell_total_premium: number;
  trade_date: string;
  trans_size: string;
}

export interface OCC {
  'Index - PUTS'?: number;
  'Index - CALLS'?: number;
  'ETF - PUTS'?: number;
  'ETF - CALLS'?: number;
  'Equity - PUTS'?: number;
  'Equity - CALLS'?: number;
  epoch_millis: number;
  price: number;
}

export enum OCCTransactionSize {
  ALL = 'CUST (ALL)',
  RETAIL = 'CUST (1-10)',
  INSTITUTIONAL = 'CUST (50-UP)',
}

export enum OCCCategory {
  INDEX_PUTS = 'Index - PUTS',
  INDEX_CALLS = 'Index - CALLS',
  ETF_PUTS = 'ETF - PUTS',
  ETF_CALLS = 'ETF - CALLS',
  EQUITY_PUTS = 'Equity - PUTS',
  EQUITY_CALLS = 'Equity - CALLS',
}

export const OCCCategories = [
  OCCCategory.EQUITY_CALLS,
  OCCCategory.EQUITY_PUTS,
  OCCCategory.ETF_CALLS,
  OCCCategory.ETF_PUTS,
  OCCCategory.INDEX_CALLS,
  OCCCategory.INDEX_PUTS,
];

export const OCCFields = [...OCCCategories];

export enum OCCCustomerOptionType {
  BUY_OPEN_CONTRACTS = 'buy_open_contracts',
  SELL_OPEN_CONTRACTS = 'sell_open_contracts',
  BUY_CLOSE_CONTRACTS = 'buy_close_contracts',
  SELL_CLOSE_CONTRACTS = 'sell_close_contracts',
  BUY_OPEN_PREMIUM = 'buy_open_premium',
  SELL_OPEN_PREMIUM = 'sell_open_premium',
  BUY_TOTAL_CONTRACTS = 'buy_total_contracts',
  SELL_TOTAL_CONTRACTS = 'sell_total_contracts',
  BUY_TOTAL_PREMIUM = 'buy_total_premium',
  SELL_TOTAL_PREMIUM = 'sell_total_premium',
  NET_CONTRACTS = 'net_contracts',
  NET_PREMIUM = 'net_premium',
}

export type RawEquityPCRatio = {
  trade_date: string;
  vol_ratio: number;
  oi_ratio: number;
};

export type EquityPCRatio = {
  epoch_millis: number;
  vol_ratio: number;
  oi_ratio: number;
};

export enum SentimentTab {
  OCC = 's_occ',
  ZeroDTE = 's_0dte',
  EquityPutCallRatio = 's_equity_pc_ratio',
  ExpirationConcentration = 's_exp_concentration',
}

export const SENTIMENT_TABS: Set<string> = new Set(
  Object.values(SentimentTab).map((s) => s as string),
);

import {
  Box,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  userDetailsState,
  isMobileState,
  loginErrorMessageState,
  loginLoadingState,
} from 'states';
import useAuth from 'hooks/auth/useAuth';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  checkPermissions,
  getAppcues,
  logAppcuesIdentify,
  setHasLoggedIn,
} from '../../util';
import { Location, useNavigate } from 'react-router-dom';
import { PRODUCT_LINKS } from 'config';
import { ProductType } from 'types';
import { createGlobalStyle } from 'styled-components';
import useLog from 'hooks/useLog';
export interface WebLoginProps {
  location?: Location;
}

export const WebLogin = ({ location }: WebLoginProps) => {
  const theme = useTheme();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginLoading, setLoginLoading] = useRecoilState(loginLoadingState);
  const [loginErrorMessage, setLoginErrorMessage] = useRecoilState(
    loginErrorMessageState,
  );
  const userDetails = useRecoilValue(userDetailsState);

  const { getLogin, setTokensAndUserDetails } = useAuth();
  const navigate = useNavigate();
  const isMobile = useRecoilValue(isMobileState);
  const { logError, getTimeSignature } = useLog('Login');

  const DisableMobileZoomStyle = createGlobalStyle`
      input {
        // mobile browsers auto zoom in when font size < 16px. 
        // this zoom is never reset unless the user manually does it
        // when the user logins in and is redirected to the home page, they get a zoomed in view
        // and it is not obvious that they need to unzoom
        font-size: 16px !important; 
      }
  `;

  const handleUsernameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
    },
    [setUsername],
  );

  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    [setPassword],
  );

  const showIPAddressError = useCallback(
    (message: string) => {
      async function fetchIPAndShowErro() {
        const resp = await fetch('https://api.ipify.org?format=json');
        const { ip } = await resp.json();
        setLoginErrorMessage(
          <>
            <div>IP address: {ip}</div>
            <div>{message}</div>
          </>,
        );
      }
      fetchIPAndShowErro();
    },
    [setLoginErrorMessage],
  );

  const handleAuthPayload = useCallback(
    (payload: any, sawLoginScreen: boolean = false) => {
      if (payload?.data?.code != null) {
        setLoginErrorMessage(payload.data.message);
        return setTokensAndUserDetails(null, null);
      } else if (payload?.data?.error != null) {
        let err = payload?.data?.error;
        try {
          err = JSON.parse(err);
        } catch (e) {
          logError(err, 'handleAuthPayload 1');
        }
        if (err.name === 'TokenExpiredError') {
          // Silently log the error, but no need to confuse end-users with this information.
          logError(payload.data.error, 'handleAuthPayload TokenExpiredError');
        } else {
          setLoginErrorMessage(err.message);
        }
        return setTokensAndUserDetails(null, null);
      }
      const { hasAccess, errorMessage } = checkPermissions(payload.data);

      if (errorMessage) {
        setLoginErrorMessage(errorMessage);
      }

      if (!hasAccess) {
        return setTokensAndUserDetails(null, null);
      }
      setTokensAndUserDetails(payload.sgToken, payload.data);
      setHasLoggedIn();
      if (logAppcuesIdentify(payload.data)) {
        // identify must be called before tracking
        getAppcues().track('login', { sawLoginScreen });
      }
      navigate(
        location?.state?.postLoginPath ?? PRODUCT_LINKS[ProductType.HOME].link,
      );
    },
    [navigate, location, setLoginErrorMessage, setTokensAndUserDetails],
  );

  const onLogin = useCallback(() => {
    async function fetchData() {
      setLoginLoading(true);
      const payload: any = await getLogin(username, password);
      if (payload.error != null) {
        let message = typeof payload.error === 'string' ? payload.error : null;
        message ??=
          payload.error_description ??
          'An error has occurred. Are you using the right username and password?';
        if (/IP address/i.test(message)) {
          showIPAddressError(message);
        }
        setLoginErrorMessage(message);
      } else if (payload.sgToken == null) {
        setLoginErrorMessage('Unknown error. Please try again');
      } else {
        handleAuthPayload(payload, true);
      }
      setLoginLoading(false);
    }
    if (username.length === 0) {
      setLoginErrorMessage('Please enter your username');
    } else if (password.length === 0) {
      setLoginErrorMessage('Please enter your password');
    } else {
      fetchData();
    }
    // getLogin is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    username,
    password,
    setLoginErrorMessage,
    setLoginLoading,
    handleAuthPayload,
    showIPAddressError,
  ]);

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        onLogin();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onLogin]);

  if (userDetails != null) {
    navigate(
      location?.state?.postLoginPath ?? PRODUCT_LINKS[ProductType.HOME].link,
    );
  }

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: '0px',
        left: '0px',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {isMobile && <DisableMobileZoomStyle />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <img alt="SpotGamma logo" src="images/logo.png" width="30px" />
        <Box
          className="hoverable"
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '18px',
            color: theme.palette.text.primary,
            marginLeft: '10px',
          }}
        >
          SpotGamma
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.palette.shadows.paperBoxShadow,
          width: '400px',
          height: 'auto',
          padding: '50px',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          gap: '10px',
        }}
      >
        <Box sx={{ mb: '10px' }}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: theme.palette.text.disabled,
              display: 'block',
            }}
          >
            Welcome Back
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Satoshi Complete',
              fontWeight: 700,
              fontSize: '20px',
            }}
          >
            Login to your account
          </Typography>
        </Box>

        {loginErrorMessage != null && (
          <Box
            sx={{
              backgroundColor: 'rgb(22, 11, 11)',
              display: 'flex',
              padding: '8px 16px',
              color: '#aa5555',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mb: '10px',
              borderRadius: '5px',
              border: '1px solid rgb(34, 17, 17)',
            }}
          >
            <ErrorOutlineIcon fontSize="small" />
            <Typography fontSize="12px" color="rgb(244, 199, 199)">
              {loginErrorMessage}
            </Typography>
          </Box>
        )}
        <TextField
          required
          id="login-username"
          label="Email"
          value={username}
          onChange={handleUsernameChange}
          sx={{
            backgroundColor: `${theme.palette.background.paper} !important`,
          }}
        />
        <TextField
          required
          id="login-password"
          label="Password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          sx={{
            backgroundColor: `${theme.palette.background.paper} !important`,
          }}
        />
        <LoadingButton
          type="submit"
          onClick={onLogin}
          loading={loginLoading}
          sx={{
            fontFamily: 'Satoshi Complete',
            fontWeight: 700,
            textTransform: 'capitalize',
          }}
          variant="contained"
          loadingIndicator={<CircularProgress color="info" size={16} />}
        >
          Login
        </LoadingButton>
        <Typography fontSize={11} color="text.secondary" gutterBottom>
          Not currently a SpotGamma subscriber?{' '}
          <Link
            underline="hover"
            color="text.primary"
            href="https://spotgamma.com/subscribe-to-spotgamma/"
          >
            Get Started today!
          </Link>
        </Typography>
        <Link
          underline="hover"
          fontSize={11}
          color="text.disabled"
          href="https://spotgamma.com/login/?action=forgot_password"
        >
          Forgot Password?
        </Link>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          textDecoration: 'none',
          width: '100%',
          padding: '0px 15px',
        }}
      >
        {!isMobile && (
          <Box sx={{ marginBottom: '-3px' }}>{getTimeSignature(false)}</Box>
        )}
        <Box sx={{ display: 'flex', gap: '7px' }}>
          <Link
            href="https://spotgamma.com/terms-and-conditions/"
            variant="body2"
            underline="hover"
            color="#7a7d82"
            sx={{ alignSelf: 'end' }}
          >
            Terms and Conditions
          </Link>
          <Link
            href="https://spotgamma.com/privacy-policy/"
            variant="body2"
            underline="hover"
            color="#7a7d82"
            sx={{ alignSelf: 'end' }}
          >
            Privacy Policy
          </Link>
          <Link
            href="https://spotgamma.com/model-faq/disclaimer/"
            variant="body2"
            underline="hover"
            color="#7a7d82"
            sx={{ alignSelf: 'end' }}
          >
            Disclaimer
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

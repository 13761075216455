import { Dayjs } from 'dayjs';
import { dayjs, ET } from './shared/date';
import { TokenTimeframe, ALPHA_TOKEN_OVERRIDES } from '../config/user';
import { getCachedToken } from './shared/auth';

// find the first matching valid override, or undefined if no matches
export const getTokenOverrideTimeframe = (time: Dayjs) =>
  ALPHA_TOKEN_OVERRIDES.find((tf) => isInOverrideTimeframe(time, tf));

export const isInOverrideTimeframe = (
  time: Dayjs,
  tokenTimeframe: TokenTimeframe,
) => {
  return time.isBetween(tokenTimeframe.minDate, tokenTimeframe.maxDate);
};

export const isValidTokenOverrideTimeframe = () => {
  const nowET = dayjs().tz(ET);

  return ALPHA_TOKEN_OVERRIDES.some((tokenTimeframe) =>
    isInOverrideTimeframe(nowET, tokenTimeframe),
  );
};

export const getOverrideToken = () => {
  const nowET = dayjs().tz(ET);

  const validOverride = ALPHA_TOKEN_OVERRIDES.find((tokenTimeframe) =>
    isInOverrideTimeframe(nowET, tokenTimeframe),
  );

  if (validOverride != null) {
    return validOverride.token;
  }

  return null;
};

export const getUserOverrideToken = (hasAlphaAccess = false) => {
  if (hasAlphaAccess) {
    return getCachedToken(); // No need for the special token since user already has alpha access
  }

  // Falls outside the valid timeframe so return the regular user cached token if any
  return getOverrideToken() ?? getCachedToken();
};

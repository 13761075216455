import { IVolTab } from 'types';

export const IVOL_UPSELL = {
  title: 'Unlock Unlimited Implied Volatility Access',
  subtitle: "With a SpotGamma Alpha subscription, you'll get:",
  items: [
    'Real-time charts incorporating all options trades',
    'Personalized watchlist and trending names',
    'Highly actionable alerts for options and equity traders',
  ],
};

export const IVOL_APPCUES = {
  [IVolTab.FixedStrikeMatrix]: '1506c18a-ddab-4b9b-9b8a-4d3057653fb1',
  [IVolTab.TermStructure]: '0df4712b-7bed-4656-88f3-1fa779d3a156',
  [IVolTab.VolSkew]: 'dd93bfb8-8298-451c-bdce-3afec2302957',
};

import {
  ButtonGroup,
  FormControlLabel,
  Stack,
  Switch,
  Box,
} from '@mui/material';
import { HiroLense, HiroUpdatingType } from '../../../../types';
import { LENSE_LABELS, LenseToCfg } from '../../../../config';
import { useRecoilValue } from 'recoil';
import {
  lenseState,
  optionTypeState,
  candleDurationState,
  hiroUpdatingTypeState,
} from '../../../../states';
import Button from '@mui/material/Button';
import useUserDetails from '../../../../hooks/user/useUserDetails';
import { SGTooltip } from '../../../core';
import { SGSelect } from '../../../shared';

type HiroInlineControlsProps = {
  isInline: boolean;
};

const LENSE_LABELS_TO_TOOLTIP = {
  [HiroLense.All]: 'Show all options trades',
  [HiroLense.NextExp]:
    'Show options trades for the next available expiration for this symbol',
  [HiroLense.Retail]: 'Show options trades we think are from retail investors',
};

export const HiroInlineControls = ({ isInline }: HiroInlineControlsProps) => {
  const selectedLenses = useRecoilValue(lenseState);
  const optionType = useRecoilValue(optionTypeState);
  const candleDuration = useRecoilValue(candleDurationState);
  const hiroUpdatingType = useRecoilValue(hiroUpdatingTypeState);

  const { changeHiroChartSetting } = useUserDetails();

  const putCallButton = (
    <SGTooltip
      title="Show combined HIRO value or split by puts/calls"
      placement="top"
    >
      <ButtonGroup
        variant="outlined"
        aria-label="outlined secondary button group"
        sx={{
          alignSelf: 'center',
          height: '28px',
          width: '96px',
        }}
      >
        <Button
          sx={{
            fontFamily: 'SF Pro Display',
            fontSize: 12,
            textTransform: 'capitalize',
            backgroundColor:
              optionType === 'total'
                ? 'rgba(24, 189, 172, 0.25)'
                : 'transparent',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          onClick={() => {
            changeHiroChartSetting({ optionType: 'total' });
          }}
        >
          Total
        </Button>
        <Button
          sx={{
            fontFamily: 'SF Pro Display',
            fontSize: 12,
            textTransform: 'capitalize',
            backgroundColor:
              optionType === 'pc' ? 'rgba(24, 189, 172, 0.25)' : 'transparent',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          onClick={() => {
            changeHiroChartSetting({ optionType: 'pc' });
          }}
        >
          Put/Call
        </Button>
      </ButtonGroup>
    </SGTooltip>
  );

  const lenses = isInline
    ? [HiroLense.All, HiroLense.NextExp]
    : Object.values(HiroLense);
  const controlSlotProps = {
    typography: {
      fontSize: '12px',
    },
  };

  return (
    <Box
      sx={{
        marginRight: isInline ? '3px' : undefined,
      }}
    >
      {!isInline && putCallButton}
      <Stack direction="row" sx={{ marginTop: isInline ? '-5px' : '10px' }}>
        {isInline && putCallButton}
        {lenses.map((lense) => {
          return (
            <SGTooltip
              title={LENSE_LABELS_TO_TOOLTIP[lense] ?? ''}
              placement="top"
              key={lense}
            >
              <FormControlLabel
                slotProps={controlSlotProps}
                value="bottom"
                sx={{
                  marginLeft: '2px',
                  marginRight: '2px',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
                control={
                  <Switch
                    size="small"
                    color={LenseToCfg[lense].check}
                    checked={selectedLenses[lense] === 1}
                    onChange={(e) => {
                      const newSelectedLenses = {
                        ...selectedLenses,
                        [lense]: e.target.checked ? 1 : 0,
                      };
                      changeHiroChartSetting({
                        selectedLenses: newSelectedLenses,
                      });
                    }}
                  />
                }
                label={LENSE_LABELS[lense]}
                labelPlacement="end"
              />
            </SGTooltip>
          );
        })}
        {hiroUpdatingType === HiroUpdatingType.STREAMING && (
          <SGSelect
            key={'candle_duration'}
            label={'Candle Duration'}
            multiple={false}
            value={candleDuration}
            setter={(newVal: number) =>
              changeHiroChartSetting({
                candleDurationSecs: newVal,
              })
            }
            options={[5, 30, 60, 300]}
            optionsTextTransform={(duration: any) =>
              duration >= 60 ? `${duration / 60}m` : `${duration}s`
            }
            tooltipTransform={(duration: any) =>
              `Set the duration of each HIRO candle to ${
                duration >= 60
                  ? `${duration / 60} minute${duration <= 60 ? '' : 's'}`
                  : `${duration} seconds`
              }`
            }
            sx={{
              width: '43px',
              height: '27px',
              textAlign: 'center',
              alignSelf: 'center',
              '.MuiSelect-select': {
                paddingLeft: '6px !important',
                paddingRight: '15px !important',
              },
              marginLeft: '5px',
            }}
            iconSx={{ marginRight: '-8px !important' }}
            selectTooltipProps={{
              placement: 'bottom',
              title: 'Customize the duration of each HIRO candle.',
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

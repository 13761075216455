import React, { useState } from 'react';
import {
  Collapse,
  Stack,
  Typography,
  useTheme,
  IconButton,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

// Styled component to rotate the ExpandMoreIcon when expanded
const ExpandMore = styled(
  (props: { expand: boolean } & React.ComponentProps<typeof IconButton>) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  },
)(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ConjunctionFilterSelectorProps {
  title: string;
  children: JSX.Element;
}

const GroupedFiltersSection: React.FC<ConjunctionFilterSelectorProps> = ({
  title,
  children,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(true);

  const handleToggleCollapse = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Stack sx={{ gap: 1, width: '100%' }}>
      {/* Header with Title and Toggle Button */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleToggleCollapse}
        aria-expanded={open}
        aria-controls={`filter-content-${title}`}
      >
        <Typography
          sx={{
            textTransform: 'capitalize',
            color: theme.palette.text.primary,
            fontSize: 12,
            fontWeight: 'medium',
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
        <ExpandMore
          color="primary"
          expand={open}
          aria-label={open ? 'Collapse filters' : 'Expand filters'}
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the collapse when clicking the button
            handleToggleCollapse();
          }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>

      {/* Collapsible Section */}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>{children}</Box>
      </Collapse>
    </Stack>
  );
};

export default GroupedFiltersSection;

import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { sgTooltipsState } from '../states';
import useHomeContent from './home/useHomeContent';
import { processTooltips } from '../util';
import { TooltipDefinition } from '../components/admin/TooltipEditor';

const useTooltips = () => {
  const [sgTooltips, setSgTooltips] = useRecoilState(sgTooltipsState);
  const { getTooltips } = useHomeContent();

  const loadTooltips = useCallback(async () => {
    if (sgTooltips.length === 0) {
      const tooltipsResponse = await getTooltips();
      setSgTooltips(
        processTooltips(
          (tooltipsResponse?.content ?? []) as TooltipDefinition[],
        ),
      );
    }
    return sgTooltips;
  }, []);

  return { loadTooltips };
};

export default useTooltips;

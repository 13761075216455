import {
  Stack,
  Box,
  useTheme,
  SxProps,
  Typography,
  Button,
} from '@mui/material';
import { SetterOrUpdater, useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HomeQuadrantTabId, QuadrantTab } from 'types';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabPanel } from '@mui/lab';
import { Tabs } from 'components';
import ContentWrapper from './ContentWrapper';

interface QuadrantProps {
  tabs: QuadrantTab[];
  activeTabId?: string;
  setEditorOpen: SetterOrUpdater<boolean>;
  setActiveTabId: (id: string) => void;
  sx?: SxProps;
  onSymChange?: (newSym: string, tabId: string) => Promise<void>;
  symChangeLoadingMap?: Map<string, boolean>;
}

const Quadrant = ({
  tabs,
  activeTabId,
  setActiveTabId,
  setEditorOpen,
  symChangeLoadingMap,
  onSymChange,
  sx,
}: QuadrantProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: HomeQuadrantTabId,
  ) => {
    setActiveTabId(newValue);
  };

  const tabOptions =
    tabs.length > 0
      ? tabs.reduce(
          (map, item) =>
            map.set(item.id, `${item.sym ? item.sym + ' ' : ''}${item.label}`),
          new Map<string, string>(),
        )
      : [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: isMobile ? 1 : undefined,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        paddingX: '12px',
        paddingBottom: '8px',
        height: isMobile ? undefined : 'calc(50vh - 85px)',
        minHeight: isMobile ? undefined : '300px',
        ...(sx ?? {}),
      }}
    >
      {activeTabId && tabs.length > 0 ? (
        <TabContext value={activeTabId}>
          <Tabs
            options={tabOptions}
            onChange={handleChange}
            defaultScrollable={true}
            fontSize={isMobile ? '12px' : '14px'}
          />
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              overflow: isMobile ? 'hidden' : 'auto',
            }}
          >
            {tabs.map((t: QuadrantTab) => (
              <TabPanel
                key={t.id}
                value={t.id}
                sx={{ paddingX: 0, paddingY: '8px' }}
              >
                <ContentWrapper
                  tab={t}
                  onSymChange={onSymChange}
                  symChangeLoadingMap={symChangeLoadingMap}
                />
              </TabPanel>
            ))}
          </Box>
        </TabContext>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={3}
          sx={{
            height: '100%',
            paddingX: '12px',
            textAlign: 'center',
          }}
        >
          <InfoOutlinedIcon
            sx={{
              fontSize: 42,
              color: theme.palette.text.secondary,
              opacity: 0.5,
            }}
          />
          <Typography
            sx={{
              fontSize: {
                xs: 18,
                md: 24,
              },
            }}
          >
            This quadrant is empty.
          </Typography>
          <Typography sx={{ color: theme.palette.text.secondary }}>
            You can choose which tabs to display in each quadrant.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.button.default,
              textTransform: 'none',
              color: theme.palette.getContrastText(
                theme.palette.button.default,
              ),
            }}
            startIcon={<CloseIcon />}
            onClick={() => setEditorOpen(true)}
          >
            Customize
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default Quadrant;

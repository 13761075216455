import { useCallback } from 'react';
import useLog from '../useLog';

const useHistorical = () => {
  const { fetchAPIWithLog } = useLog('useHistorical');

  const getHistorical = useCallback(async (sym: string) => {
    return await fetchAPIWithLog(`cboeOCCLevels?sym=${sym}`);
  }, []);

  return { getHistorical };
};

export default useHistorical;

import ReactHtmlParser from 'react-html-parser';
import { Box, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createGlobalStyle } from 'styled-components';
import { Theme } from '@mui/material/styles';

export const SGHtmlGlobalStyles = ({
  theme,
  id,
}: {
  theme?: Theme;
  id?: string;
}) => {
  const siteTheme = useTheme();
  theme = theme ?? siteTheme;
  const Styles = createGlobalStyle`
    .ql-editor img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
    }
    .ql-editor p:has(> img) {
      text-align: center;
    }
    .ql-editor a {
      color: ${theme.palette.primary.main} !important;
    }
    .sg-html-editor .ql-editor img {
      // make images appear smaller in editor so as to make editing easier
      // but make horizontal margin take up full width to ensure no content is added to the sides
      max-width: 50%;
      margin-right: 25%;
      margin-left: 25%;
      padding: 0;
    }
    
    ${id ? `#${id}` : '*'} {
      .founders-note-disclaimer {
        font-size: 11px;
        margin-top: 20px;
        padding: 10px;
        color: ${theme.palette.text.secondary};
      }
      .founders-note-disclaimer a {
        color: ${theme.palette.text.secondary};
      }

    }
  `;
  return <Styles />;
};

export const SGHtmlContent = ({
  html,
  theme,
  sx = {},
}: {
  html?: string | null;
  theme?: Theme;
  sx?: SxProps;
}) => {
  const siteTheme = useTheme();
  theme = theme ?? siteTheme;

  if (html == null) {
    return <></>;
  }

  return (
    <Box
      className="ql-editor"
      width={1}
      height={1}
      sx={{
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        ...sx,
      }}
    >
      <SGHtmlGlobalStyles theme={theme} />
      {ReactHtmlParser(html)}
    </Box>
  );
};

import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { fetchAPI } from '../../util';
import { selectedEquityState } from '../../states/equityhub';
import { ProductType, RawSkewObject } from '../../types';
import useAuth from 'hooks/auth/useAuth';

const useSkew = () => {
  const selectedEquity = useRecoilValue(selectedEquityState);
  const { hasAccessToProduct } = useAuth();
  const hasAccess = hasAccessToProduct(ProductType.EQUITYHUB);

  const getSkew = useCallback(async (): Promise<RawSkewObject | null> => {
    if (selectedEquity?.sym) {
      const url = hasAccess ? 'v2/skew' : 'v2/free_skew';
      return await fetchAPI(`${url}?sym=${selectedEquity?.sym}`);
    }

    return null;
  }, [hasAccess, selectedEquity?.sym]);

  return { getSkew };
};

export default useSkew;

import {
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const link =
  'https://www.tradingview.com/script/dC0gMJQr-SpotGamma-Levels-with-Monday-Open';

export const TradingViewIntegration = () => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Stack gap={4}>
      <Typography
        sx={{
          fontSize: {
            xs: 14,
            md: 16,
          },
        }}
      >
        Add the <Link href={link}>SpotGamma Levels indicator</Link> to your
        chart
      </Typography>
      <Typography variant="h2" sx={{ fontSize: 24, fontWeight: 600 }}>
        How to add SpotGamma Levels to TradingView
      </Typography>
      <span>
        <iframe
          width={isSmUp ? '560' : '300'}
          height={isSmUp ? '315' : '170'}
          src="https://www.youtube.com/embed/WyPmTPuqtVA"
          title="Adding SpotGamma Levels in TradingView"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </span>
      <Box sx={{ marginTop: '2em' }}>
        <img
          decoding="async"
          width={isLgUp ? '1024' : isSmUp ? '520' : '310'}
          height={isLgUp ? '689' : isSmUp ? '290' : '170'}
          src="images/integrations/trading-view-latest.png"
          alt="TrendSpider screenshot"
        />
      </Box>
    </Stack>
  );
};

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ET } from '../../../util';

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const combine = (entry: any, addend: any) => {
  entry.mid_signal += addend.mid_signal;
  entry.stock_price = addend.stock_price;
};

interface Bounds {
  min: number;
  max: number;
}

// For a given set of contiguous HIRO entries, create all the daily Bounds in
// order.  It is structured this way to avoid calling into dayjs (expensive)
// for every entry.  We create the raw UTC timestamps once, and quickly compare
// against them.
function getRTHBounds(entries: any, timezone: any): Bounds[] {
  if (entries?.length < 1) {
    return [];
  }
  const first = entries[0].utc_time;
  const last = entries[entries.length - 1].utc_time;
  const bounds = [];
  for (let d = dayjs.utc(first); d.valueOf() <= last; d = d.add(1, 'day')) {
    bounds.push({
      min: d.tz(ET).hour(9).minute(30).second(0).millisecond(0).valueOf(),
      max: d.tz(ET).hour(16).minute(15).second(0).millisecond(0).valueOf(),
    });
  }
  return bounds;
}

function isRTH(utcTime: number, rthBounds: Bounds[]) {
  return rthBounds.some((b: Bounds) => utcTime <= b.max && utcTime >= b.min);
}

export function convertData(rawData: any, includeETH: any, timezone: any) {
  const hiroData: any = {};
  for (const [lense, entries] of Object.entries(rawData)) {
    let total: any = [];
    const rthBounds = getRTHBounds(entries, timezone);
    hiroData[lense] = { TOT: total, P: [], C: [] };
    for (const e of entries as any) {
      if (!includeETH && !isRTH(e.utc_time, rthBounds)) {
        continue;
      }
      const entry = {
        option_type: e.option_type ?? 'TOT',
        mid_signal: e.mid_signal,
        stock_price: e.stock_price,
        utc_time: e.utc_time,
      };
      hiroData[lense][entry.option_type].push(entry);
      if (total[total.length - 1]?.utc_time === entry.utc_time) {
        combine(total[total.length - 1], entry);
      } else {
        total.push({ ...entry, option_type: null });
      }
    }
  }
  return hiroData;
}

// Destructively ensure increasing timestamps.
// Data comes down from the API layer already sorted by time, BUT
// lightweight-charts demands stricly increasing values.  Adjust our timestamps
// accordingly, adding a small amount of time when timestamps are equal
export function toAscendingTimes(data: any) {
  for (let i = 1; i < data.length; ++i) {
    // Because we may have increased the last element in the previous step
    // (i.e. 3 elements with the same time), check for whether the current
    // element is <= the last (rather than comparing for equality)
    if (data[i].utc_time <= data[i - 1].utc_time) {
      data[i].utc_time = data[i - 1].utc_time + 0.001;
    }
  }
  return data;
}

export default convertData;

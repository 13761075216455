import { Box, Link } from '@mui/material';
import useIntegrations from '../../hooks/integrations/useIntegrations';

export const JigsawIntegration = () => {
  const { getIntegrationsLinksBlock } = useIntegrations();

  return (
    <Box>
      <p>
        Updated: <strong>12/21/23</strong>
      </p>
      {getIntegrationsLinksBlock('jigsaw', ['SPY', 'QQQ', 'RUT'])}
    </Box>
  );
};

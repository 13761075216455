import { BottomDrawerButton } from './BottomDrawerButton';
import { SecondarySidebarButton } from './SecondarySidebarButton';
import { FullScreenButton } from './index';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { IconButton, Popper, Box } from '@mui/material';
import { SGTooltip } from '../core';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useCallback, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';

export const SizeControls = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const theme = useTheme();
  const [anchorSettingsEl, setAnchorSettingsEl] = useState<any>(null);
  const onClickAway = useCallback(() => {
    setOpen(false);
  }, []);
  const isMobile = useRecoilValue(isMobileState);

  const sx = {
    padding: '5px',
    justifyContent: 'flex-start',
  };

  if (isMobile) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label="chart sizing options"
        onClick={(event) => {
          setAnchorSettingsEl(event.currentTarget);
          setOpen(!open);
        }}
        size={'medium'}
        color="primary"
      >
        <SGTooltip title="Chart Sizing Options" placement="top">
          <AspectRatioIcon fontSize="inherit" />
        </SGTooltip>
      </IconButton>
      <Popper
        id="hiro-sizeControls"
        open={open}
        anchorEl={anchorSettingsEl}
        placement="bottom-end"
        sx={{ zIndex: 3 }}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              padding: '10px',
              background: theme.palette.background.paper,
            }}
          >
            <BottomDrawerButton sx={sx} withText={true} onClick={onClickAway} />
            <SecondarySidebarButton
              sx={sx}
              withText={true}
              onClick={onClickAway}
            />
            <FullScreenButton sx={sx} withText={true} onClick={onClickAway} />
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

import { IconButton } from '@mui/material';
import { SetterOrUpdater } from 'recoil';
import { SGTooltip } from '../core';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

interface FoldableContentBtnProps {
  isUnfolded: boolean;
  setIsUnfolded: SetterOrUpdater<boolean>;
}

export const FoldableContentBtn = ({
  isUnfolded,
  setIsUnfolded,
}: FoldableContentBtnProps): JSX.Element => (
  <SGTooltip title={isUnfolded ? 'Collapse' : 'Expand'}>
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        setIsUnfolded((prev) => !prev);
      }}
      size="small"
      color="primary"
    >
      {isUnfolded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
    </IconButton>
  </SGTooltip>
);

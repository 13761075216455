import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from '../types';
import { AlertsList } from './components/AlertsList';
import { useCallback } from 'react';

export const AlertsPage = () => {
  const render = useCallback(() => <AlertsList />, []);

  return (
    <AccessProtectedPage
      productType={ProductType.EQUITYHUB}
      renderContent={render}
    />
  );
};

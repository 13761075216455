import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { isMobileState } from '../../states';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { shadeColor } from '../../util';
import CloseIcon from '@mui/icons-material/Close';

type PageBannerProps = {
  message: string | JSX.Element | null;
  onClick?: () => void;
  type?: PageBannerType;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export enum PageBannerType {
  Info,
  Error,
}

export const PageBanner = ({
  message,
  onClick,
  isOpen,
  setIsOpen,
  type = PageBannerType.Info,
}: PageBannerProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const bg =
    type === PageBannerType.Info
      ? shadeColor(theme.palette.primary.main, -50)
      : shadeColor(theme.palette.error.main, -30);

  if (message == null) {
    return null;
  }

  return (
    <Collapse in={isOpen} unmountOnExit>
      <Box
        sx={{
          width: '100%',
          padding: isMobile ? '10px' : '15px',
          marginBottom: '4px',
          textAlign: 'center',
          background: bg,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          cursor: onClick ? 'pointer' : 'inherit',
          borderRadius: 1,
          position: 'relative',
        }}
        onClick={onClick}
      >
        {typeof message === 'string' ? (
          <Typography
            sx={{
              fontSize: '14px',
              maxWidth: '80%',
              display: 'inline-block',
              color: '#fff',
            }}
          >
            {message}
          </Typography>
        ) : (
          message
        )}

        <IconButton
          aria-label="close"
          sx={{
            color: theme.palette.text.primary,
            position: 'absolute',
            right: isMobile ? '10px' : '30px',
          }}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Collapse>
  );
};

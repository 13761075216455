import { AdminContentCategory, ProductType } from '../types';

export const AdminStrategyCategoryForProductType: Map<
  ProductType,
  AdminContentCategory
> = new Map([
  [ProductType.HIRO, AdminContentCategory.StrategyHiro],
  [ProductType.EQUITYHUB, AdminContentCategory.StrategyEquityhub],
  [ProductType.IMPLIED_VOL, AdminContentCategory.StrategyImpliedVol],
  [ProductType.FOUNDERS_NOTES, AdminContentCategory.StrategyFoundersNotes],
  [ProductType.TAPE, AdminContentCategory.StrategyTape],
]);

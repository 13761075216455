import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentToastState } from 'states';
import {
  foundersNoteReviewContentKeyState,
  foundersNotesSelectedNoteReviewState,
  isReviewingFoundersNoteState,
} from 'states/foundersNotes';
import { FoundersNoteReviewData } from 'types';
import { fetchAPI } from '../../util/shared/fetch';
import useLog from '../useLog';

const useReviewFoundersNotes = () => {
  const setIsReviewing = useSetRecoilState(isReviewingFoundersNoteState);
  const setSelectedNoteReview = useSetRecoilState(
    foundersNotesSelectedNoteReviewState,
  );
  const { name, id } = useRecoilValue(foundersNoteReviewContentKeyState);
  const { fetchAPIWithLog } = useLog('useReviewFoundersNotes');

  const submitReview = useCallback(
    async (rating: number, comments: string) => {
      const payload = {
        id,
        name,
        rating,
        comments,
      };
      return await fetchAPIWithLog(`v1/me/submitReview`, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
    },
    [name, id],
  );

  const getAllReviews = useCallback(async () => {
    const data = await fetchAPIWithLog(`v1/allReviews`);
    if (!data.error) {
      const groupReviewsByNote = data.reduce(
        (
          map: Map<string, FoundersNoteReviewData[]>,
          review: FoundersNoteReviewData,
        ) => {
          const noteId = review.content_name || '';
          const reviews = map.get(noteId) ?? [];
          reviews.push(review);
          return map.set(noteId, reviews);
        },
        new Map<string, FoundersNoteReviewData[]>(),
      );
      return groupReviewsByNote;
    }
    return;
  }, []);

  const onOpenReview = useCallback(async () => {
    setIsReviewing(true);
    const data = await fetchAPIWithLog(`v1/me/review?key=${id}`);
    if (!data.error) {
      setSelectedNoteReview(data as FoundersNoteReviewData);
    } else {
      setSelectedNoteReview(undefined);
    }
  }, [id, setSelectedNoteReview]);

  return {
    submitReview,
    getAllReviews,
    onOpenReview,
  };
};

export default useReviewFoundersNotes;

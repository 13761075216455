import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { Box, Menu, MenuItem, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { screenWidthState } from 'states';

export interface TabbedControlsProps {
  options: (JSX.Element | null)[];
  fixedOptions?: (JSX.Element | null)[];
  sizingOptions?: (JSX.Element | null)[];
}

export const TabbedControls = ({
  options,
  sizingOptions,
  fixedOptions,
}: TabbedControlsProps): JSX.Element => {
  const screenWidth = useRecoilValue(screenWidthState);
  const [mobileOptionsAnchorEl, setMobileOptionsAnchorEl] =
    useState<null | HTMLElement>(null);

  const validOptions = options.filter((o) => o != null);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'center',
        pr: '4px',
        ml: '4px',
      }}
    >
      {fixedOptions}
      {validOptions.length > 1 && screenWidth < 1100 ? (
        <>
          <IconButton
            onClick={(e) => setMobileOptionsAnchorEl(e.currentTarget)}
          >
            <SettingsIcon color="primary" fontSize="small" />
          </IconButton>
          <Menu
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            open={mobileOptionsAnchorEl != null}
            anchorEl={mobileOptionsAnchorEl}
            onClose={() => setMobileOptionsAnchorEl(null)}
          >
            {validOptions.map((o, idx) => (
              <MenuItem
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={`${o?.key}-${o?.type}-${idx}`}
              >
                {o}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        options
      )}
      {sizingOptions}
    </Box>
  );
};

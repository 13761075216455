import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import { OpenInterestContainer } from './components/OpenInterestContainer';
import { useCallback } from 'react';

export const OpenInterestPage = () => {
  return (
    <AccessProtectedPage
      productType={ProductType.INTERNAL_OPEN_INTEREST}
      renderContent={useCallback(
        () => (
          <OpenInterestContainer />
        ),
        [],
      )}
    />
  );
};

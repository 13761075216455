import styled from 'styled-components';

interface TableWrapperProps {
  flex?: string;
  overflowX?: string;
  overflowY?: string;
  width?: string;
}

export const TableWrapper = styled.div<TableWrapperProps>`
  overflow-x: ${(p) => p.overflowX || 'auto'};
  overflow-y: ${(p) => p.overflowY || 'auto'};
  table {
    border-spacing: 0;
    border: none;
    width: 100%;
  }
  flex: ${(p) => p.flex || 'auto'};
`;

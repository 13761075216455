import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Dispatch, useState } from 'react';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { currentToastState } from 'states';
import CloseIcon from '@mui/icons-material/Close';
import {
  Quadrant,
  QuadrantId,
  QuadrantTab,
  QuadrantTabCategory,
  SGSettings,
} from 'types';
import { QuadrantEditorContainer } from './QuadrantEditorContainer';
import TopLeftQuadrantIcon from 'icons/TopLeftQuadrantIcon';
import TopRightQuadrantIcon from 'icons/TopRightQuadrantIcon';
import BottomLeftQuadrantIcon from 'icons/BottomLeftQuadrantIcon';
import BottomRightQuadrantIcon from 'icons/BottomRightQuadrantIcon';
import useUserDetails from 'hooks/user/useUserDetails';

interface QuadrantEditorModalProps {
  title: string;
  allAvailableQuadrantTabs: QuadrantTab[];
  availableContentCategories: QuadrantTabCategory[];
  defaultQuadrantsMap: Map<QuadrantId, Quadrant>;
  quadrants: Map<QuadrantId, Quadrant>;
  setActiveQuadrantTabs: SetterOrUpdater<Map<QuadrantId, string | undefined>>;
  isOpen: boolean;
  setIsOpen: SetterOrUpdater<boolean>;
  settingsSaveKey: keyof SGSettings;
  draftQuadrants: Map<QuadrantId, Quadrant>;
  setDraftQuadrants: Dispatch<React.SetStateAction<Map<QuadrantId, Quadrant>>>;
}

const QuadrantEditorModal = ({
  title,
  isOpen,
  availableContentCategories,
  allAvailableQuadrantTabs,
  defaultQuadrantsMap,
  setIsOpen,
  quadrants,
  draftQuadrants,
  setDraftQuadrants,
  setActiveQuadrantTabs,
  settingsSaveKey,
}: QuadrantEditorModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const setToast = useSetRecoilState(currentToastState);

  const handleClose = () => setIsOpen(false);

  const { saveSgSettings } = useUserDetails();

  const handleQuadrantRowRemoval = (quadrantId: QuadrantId, tabId: string) =>
    setDraftQuadrants((prevQuadrants) =>
      new Map(prevQuadrants).set(quadrantId, {
        ...prevQuadrants.get(quadrantId)!,
        tabs: prevQuadrants
          .get(quadrantId)!
          .tabs.filter((tab) => tab.id !== tabId),
      }),
    );

  const handleQuadrantRowAdd = (
    quadrantId: QuadrantId,
    newTab: QuadrantTab,
  ) => {
    // todo: enhance add
    setDraftQuadrants((prevQuadrants) =>
      new Map(prevQuadrants).set(quadrantId, {
        ...prevQuadrants.get(quadrantId)!,
        tabs: [...prevQuadrants.get(quadrantId)!.tabs, newTab],
      }),
    );
  };

  const handleQuadrantRowSymChange = (
    quadrantId: QuadrantId,
    tabId: string,
    newSym: string,
  ) =>
    setDraftQuadrants((prevQuadrants) =>
      new Map(prevQuadrants).set(quadrantId, {
        ...prevQuadrants.get(quadrantId)!,
        tabs: prevQuadrants
          .get(quadrantId)!
          .tabs.map((tab) =>
            tab.id === tabId ? { ...tab, sym: newSym } : tab,
          ),
      }),
    );

  const handleQuadrantRowMove = (
    quadrantId: QuadrantId,
    tabId: string,
    newPosition: number,
  ) =>
    setDraftQuadrants((prevQuadrants) => {
      const updatedQuadrants = new Map(prevQuadrants);
      const quadrant = updatedQuadrants.get(quadrantId);
      if (quadrant) {
        const { tabs } = quadrant;
        const currentIndex = tabs.findIndex((tab) => tab.id === tabId);
        if (
          currentIndex !== -1 &&
          newPosition >= 0 &&
          newPosition < tabs.length
        ) {
          // Remove the tab from its current position
          const item = tabs[currentIndex];
          // Build a new array with the item moved to the new position
          const newTabs = [
            ...tabs.slice(0, currentIndex),
            ...tabs.slice(currentIndex + 1),
          ];
          newTabs.splice(newPosition, 0, item);

          // Update the quadrant with the newly ordered tabs
          updatedQuadrants.set(quadrantId, { ...quadrant, tabs: newTabs });
        }
      }
      return updatedQuadrants;
    });

  const handleResetToDefault = () => setDraftQuadrants(defaultQuadrantsMap);

  const handleSaveChanges = async () => {
    setIsSaveLoading(true);

    const resp = await saveSgSettings(
      {
        [settingsSaveKey]: Object.fromEntries(draftQuadrants) as {
          [key in QuadrantId]: Quadrant;
        },
      },
      true,
    );

    if (resp?.error != null) {
      setToast({
        message:
          'Something went wrong while saving your changes. Try again or contact us if the issue persists.',
        type: 'error',
        duration: 10_000,
      });
    } else {
      setActiveQuadrantTabs((prevActiveQuadrantTabs) => {
        const activeTabs = new Map(prevActiveQuadrantTabs);
        const newActiveTabs = new Map<QuadrantId, string | undefined>();

        draftQuadrants.forEach((quadrant, quadrantId) => {
          const currentActiveTab = activeTabs.get(quadrantId);
          const tabsExist = quadrant.tabs.length > 0;
          const activeTabExists = quadrant.tabs.some(
            (tab) => tab.id === currentActiveTab,
          );

          if (!tabsExist) {
            // No tabs left in the quadrant
            newActiveTabs.set(quadrantId, undefined);
          } else if (!activeTabExists) {
            // Previously active tab was removed, set first tab as active
            newActiveTabs.set(quadrantId, quadrant.tabs[0].id);
          } else {
            // Active tab still exists, no update needed
            newActiveTabs.set(quadrantId, currentActiveTab);
          }
        });

        return newActiveTabs;
      });
      setIsSaveLoading(false);
      setToast({
        message: 'Successfully saved your changes!',
        type: 'success',
        duration: 5_000,
      });
      handleClose();
    }
  };

  const getIconElementForQuadrant = (quadrantId: QuadrantId) => {
    const style = {
      color: theme.palette.text.secondary,
      fontSize: 24,
    };

    switch (quadrantId) {
      case QuadrantId.TOP_LEFT:
        return <TopLeftQuadrantIcon sx={{ ...style }} />;
      case QuadrantId.TOP_RIGHT:
        return <TopRightQuadrantIcon sx={{ ...style }} />;
      case QuadrantId.BOTTOM_LEFT:
        return <BottomLeftQuadrantIcon sx={{ ...style }} />;
      case QuadrantId.BOTTOM_RIGHT:
        return <BottomRightQuadrantIcon sx={{ ...style }} />;
      default:
        return undefined;
    }
  };

  const modalActionBtns = (
    <>
      <Button
        onClick={handleResetToDefault}
        sx={{
          textTransform: 'none',
          color: theme.palette.text.secondary,
          fontWeight: 300,
          width: {
            xs: '100%',
            md: 'inherit',
          },
        }}
      >
        Reset to default
      </Button>
      <Button
        variant="contained"
        onClick={handleSaveChanges}
        sx={{
          backgroundColor: theme.palette.button.default,
          color: theme.palette.text.primary,
          textTransform: 'none',
          width: {
            xs: '100%',
            md: 'inherit',
          },
          ':hover': {
            backgroundColor: theme.palette.button.hover,
          },
        }}
      >
        {isSaveLoading ? <CircularProgress /> : 'Save'}
      </Button>
    </>
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-quadrant-editor-modal"
      maxWidth="lg"
    >
      <Stack gap={2} sx={{ padding: '16px 24px' }}>
        <Stack direction="row" gap={1} justifyContent="space-between">
          <Typography
            sx={{
              fontSize: {
                xs: 18,
                sm: 24,
              },
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              setDraftQuadrants(quadrants);
              handleClose();
            }}
            sx={{ color: theme.palette.text.secondary }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography
          variant="subtitle1"
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          Select which tabs you want to see in each quadrant.
        </Typography>
      </Stack>

      <DialogContent>
        <Stack gap={8}>
          <Grid container spacing={3}>
            {[...draftQuadrants.entries()].map(([index, quadrant]) => {
              return (
                <Grid key={index} item xs={12} md={6}>
                  <QuadrantEditorContainer
                    headerIcon={getIconElementForQuadrant(index)}
                    title={`Quadrant ${index}`}
                    tabs={quadrant.tabs}
                    action="remove"
                    onRemoveTab={(tabId: string) =>
                      handleQuadrantRowRemoval(index, tabId)
                    }
                    onMoveTab={(tabId: string, newPosition: number) =>
                      handleQuadrantRowMove(index, tabId, newPosition)
                    }
                    onUpdateTabSym={(tabId: string, newSym: string) =>
                      handleQuadrantRowSymChange(index, tabId, newSym)
                    }
                    sx={{
                      minHeight: 220,
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={3}>
            {availableContentCategories.map((category: QuadrantTabCategory) => (
              <Grid key={category} item xs={12} md={6}>
                <QuadrantEditorContainer
                  sx={{
                    backgroundColor: 'transparent',
                  }}
                  title={`Add ${category}`}
                  tabs={allAvailableQuadrantTabs.filter(
                    (t: QuadrantTab) => t.category === category,
                  )}
                  action="add"
                  onAddTab={handleQuadrantRowAdd}
                />
              </Grid>
            ))}
          </Grid>

          {/* This is to bring save and reset action buttons into the body and place them at the bottom on smaller screens to avoid "support" button overlapping */}
          <Stack
            direction="row"
            gap={1}
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
              justifyContent: 'space-between',
              paddingBottom: '70px',
            }}
          >
            {modalActionBtns}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        {modalActionBtns}
      </DialogActions>
    </Dialog>
  );
};

export default QuadrantEditorModal;

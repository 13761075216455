import { AlertColor, SelectChangeEvent } from '@mui/material';

export enum HiroOptionType {
  TOT = 'TOT',
  P = 'P',
  C = 'C',
}

export enum ProductType {
  HIRO = 'hiro',
  EQUITYHUB = 'equityhub',
  SCANNERS = 'scanners',
  INDICES = 'indices',
  FOUNDERS_NOTES = 'founders_notes',
  HOME = 'home',
  INTEGRATIONS = 'integrations',
  IMPLIED_VOL = 'ivol',
  TAPE = 'tape',
  INTERNAL_OPEN_INTEREST = 'oi',
  TRACE = 'trace',
}

export type Label = { name: string; description?: string; icon?: JSX.Element };

export type Level = {
  field: string;
  value: number;
  name: string;
};

export type Gamma = {
  call_gnot: number;
  put_gnot: number;
  price: number;
};

export type Bounds = {
  left: number | string;
  right: number | string;
  leftIdx?: number;
  rightIdx?: number;
};

export type ChartZoomConfig = Bounds & {
  data: any[] | undefined;
  refAreaLeft: number | string;
  refAreaRight: number | string;
  top: number | string;
  bottom: number | string;
};

export type BiaxialChartZoomConfig = ChartZoomConfig & {
  top2: number | string;
  bottom2: number | string;
};

export type BrushZoomConfig = {
  data: any[] | undefined;
  leftIdx: number | undefined;
  refAreaLeft: any;
  rightIdx: number | undefined;
  refAreaRight: any;
};

export enum ToastType {
  SUCCESS = 'success',
}

export type ToastData = {
  message: string;
  type: AlertColor;
  duration?: number;
};

export interface EconomicCalendarData {
  event: string;
  date: string;
  country: string;
  currency: string | null;
  actual: number | null;
  previous: number | null;
  change: number | null;
  changePercentage: number | null;
  estimate: any;
  impact: string | null;
}

export interface EconomicCalendarData {
  event: string;
  date: string;
  country: string;
  currency: string | null;
  actual: number | null;
  previous: number | null;
  change: number | null;
  changePercentage: number | null;
  estimate: any;
  impact: string | null;
}

export interface SuggestionSearchData {
  symbol: string;
  name: string;
}

export type SearchHandlerFunction = (value: string) => void;

export type TimezoneOption = {
  label: string;
  value: string;
};

export enum LinkGroupId {
  Resources = 'Resources',
  Social = 'Social',
  Account = 'Account',
}

export enum StreamType {
  AbsolutePrice = 0x4,

  FILTERED_DELTA = 0x000001,
  ABS_DELTA = 0x000002,
  PRICES = 0x000004,

  // All
  FilteredTotalDelta = 0x08,
  AbsoluteTotalDelta = 0x10,
  FilteredCallDelta = 0x20,
  FilteredPutDelta = 0x40,

  // NextExp
  FilteredNextExpTotalDelta = 0x80,
  AbsoluteNextExpTotalDelta = 0x100,
  FilteredNextExpCallDelta = 0x200,
  FilteredNextExpPutDelta = 0x400,

  // Retail
  FilteredRetailTotalDelta = 0x800,
  AbsoluteRetailTotalDelta = 0x1000,
  FilteredRetailCallDelta = 0x2000,
  FilteredRetailPutDelta = 0x4000,

  // Volume
  UNDERLYING_VOLUME = 0x08000,

  // Full absolute signal (price, bid, ask, & ivol)
  FULL_ABSOLUTE_SIGNAL = 0x10000,
}

export enum QuadrantTabCategory {
  CHART = 'charts',
  TABLE = 'tables',
  TEXT_CONTENT = 'articles',
  LIST = 'lists',
}

export interface QuadrantTab {
  id: string;
  contentId: string;
  label: string;
  category: QuadrantTabCategory;
  icon?: JSX.Element;
  sym?: string;
  symOptions?: string[];
}

export enum QuadrantId {
  TOP_LEFT = '1',
  TOP_RIGHT = '2',
  BOTTOM_LEFT = '3',
  BOTTOM_RIGHT = '4',
}

export interface Quadrant {
  tabs: QuadrantTab[];
}

export type QuadrantRowAction = 'add' | 'remove';

export type SymSelectorSettings = {
  value: string;
  options: string[];
  loading?: boolean;
  onChange: (event: SelectChangeEvent) => void;
};

export enum DataExchangeAgreement {
  INSTITUTIONAL_TRADER = 'institutional_trader',
  NON_BUSINESS_DATA_USAGE = 'data_usage_non_business',
  REGISTERED_BODY = 'is_registered_body',
  ASSET_MANAGER = 'is_asset_manager',
  EXTERNAL_CAPITAL_USE = 'external_capital_usage',
  TRADING_FOR_CORP = 'trading_for_corp',
  IS_PROFIT_SHARING = 'is_profit_sharing',
  BENEFIT_EXCHANGE_FOR_TRADING = 'benefit_exchange_for_trading',
}

export type DataExchangeAgreementMap = Record<
  DataExchangeAgreement,
  boolean | null
>;

export type CustomGridController = {
  elementId: string;
  component: JSX.Element;
};

export interface Earnings {
  companyName: string;
  day: string;
  utc: string;
  sym: string;
  pv: number;
  period: string;
  inHiro: boolean;
  impliedMove: number;
  cv: number;
  confirmed: number;
  activityFactor: number;
}

export interface FetchAuxOptions extends RequestInit {
  local?: boolean;
  maxRetries?: number;
  retryCounter?: number;
  buffer?: boolean;
  host?: string;
}

export interface ComboGroup {
  combo: string;
  shared: number;
  symbols: string[];
  price_sym: string;
}

export interface Manifest {
  combos: ComboGroup[];
  combosSet: Set<string>;
  underlyingsSet: Set<string>;
}

export type ErrorResponse = {
  error: string;
};

type PollAuxResponse = { status: number; headers: any };
export type PollJsonResponse = { json: any } & PollAuxResponse;
export type PollBufferResponse = { data: any } & PollAuxResponse;

export interface PopperInfo {
  label?: string;
  description: string;
}

export type ValueFormat = 'currency' | 'date' | 'number';

import React from 'react';
import {
  alpha,
  Button,
  ButtonGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FilterOption } from 'types/tape';

export interface MultiOptionSelectorProps {
  title: string;
  options: FilterOption[];
  selectedOptions: string | boolean | string[];
  onSelect: (selected: string | boolean | string[]) => void;
  viewOnly?: boolean;
  singleSelect?: boolean;
}

const MultiOptionSelector: React.FC<MultiOptionSelectorProps> = ({
  title,
  options,
  selectedOptions,
  onSelect,
  viewOnly = false,
  singleSelect = false, // Default to false (multi-select mode)
}) => {
  const theme = useTheme();

  const handleOptionClick = (option: string | boolean) => {
    if (viewOnly) {
      return; // Prevent interactions in view-only mode
    }

    if (singleSelect || typeof option === 'boolean') {
      // Single-Select Mode: Toggle selection
      onSelect(selectedOptions === option ? '' : option);
    } else {
      // Multi-Select Mode: Toggle inclusion of the option
      const currentSelected = Array.isArray(selectedOptions)
        ? selectedOptions
        : [];
      onSelect(
        currentSelected.includes(option)
          ? currentSelected.filter((item) => item !== option)
          : [...currentSelected, option],
      );
    }
  };

  const isOptionSelected = (value: string | boolean): boolean => {
    if (value === '') {
      return false;
    } else if (typeof value === 'boolean' || singleSelect) {
      return selectedOptions === value;
    } else {
      return Array.isArray(selectedOptions) && selectedOptions.includes(value);
    }
  };

  return (
    <Stack
      sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', width: '100%' }}
    >
      <Typography
        sx={{
          textTransform: 'uppercase',
          color: theme.palette.text.secondary,
          fontSize: 11,
          minWidth: 60,
          maxWidth: 60,
        }}
      >
        {title}
      </Typography>

      <ButtonGroup
        variant="contained"
        sx={{
          borderRadius: 1,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.primary.main}`,
          width: '100%',
          height: 28,
        }}
      >
        {options.map(({ label, value }) => (
          <Button
            key={label}
            onClick={() => handleOptionClick(value)}
            disabled={viewOnly} // Disable in view-only mode
            sx={{
              flex: 1,
              fontSize: 12,
              backgroundColor: isOptionSelected(value)
                ? theme.palette.button.default
                : 'transparent',
              color: isOptionSelected(value)
                ? viewOnly
                  ? theme.palette.text.disabled // Disabled color
                  : theme.palette.sgGreen
                : viewOnly
                ? theme.palette.text.disabled // Disabled color for non-selected
                : theme.palette.sgGreen,
              '&:hover': {
                backgroundColor: !viewOnly
                  ? theme.palette.button.hover
                  : 'transparent', // No hover effect in view-only mode
              },
              '&.Mui-disabled': {
                backgroundColor: isOptionSelected(value)
                  ? alpha(theme.palette.button.default, 0.5)
                  : 'transparent',
              },
              textTransform: 'capitalize',
              display: 'flex',
              justifyContent: 'center',
              padding: '0 12px',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </Stack>
  );
};

export default MultiOptionSelector;

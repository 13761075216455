import {
  Button,
  ButtonGroup,
  SxProps,
  Theme,
  alpha,
  useTheme,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers-pro';
import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import EditIcon from '@mui/icons-material/Edit';

interface TabButtonProps {
  label: string;
  onClick: () => void;
  isActive: boolean;
  sx?: SxProps<Theme>;
  onEdit?: () => void;
}

const TabButton = ({
  label,
  onClick,
  isActive,
  onEdit,
  sx,
}: TabButtonProps) => {
  const isMobile = useRecoilValue(isMobileState);

  const theme = useTheme();

  const borderStyle = `2px solid ${alpha(theme.palette.button.hover, 0.2)}`;

  const sharedStyles: SxProps = {
    backgroundColor: isActive
      ? theme.palette.background.paper
      : theme.palette.background.default,
    color: isActive ? theme.palette.primary.main : theme.palette.text.secondary,
    fontWeight: isActive ? 'bold' : 400,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
    borderTop: borderStyle,
    borderRight: borderStyle,
    borderLeft: borderStyle,
    textTransform: 'capitalize',
    fontSize: isMobile ? 11 : 16,
    boxShadow: isActive ? theme.palette.shadows.paperBoxShadow : 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.button.hover, 0.25),
      borderTop: borderStyle,
      borderRight: borderStyle,
      borderLeft: borderStyle,
      borderBottom: 0,
    },
  };

  return onEdit ? (
    <ButtonGroup
      sx={{
        '& .MuiButton-root': {
          ...sharedStyles,
        },
      }}
    >
      <Button size="small" onClick={onClick} sx={{ whiteSpace: 'nowrap' }}>
        {label}
      </Button>
      <Button
        size="small"
        aria-label="Edit"
        onClick={onEdit}
        sx={{
          fontSize: isMobile ? 12 : 16,
        }}
      >
        <EditIcon />
      </Button>
    </ButtonGroup>
  ) : (
    <Button
      size="small"
      onClick={onClick}
      sx={{
        minWidth: isMobile ? 38 : 80,
        ...sharedStyles,
        ...sx,
      }}
    >
      {label}
    </Button>
  );
};

export default TabButton;

import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMacroCalendar from 'hooks/home/useMacroCalendar';
import { useEffect, useMemo, useState } from 'react';
import { ImpactType, MacroCalendarEvent } from '../../types';
import dayjs from 'dayjs';
import {
  macroCalendarCountryOptionsState,
  macroCalendarCountriesSettingState,
  macroCalendarImpactsSettingState,
  timezoneState,
} from 'states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Loader } from 'components/shared/Loader';
import { MacroCalendarSettings } from './MacroCalendarSettings';

export const MacroCalendar = () => {
  const theme = useTheme();
  const { getMacroCalendar } = useMacroCalendar();
  const [data, setData] = useState<MacroCalendarEvent[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setCountryOptions = useSetRecoilState(macroCalendarCountryOptionsState);
  const selectedCountries = useRecoilValue(macroCalendarCountriesSettingState);
  const selectedImpacts = useRecoilValue(macroCalendarImpactsSettingState);
  const currentTimezone = useRecoilValue(timezoneState);

  useEffect(() => {
    async function getCalendar() {
      setIsLoading(true);
      let data = await getMacroCalendar();
      const countries = [...data].map((d: MacroCalendarEvent) => d.country);
      setCountryOptions(Array.from(new Set(countries)).sort());
      setData(data);
      setIsLoading(false);
    }
    if (data == null) {
      getCalendar();
    }
  }, [
    getMacroCalendar,
    setIsLoading,
    data,
    setData,
    setCountryOptions,
    selectedCountries,
    selectedImpacts,
  ]);

  const displayImpact = (impact: string) => {
    if (impact === 'Low') {
      return '!';
    } else if (impact === 'Medium') {
      return '!!';
    } else if (impact === 'High') {
      return '!!!';
    } else {
      return '';
    }
  };

  const filteredData = useMemo(() => {
    if (data == null) {
      return [];
    }
    return ([...data] as MacroCalendarEvent[])
      .filter(
        (d) =>
          selectedCountries.includes(d.country) &&
          dayjs.utc(d.date) >= dayjs.utc().hour(0).minute(0).second(0) &&
          (!selectedImpacts.length ||
            selectedImpacts.includes(d.impact as ImpactType)),
      )
      .sort(
        (a: MacroCalendarEvent, b: MacroCalendarEvent) =>
          dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
      );
  }, [data, selectedImpacts, selectedCountries]);

  return (
    <Box sx={{ height: '100%' }}>
      <Loader isLoading={isLoading}>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Stack sx={{ width: '100%' }}>
            {filteredData?.map((d, idx) => {
              const rawDate = dayjs(d.date);
              // if midnight then it's usually a daylong event so keep it as 12:00AM for display
              // otherwise set tz for date to utc to do timezone offset
              let date =
                rawDate.hour() === 0
                  ? rawDate
                  : dayjs.utc(d.date).tz(currentTimezone);
              return (
                <Box sx={{ gap: '2px', my: '6px' }} key={`${d.date}${d.event}`}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        textTransform: 'uppercase',
                        fontSize: '12px',
                      }}
                    >
                      {date.format('MM-DD hh:mm a z')}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.text.disabled,
                        textTransform: 'uppercase',
                        fontSize: '12px',
                      }}
                    >
                      {d.country}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.warning.dark,
                        fontSize: '10px',
                        px: '4px',
                      }}
                    >
                      {displayImpact(d.impact)}
                    </Typography>
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      {d.event}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Stack>
          <MacroCalendarSettings />
        </Stack>
      </Loader>
    </Box>
  );
};

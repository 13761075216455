import { alpha, SelectChangeEvent, useTheme } from '@mui/material';
import {
  ExpandableContentWrapper,
  GammaVannaModelChart,
  GammaLevels,
  ComboStrikesChart,
  TiltChart,
  ExpConcentrationChart,
  ZeroDTEChart,
  OIVolumeChart,
  ExpirationConcentrationTable,
  StrikeConcentrationTable,
} from 'components';
import { EarningsCalendar } from 'components/home/EarningsCalendar';
import { FuturesChart } from 'components/home/FuturesChart';
import { MacroCalendar } from 'components/home/MacroCalendar';
import { HistoricalChart } from 'components/indices/charts/HistoricalChart';
import { MaxRealVolChart } from 'components/indices/charts/MaxRealVolChart';
import { OptionsRiskReversalChart } from 'components/indices/charts/OptionsRiskReversalChart';
import { RealVolHistogramChart } from 'components/indices/charts/RealVolHistogramChart';
import { VolatilityChart } from 'components/indices/charts/VolatilityChart';
import { EarningsChart } from 'components/resources/EarningsChart';
import { EquityPCRatioChart } from 'components/sentiment/EquityPCRatioChart';
import { OCCChart } from 'components/sentiment/OCC';
import {
  GammaVannaModel,
  HomeEventsCalendarTab,
  HomeQuadrantTabId,
  IndexSymbols,
  IndicesContentType,
  QuadrantTab,
  Scanner,
  SentimentTab,
} from 'types';
import { getDefaultSymOptions } from 'util/indices';
import AtSpotgamma from '../HomeTabs/AtSpotgamma';
import IndexLevels from '../HomeTabs/IndexLevels';
import IndexMetrics from '../HomeTabs/IndexMetrics';
import KeySuppResistanceStrikes from '../HomeTabs/KeySuppResistanceStrikes';
import ScannerTable from '../HomeTabs/ScannerTable';
import SGPropietaryLevels from '../HomeTabs/SGPropietaryLevels';
import { ComboSymbol } from 'config';
import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';

interface ContentWrapperProps {
  tab: QuadrantTab;
  onSymChange?: (newSym: string, tabId: string) => Promise<void>;
  symChangeLoadingMap?: Map<string, boolean>;
}

const ContentWrapper = ({
  tab,
  symChangeLoadingMap,
  onSymChange,
}: ContentWrapperProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();

  const defaultChartStyles: React.CSSProperties = {
    padding: '12px',
    borderRadius: '8px',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.35)}`,
    height: isMobile ? '400px' : '100%',
  };

  const defaultContentContainerStyles = {
    backgroundColor: theme.palette.background.default,
    padding: '12px',
    borderRadius: '8px',
  };

  const futuresChart = (
    <ExpandableContentWrapper type={HomeQuadrantTabId.LIVE_PRICES_CHART}>
      <FuturesChart
        selectedSym={tab.sym ?? ComboSymbol.ES_F}
        {...(tab.sym != null &&
          onSymChange != null && {
            symSelectorSettings: {
              value: tab.sym,
              options: [ComboSymbol.ES_F, ...Array.from(IndexSymbols)],
              loading: symChangeLoadingMap?.get(tab.id),
              onChange: (event: SelectChangeEvent) =>
                onSymChange(event.target.value, tab.id),
            },
          })}
      />
    </ExpandableContentWrapper>
  );

  const comboStrikes = (
    <ExpandableContentWrapper type={IndicesContentType.COMBO_STRIKES}>
      <ComboStrikesChart
        chartStyleOverrides={defaultChartStyles}
        containerStyleOverrides={defaultContentContainerStyles}
        selectedSym={tab.sym ?? 'SPX'}
        {...(tab.sym &&
          onSymChange && {
            symSelectorSettings: {
              value: tab.sym,
              options: getDefaultSymOptions(IndicesContentType.COMBO_STRIKES),
              loading: symChangeLoadingMap?.get(tab.id),
              onChange: (event: SelectChangeEvent) =>
                onSymChange(event.target.value, tab.id),
            },
          })}
      />
    </ExpandableContentWrapper>
  );

  const gammaModel = (
    <ExpandableContentWrapper type={IndicesContentType.GAMMA_MODEL}>
      <GammaVannaModelChart
        model={GammaVannaModel.GAMMA}
        chartStyleOverrides={defaultChartStyles}
        containerStyleOverrides={defaultContentContainerStyles}
        selectedSym={tab.sym ?? 'SPX'}
        {...(tab.sym != null &&
          onSymChange != null && {
            symSelectorSettings: {
              value: tab.sym,
              options: getDefaultSymOptions(IndicesContentType.GAMMA_MODEL),
              loading: symChangeLoadingMap?.get(tab.id),
              onChange: (event: SelectChangeEvent) =>
                onSymChange(event.target.value, tab.id),
            },
          })}
      />
    </ExpandableContentWrapper>
  );

  const gammaLevels = (
    <ExpandableContentWrapper type={IndicesContentType.GAMMA_LEVELS}>
      <GammaLevels
        chartStyleOverrides={defaultChartStyles}
        containerStyleOverrides={defaultContentContainerStyles}
        selectedSym={tab.sym ?? 'SPX'}
        {...(tab.sym &&
          onSymChange && {
            symSelectorSettings: {
              value: tab.sym,
              options: getDefaultSymOptions(IndicesContentType.GAMMA_LEVELS),
              loading: symChangeLoadingMap?.get(tab.id),
              onChange: (event: SelectChangeEvent) =>
                onSymChange(event.target.value, tab.id),
            },
          })}
      />
    </ExpandableContentWrapper>
  );

  // NOTE: anytime content keys change here, the the "old" entry should remain here so the older key/value pairing
  // does not break on the ui since keys are fetched from the DB. Content keys should rarely need to be changed.
  const contentForTypeMap = new Map<string, JSX.Element>([
    // old entries
    ['live_es_futures_chart', futuresChart],
    ['combo_strikes_chart', comboStrikes],
    ['gamma_model_chart', gammaModel],
    ['absolute_gamma_chart', gammaLevels],
    // all entries
    [HomeQuadrantTabId.LIVE_PRICES_CHART, futuresChart],
    [IndicesContentType.COMBO_STRIKES, comboStrikes],
    [IndicesContentType.GAMMA_MODEL, gammaModel],
    [IndicesContentType.GAMMA_LEVELS, gammaLevels],
    [HomeQuadrantTabId.INDEX_LEVELS, <IndexLevels />],
    [HomeQuadrantTabId.SG_LEVELS, <SGPropietaryLevels />],
    [HomeQuadrantTabId.INDEX_METRICS, <IndexMetrics />],
    [HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS, <KeySuppResistanceStrikes />],
    [HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR, <MacroCalendar />],
    [HomeQuadrantTabId.EARNINGS, <EarningsCalendar />],
    [
      HomeQuadrantTabId.EARNINGS_CHART,
      <ExpandableContentWrapper
        type={HomeEventsCalendarTab.EARNINGS_CHART}
        styleOverrides={{
          p: 0,
          width: '100%',
          // chart is weird, 100% height makes it extend infinitely, but 98% does not
          height: '98%',
        }}
      >
        <EarningsChart chartOnly={true} />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES,
      <ScannerTable scanner={Scanner.SQUEEZE} />,
    ],
    [
      HomeQuadrantTabId.SCANNER_VRP_CANDIDATES,
      <ScannerTable scanner={Scanner.VOL_RISK_PREMIUM} />,
    ],
    [HomeQuadrantTabId.AT_SPOTGAMMA, <AtSpotgamma />],
    [
      HomeQuadrantTabId.OCC_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.OCC}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <OCCChart />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.ZeroDTE}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <ZeroDTEChart isSentiment={true} selectedSym={tab.sym ?? 'SPX'} />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.EquityPutCallRatio}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <EquityPCRatioChart />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.EXP_CONCENTRATION_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.ExpirationConcentration}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <ExpConcentrationChart selectedSym={tab.sym ?? 'SPX'} />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.DELTA_MODEL, // delta model
      <ExpandableContentWrapper type={IndicesContentType.DELTA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.DELTA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.DELTA_MODEL),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VANNA_MODEL, // vanna model
      <ExpandableContentWrapper type={IndicesContentType.VANNA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.VANNA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.VANNA_MODEL),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.TILT, // gamma tilt
      <ExpandableContentWrapper type={IndicesContentType.TILT}>
        <TiltChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.TILT),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.EXP_CONCENTRATION, // Expiration Concentration
      <ExpandableContentWrapper type={IndicesContentType.EXP_CONCENTRATION}>
        <ExpConcentrationChart
          isIndices
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.EXP_CONCENTRATION,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.ZERO_DTE, // 0DTE Volume/Open Interest
      <ExpandableContentWrapper type={IndicesContentType.ZERO_DTE}>
        <ZeroDTEChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.ZERO_DTE),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VOLFORECAST_MODEL, // SIV Index
      <ExpandableContentWrapper type={IndicesContentType.VOLFORECAST_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.VOLFORECAST}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.VOLFORECAST_MODEL,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.OPTIONS_RISK_REVERSAL_CHART, // Risk Reversal
      <ExpandableContentWrapper
        type={IndicesContentType.OPTIONS_RISK_REVERSAL_CHART}
      >
        <OptionsRiskReversalChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.MAX_REAL_VOL, // Price vs Realized Volatility
      <ExpandableContentWrapper type={IndicesContentType.MAX_REAL_VOL}>
        <MaxRealVolChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.MAX_REAL_VOL),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VOLATILITY, // Realized Volatility
      <ExpandableContentWrapper type={IndicesContentType.VOLATILITY}>
        <VolatilityChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.VOLATILITY),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.HIST_RETURNS, // 5 Day & 1 Month Return Histogram
      <ExpandableContentWrapper type={IndicesContentType.HIST_RETURNS}>
        <RealVolHistogramChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.HIST_RETURNS),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.OI_VOLUME, // OI & Volume
      <ExpandableContentWrapper type={IndicesContentType.OI_VOLUME}>
        <OIVolumeChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.OI_VOLUME),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.HISTORICAL_CHART,
      <ExpandableContentWrapper type={IndicesContentType.HISTORICAL_CHART}>
        <HistoricalChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.HISTORICAL_CHART,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.EXP_CONCENTRATION_TABLE, // gamma model
      <ExpandableContentWrapper
        type={IndicesContentType.EXP_CONCENTRATION_TABLE}
      >
        <ExpirationConcentrationTable
          containerStyleOverrides={{
            ...defaultContentContainerStyles,
          }}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.EXP_CONCENTRATION_TABLE,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.STRIKE_CONCENTRATION_TABLE, // gamma model
      <ExpandableContentWrapper
        type={IndicesContentType.STRIKE_CONCENTRATION_TABLE}
      >
        <StrikeConcentrationTable
          containerStyleOverrides={{
            ...defaultContentContainerStyles,
          }}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.STRIKE_CONCENTRATION_TABLE,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
  ]);

  return contentForTypeMap.get(tab.contentId ?? tab.id) ?? null;
};

export default ContentWrapper;

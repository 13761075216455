import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

export const TrendSpiderIntegration = () => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Stack gap={4}>
      <Typography variant="h2" sx={{ fontSize: 24, fontWeight: 600 }}>
        How to add SpotGamma Levels to TrendSpider
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: 14,
            md: 16,
          },
          fontWeight: 600,
        }}
      >
        Written Instructions (see video for demonstration):
      </Typography>
      <Stack gap={1}>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 16,
            },
          }}
        >
          1) Search for and select "SpotGamma Levels" in the TrendSpider
          Indicators menu bringing up the SpotGamma page in the TrendSpider
          Tools Store
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 16,
            },
          }}
        >
          2) In the TrendSpider Tools Store page for SpotGamma, select{' '}
          <strong>Add to TrendSpider</strong>
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 16,
            },
          }}
        >
          3) Navigate to SpotGamma's Market Overview page and select Index
          Levels
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 16,
            },
          }}
        >
          4) Click the <strong>Copy All</strong> button in the top right section
          of the Index Levels tab
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 16,
            },
          }}
        >
          5) In TrendSpider, locate "SpotGamma Levels by SpotGamma" under the
          Indicators menu and paste in the levels in the Input Data text box
        </Typography>
      </Stack>
      <span>
        <iframe
          width={isLgUp ? '882' : isSmUp ? '560' : '300'}
          height={isLgUp ? '464' : isSmUp ? '315' : '170'}
          src="https://www.youtube.com/embed/4xLeh2IZ76I"
          title="Adding SpotGamma Levels in TradingView"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </span>
    </Stack>
  );
};

import { CircularProgress } from '@mui/material';
import { Center } from './Center';

interface LoaderProps {
  isLoading: boolean;
  children?: any;
}
export const Loader = ({ isLoading, children }: LoaderProps) => {
  return isLoading ? (
    <Center>
      <CircularProgress color="primary" sx={{ alignSelf: 'center' }} />
    </Center>
  ) : (
    children ?? <></>
  );
};

import { Page } from './shared/Page';
import { Resources } from './components/Resources';

export const ResourcesPage = () => {
  return (
    <Page
      showLoggedOutBanner={false}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Resources />
    </Page>
  );
};

import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import {
  CompassChartData,
  CompassSymbolData,
  StrategyCompassXYZAxisReadableMap,
} from '../../../types';
import { displayValue, generateTooltipText } from '../../../util/compass';
import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import { useRecoilValue } from 'recoil';
import { earningsCalendarBySymState } from '../../../states';
import { earningsEntryForSym } from '../../../util/home';
import { ET, dayjs } from '../../../util';
import { EARNINGS_PERIOD_LABEL } from '../../../config/home';

type StrategyCompassTooltipProps = {
  chartData: CompassChartData[];
  symDataMap: Map<string, CompassSymbolData>;
  compassParams: CompassParams;
} & TooltipProps<ValueType, NameType>;

export const StrategyCompassTooltip = ({
  active,
  payload,
  chartData,
  symDataMap,
  compassParams,
}: StrategyCompassTooltipProps) => {
  const earningsCalendar = useRecoilValue(earningsCalendarBySymState);

  const theme = useTheme();
  const { xAxis, yAxis, zAxis, zAxisDataKey } = compassParams;

  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    const sym = data.sym;
    const symChartData = chartData.find((d) => d.sym === sym)!;
    const symData = symDataMap.get(sym)!;
    const earningsEntry = earningsCalendar?.get(sym);

    return (
      <Stack
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            textDecoration: 'underline',
            fontWeight: 'bold',
            fontSize: '15px',
          }}
        >
          {sym}
        </Typography>
        <Stack sx={{ '*': { fontSize: '13px' } }} direction="row">
          <Stack sx={{ marginTop: '3px' }} gap={'1px'}>
            <Typography>
              {StrategyCompassXYZAxisReadableMap.get(xAxis)}:{' '}
              <b>{displayValue(data.unroundedX, xAxis)}</b>
            </Typography>
            <Typography>
              {StrategyCompassXYZAxisReadableMap.get(yAxis)}:{' '}
              <b>{displayValue(data.unroundedY, yAxis)}</b>
            </Typography>
            {zAxisDataKey != null && (
              <>
                <Typography>
                  {StrategyCompassXYZAxisReadableMap.get(zAxis!)}:{' '}
                  <b>{displayValue(data.unroundedZ, zAxis!)}</b>
                </Typography>
                <Typography
                  sx={{ fontSize: '10px', color: theme.palette.text.secondary }}
                >
                  (the z axis is displayed as the size of the dot)
                </Typography>
              </>
            )}
            <Typography>
              Price: <b>${symData.price.toFixed(2)}</b>
              &nbsp;&nbsp;Call wall: <b>{symData.equity?.cws}</b>
              &nbsp;&nbsp;Put wall: <b>{symData.equity?.pws}</b>
            </Typography>
            {earningsCalendar === null && (
              <Typography
                sx={{
                  fontSize: '13px',
                  color: theme.palette.primary.main,
                }}
              >
                Error: Earnings dates unavailable
              </Typography>
            )}
            {earningsEntry != null && (
              <Typography
                sx={{
                  fontSize: '13px',
                  color: theme.palette.primary.main,
                }}
              >
                Earnings:{' '}
                {EARNINGS_PERIOD_LABEL.get(earningsEntry.period) ?? ''}{' '}
                {dayjs.tz(earningsEntry.day, ET).format('dddd MM/DD')}
              </Typography>
            )}
          </Stack>
        </Stack>

        <>
          <hr style={{ marginTop: '8px', marginBottom: '8px' }} />
          <Stack sx={{ maxWidth: '250px', gap: '5px' }}>
            {generateTooltipText(symChartData, symData, compassParams)}
          </Stack>
        </>
      </Stack>
    );
  }
  return null;
};

import { ReactNode } from 'react';
import { Box } from '@mui/material';

export const DetailsLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        width: 1.0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      {children}
    </Box>
  );
};

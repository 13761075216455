import React, {
  createContext,
  forwardRef,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import { Context } from './Chart';

export const SeriesContext = createContext(undefined as any);

const TYPE_TO_SERIES_FACTORY = {
  area: 'addAreaSeries',
  bar: 'addBarSeries',
  histogram: 'addHistogramSeries',
  baseline: 'addBaselineSeries',
  chart: 'addChartSeries',
  line: 'addLineSeries',
  candle: 'addCandlestickSeries',
};

const Series = forwardRef((props: any, ref) => {
  const parent = useContext(Context);
  const { children, data, markers, name, type, ...rest } = props;
  const context = useRef({
    series: undefined as any,
    api() {
      if (this.series != null) {
        return this.series;
      }
      const fn = (TYPE_TO_SERIES_FACTORY as any)[type] || 'addLineSeries';
      this.series = parent.api()[fn](rest);
      console.debug(`setData (api) ${name}... ${data?.length}`);
      this.series.setData(data);
      this.series.setMarkers(markers ?? []);
      return this.series;
    },
    destroy() {
      if (this.series != null) {
        console.debug(`removeSeries ${name}`);
        parent.removeSeries(this.series);
        this.series = null;
      }
    },
  });

  // Rely on imperative update API called in HiroChart rather than the below.
  // Calling `setData` below sets the viewport and shouldn't
  useLayoutEffect(() => {
    const api = context.current.api();
    api.setData(data);
    api.setMarkers(markers ?? []);
    api.applyOptions(rest);
  }, [rest, data, markers]);

  useLayoutEffect(() => {
    const currentRef = context.current;
    currentRef.api();
    return () => {
      console.debug(`destroying ${name}...`);
      currentRef.destroy();
    };
  }, [name]);

  useLayoutEffect(() => {
    context.current.api().applyOptions(rest);
    if (props.priceScaleOptions) {
      context.current.api().priceScale().applyOptions(props.priceScaleOptions);
    }
  });

  // Useful if we want to utilize the addData API
  useImperativeHandle(ref, () => context.current.api(), []);

  return (
    <SeriesContext.Provider value={context.current}>
      {children}
    </SeriesContext.Provider>
  );
});

Series.displayName = 'Series';

export default Series;

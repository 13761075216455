import { BbgLogin, BbgLoginProps } from '../components/bloomberg/BbgLogin';
import { WebLogin, WebLoginProps } from './components/WebLogin';
import { isBloomberg } from '../util';
import { Page } from './shared/Page';

export const LoginPage = ({ location }: BbgLoginProps & WebLoginProps) => {
  // if rendered within a page, BbgLogin looks odd given the styling on Page
  // it's not important that this be rendered within page so let's just return it here
  if (isBloomberg()) {
    return <BbgLogin />;
  }

  return (
    <Page showLoggedOutBanner={false}>
      <WebLogin location={location} />
    </Page>
  );
};

import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { HistoryTable } from '../EquityhubTable/HistoryTable';
import { SkewGraph } from './SkewGraph';
import { RealTimeGraph } from './RealTimeGraph';
import { PutCallImpactGraph } from './PutCallImpactGraph';
import { CompositeViewGraph } from './CompositeViewGraph';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isMobileState,
  newModelPcTypeState,
  putCallImpactInitialDataState,
  putCallImpactZoomConfigState,
  realTimeChartZoomConfigState,
  realTimeInitialDataState,
  rrChartZoomConfigState,
  rrInitialDataState,
  selectedEquitySymbolState,
  userDetailsState,
} from '../../../states';
import {
  InfoButton,
  mapFromObj,
  SGSelect,
  Tabs,
  UpsellModal,
  ZoomOutButton,
} from '../../shared';
import {
  IVolTab,
  NewModelPcType,
  ProductType,
  SubLevel,
  TAB_KEYS,
  TABS,
} from 'types';
import { DEFAULT_BRUSH_ZOOM_CONFIG, USING_NEW_MODEL } from 'config';
import { RiskReversalGraph } from './RiskReversalGraph';
import { getSubscriptionLevel, updateSearch } from '../../../util';
import { TermStructureChartSettings } from 'components/implied_vol/TermStructure';
import {
  termStructureChartDataState,
  termStructureChartZoomConfigState,
  volSkewChartDataState,
  volSkewChartZoomConfigState,
} from 'states/iVol';
import { VolSkewChartSettings } from 'components/implied_vol/VolSkew/VolSkewControls';
import { FixedStrikeTableSettings } from 'components/implied_vol/FixedStrikeMatrix/FixedStrikeTableControls';
import { FixedStrikeTable } from 'components/implied_vol/FixedStrikeMatrix';
import TermStructureChart from 'components/implied_vol/TermStructure/TermStructureChart';
import VolSkewChart from 'components/implied_vol/VolSkew/VolSkewChart';
import { DownloadChartButton } from 'components/shared/DownloadChartButton';
import { IVOL_UPSELL } from 'config/iVol';
import { SGTooltip } from '../../core';
import { SizeControls } from '../../shared/SizeControls';
import { SymbolTabs } from '../../hiro/HiroChart/controls/SymbolTabs';
import useAuth from 'hooks/auth/useAuth';

const ivolChartTabs = [
  IVolTab.TermStructure as string,
  IVolTab.VolSkew as string,
];

const ivolTabs = [IVolTab.FixedStrikeMatrix as string, ...ivolChartTabs];

export const EquityGraph = () => {
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const theme = useTheme();
  const tsRef = useRef<HTMLDivElement | null>(null);
  const vsRef = useRef<HTMLDivElement | null>(null);
  const [zoomedIn, setZoomedIn] = useState<boolean>(false);
  const [sizeOptionsOpen, setSizeOptionsOpen] = useState(false);
  const [searchParams, setSearch] = useSearchParams();
  const userDetails = useRecoilValue(userDetailsState);
  const [newModelPcType, setNewModelPcType] =
    useRecoilState(newModelPcTypeState);

  const { hasAccessToProduct } = useAuth();
  const hasImpliedVolAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);
  const membership = useMemo(
    () => getSubscriptionLevel(userDetails),
    [userDetails],
  );

  const currentTab = useMemo(
    () => searchParams.get('tab') ?? 'composite',
    [searchParams],
  );

  useEffect(() => {
    if (currentTab == null || !TAB_KEYS.has(currentTab)) {
      setSearch(updateSearch({ tab: 'composite' }));
    }
  }, [currentTab, setSearch]);

  useEffect(() => {
    if (ivolTabs.includes(currentTab) && !hasImpliedVolAccess) {
      setUpsellOpen(true);
    }
  }, [currentTab, hasImpliedVolAccess]);

  const handleChange = (_evt: React.SyntheticEvent, tab: string) => {
    if (ivolTabs.includes(tab) && !hasImpliedVolAccess) {
      setUpsellOpen(true);
    } else {
      setZoomedIn(false);
      setSearch(updateSearch({ tab }));
    }
  };

  const selectedSymbol = useRecoilValue(selectedEquitySymbolState);
  const [showKeyLevels, setShowKeyLevels] = useState(true);
  const isMobile = useRecoilValue(isMobileState);
  const [realTimeZoomConfig, setRealTimeZoomConfig] = useRecoilState(
    realTimeChartZoomConfigState,
  );
  const realTimeInitialData = useRecoilValue(realTimeInitialDataState);
  const [putCallImpactZoomConfig, setPutCallImpactZoomConfig] = useRecoilState(
    putCallImpactZoomConfigState,
  );
  const putCallImpactInitialData = useRecoilValue(
    putCallImpactInitialDataState,
  );
  const [rrImpactZoomConfig, setRRImpactZoomConfig] = useRecoilState(
    rrChartZoomConfigState,
  );
  const rrImpactInitialData = useRecoilValue(rrInitialDataState);
  const pcImpactInitialData = useRecoilValue(putCallImpactInitialDataState);

  const [termStructureChartZoomConfig, setTermStructureChartZoomConfig] =
    useRecoilState(termStructureChartZoomConfigState);
  const termStructureChartData = useRecoilValue(termStructureChartDataState);
  const [volSkewChartZoomConfig, setVolSkewChartZoomConfig] = useRecoilState(
    volSkewChartZoomConfigState,
  );
  const volSkewChartData = useRecoilValue(volSkewChartDataState);

  const zoomButton = useMemo(() => {
    switch (currentTab) {
      case 'live_price':
        return (
          <ZoomOutButton
            zoomConfig={realTimeZoomConfig}
            setZoomConfig={setRealTimeZoomConfig}
            initialData={realTimeInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: realTimeInitialData.length - 1,
            }}
          />
        );
      case 'pc_impact':
        return (
          <ZoomOutButton
            zoomConfig={putCallImpactZoomConfig}
            setZoomConfig={setPutCallImpactZoomConfig}
            initialData={putCallImpactInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: pcImpactInitialData.length - 1,
            }}
          />
        );
      case 'rr':
        return (
          <ZoomOutButton
            zoomConfig={rrImpactZoomConfig}
            setZoomConfig={setRRImpactZoomConfig}
            initialData={rrImpactInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: rrImpactInitialData.length - 1,
            }}
          />
        );
      case IVolTab.TermStructure:
        return (
          <ZoomOutButton
            key="zoom"
            zoomConfig={termStructureChartZoomConfig}
            setZoomConfig={setTermStructureChartZoomConfig}
            initialData={termStructureChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: termStructureChartData.length - 1,
            }}
          />
        );
      case IVolTab.VolSkew:
        return (
          <ZoomOutButton
            key="zoom"
            zoomConfig={volSkewChartZoomConfig}
            setZoomConfig={setVolSkewChartZoomConfig}
            initialData={volSkewChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: volSkewChartData.length - 1,
            }}
          />
        );
      default:
        return null;
    }
  }, [
    currentTab,
    realTimeZoomConfig,
    setRealTimeZoomConfig,
    realTimeInitialData,
    putCallImpactZoomConfig,
    setPutCallImpactZoomConfig,
    putCallImpactInitialData,
    rrImpactZoomConfig,
    setRRImpactZoomConfig,
    rrImpactInitialData,
    termStructureChartZoomConfig,
    setTermStructureChartZoomConfig,
    termStructureChartData,
    volSkewChartZoomConfig,
    setVolSkewChartZoomConfig,
    volSkewChartData,
  ]);

  const iVolSettingsBtn = useMemo(() => {
    switch (currentTab) {
      case IVolTab.FixedStrikeMatrix:
        return (
          <FixedStrikeTableSettings
            key="fixed-strike-table-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      case IVolTab.TermStructure:
        return (
          <TermStructureChartSettings
            key="term-structure-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      case IVolTab.VolSkew:
        return (
          <VolSkewChartSettings
            key="volatility-skew-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      default:
        return null;
    }
  }, [currentTab, selectedSymbol]);

  const downloadBtn = useMemo(() => {
    switch (currentTab) {
      case IVolTab.TermStructure:
        return (
          <DownloadChartButton
            chartRef={tsRef}
            selectedSym={selectedSymbol ?? ''}
            chartName="Term-Structure"
          />
        );
      case IVolTab.VolSkew:
        return (
          <DownloadChartButton
            chartRef={vsRef}
            selectedSym={selectedSymbol ?? ''}
            chartName="Volatility-Skew"
          />
        );
      default:
        return null;
    }
  }, [currentTab, selectedSymbol]);

  const options = [
    !['skew', 'history', ...ivolTabs].includes(currentTab) ? (
      <FormControlLabel
        sx={{
          width: '120px',
          marginLeft: !isMobile ? '4px' : 0,
        }}
        control={
          <Switch
            checked={showKeyLevels}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setShowKeyLevels(event.target.checked)
            }
          />
        }
        label={
          <Typography
            sx={{
              fontSize: isMobile ? 11 : 13,
            }}
          >
            Show key levels
          </Typography>
        }
        labelPlacement={isMobile ? 'top' : 'end'}
      />
    ) : null,
    !['history'].includes(currentTab) ? (
      <InfoButton key={`${currentTab}-info`} articleKey={currentTab} />
    ) : null,
    ivolChartTabs.includes(currentTab) ? downloadBtn : null,
    zoomedIn || !zoomButton ? (
      zoomButton
    ) : (
      <SGTooltip title="Drag on the chart to zoom in" placement="top">
        {zoomButton}
      </SGTooltip>
    ),
  ];

  const fixedOptions = [ivolTabs.includes(currentTab) ? iVolSettingsBtn : null];

  const sizingOptions = [
    <SizeControls open={sizeOptionsOpen} setOpen={setSizeOptionsOpen} />,
  ];

  const filteredTABS = Object.keys(TABS)
    .filter((key) => !(membership === SubLevel.ALPHA && key === 'skew'))
    .reduce((obj, key) => {
      obj[key] = TABS[key];
      return obj;
    }, {} as { [key: string]: string });

  return (
    <Box
      sx={{
        transition: '0.5s',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: isMobile ? '550px' : '100%',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
      }}
    >
      <TabContext value={currentTab}>
        <Tabs
          options={mapFromObj(filteredTABS)}
          onChange={handleChange}
          controlProps={{ options, sizingOptions, fixedOptions }}
          defaultScrollable={true}
          fontSize={isMobile ? '12px' : '14px'}
        />
        <Box
          sx={{
            paddingLeft: '10px',
            paddingRight: '15px',
            paddingTop: '3px',
            marginBottom: '5px',
          }}
        >
          <SymbolTabs productType={ProductType.EQUITYHUB} />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            overflowX: 'auto',
          }}
        >
          <TabPanel value="composite" sx={{ paddingBottom: 0 }}>
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <CompositeViewGraph showKeyLevels={showKeyLevels} />
            </Box>
          </TabPanel>
          <TabPanel value="pc_impact">
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              {USING_NEW_MODEL && (
                <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                  <SGSelect
                    key={'new_model_pc_type'}
                    label={'New Model Type'}
                    value={newModelPcType}
                    setter={setNewModelPcType}
                    options={[
                      NewModelPcType.ABS_GAMMA,
                      NewModelPcType.SYNTH_GAMMA,
                      NewModelPcType.OI,
                    ]}
                    multiple={false}
                  />
                </Stack>
              )}
              <PutCallImpactGraph showKeyLevels={showKeyLevels} />
            </Box>
          </TabPanel>
          <TabPanel value="live_price">
            <Box
              sx={{
                transition: '0.5s',
                display: 'block',
                flexDirection: 'column',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
              }}
            >
              <RealTimeGraph showKeyLevels={showKeyLevels} />
            </Box>
          </TabPanel>

          {membership !== SubLevel.ALPHA && (
            <TabPanel value="skew">
              <Box
                sx={{
                  transition: '0.5s',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  height: '100%',
                }}
              >
                <SkewGraph />
              </Box>
            </TabPanel>
          )}

          <TabPanel value="history">
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'auto',
                height: '100%',
                width: '100%',
                alignSelf: 'flex-start',
              }}
            >
              <HistoryTable />
            </Box>
          </TabPanel>
          <TabPanel value="rr">
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                alignSelf: 'flex-start',
              }}
            >
              <RiskReversalGraph />
            </Box>
          </TabPanel>
          {selectedSymbol && (
            <>
              <TabPanel value={IVolTab.FixedStrikeMatrix as string}>
                <Box
                  sx={{
                    transition: '0.5s',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%',
                  }}
                >
                  <FixedStrikeTable selectedSym={selectedSymbol} />
                </Box>
              </TabPanel>
              <TabPanel value={IVolTab.TermStructure as string}>
                <Box
                  sx={{
                    transition: '0.5s',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%',
                  }}
                >
                  <TermStructureChart
                    ref={tsRef}
                    selectedSym={selectedSymbol}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={IVolTab.VolSkew as string}>
                <Box
                  sx={{
                    transition: '0.5s',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%',
                  }}
                >
                  <VolSkewChart ref={vsRef} selectedSym={selectedSymbol} />
                </Box>
              </TabPanel>
            </>
          )}
        </Box>
      </TabContext>
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={IVOL_UPSELL.title}
        subtitle={IVOL_UPSELL.subtitle}
        items={IVOL_UPSELL.items}
      />
    </Box>
  );
};

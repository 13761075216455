import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Column, Row, useRowSelect, useSortBy, useTable } from 'react-table';
import { HiroOverview, ProductType } from '../../../types';
import { alpha, useTheme } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { HIRO_UPSELL, LEFT_PROPS, RIGHT_PROPS } from 'config';
import { userDetailsState } from 'states';
import useSetSym from '../../../hooks/hiro/useSetSym';
import { UpsellModal } from 'components/shared';
import useAuth from 'hooks/auth/useAuth';

interface HiroTableProps {
  columns: Column<HiroOverview>[];
  data: HiroOverview[];
}

// TODO: There should be a way to natively declare certain columns/headers left
// or right aligned (flow-direction left/right)
const HEADER_PROPS = new Map([
  ['instrument', LEFT_PROPS],
  ['sector', LEFT_PROPS],
]);

export const HiroTable = ({ columns, data }: HiroTableProps) => {
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<HiroOverview>(
      {
        columns,
        data,
        autoResetSortBy: false,
      },
      useSortBy,
      useRowSelect,
    );
  const { getSym, setSym } = useSetSym();
  const theme = useTheme();
  const hiroSym = getSym(ProductType.HIRO);
  const userDetails = useRecoilValue(userDetailsState);
  const { hasAccessToProduct } = useAuth();
  const hasHiroAccess = hasAccessToProduct(ProductType.HIRO);

  const isRowDisabled = useCallback(
    (row: Row<HiroOverview>) => {
      return !hasHiroAccess && !row.original.live;
    },
    [hasHiroAccess],
  );

  const onRowClick = useCallback(
    (row: Row<HiroOverview>) => {
      if (isRowDisabled(row)) {
        setSym(row.original.instrument, ProductType.HIRO);
        setUpsellOpen(true);
      } else {
        setSym(row.original.instrument, ProductType.HIRO);
      }
    },
    [isRowDisabled, setSym],
  );

  return (
    <Box
      sx={{
        flex: 2,
      }}
    >
      <Table stickyHeader {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const boxProps = HEADER_PROPS.get(column?.id) ?? RIGHT_PROPS;
                return (
                  <TableCell
                    sx={{
                      padding: '6px',
                      border: 'none',
                      color: '#6e7d91',
                      letterSpacing: '0px',
                      fontSize: '11px',
                      textTransform: 'uppercase',
                      fontWeight: 500,
                    }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '2px',
                        ...boxProps,
                        paddingRight: '20%',
                      }}
                    >
                      {column.render('Header')}
                      <>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownwardIcon sx={{ fontSize: '12px' }} />
                          ) : (
                            <ArrowUpwardIcon sx={{ fontSize: '12px' }} />
                          )
                        ) : (
                          ''
                        )}
                      </>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: Row<HiroOverview>) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={() => onRowClick(row)}
                sx={{
                  backgroundColor:
                    hiroSym === row.original.instrument
                      ? 'rgba(24, 189, 172, 0.25)'
                      : 'transparent',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.text.primary, 0.1),
                  },
                }}
                data-sym={row.original.instrument}
                hover={isRowDisabled(row)}
                className={isRowDisabled(row) ? `.MuiTableRow-disabled` : ''}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      sx={{ border: 'none', padding: '6px' }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={HIRO_UPSELL.title}
        subtitle={
          userDetails != null
            ? HIRO_UPSELL.upgrade_subtitle
            : HIRO_UPSELL.subtitle
        }
        items={HIRO_UPSELL.items}
      />
    </Box>
  );
};

import { Box, Typography } from '@mui/material';

interface StatisticProps {
  statistic: string;
  value: string | number;
}

export const Statistic = ({ statistic, value }: StatisticProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body1" fontSize="14px" color="text.secondary">
        {statistic}
      </Typography>
      <Typography variant="body1" fontSize="14px" color="text.primary">
        {value}
      </Typography>
    </Box>
  );
};

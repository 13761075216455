import { useCallback } from 'react';
import { fetchAPI } from '../../util/shared/fetch';
import { AdminWrittenContent } from '../../types/index';
import useLog from '../useLog';

export const useContent = () => {
  const { fetchAPIWithLog } = useLog('useContent');

  const saveContent = useCallback(async (content: AdminWrittenContent) => {
    return await fetchAPIWithLog(`admin/saveContent`, {
      method: 'POST',
      body: JSON.stringify(content),
    });
  }, []);

  const publishContent = useCallback(async (content: AdminWrittenContent) => {
    return await fetchAPIWithLog(`admin/publishContent`, {
      method: 'POST',
      body: JSON.stringify(content),
    });
  }, []);

  const fetchHtmlForKey = useCallback(
    async (key: string, published: boolean) => {
      return await fetchAPIWithLog(
        `admin/contentForKey?key=${key}&published=${published ? 1 : 0}`,
      );
    },
    [],
  );

  const fetchContentList = useCallback(
    async (draftsCategory: string, publishedCategory: string) => {
      return await fetchAPIWithLog(
        `admin/contentList?draftsCategory=${draftsCategory}&publishedCategory=${publishedCategory}`,
      );
    },
    [],
  );

  const deleteContent = useCallback(async (key: string, published: boolean) => {
    return await fetchAPIWithLog(
      `admin/deleteContent?key=${key}&published=${published ? 1 : 0}`,
      {
        method: 'DELETE',
      },
    );
  }, []);

  const previewContent = useCallback(async (content: AdminWrittenContent) => {
    return await fetchAPIWithLog(`admin/previewContent`, {
      method: 'POST',
      body: JSON.stringify(content),
    });
  }, []);

  return {
    saveContent,
    fetchContentList,
    publishContent,
    fetchHtmlForKey,
    deleteContent,
    previewContent,
  };
};

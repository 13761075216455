import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import { useCallback } from 'react';
import { Trace } from './components/Trace';

export const TracePage = () => {
  return (
    <AccessProtectedPage
      productType={ProductType.TRACE}
      renderContent={useCallback(
        () => (
          <Trace productType={ProductType.TRACE} />
        ),
        [],
      )}
    />
  );
};

import { useCallback } from 'react';
import useLog from '../useLog';

const useCompanyStatistics = () => {
  const { fetchAPIWithLog } = useLog('useCompanyStatistics');
  const getCompanyStatistics = useCallback(async (instrument: string) => {
    return await fetchAPIWithLog(`v1/twelve_quote?symbol=${instrument}`);
  }, []);

  return { getCompanyStatistics };
};

export default useCompanyStatistics;

import { Integrations } from '../components/integrations/Integrations';
import useCleanup from '../hooks/useCleanup';
import { ProductType } from 'types';
import { AccessProtectedPage } from './AccessProtectedPage';
import { useCallback } from 'react';

export const IntegrationsPage = () => {
  useCleanup();
  const render = useCallback(() => <Integrations />, []);

  return (
    <AccessProtectedPage
      productType={ProductType.INTEGRATIONS}
      renderContent={render}
    />
  );
};

import useLog from 'hooks/useLog';
import { useCallback } from 'react';
import { AnonUserDetails, DataExchangeAgreementMap } from 'types';
import useUserDetails from './useUserDetails';
import useAuth from '../auth/useAuth';
import { v4 as uuidv4 } from 'uuid';

const useInstituionalDetails = () => {
  const { fetchAPIWithLog } = useLog('InstitutionalDetails');
  const { setPartialUserDetails } = useUserDetails();
  const { setToken } = useAuth();

  const saveIsInstitutional = useCallback(
    async (agreementRecord: DataExchangeAgreementMap): Promise<boolean> => {
      const response = await fetchAPIWithLog(`v1/me/saveIsInstitutional`, {
        method: 'POST',
        body: JSON.stringify(agreementRecord),
      });

      if (response.error != null) {
        throw new Error(response.error);
      }

      const newToken = response?.updatedToken;

      if (newToken) {
        setToken(newToken);
        setPartialUserDetails({ isInstitutional: response?.isInstitutional });
      }

      return response?.isInstitutional;
    },
    [],
  );

  const saveIsInstitutionalAnon = useCallback(
    async (
      user: AnonUserDetails,
      agreementRecord: DataExchangeAgreementMap,
    ): Promise<void> => {
      const anonUserId = localStorage.getItem('anon_uuid');
      const userID = anonUserId != null ? anonUserId : uuidv4();
      localStorage.setItem('anon_uuid', userID);
      const response = await fetchAPIWithLog(`v1/anonInstitutionalForm`, {
        method: 'POST',
        body: JSON.stringify({
          userID,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          form: agreementRecord,
        }),
      });

      if (response.error != null) {
        throw new Error(response.error);
      }
    },
    [],
  );

  const getInstitutionalForm = useCallback(async () => {
    const response = await fetchAPIWithLog(`v1/me/institutionalForm`);
    if (response.error != null) {
      throw new Error(response.error);
    }
    return response?.institutionForm as DataExchangeAgreementMap;
  }, []);

  return {
    saveIsInstitutional,
    getInstitutionalForm,
    saveIsInstitutionalAnon,
  };
};

export default useInstituionalDetails;

import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { COMBINED_SIGNALS } from 'config';
import useCompanyStatistics from 'hooks/hiro/useCompanyStatistics';
import {
  selectedHiroStatisticsState,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
} from 'states';
import { HiroStatistics, ProductType } from 'types';
import { Details } from './Details';
import { DetailsLayout } from './DetailsLayout';
import useSetSym from '../../../hooks/hiro/useSetSym';

export const StockDetails = () => {
  const [data, setData] = useRecoilState(selectedHiroStatisticsState);
  const stocks = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const { getCompanyStatistics } = useCompanyStatistics();
  const { getSym } = useSetSym();
  const hiroSym = getSym(ProductType.HIRO);

  useEffect(() => {
    async function fetchData() {
      if (hiroSym != null) {
        if (COMBINED_SIGNALS.has(hiroSym)) {
          setData({} as HiroStatistics);
          return;
        }
        const data = await getCompanyStatistics(hiroSym);
        setData(data[hiroSym] as HiroStatistics);
      }
    }
    fetchData();
  }, [getCompanyStatistics, hiroSym, setData]); // Or [] if effect doesn't need props or state

  if (hiroSym == null) {
    return <Box />;
  }
  return (
    <DetailsLayout>
      <Details hiro={stocks.get(hiroSym.toUpperCase())} stats={data} />
    </DetailsLayout>
  );
};

import { useEffect, useState, useRef } from 'react';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceLine,
  Label,
} from 'recharts';
import {
  GammaVanna,
  GammaVannaModel,
  IndexSymbol,
  IndicesContentType,
  RawModelRow,
  SymSelectorSettings,
} from '../../../types';
import {
  formatAsCompactNumber,
  parseSpaceDelimittedList,
  nextBusinessDay,
  formatAsCurrency,
} from '../../../util';
import useModels from '../../../hooks/indices/useModels';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import dayjs from 'dayjs';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';

interface GammaVannaModelChartProps {
  model: GammaVannaModel;
  prefetchedData?: any;
  expandable?: boolean;
  withLevels?: boolean;
  autoScroll?: boolean;
  hideTitle?: boolean;
  selectedSym: string;
  symSelectorSettings?: SymSelectorSettings;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
}

const SUPPRESSED_SIV_SYMS = new Set(['NDX', 'RUT']);

export const GammaVannaModelChart = ({
  model,
  prefetchedData,
  expandable = true,
  withLevels = false,
  autoScroll = false,
  hideTitle,
  selectedSym,
  symSelectorSettings,
  chartStyleOverrides,
  containerStyleOverrides,
}: GammaVannaModelChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [gammaVannaData, setGammaVannaData] = useState<
    Map<string, GammaVanna[]> | undefined
  >(undefined);
  const { getModels } = useModels();
  const [tradeDateIndex, setTradeDateIndex] = useState<number>(0);
  const [tradeDates, setTradeDates] = useState<string[]>([]);
  const [maxStrike, setMaxStrike] = useState<number | undefined>();
  const [errored, setErrored] = useState<string | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timer | undefined>(undefined);

  useEffect(() => {
    async function generateChartData() {
      try {
        let data = prefetchedData;
        if (data == null) {
          data = await getModels(
            [model],
            selectedSym,
            3 /*# of rows in result*/,
          );
        }

        const transformedData = new Map(
          (data as RawModelRow[]).map(
            ({ strike_list, current_list, next_exp_list, trade_date }) => {
              const strikeList = JSON.parse(strike_list);
              const currentList =
                model === GammaVannaModel.VANNA
                  ? current_list.replace('[', '').replace(']', '').split(' ')
                  : parseSpaceDelimittedList(current_list, ' ');
              const nextExpList =
                model === GammaVannaModel.VOLFORECAST
                  ? null
                  : model === GammaVannaModel.VANNA
                  ? next_exp_list.replace('[', '').replace(']', '').split(' ')
                  : parseSpaceDelimittedList(next_exp_list, ' ');
              setMaxStrike(Math.max(...strikeList));
              const d = strikeList
                .map((px: number, i: number) => ({
                  strike: px,
                  current: Number(currentList[i]),
                  next_exp: nextExpList ? Number(nextExpList[i]) : null,
                }))
                .sort((a: GammaVanna, b: GammaVanna) => a.strike - b.strike);
              const tradeDate = dayjs.utc(trade_date);
              return [nextBusinessDay(tradeDate).format('MMM D'), d];
            },
          ),
        );

        if (transformedData.size > 0) {
          const dates = Array.from(transformedData.keys());
          setTradeDateIndex(0);
          setTradeDates(dates);
        }
        setGammaVannaData(transformedData);
        setErrored(null);
      } catch (e) {
        setErrored(e as string);
      }
    }
    generateChartData();
  }, [getModels, model, selectedSym, setGammaVannaData, prefetchedData]);

  useEffect(() => {
    if (timer == null && autoScroll && tradeDates.length > 0) {
      let n = 0;
      setTimer(
        setInterval(() => {
          n = (n + 1) % tradeDates.length;
          setTradeDateIndex(n);
        }, 2500),
      );
      return () => {
        clearInterval(timer);
      };
    }
  }, [tradeDates, autoScroll, timer, setTradeDateIndex, tradeDateIndex]);

  if (errored != null) {
    return null;
  }

  let referenceLines;
  if (withLevels) {
    const fieldColorMapping = theme.palette.equityHub.fieldColorMapping;
    const referenceLineMapping = [
      ['putwallstrike', 'Put Wall', fieldColorMapping.pws],
      ['callwallstrike', 'Call Wall', fieldColorMapping.cws],
      ['topabs_strike', 'Abs Gamma', fieldColorMapping.maxfs],
      ['max_g_strike', 'Vol Trigger', fieldColorMapping.keyd],
      ['upx', 'Last Price', fieldColorMapping.upx],
    ];
    referenceLines = referenceLineMapping.map((levelData, idx) => {
      const val = prefetchedData[0][levelData[0]];
      let x = 0;
      const y = 50 * idx + 50;
      if (maxStrike != null && maxStrike / val < 1.025) {
        x = -100;
      }

      return (
        <ReferenceLine
          x={val}
          key={levelData[0]}
          stroke={levelData[2]}
          strokeWidth={2}
          label={
            <Label
              position={{ x: x, y: y }}
              value={`${levelData[1]}: ${val}`}
              angle={0}
              height={20}
              style={{
                textAnchor: 'start',
                fill: levelData[2],
                fontSize: 14,
                fontWeight: '600',
              }}
            />
          }
          isFront
        />
      );
    });
  }

  const xLabel = model === GammaVannaModel.VANNA ? 'Spot' : 'Strike';
  const suppress =
    model === GammaVannaModel.VOLFORECAST &&
    SUPPRESSED_SIV_SYMS.has(selectedSym);

  return suppress ? null : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        opacity: suppress ? '0.2' : '1',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      {!autoScroll ? (
        <IndicesHeader
          type={`${model}_model` as IndicesContentType}
          title={
            model === GammaVannaModel.VOLFORECAST
              ? 'SIV Index'
              : `${model} Model`
          }
          expandable={expandable}
          symSelectorSettings={symSelectorSettings}
          hideTitle={hideTitle}
          controllerProps={{
            buttonGroups: [
              {
                buttons: tradeDates.map((d, idx) => ({ value: idx, label: d })),
                setter: setTradeDateIndex,
                curValue: tradeDateIndex,
              },
            ],
          }}
          symbol={selectedSym}
        />
      ) : (
        <Box sx={{ textAlign: 'center', p: theme.spacing(2) }}>
          <Typography
            variant="h3"
            gutterBottom
            color="text.primary"
            textTransform="capitalize"
          >
            Est. SPX Gamma | SpotGamma
          </Typography>
          <Typography variant="h4" sx={{ color: theme.palette.grey[500] }}>
            {dayjs.utc(tradeDates[tradeDateIndex]).format('dddd MMM DD')}
          </Typography>
        </Box>
      )}
      {gammaVannaData && (
        <ChartWatermarkContainer
          ref={ref}
          style={{ ...chartStyleOverrides }}
          size={25}
          offsetX={55}
          offsetY={40}
        >
          <ResponsiveContainer>
            <LineChart
              data={Array.from(
                gammaVannaData.get(tradeDates[tradeDateIndex])?.values() || [],
              )}
              margin={{ ...DEFAULT_CHART_MARGINS, left: 10, right: 20 }}
            >
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                dataKey="strike"
                tickFormatter={(v: number) => formatAsCurrency(v, true)}
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 11 }}
                label={{
                  value: xLabel,
                  ...DEFAULT_X_AXIS_STYLES,
                }}
                type="number"
              />
              <YAxis
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 11 }}
                tickFormatter={(v: number) =>
                  `${formatAsCompactNumber(
                    v,
                    model !== GammaVannaModel.VOLFORECAST
                      ? {
                          style: 'currency',
                          currency: 'USD',
                        }
                      : {},
                  )}`
                }
                label={{
                  value:
                    model === GammaVannaModel.VOLFORECAST
                      ? 'Impl. Move %'
                      : model === GammaVannaModel.VANNA ||
                        model === GammaVannaModel.DELTA
                      ? 'Delta Notional'
                      : 'Gamma Notional',
                  ...DEFAULT_Y_AXIS_STYLES,
                  offset: -3,
                }}
              />
              <Tooltip
                formatter={(v: string) =>
                  model === GammaVannaModel.VOLFORECAST
                    ? v.toLocaleString()
                    : formatAsCurrency(v, true)
                }
                labelFormatter={(v: number) =>
                  `${xLabel}: ${formatAsCurrency(v, true)}`
                }
                itemStyle={{ fontSize: '11px' }}
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              <Line
                type="monotone"
                dataKey="next_exp"
                name={
                  model === GammaVannaModel.VANNA ? 'Delta' : 'Next Expiration'
                }
                stroke={theme.palette.indices.gammaVanna.nextExp}
                dot={false}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="current"
                name={
                  model === GammaVannaModel.VANNA
                    ? 'Delta w/ IV adj.'
                    : 'Current'
                }
                stroke={theme.palette.indices.gammaVanna[model]}
                dot={false}
                strokeWidth={2}
              />
              {referenceLines}
            </LineChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      )}
    </Box>
  );
};

import { Chip } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isMobileState, mainContentWidthState } from '../../../../states';
import { SGTooltip } from '../../../core';

type SymbolTabProps = {
  symbol: string;
  onDelete: (symbol: string) => void;
  onClick: (symbol: string) => void;
  active: boolean;
  totalCount: number;
  disabledMessage?: string;
};

export const SymbolTab = ({
  onDelete,
  onClick,
  active,
  symbol,
  totalCount,
  disabledMessage,
}: SymbolTabProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const mainContentWidth = useRecoilValue(mainContentWidthState);
  const isMobile = useRecoilValue(isMobileState);

  let fontSizeAndPadding = 12;

  if (!isMobile) {
    const avgTabAvailWidth = mainContentWidth / totalCount;
    fontSizeAndPadding = Math.min(fontSizeAndPadding, avgTabAvailWidth / 7);
    fontSizeAndPadding = Math.max(fontSizeAndPadding, 9); // set a floor and after that it will be scrollable
  }

  const showX = isMobile || isHovering;
  const disabled = disabledMessage != null;

  return (
    <SGTooltip title={disabledMessage} placement={'top'}>
      <span>
        {/* span/some other wrapper is required for a tooltip to show on a disabled MUI element */}
        <Chip
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => !disabled && onClick(symbol)}
          label={symbol}
          onDelete={() => onDelete(symbol)}
          variant={active ? undefined : 'outlined'}
          className={active ? 'active' : 'notActive'}
          sx={{
            '.MuiChip-deleteIcon': {
              visibility: showX ? 'visible' : 'hidden',
              opacity: showX ? 1 : 0,
              width: showX ? '20px' : 0,
              transition: 'all 0.2s ease',
            },
            '.MuiChip-label': {
              fontSize: `${fontSizeAndPadding}px`,
              paddingLeft: `${fontSizeAndPadding}px`,
              paddingRight: `${fontSizeAndPadding}px`,
              fontWeight: fontSizeAndPadding < 12 ? 900 : undefined,
            },
            ...(disabled ? { opacity: 0.5 } : {}),
          }}
        />
      </span>
    </SGTooltip>
  );
};

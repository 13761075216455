import { IconButton, Tooltip } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useRecoilState } from 'recoil';
import { componentIsExpandedState } from 'states';
import { HideSupportStyle } from './HideSupportStyle';
import { SGTooltip } from '../core';

interface ExpandableContentButtonProps {
  type: string;
}

export const ExpandableContentButton = ({
  type,
}: ExpandableContentButtonProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useRecoilState(
    componentIsExpandedState(type),
  );

  return isExpanded ? (
    <>
      <HideSupportStyle />
      <SGTooltip title="Exit Fullscreen">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(false);
          }}
          size="small"
          color="primary"
        >
          <FullscreenExitIcon />
        </IconButton>
      </SGTooltip>
    </>
  ) : (
    <SGTooltip title="Fullscreen">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(true);
        }}
        size="small"
        color="primary"
      >
        <FullscreenIcon />
      </IconButton>
    </SGTooltip>
  );
};

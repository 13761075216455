import { useRecoilState, useRecoilValue } from 'recoil';
import { bottomDrawerExpandedState, isMobileState } from 'states';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import { ChartSizingButton } from './ChartSizingButton';

export const FullscreenBottomDrawerButton = ({
  withText,
}: {
  withText?: boolean;
}) => {
  const [bottomDrawerExpanded, setBottomDrawerExpanded] = useRecoilState(
    bottomDrawerExpandedState,
  );
  const isMobile = useRecoilValue(isMobileState);
  if (isMobile) {
    return null;
  }

  return (
    <ChartSizingButton
      onClick={() => {
        setBottomDrawerExpanded(!bottomDrawerExpanded);
      }}
      icon={
        bottomDrawerExpanded ? (
          <FullscreenExitSharpIcon />
        ) : (
          <FullscreenSharpIcon />
        )
      }
      title={
        bottomDrawerExpanded
          ? 'Minimize Bottom Drawer'
          : 'Maximize Bottom Drawer'
      }
      withText={withText}
    />
  );
};

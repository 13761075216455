import { useCallback } from 'react';
import { Watchlist } from '../types';
import useLog from './useLog';

const useWatchlists = () => {
  const { fetchAPIWithLog } = useLog('useWatchlists');

  const saveWatchlists = useCallback(async (watchlists: Watchlist[]) => {
    const body = JSON.stringify({
      watchlists,
    });
    const postOpts = { method: 'POST', body };
    return await fetchAPIWithLog('v1/me/saveWatchlists', postOpts);
  }, []);

  return { saveWatchlists };
};

export default useWatchlists;

import { CircularProgress, Typography, Box, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userDetailsState } from '../../states';
import { bbAppComponent, isBbgDemo, isBloomberg } from '../../util';
import useTerminalConnect from 'hooks/bloomberg/api/useTerminalConnect';
import useOauth from 'hooks/bloomberg/api/useOauth';
import { useTheme } from '@mui/material/styles';
import useLog from '../../hooks/useLog';
import { useNavigate } from 'react-router-dom';
import useSetSym from '../../hooks/hiro/useSetSym';

export interface BbgLoginProps {}

export const BbgLogin = ({}: BbgLoginProps) => {
  const [loginLoading, setLoginLoading] = useState(true);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null,
  );
  const [loginStatusMessage, setLoginStatusMessage] = useState<string | null>(
    null,
  );
  // login automatically starts unless this is the web demo in which case we need a username/pw first
  const [startLogin, setStartLogin] = useState(!isBbgDemo());
  const [password, setPassword] = useState(''); // only for demo
  const userDetails = useRecoilValue(userDetailsState);
  const { fetchBssoGraphQlToken } = useTerminalConnect();
  const { oauthLogin } = useOauth();
  const theme = useTheme();
  const { getTimeSignature } = useLog('BbgLogin');
  const navigate = useNavigate();
  const { getSym } = useSetSym();

  useEffect(() => {
    if (!startLogin) {
      return;
    }

    const errorCallback = (error: string | null) => {
      setLoginErrorMessage(error);
      if (isBbgDemo()) {
        setStartLogin(false); // allow login retry for demo if wrong password
      }
    };
    oauthLogin(errorCallback, { demoPassword: password });
    fetchBssoGraphQlToken();

    const timeout = isBbgDemo()
      ? undefined
      : setTimeout(() => {
          if (userDetails == null) {
            setLoginErrorMessage(
              'Your login has timed out. Please reopen the app to try again. Code 153',
            );
          }
        }, 20000);

    return () => {
      clearTimeout(timeout);
    };
  }, [startLogin]);

  useEffect(() => {
    bbAppComponent?.setTitle('HIRO by SpotGamma');
  }, []);

  useEffect(() => {
    if (userDetails != null) {
      setLoginLoading(false);
      navigate(`/hiro?sym=${encodeURIComponent(getSym())}`);
    }
  }, [userDetails]);

  if (!isBloomberg()) {
    return null;
  }

  if (isBbgDemo()) {
    return (
      <Box
        width={1}
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <TextField
          required
          id="login-password"
          label="Password"
          type="password"
          sx={{
            width: '300px',
            background: theme.palette.background.paper,
            borderRadius: '4px',
            fontSize: '18px',
          }}
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
        />
        <LoadingButton
          type="submit"
          onClick={() => setStartLogin(true)}
          sx={{
            fontFamily: 'Satoshi Complete',
            fontWeight: 700,
            textTransform: 'capitalize',
            marginTop: '5px',
          }}
          variant="contained"
          loading={startLogin}
          loadingIndicator={<CircularProgress color="info" size={16} />}
        >
          Login
        </LoadingButton>
      </Box>
    );
  }

  return (
    <Box
      width={1}
      height={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loginLoading && loginErrorMessage == null ? (
        <Box display="flex" gap="7px">
          <Typography sx={{ marginTop: '10px', fontSize: '18px' }}>
            Logging in...
          </Typography>
          <CircularProgress color="primary" />
          {loginStatusMessage != null && (
            <Typography
              sx={{ fontSize: '16px', marginTop: '30px', display: 'block' }}
            >
              {loginStatusMessage}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography sx={{ fontSize: '18px' }}>
          {loginErrorMessage ?? 'There was an error. Please try again.'}
        </Typography>
      )}
      <Box style={{ position: 'absolute', left: '20px', bottom: '20px' }}>
        {getTimeSignature()}
      </Box>
    </Box>
  );
};

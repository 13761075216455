import { Chip } from '@mui/material';

interface TagProps {
  label: string;
  color?: string;
}

export const Tag = ({ label, color = '#a6a6a6' }: TagProps) => {
  return (
    <Chip
      label={label}
      sx={{
        '&.MuiChip-root': {
          background: 'transparent !important',
          height: 'auto',
          color,
        },
        '& .MuiChip-label': {
          padding: '4px 6px',
          textTransform: 'uppercase',
          color,
          border: `1px solid ${color}3b`,
          background: `${color}3b !important`,
          fontSize: '8px',
          fontWeight: 600,
          borderRadius: '8px',
        },
      }}
    />
  );
};

export const NewTag = () => {
  return <Tag label="New" color="#1AA251" />;
};

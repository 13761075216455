import { useCallback, useMemo } from 'react';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { SetterOrUpdater, useRecoilValue } from 'recoil';
import { BrushZoomConfig } from '../types';
import { isMobileState } from 'states';

const useBrushZoom = <Type>(
  zoomConfig: BrushZoomConfig,
  setZoomConfig: SetterOrUpdater<BrushZoomConfig>,
  xField: string,
  initialData: Type[],
) => {
  const isMobile = useRecoilValue(isMobileState);
  const zoom = useCallback(() => {
    let { refAreaLeft, refAreaRight, leftIdx, rightIdx, data } = zoomConfig;

    if (
      zoomConfig.refAreaLeft === zoomConfig.refAreaRight ||
      zoomConfig.refAreaRight === '' ||
      zoomConfig.refAreaLeft === ''
    ) {
      setZoomConfig((prev: any) => ({
        ...prev,
        leftIdx: leftIdx,
        rightIdx: rightIdx,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    let refLeft = zoomConfig.refAreaLeft;
    let refRight = zoomConfig.refAreaRight;

    if (zoomConfig.refAreaLeft > zoomConfig.refAreaRight) {
      [refLeft, refRight] = [refRight, refLeft]; // swap
    }

    let newLeftIdx = leftIdx;
    let newRightIdx = rightIdx;
    if (zoomConfig.refAreaLeft !== '') {
      newLeftIdx =
        initialData.findIndex((obj: any) => obj[xField] === refLeft) ?? leftIdx;
    }

    if (zoomConfig.refAreaRight !== '') {
      newRightIdx =
        initialData.findIndex((obj: any) => obj[xField] === refRight) ??
        rightIdx;
    }

    data = data?.slice();
    refAreaLeft = '';
    refAreaRight = '';
    setZoomConfig((prev: any) => ({
      ...prev,
      refAreaLeft,
      refAreaRight,
      leftIdx: newLeftIdx,
      rightIdx: newRightIdx,
      data,
    }));
  }, [zoomConfig, setZoomConfig, initialData, xField]);

  const zoomChartConfig = useMemo(() => {
    return {
      data: zoomConfig.data,
      onMouseDown: (e: CategoricalChartState) => {
        if (isMobile) {
          return;
        }
        setZoomConfig((prev: any) => ({
          ...prev,
          refAreaLeft: e?.activeLabel ?? '',
        }));
      },
      onMouseMove: (e: CategoricalChartState) => {
        if (isMobile) {
          return;
        } else if (zoomConfig.refAreaLeft) {
          setZoomConfig((prev: any) => ({
            ...prev,
            refAreaRight: e?.activeLabel ?? '',
          }));
        }
      },
      onMouseUp: zoom,
    };
  }, [setZoomConfig, zoomConfig, zoom]);

  return { zoomChartConfig };
};

export default useBrushZoom;

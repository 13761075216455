import { useCallback } from 'react';
import { businessDaysAdd, getQueryDate } from '../../util';
import useLog from '../useLog';
import { MacroCalendarEvent } from 'types';

const useMacroCalendar = () => {
  const { fetchAPIWithLog } = useLog('useMacroCalendar');

  const getMacroCalendar = useCallback(async (from?: string, to?: string) => {
    const fromDate = from ?? getQueryDate().format('YYYY-MM-DD');
    const toDate =
      to ?? businessDaysAdd(getQueryDate(), 10).format('YYYY-MM-DD');
    const url = `v1/fmp/api/v3/economic_calendar?from=${fromDate}&to=${toDate}`;
    const data = await fetchAPIWithLog(url);
    return data.map((d: MacroCalendarEvent) => ({
      ...d,
      country: d.country === '' ? '<Global>' : d.country,
    }));
  }, []);

  return {
    getMacroCalendar,
  };
};

export default useMacroCalendar;

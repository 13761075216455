import { CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GridTableOverlay from 'components/shared/GridTableOverlay';
import EmptyGrid from 'icons/EmptyGrid';

interface WrapperProps {
  isError?: boolean;
  isLoading?: boolean;
}

const GridTableOverlayWrapper = ({ isError, isLoading }: WrapperProps) => {
  return (
    <GridTableOverlay
      icon={
        isError ? (
          <ErrorOutlineIcon />
        ) : isLoading ? (
          <CircularProgress />
        ) : (
          <EmptyGrid sx={{ width: 36, height: 36 }} />
        )
      }
      text={
        isError
          ? 'Something went wrong while fetching data...'
          : isLoading
          ? 'Waiting for data...'
          : 'No matching results'
      }
    />
  );
};

export default GridTableOverlayWrapper;

import { useCallback } from 'react';
import useLog from '../useLog';

const useGammaLevels = () => {
  const { fetchAPIWithLog } = useLog('useGammaLevels');

  const getGammaLevels = useCallback(async (sym: string) => {
    return await fetchAPIWithLog(`absGammaLevels?sym=${sym}`);
  }, []);

  return { getGammaLevels };
};

export default useGammaLevels;

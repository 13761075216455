import { useCallback, useEffect } from 'react';
import {
  businessDaysAdd,
  businessDaysSubtract,
  getCachedToken,
  getDateFormatted,
  getEnv,
  getOverrideHeader,
  getQueryDate,
  isZerohedge,
  logAppcuesIdentify,
  ONE_HOUR_MS,
  productTypeForPath,
  READABLE_PROD_TYPES,
} from './util';
import ReactGA from 'react-ga4';
import { PollJsonResponse, ProductType } from './types';
import { LOG_FLUSH_INTERVAL_MS, useSetSym } from './hooks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentProductTypeState,
  earningsCalendarState,
  isMobileState,
  screenHeightState,
  screenWidthState,
  userDetailsState,
  windowIsVisibleState,
} from './states';
import { useMarketOpenCloseListener } from './hooks/useMarketOpenCloseListener';
import { MOBILE_MAX_WIDTH, PRODUCT_LINKS } from './config';
import useLog from './hooks/useLog';
import { useCookies } from 'react-cookie';
import { Location } from 'react-router-dom';
import useEquities from './hooks/equityhub/useEquities';
import useHiroList from './hooks/hiro/useHiroList';
import poll from './util/poll';
import PollingWorker from 'PollingWorker';
import dayjs from 'dayjs';

type AppMetadataProps = {
  location: Location;
  worker: PollingWorker;
};

export const AppMetadata = ({ location, worker }: AppMetadataProps) => {
  // we are intentionally making this a component instead of a hook to avoid the
  // main App.tsx component re-rendering whenever state/useEffects here change

  const [currentProductType, setCurrentProductType] = useRecoilState(
    currentProductTypeState,
  );
  const userDetails = useRecoilValue(userDetailsState);
  const setIsVisible = useSetRecoilState(windowIsVisibleState);
  const setIsMobile = useSetRecoilState(isMobileState);
  const setScreenWidth = useSetRecoilState(screenWidthState);
  const setScreenHeight = useSetRecoilState(screenHeightState);
  const setEarningsCalendar = useSetRecoilState(earningsCalendarState);

  const { sym } = useSetSym();
  const { setupEquitiesPoller } = useEquities();
  const { logtail } = useLog('AppMetadata');
  const [cookies, setCookie] = useCookies(['disableRedirect', 'sgToken']);
  const { fetchHiroStocks, POLL_INTERVAL, url, handleResponse } = useHiroList();
  useMarketOpenCloseListener();

  const handleWindowSizeChange = useCallback(() => {
    const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;
    setIsMobile(isMobile);
    ReactGA.set({ mobile: isMobile });
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
  }, [setScreenHeight, setScreenWidth, setIsMobile]);

  handleWindowSizeChange();

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  useEffect(() => {
    const toggleVisible = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', toggleVisible);

    return () => {
      document.removeEventListener('visibilitychange', toggleVisible);
    };
  }, [setIsVisible]);

  useEffect(() => {
    const flush = () => {
      logtail?.flush();
    };
    const flushInterval = setInterval(flush, LOG_FLUSH_INTERVAL_MS);
    window.addEventListener('beforeunload', flush);

    return () => {
      clearInterval(flushInterval);
      window.removeEventListener('beforeunload', flush);
    };
  }, []);

  useEffect(() => {
    // note that this useEffect will be triggered on preferences saves as well
    logAppcuesIdentify(userDetails);
  }, [userDetails]);

  useEffect(() => {
    setCurrentProductType(productTypeForPath(location.pathname));
  }, [location]);

  useEffect(() => {
    const userId =
      userDetails == null ? 'rubber_chicken' : userDetails.id ?? 'id';
    ReactGA.set({ userId });
  }, [userDetails]);

  useEffect(() => {
    const readableStr =
      currentProductType == null
        ? null
        : READABLE_PROD_TYPES[currentProductType];
    if (readableStr == null) {
      document.title = 'SpotGamma';
      return;
    }

    const symStr = [
      ProductType.HIRO,
      ProductType.EQUITYHUB,
      ProductType.IMPLIED_VOL,
      ProductType.INDICES,
    ].includes(currentProductType!)
      ? `${sym} `
      : '';

    document.title = `${symStr}${readableStr} - SpotGamma`;
  }, [currentProductType, sym]);

  useEffect(() => {
    const token = getCachedToken();
    // If beta user logs into prod with valid token, redirect them to staging while setting the disableRedirect
    if (
      getEnv() !== 'development' &&
      !window.location.host.includes('localhost') &&
      token &&
      window.location.host !== process.env.REACT_APP_STAGING_HOST &&
      userDetails?.wordpress?.profile?.mepr_modern_dashboard_access ===
        'beta' &&
      !cookies.disableRedirect
    ) {
      setCookie('sgToken', token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000), // 24 hours
        path: '/',
        domain: '.spotgamma.com',
        secure: true,
        sameSite: 'none', // necessary if cookies need to be sent in cross-site requests
      });
      window.location.href = `https://${process.env.REACT_APP_STAGING_HOST}${
        location?.state?.postLoginPath ?? PRODUCT_LINKS[ProductType.HOME].link
      }`;
    }
  }, [userDetails]);

  // Ensure we fetch the running HIRO list at least once.  It will ensure it
  // updates within its own useEffects
  useEffect(() => {
    fetchHiroStocks();
  }, []);

  useEffect(() => {
    if (isZerohedge()) {
      return;
    }
    return poll(
      worker,
      {
        url,
        interval: POLL_INTERVAL,
        onResponse: handleResponse,
        noPollOnInit: true,
      },
      getOverrideHeader(),
    );
  }, [worker, handleResponse, url]);

  useEffect(() => {
    return setupEquitiesPoller();
  }, [setupEquitiesPoller]);

  const handleEarningsResponse = ({ json }: PollJsonResponse) => {
    try {
      const earnings = (json ?? [])
        .filter((e: any) => e.cv != null && e.utc != null)
        .reduce((map: Map<String, any>, entry: any) => {
          const date = getDateFormatted(dayjs.utc(entry.day));
          const entries = map.get(date) ?? [];
          entries.push(entry);
          return map.set(date, entries);
        }, new Map());
      setEarningsCalendar(earnings);
    } catch (err) {
      console.error(err);
      setEarningsCalendar(null);
    }
  };

  useEffect(() => {
    const today = getQueryDate(true);
    const start = businessDaysSubtract(today, 1).format('YYYY-MM-DD');
    const end = businessDaysAdd(today, 21).format('YYYY-MM-DD');

    return poll(
      worker,
      {
        url: `v1/earnings?start=${start}&end=${end}`,
        interval: 24 * ONE_HOUR_MS,
        onResponse: handleEarningsResponse,
      },
      { maxRetries: 1, ...getOverrideHeader() },
    );
  }, [worker]);

  return <></>;
};

import { useCallback } from 'react';
import useLog from '../useLog';

const useEarnings = () => {
  const { fetchAPIWithLog } = useLog('useHomeContent');

  const getEarnings = useCallback(async (start: string, end: string) => {
    return await fetchAPIWithLog(`v1/free_earnings?start=${start}&end=${end}`);
  }, []);

  return { getEarnings };
};

export default useEarnings;

import { PreferencesTab } from 'types';
import { dayjs, ET } from '../util/shared/date';
import { Dayjs } from 'dayjs';
import { isBloomberg } from 'util/bloomberg';

export const EDITABLE_ACCOUNT_DETAILS = isBloomberg()
  ? []
  : [
      {
        field: 'first_name',
        label: 'First Name',
        type: 'string',
      },
      {
        field: 'last_name',
        label: 'Last Name',
        type: 'string',
      },
      {
        field: 'email',
        label: 'Email',
        type: 'email',
      },
    ];

export const PREFERENCES_LABEL_MAPPING = new Map(
  isBloomberg()
    ? [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Alerts, 'Alerts'],
      ]
    : [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Payments, 'Payments'],
        [PreferencesTab.Watchlists, 'Watchlists'],
        [PreferencesTab.Alerts, 'Alerts'],
      ],
);

// putting everything in one place so it can be easily removed later

export type TokenTimeframe = {
  minDate: Dayjs;
  maxDate: Dayjs;
  token: string;
};

export const OH_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTcG90R2FtbWEiLCJ1c2VyX2lkIjoiY2RhYTkxYmItYWZiMC00ZmI2LWE2ZGQtNjZkNzEzMWRhNDliIiwiaXNfaW5zdGl0dXRpb25hbCI6ZmFsc2UsIndwSUQiOjE2NjExLCJlbWFpbCI6ImVuZythbHBoYUBzcG90Z2FtbWEuY29tIiwibWVtYmVyc2hpcHMiOlsiQWxwaGEiXSwicm9sZXMiOlsic3Vic2NyaWJlciJdLCJ1c2VyVHlwZSI6MSwid3BUb2tlbiI6eyJhY2Nlc3NfdG9rZW4iOiI5ZjJxcmt5bXl4eTlwbXl6dnBlbnpqYmJ3eW55ZmVlbXh4ZWhlNWIxIiwiZXhwaXJlc19pbiI6MjU5MjAwLCJ0b2tlbl90eXBlIjoiQmVhcmVyIiwic2NvcGUiOiJiYXNpYyIsInJlZnJlc2hfdG9rZW4iOiJjams2ZzRlNXNjN3ltMnhkejd0ZmhqcG1mbXVib3FwNXVtcGl1bXo4In0sImlhdCI6MTczMzc4MjczNSwiZXhwIjoxNzM0MzI1MjAwfQ.ZLbW-pYJdeVY39-aZzSb4WoxIbjE7_J2I4IMexoGrwQ';

export const ALPHA_TOKEN_OVERRIDES: TokenTimeframe[] = [
  {
    minDate: dayjs.tz('2024-12-09 00:00', ET),
    maxDate: dayjs.tz('2024-12-15 00:00', ET),
    token: OH_TOKEN,
  },
];

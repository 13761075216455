export type AdminWrittenContent = {
  title: string | null;
  key: string | null;
  html: string | null;
  category: string;
  published?: boolean;
  copiedFromKey?: string | null;
  fromWordpress?: boolean;
  freePreviewKey?: string;
  extra?: any;
  id: string | null;
};

export const SGCustomTag = '[CUSTOM-SG-TAG-DO-NOT-EDIT]';

// must match middle-layer admin.js
export enum SGCustomTagType {
  SGPropKeyLevelsTable = '[sg-prop-key-levels-table]',
  SGAddtlKeyLevelsTable = '[sg-addtl-key-levels-table]',
  SGSupportResistanceTable = '[sg-support-resistance-key-levels-table]',
  SGMacroTheme = '[sg-macro-theme]',
  SGWelcomeMessage = '[sg-welcome-message]',
  SGGammaCurve = '[sg-gamma-curve]',
  SGHistoricalChart = '[sg-historical-chart]',
}

export const sgCustomTagTypeToReadable = (tagType: SGCustomTagType) => {
  switch (tagType) {
    case SGCustomTagType.SGPropKeyLevelsTable:
      return 'SpotGamma Proprietary Key Levels Table';
    case SGCustomTagType.SGAddtlKeyLevelsTable:
      return 'Additional Key Levels Table';
    case SGCustomTagType.SGSupportResistanceTable:
      return 'Support & Resistance Table';
    case SGCustomTagType.SGMacroTheme:
      return 'Macro Theme';
    case SGCustomTagType.SGGammaCurve:
      return 'Gamma Curve';
    case SGCustomTagType.SGWelcomeMessage:
      return 'Welcome Message';
    case SGCustomTagType.SGHistoricalChart:
      return 'Historical SG Levels Chart';
    default:
      return tagType;
  }
};

// must match middle-layer admin.js
export enum AdminContentCategory {
  FoundersNote = 'founders-notes',
  MacroTheme = 'macro-theme',
  WelcomeMessage = 'welcome-message',
  Tooltips = 'tooltips',
  StrategyHiro = 'strategy-hiro',
  StrategyEquityhub = 'strategy-equityhub',
  StrategyFoundersNotes = 'strategy-founders-notes',
  StrategyImpliedVol = 'strategy-implied-vol',
  StrategyIndices = 'strategy-indices',
  StrategyTape = 'strategy-tape',
}

export const adminContentSpecialCategories = [
  AdminContentCategory.MacroTheme,
  AdminContentCategory.WelcomeMessage,
  AdminContentCategory.Tooltips,
  AdminContentCategory.StrategyIndices,
  AdminContentCategory.StrategyHiro,
  AdminContentCategory.StrategyFoundersNotes,
  AdminContentCategory.StrategyImpliedVol,
  AdminContentCategory.StrategyEquityhub,
  AdminContentCategory.StrategyTape,
];

import {
  LabelList,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import {
  SearchHandlerFunction,
  StrategyCompassXYZAxis,
  StrategyCompassXYZAxisReadableMap,
} from '../../../types';
import { useEffect, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  equitiesFetchLoadingState,
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  searchHandlerState,
} from '../../../states';
import { difference } from 'lodash';
import { Box, Typography } from '@mui/material';
import { StrategyCompassMode } from '../../../types/compass';
import { isValidCompassSymbol } from '../../../util';
import {
  compassHoveredSymbolState,
  compassScannerSymbolsState,
} from '../../../states/scanners';
import { StrategyCompassControls } from './StrategyCompassControls';
import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import { StrategyCompassTooltip } from './StrategyCompassTooltip';
import { Loader } from '../../shared';
import { CompassData } from '../../../hooks/scanners/useCompassData';

const SPREAD_THRESHOLD = 0.25;
const transparent = 'rgba(0,0,0,0)';
const labelColor = '#a4a4a4';
const EDGE_ALLOWED_SPACING = 30;

const X_MIN = 0;
const X_MAX = 1;
const SEGMENT_PADDING = X_MAX * 0.02;
const Y_MIN = 0;
const Y_MAX = 1;
const X_MID = (X_MIN + X_MAX) / 2.0;
const Y_MID = (Y_MIN + Y_MAX) / 2.0;
// add some buffer because points at the exact min/max are not visible on the plot
const X_DOMAIN = [X_MIN - X_MAX * 0.035, X_MAX + X_MAX * 0.035];
const Y_DOMAIN = [Y_MIN - Y_MAX * 0.035, Y_MAX + Y_MAX * 0.035];

type StrategyCompassProps = {
  compassParams: CompassParams;
  compassData: CompassData;
};

export const StrategyCompass = ({
  compassParams,
  compassData,
}: StrategyCompassProps) => {
  const {
    mode,
    syms,
    sym,
    setSyms,
    xAxis,
    yAxis,
    editable,
    searchParams,
    zAxis,
  } = compassParams;
  const { fetchData, loading, chartData, data, visibleChartData } = compassData;

  const theme = useTheme();
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  const axisTextColor = theme.palette.text.secondary;
  const positiveTrend = useRecoilValue(positiveTrendColorState);
  const negTrend = useRecoilValue(negativeTrendColorState);
  const [compassScannerSymbols, _setCompassScannerSymbols] = useRecoilState(
    compassScannerSymbolsState,
  );
  const equitiesLoading = useRecoilValue(equitiesFetchLoadingState);
  const hoveredSymbol = useRecoilValue(compassHoveredSymbolState);
  const isMobile = useRecoilValue(isMobileState);

  const fontSize = isMobile ? 11 : 13;
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 },
  );

  const shadeOverlayColor = alpha(theme.palette.text.primary, 0.1); // light shade color

  const addSyms = (symsToAdd: string[]) => {
    if (!editable) {
      return;
    }

    const newSyms = [...new Set(syms.concat(symsToAdd)).values()];

    setSyms(newSyms);
  };

  useEffect(() => {
    // it's possible we've fetched data for these symbols already and they are just being re-enabled
    // if so, dont fetch again
    const currSymsFetched = [...data.keys()];
    const newSymsToFetch = difference(syms, currSymsFetched).filter(
      isValidCompassSymbol,
    );
    if (newSymsToFetch.length === 0) {
      return;
    }

    fetchData(newSymsToFetch);
  }, [fetchData, syms, data]);

  useEffect(() => {
    addSyms([sym]);
  }, [sym]);

  useEffect(() => {
    addSyms(compassScannerSymbols);
  }, [compassScannerSymbols]);

  const yAxisTitle = StrategyCompassXYZAxisReadableMap.get(yAxis)!;
  const xAxisTitle = StrategyCompassXYZAxisReadableMap.get(xAxis)!;
  const zAxisTitle =
    zAxis == null || zAxis === StrategyCompassXYZAxis.None
      ? null
      : StrategyCompassXYZAxisReadableMap.get(zAxis)!;

  const lineColor = mode === StrategyCompassMode.Compass ? '#000' : '#fff';
  const strokeWidth = mode === StrategyCompassMode.Compass ? 2 : 0.5;
  const axisLinesComponent = (
    <>
      <ReferenceLine
        segment={[
          { x: X_MIN - SEGMENT_PADDING, y: Y_MID },
          { x: X_MAX + SEGMENT_PADDING, y: Y_MID },
        ]}
        stroke={lineColor}
        strokeWidth={strokeWidth}
        label={(_props) => (
          <>
            <text
              x={chartSize.width / 2.0 - yAxisTitle.length * 2.5}
              y={8}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText(yAxisTitle)}
            </text>
            <text
              x={chartSize.width / 2.0 - 50}
              y={21}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText('↑ higher')}
            </text>
            <text
              x={chartSize.width / 2.0 + 5}
              y={21}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText('↓ lower')}
            </text>
          </>
        )}
      />
      <ReferenceLine
        segment={[
          { x: X_MID, y: Y_MIN - SEGMENT_PADDING },
          { x: X_MID, y: Y_MAX + SEGMENT_PADDING },
        ]}
        stroke={lineColor}
        strokeWidth={strokeWidth}
        label={(_props) => (
          <>
            <text
              x={10}
              y={chartSize.height / 2.0 - 10}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText(xAxisTitle)}
            </text>
            <text
              x={10}
              y={chartSize.height / 2.0 + 15}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText(
                mode === StrategyCompassMode.Compass
                  ? '← often bullish, often bearish →'
                  : '← lower, higher →',
              )}
            </text>
          </>
        )}
      />
    </>
  );

  const labeledReferenceAreas = mode === StrategyCompassMode.Compass && (
    <>
      <ReferenceArea
        x1={X_MID - SPREAD_THRESHOLD}
        y1={Y_MID - SPREAD_THRESHOLD}
        x2={X_MID + SPREAD_THRESHOLD}
        y2={Y_MID + SPREAD_THRESHOLD}
        fill={shadeOverlayColor}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID - SPREAD_THRESHOLD}
        y2={Y_MID - SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.33 - (isMobile ? 15 : 0)}
            y={chartSize.height * 0.62}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Call Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID - SPREAD_THRESHOLD}
        y2={Y_MID + SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.33 - (isMobile ? 15 : 0)}
            y={chartSize.height * 0.4}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Put Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID + SPREAD_THRESHOLD}
        y2={Y_MID + SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.56 - (isMobile ? 15 : 0)}
            y={chartSize.height * 0.4}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Call Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID + SPREAD_THRESHOLD}
        y2={Y_MID - SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.56 - (isMobile ? 15 : 0)}
            y={chartSize.height * 0.62}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Put Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MIN}
        y2={Y_MIN}
        fill={shadeOverlayColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.15 - (isMobile ? 15 : 0)}
            y={chartSize.height * 0.8}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Call')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MIN}
        y2={Y_MAX}
        fill={shadeOverlayColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.15 - (isMobile ? 15 : 0)}
            y={chartSize.height * 0.2}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Put')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MAX}
        y2={Y_MAX}
        fill={shadeOverlayColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.8}
            y={chartSize.height * 0.2}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Call')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MAX}
        y2={Y_MIN}
        fill={shadeOverlayColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.8}
            y={chartSize.height * 0.8}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Put')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MIN}
        x2={X_MID}
        y2={Y_MIN}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <>
            <text
              x={chartSize.width / 2.0 - 70 + (isMobile ? 15 : 0)}
              y={chartSize.height - 80}
              fill={labelColor}
              fontSize={fontSize}
            >
              {renderLabelText('Buy Straddle / Strangle')}
            </text>
          </>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MAX}
        x2={X_MID}
        y2={Y_MAX}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <>
            <text
              x={chartSize.width / 2.0 - 70 + (isMobile ? 15 : 0)}
              y={80}
              fill={labelColor}
              fontSize={fontSize}
            >
              {renderLabelText('Sell Straddle / Strangle')}
            </text>
          </>
        )}
      />
    </>
  );

  useEffect(() => {
    if (!editable) {
      return;
    }
    const suggestionsHandler: SearchHandlerFunction = (value: string): void => {
      addSyms([value]);
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [editable, searchParams]);

  function renderLabelText(str: string) {
    return chartSize.width > 0 && chartSize.height > 0 ? str : '';
  }

  return (
    <Box width={1} height={isMobile ? '450px' : 1}>
      <Box width={1} height={'calc(100% - 50px)'}>
        <StrategyCompassControls
          editable={editable}
          loading={loading}
          chartSize={chartSize}
          compassParams={compassParams}
        />
        <Box
          sx={{
            ...(isMobile ? { textAlign: 'center' } : { marginLeft: '22px' }),
            marginTop: '5px',
            marginBottom: isMobile ? '15px' : '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: isMobile ? '11px' : '12px',
              color: theme.palette.text.secondary,
            }}
          >
            {mode === StrategyCompassMode.Freeform
              ? 'Overlay from Trade Compass mode does not apply in Freeform'
              : 'This trade overlay only applies in Compass mode'}
          </Typography>
        </Box>
        <ResponsiveContainer
          onResize={(width, height) => setChartSize({ width, height })}
        >
          {equitiesLoading ? (
            <Loader isLoading={true} /> // chart doesnt render correctly as a child of Loader
          ) : (
            <ScatterChart>
              <XAxis
                type="number"
                dataKey="x"
                name={xAxisTitle}
                hide
                domain={X_DOMAIN}
              />
              <YAxis
                type="number"
                hide
                dataKey="y"
                name={yAxisTitle}
                domain={Y_DOMAIN}
              />
              {zAxisTitle != null && (
                <ZAxis
                  type="number"
                  dataKey="z"
                  name={zAxisTitle}
                  range={[10, 150]}
                  domain={Y_DOMAIN}
                />
              )}
              {axisLinesComponent}

              <defs>
                <linearGradient id={'full_gradient'}>
                  <stop
                    offset="0%"
                    stopColor={
                      mode === StrategyCompassMode.Compass
                        ? positiveTrend
                        : theme.palette.compass.freeformBg
                    }
                    stopOpacity={0.45}
                  />
                  <stop
                    offset="100%"
                    stopColor={
                      mode === StrategyCompassMode.Compass
                        ? negTrend
                        : theme.palette.compass.freeformBg
                    }
                    stopOpacity={0.45}
                  />
                </linearGradient>
              </defs>

              <ReferenceArea
                x1={X_MIN}
                y1={Y_MIN}
                x2={X_MAX}
                y2={Y_MAX}
                fill={`url(#full_gradient)`}
                stroke={'rgba(0,0,0,0)'}
                fillOpacity={1}
                strokeWidth={0}
                strokeOpacity={0}
              />

              {labeledReferenceAreas}

              <Scatter
                data={visibleChartData}
                fill={theme.palette.compass.pointColor}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey="sym"
                  content={(props: any) => {
                    let x = props.x - 1.75 * props.value.length;
                    let y = props.y - 3;
                    let forceRight = props.x <= EDGE_ALLOWED_SPACING;
                    let forceLeft =
                      props.x >= chartSize.width - EDGE_ALLOWED_SPACING;

                    if (forceLeft || forceRight) {
                      // if point is at the extreme left/right, position label next to point
                      y += 10;
                      x += forceRight ? 17 : -5.5 * props.value.length;
                    }
                    if (props.y <= EDGE_ALLOWED_SPACING) {
                      // if point is at the top, position label below point
                      y += 20;
                    }

                    return (
                      <text
                        x={x}
                        y={y}
                        fontSize={props.value === hoveredSymbol ? 14 : 12}
                        fill={
                          props.value === hoveredSymbol
                            ? theme.palette.compass.pointColor
                            : '#fff'
                        }
                        fontWeight={
                          props.value === hoveredSymbol ? 'boldest' : 'normal'
                        }
                      >
                        {props.value}
                      </text>
                    );
                  }}
                />
              </Scatter>
              <RechartsTooltip
                isAnimationActive={false}
                content={
                  <StrategyCompassTooltip
                    chartData={chartData}
                    symDataMap={data}
                    compassParams={compassParams}
                  />
                }
              />
            </ScatterChart>
          )}
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

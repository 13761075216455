import { useEffect, useState, useMemo } from 'react';
import { dayjs, getUtcYMD } from 'util/shared/date';
import { SetterOrUpdater, useRecoilValue } from 'recoil';
import {
  Box,
  Collapse,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { IndicesTable } from './IndicesTable';
import useConcentration from '../../../hooks/indices/useConcentration';
import { ErrorContent } from '../../shared';
import {
  ExpirationConcentration,
  ExpirationPutCallConcentration,
  IndicesContentType,
  PutCall,
  SymSelectorSettings,
} from '../../../types';
import { nullsToEndComparator, formatAsCurrency } from '../../../util';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  gridDateComparator,
  gridNumberComparator,
} from '@spotgamma/x-data-grid-premium';
import { IndicesHeader } from '../shared';
import { isMobileState } from 'states';

interface ExpirationConcentrationTableProps {
  selectedSym: string;
  containerStyleOverrides?: SxProps<Theme>;
  isUnfolded?: boolean;
  setIsUnfolded?: SetterOrUpdater<boolean>;
  symSelectorSettings?: SymSelectorSettings;
}

export const ExpirationConcentrationTable = ({
  containerStyleOverrides,
  selectedSym,
  isUnfolded,
  setIsUnfolded,
  symSelectorSettings,
}: ExpirationConcentrationTableProps) => {
  const { getConcentrationByExp } = useConcentration();
  const [data, setData] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();

  useEffect(() => {
    async function generateTableData() {
      setIsLoading(true);
      setData([]);
      let data = await getConcentrationByExp(selectedSym);
      let totalDelta = 0;
      let totalGamma = 0;
      const groupedData = (data as ExpirationConcentration[]).reduce(
        (groups, { Expiration, Type, oi, delta, gamma }) => {
          let group = groups.get(Expiration) ?? { delta: 0, gamma: 0 };
          totalDelta = Math.abs(delta) + totalDelta;
          totalGamma = Math.abs(gamma) + totalGamma;
          group = {
            ...group,
            expiration: Expiration,
            delta: group.delta + Math.abs(delta),
            gamma: group.gamma + Math.abs(gamma),
            ...(Type === PutCall.CALL
              ? {
                  call_delta: Math.abs(delta),
                  call_gamma: Math.abs(gamma),
                  call: oi,
                }
              : {
                  put_delta: Math.abs(delta),
                  put_gamma: Math.abs(gamma),
                  put: oi,
                }),
          };
          return groups.set(
            Expiration,
            group as ExpirationPutCallConcentration,
          );
        },
        new Map<string, ExpirationPutCallConcentration>(),
      );
      setIsLoading(false);
      setData(
        Array.from(groupedData.values()).map((d) => ({
          ...d,
          id: d.expiration,
          percent_gamma: (d.gamma / totalGamma || 0) * 100,
          percent_delta: (d.delta / totalDelta || 0) * 100,
        })),
      );
    }
    generateTableData();
  }, [getConcentrationByExp, selectedSym]);

  const defaultHeaderTitleStyles = useMemo(
    () => ({
      fontSize: isMobile ? 11 : 14,
      color: theme.palette.sgGreen,
      textTransform: 'capitalize',
      fontWeight: 'bold',
      whiteSpace: 'normal',
      lineHeight: 'normal',
    }),
    [isMobile, theme.palette.sgGreen],
  );

  const columns = useMemo(
    () =>
      [
        {
          headerName: 'Expiration',
          headerClassName: 'grid-header-cell',
          field: 'expiration',
          flex: 1,
          minWidth: 105,
          type: 'date',
          getSortComparator: nullsToEndComparator(gridDateComparator),
          renderHeader: (_params: GridColumnHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
              }}
            >
              Expiration
            </Typography>
          ),
          valueGetter: (value: string) => {
            return value && new Date(dayjs.utc(value).valueOf());
          },
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography>
                {params.value && getUtcYMD(params.value.valueOf())}
              </Typography>
            );
          },
        },
        {
          headerName: 'Put',
          headerClassName: 'grid-header-cell',
          field: 'put',
          flex: 1,
          minWidth: 90,
          type: 'number',
          getSortComparator: nullsToEndComparator(gridNumberComparator),
          renderHeader: (_params: GridColumnHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
              }}
            >
              Put
            </Typography>
          ),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography>
                {params.value && Math.round(params.value).toLocaleString()}
              </Typography>
            );
          },
        },
        {
          headerName: 'Call',
          headerClassName: 'grid-header-cell',
          field: 'call',
          flex: 1,
          minWidth: 90,
          type: 'number',
          getSortComparator: nullsToEndComparator(gridNumberComparator),
          renderHeader: (_params: GridColumnHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
              }}
            >
              Call
            </Typography>
          ),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography>
                {params.value && Math.round(params.value).toLocaleString()}
              </Typography>
            );
          },
        },
        {
          headerName: 'Gamma Notional',
          headerClassName: 'grid-header-cell',
          field: 'gamma',
          flex: 1,
          minWidth: 135,
          type: 'number',
          getSortComparator: nullsToEndComparator(gridNumberComparator),
          renderHeader: (_params: GridColumnHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
              }}
            >
              Gamma Notional
            </Typography>
          ),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography>
                {isNaN(params.value)
                  ? ''
                  : formatAsCurrency(params.value, true)}
              </Typography>
            );
          },
        },
        {
          headerName: 'Delta Notional',
          headerClassName: 'grid-header-cell',
          field: 'delta',
          flex: 1,
          minWidth: 135,
          type: 'number',
          getSortComparator: nullsToEndComparator(gridNumberComparator),
          renderHeader: (_params: GridColumnHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
              }}
            >
              Delta Notional
            </Typography>
          ),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography>
                {isNaN(params.value)
                  ? ''
                  : formatAsCurrency(params.value, true)}
              </Typography>
            );
          },
        },
        {
          headerName: '% Delta',
          headerClassName: 'grid-header-cell',
          field: 'percent_delta',
          flex: 1,
          minWidth: 90,
          type: 'number',
          getSortComparator: nullsToEndComparator(gridNumberComparator),
          renderHeader: (_params: GridColumnHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
              }}
            >
              % Delta
            </Typography>
          ),
          renderCell: (params: GridRenderCellParams) => {
            return <Typography>{`${params.value.toFixed(2)}%`}</Typography>;
          },
        },
        {
          headerName: '% Gamma',
          headerClassName: 'grid-header-cell',
          field: 'percent_gamma',
          flex: 1,
          minWidth: 90,
          type: 'number',
          getSortComparator: nullsToEndComparator(gridNumberComparator),
          renderHeader: (_params: GridColumnHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
              }}
            >
              % Gamma
            </Typography>
          ),
          renderCell: (params: GridRenderCellParams) => {
            return <Typography>{`${params.value.toFixed(2)}%`}</Typography>;
          },
        },
      ] as GridColDef[],
    [defaultHeaderTitleStyles],
  );

  if (data == null && !isLoading) {
    return <ErrorContent />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        type={IndicesContentType.EXP_CONCENTRATION_TABLE}
        title="Concentration Table"
        expandable
        isUnfolded={isUnfolded}
        setIsUnfolded={setIsUnfolded}
        symSelectorSettings={symSelectorSettings}
        shouldHideBtns={isUnfolded === false}
        symbol={selectedSym}
      />
      <Collapse in={isUnfolded} unmountOnExit>
        <Box
          sx={{
            overflow: 'auto',
            maxHeight: '75vh',
            display:
              isUnfolded != null && setIsUnfolded != null ? 'flex' : 'none',
          }}
        >
          <IndicesTable
            data={data ?? []}
            columns={columns}
            isLoading={isLoading}
          />
        </Box>
      </Collapse>

      {isUnfolded == null && setIsUnfolded == null && (
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          <IndicesTable
            data={data ?? []}
            columns={columns}
            isLoading={isLoading}
          />
        </Box>
      )}
    </Box>
  );
};

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  chartExpandedState,
  isMobileState,
  screenWidthState,
} from '../../states';
import { isZerohedge } from '../../util';

const useHiroUi = () => {
  const screenWidth = useRecoilValue(screenWidthState);
  const chartExpanded = useRecoilValue(chartExpandedState);
  const isMobile = useRecoilValue(isMobileState);

  const chartFullWidth = chartExpanded || isMobile;
  const showInlineControls = useMemo(() => {
    return (
      !isZerohedge() &&
      ((chartFullWidth && screenWidth >= 610) ||
        (!chartFullWidth && screenWidth >= 1050))
    );
  }, [chartExpanded, screenWidth, isMobile]);

  return {
    showInlineControls,
  };
};

export default useHiroUi;

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HiroOverview, HiroStatistics, ProductType } from '../../../types';
import { Statistic } from './Statistic';
import { useRecoilValue } from 'recoil';
import { isMobileState, spotgammaDataState } from '../../../states';
import { formatAsCompactNumber, formatAsCurrency } from '../../../util';
import { COMBO_SYMS_TO_INDEX_SYMBOL } from '../../../config';
import useSetSym from '../../../hooks/hiro/useSetSym';

interface DetailProps {
  stats: HiroStatistics;
  hiro?: HiroOverview;
}

export const Details = ({ stats, hiro }: DetailProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const { getSym } = useSetSym();
  const sgData = useRecoilValue(spotgammaDataState);

  const formatCurrencyRange = (value1: string, value2: string) => {
    if (value1 === `n/a`) {
      return `n/a`;
    }
    const num1 = Number(value1).toFixed(2);
    const num2 = Number(value2).toFixed(2);
    return `$${num1} - ${num2}`;
  };

  const formatHiroRange = (
    value1: number,
    value2: number,
    separator?: string,
  ) => {
    if (!value1) {
      return `n/a`;
    }
    return `${formatAsCompactNumber(value1)} ${
      separator ? separator : '-'
    } ${formatAsCompactNumber(value2)}`;
  };

  const yearly = stats?.fifty_two_week;
  const sym = hiro?.instrument ?? stats?.symbol ?? getSym(ProductType.HIRO);
  const haveSgData =
    sgData != null &&
    (sgData.sym === sym ||
      (COMBO_SYMS_TO_INDEX_SYMBOL[sym] ?? null) === sgData.sym);
  const priceLines: any = {
    'Hedge Wall': {
      value: sgData?.maxfs,
    },
    'Key Gamma Strike': {
      value: sgData?.keyg,
    },
    'Key Delta Strike': {
      value: sgData?.keyd,
    },
    'Call Wall': {
      value: sgData?.cws,
    },
    'Put Wall': {
      value: sgData?.pws,
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: theme.spacing(5),
        gap: theme.spacing(3),
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        overflowY: 'auto',
        width: '100%',
        maxWidth: isMobile ? '500px' : '400px',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(4) }}
      >
        {hiro != null && (
          // for now we don't have sym.png for non-hiro symbols but should try to add them
          <div
            className="company-photo"
            style={{
              backgroundImage: `url("images/hiro-logos/${sym}.png")`,
              width: '50px',
              height: '50px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              borderRadius: '100%',
            }}
          ></div>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: hiro != null ? 'inherit' : 'auto',
            textAlign: hiro != null ? 'left' : 'center',
          }}
        >
          <Typography
            variant="h2"
            fontFamily="Satoshi Complete"
            fontSize="24px"
            fontWeight={500}
            gutterBottom
            color="text.primary"
          >
            {sym}
          </Typography>
          <Typography variant="h3" gutterBottom color="text.secondary">
            {hiro?.companyName ?? stats?.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          px: theme.spacing(3),
          justifyContent: 'space-evenly',
          height: '100%',
        }}
      >
        {(hiro?.sector ?? '') !== '' && (
          <Statistic statistic="Sector" value={hiro!.sector} />
        )}
        {stats?.exchange != null && (
          <Statistic statistic="Exchange" value={stats.exchange} />
        )}
        {stats?.low != null && stats?.high != null && (
          <Statistic
            statistic="Day's Range"
            value={formatCurrencyRange(stats.low, stats.high)}
          />
        )}
        {yearly?.low != null && yearly?.high != null && (
          <Statistic
            statistic="52 Week Range"
            value={formatCurrencyRange(yearly.low, yearly.high)}
          />
        )}

        {/* TODO: Turn on when volume accurately reflects daily volume
        <Statistic
          statistic="Volume"
          value={Number(stats.volume).toLocaleString()}
        />
        */}
        {stats?.average_volume && (
          <Statistic
            statistic="Avg. Volume"
            value={Number(stats.average_volume).toLocaleString()}
          />
        )}
        {hiro != null && !isNaN(hiro.price) && (
          <Statistic
            statistic="Current Price"
            value={formatAsCurrency(hiro.price)}
          />
        )}
        {hiro != null && (
          <Statistic
            statistic="Current HIRO"
            value={formatAsCompactNumber(hiro?.signal)}
          />
        )}
        {hiro != null && (
          <Statistic
            statistic="30 Day HIRO Range"
            value={formatHiroRange(
              hiro['30 day'].low,
              hiro['30 day'].high,
              ':',
            )}
          />
        )}
        {haveSgData && (
          <Box style={{ margin: '10px auto', textAlign: 'center' }}>
            <Typography
              variant="body1"
              fontSize="14px"
              color="text.secondary"
              display="block"
            >
              <img
                alt="SpotGamma logo"
                src="images/logo.png"
                width="24px"
                style={{
                  display: 'inline-block',
                  marginBottom: '-6px',
                  marginRight: '3px',
                }}
              />
              SpotGamma Levels
            </Typography>
          </Box>
        )}
        {haveSgData &&
          Object.keys(priceLines).map((levelName: string) => {
            if (priceLines[levelName].value == null) {
              return null;
            }

            return (
              <Statistic
                statistic={levelName}
                value={priceLines[levelName].value}
                key={levelName}
              />
            );
          })}
      </Box>
    </Box>
  );
};

import { atom } from 'recoil';
import { AlertData } from '../types/index';

export const latestAlertsState = atom<AlertData[]>({
  key: 'alerts-latest',
  default: [],
});

export const unseenAlertCountState = atom<number>({
  key: 'alerts-unseen',
  default: 0,
});

export const lastAlertIdMarkedSeenState = atom<number>({
  key: 'alerts-lastAlertIdMarkedSeen',
  default: -1,
});

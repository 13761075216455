import { Box, Typography } from '@mui/material';
import { SGKeyLevelsTable } from 'components/foundersNotes/custom_tags/SGKeyLevelsTable';
import { SGCustomTagType } from 'types';
import { useEffect, useState } from 'react';
import useHomeContent from 'hooks/home/useHomeContent';
import { Loader } from 'components';
import { Center } from 'components/shared/Center';
import { dayjs, nextBusinessDay } from 'util/shared';

const KeySuppResistanceStrikes = () => {
  const [keyLevelsData, setKeyLevelsData] = useState<any>();
  const [errored, setErrored] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getKeyLevelsData } = useHomeContent();

  const fetchData = async () => {
    try {
      setLoading(true);
      setErrored(false);
      const rawKeyLevelsData = await getKeyLevelsData();

      setKeyLevelsData(rawKeyLevelsData?.data);
    } catch (err) {
      setErrored(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Loader isLoading={loading}>
      {errored ? (
        <Center
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography onClick={fetchData} sx={{ fontSize: '14px' }}>
            Unfortunately, there was an error. Click here to try again.
          </Typography>
        </Center>
      ) : (
        <Box>
          {keyLevelsData && (
            <SGKeyLevelsTable
              data={{
                type: SGCustomTagType.SGSupportResistanceTable,
                data: keyLevelsData,
              }}
              updatedAt={`(${nextBusinessDay(
                dayjs.utc(keyLevelsData?.[0]?.keyLevelTradeDate),
              ).format('MM-DD')})`}
            />
          )}
        </Box>
      )}
    </Loader>
  );
};

export default KeySuppResistanceStrikes;

import { Box, Typography } from '@mui/material';

export const EmptyStockDetails = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2" gutterBottom color="text.primary">
        No Stock Selected
      </Typography>
    </Box>
  );
};

import { ProductType } from '../types';
import { Indices } from './components/Indices';
import { AccessProtectedPage } from './AccessProtectedPage';
import { useCallback } from 'react';

export const IndicesPage = () => {
  const render = useCallback(() => <Indices />, []);

  return (
    <AccessProtectedPage
      productType={ProductType.INDICES}
      renderContent={render}
    />
  );
};

import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { formatAsPercentage } from 'util/shared';

interface GradientLegendProps {
  min: number;
  max: number;
  customMinLabel?: string;
  customMaxLabel?: string;
  minColor: string;
  maxColor: string;
  label?: string;
  width?: number;
  height?: number;
  format?: 'percent' | 'float';
}

const GradientLegend = ({
  min,
  max,
  customMinLabel,
  customMaxLabel,
  minColor,
  maxColor,
  label,
  width = 300,
  height = 20,
  format = 'float',
}: GradientLegendProps) => {
  const theme = useTheme();
  const ref = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svg = d3.select(ref.current);

    // reset and re-draw
    svg.selectAll('*').remove();

    const neutralColor = theme.palette.iVol.fixedStrikeMatrix.lowValueCell;

    const totalRange = Math.abs(min) + Math.abs(max);
    const negativePercent = Math.abs(min) / totalRange;
    const positivePercent = Math.abs(max) / totalRange;

    const neutralFraction = 0.1; // 10% of the gradient for neutral

    const gradientStops = [];

    if (min < 0) {
      gradientStops.push({ offset: '0%', color: minColor });
      const pos = Math.max(negativePercent * (1 - neutralFraction), 0.2);
      gradientStops.push({
        offset: `${pos * 100}%`,
        color: neutralColor,
      });
    } else {
      gradientStops.push({ offset: '0%', color: neutralColor });
    }

    if (max > 0) {
      if (min < 0) {
        const pos = Math.min(1 - positivePercent * (1 - neutralFraction), 0.8);
        gradientStops.push({
          offset: `${pos * 100}%`,
          color: neutralColor,
        });
      }
      gradientStops.push({ offset: '100%', color: maxColor });
    }

    const gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient');

    gradientStops.forEach((stop) => {
      gradient
        .append('stop')
        .attr('offset', stop.offset)
        .attr('stop-color', stop.color);
    });

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'url(#gradient)');
  }, [
    min,
    max,
    width,
    height,
    theme.palette.iVol.fixedStrikeMatrix.lowValueCell,
    minColor,
    maxColor,
    theme.palette.primary.main,
  ]);

  const absMin = Math.abs(min);
  const absMax = Math.abs(max);

  return (
    <Stack>
      <svg ref={ref} width={width} height={height} />
      <Stack direction="row" justifyContent="space-between" position="relative">
        <Typography
          sx={{
            color: theme.palette.getContrastText(
              theme.palette.background.default,
            ),
            fontSize: '13px',
            display: 'flex',
            alignItems: 'center',
            minWidth: 'fit-content',
            wrap: 'nowrap',
          }}
        >
          {customMinLabel ??
            `${min < 0 ? '-' : ''} ${
              format === 'percent'
                ? formatAsPercentage(absMin)
                : absMin.toFixed(3)
            }`}
        </Typography>
        {label && (
          <Typography
            sx={{
              color: theme.palette.getContrastText(
                theme.palette.background.default,
              ),
              fontSize: '12px',
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content',
              wrap: 'nowrap',
            }}
          >
            {label}
          </Typography>
        )}
        <Typography
          sx={{
            color: theme.palette.getContrastText(
              theme.palette.background.default,
            ),
            fontSize: '13px',
            display: 'flex',
            alignItems: 'center',
            minWidth: 'fit-content',
            wrap: 'nowrap',
          }}
        >
          {customMaxLabel ??
            `${max < 0 ? '-' : ''} ${
              format === 'percent'
                ? formatAsPercentage(absMax)
                : absMax.toFixed(3)
            }`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default GradientLegend;

import React from 'react';
import { Box, useTheme } from '@mui/material';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { PCFlowSum, PCFlowSumColors } from 'types/tape';
import { dayjs, formatAsCompactNumber, getDateFormatted } from 'util/shared';

interface RatioPieChartProps {
  data: PCFlowSum;
  colors: PCFlowSumColors;
}

type FlowType = keyof PCFlowSum;

const RatioPieChart: React.FC<RatioPieChartProps> = ({ data, colors }) => {
  const theme = useTheme();
  // Determine whether to show the 'total' value
  const showTotal: boolean = data.call !== null && data.put !== null;

  const formatValue = (value: number) => {
    if (data.format === 'currency') {
      return `$${formatAsCompactNumber(value)}`;
    } else if (data.format === 'date') {
      return getDateFormatted(dayjs(value));
    }
    return formatAsCompactNumber(value);
  };

  // Prepare pie data excluding 'total'
  const pieData = (['put', 'call'] as const)
    .filter((key) => data[key] != null)
    .map((key) => ({
      name: key as FlowType,
      value: data[key] as number, // since we filtered out nulls
      color: colors[key],
    }));

  // Custom label renderer for inner pies ('put' and 'call')
  const renderInnerLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme.palette.text.primary}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={11}
      >
        {formatValue(value)}
      </text>
    );
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight={150}
        minWidth={150}
      >
        <PieChart
          margin={{
            left: 20,
            right: 20,
            bottom: 5,
          }}
        >
          {showTotal && (
            <text
              x="50%"
              y="10%"
              textAnchor="middle"
              className="text-sm fill-current"
              fill={theme.palette.text.primary}
              fontSize={13}
            >
              {formatValue(data.total)}
            </text>
          )}
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            outerRadius={showTotal ? '45%' : '70%'}
            dataKey="value"
            label={renderInnerLabel}
            labelLine={false}
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default RatioPieChart;

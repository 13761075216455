import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useLog from '../../hooks/useLog';
import { Loader } from './Loader';
import { SGHtmlContent } from './SGHtmlContent';
import { Box, Typography } from '@mui/material';
import { Center } from './Center';
import { PrintButton } from './PrintButton';
import { Theme } from '@mui/material/styles';

export const SGHtmlPopup = () => {
  const [content, setContent] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [printTheme, setPrintTheme] = useState<Theme | undefined>();
  const contentRef = useRef(null);

  const { category } = useParams();
  const { fetchAPIWithLog } = useLog('SGHtmlPopup');

  async function fetchData() {
    if (category == null || content != null) {
      return;
    }

    setLoading(true);
    const data = await fetchAPIWithLog(
      `home/contentForCategory?category=${category}`,
    );
    setLoading(false);
    const html = data?.content?.html;
    if (html == null) {
      if (data?.error != null) {
        setErrored(true);
      }
      return;
    }
    setContent(html);
    setErrored(false);
  }

  useEffect(() => {
    fetchData();
  }, [category]);

  return (
    <Loader isLoading={loading}>
      {errored ? (
        <Center>
          <Typography onClick={fetchData}>
            There was an error. Click here to retry.
          </Typography>
        </Center>
      ) : (
        <Box sx={{ padding: '25px' }}>
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <PrintButton
              content={contentRef.current}
              onPrintTheme={setPrintTheme}
            />
          </Box>

          <Box ref={contentRef}>
            <SGHtmlContent html={content} theme={printTheme} />
          </Box>
        </Box>
      )}
    </Loader>
  );
};

import { Box, Link } from '@mui/material';
import { GammaVannaModelChart } from 'components';
import { useSearchParams } from 'react-router-dom';
import { GammaVannaModel } from 'types';

export const ZeroHedge = () => {
  const [searchParams] = useSearchParams();
  const showLink = searchParams.get('supressLink') !== 'true';

  return (
    <Box
      sx={{
        width: '300px',
        height: '300px',
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={() =>
        window.open(
          'https://zerohedge.com/spotgamma?utm_source=ZeroHedge%20Widget&utm_medium=ZeroHedge%20premium%20applet&utm_campaign=ZeroHedge%20Premium%20Offering',
          '_blank',
        )
      }
    >
      <GammaVannaModelChart
        model={GammaVannaModel.GAMMA}
        expandable={false}
        autoScroll={true}
        selectedSym="SPX"
      />
      {showLink && (
        <Link
          underline="hover"
          fontSize={11}
          href="https://zerohedge.com/spotgamma?utm_source=ZeroHedge%20Widget&utm_medium=ZeroHedge%20premium%20applet&utm_campaign=ZeroHedge%20Premium%20Offering"
        >
          Free trial at ZeroHedge.com/SpotGamma
        </Link>
      )}
    </Box>
  );
};

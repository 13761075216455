import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const Discord = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path
        d="M 11.859375 2.464844 C 10.953125 2.042969 9.984375 1.734375 8.972656 1.558594 C 8.847656 1.785156 8.703125 2.085938 8.601562 2.328125 C 7.523438 2.164062 6.457031 2.164062 5.398438 2.328125 C 5.300781 2.085938 5.152344 1.785156 5.027344 1.558594 C 4.011719 1.734375 3.042969 2.042969 2.136719 2.46875 C 0.308594 5.230469 -0.1875 7.925781 0.0585938 10.578125 C 1.273438 11.484375 2.445312 12.035156 3.601562 12.394531 C 3.886719 12.003906 4.140625 11.585938 4.359375 11.144531 C 3.941406 10.988281 3.542969 10.792969 3.164062 10.5625 C 3.265625 10.488281 3.363281 10.410156 3.457031 10.332031 C 5.761719 11.410156 8.265625 11.410156 10.542969 10.332031 C 10.636719 10.410156 10.734375 10.488281 10.835938 10.5625 C 10.457031 10.792969 10.054688 10.988281 9.636719 11.144531 C 9.855469 11.585938 10.109375 12.003906 10.394531 12.394531 C 11.550781 12.035156 12.726562 11.484375 13.941406 10.578125 C 14.230469 7.5 13.445312 4.832031 11.859375 2.464844 Z M 4.675781 8.945312 C 3.984375 8.945312 3.414062 8.300781 3.414062 7.515625 C 3.414062 6.730469 3.972656 6.082031 4.675781 6.082031 C 5.378906 6.082031 5.945312 6.726562 5.933594 7.515625 C 5.933594 8.300781 5.378906 8.945312 4.675781 8.945312 Z M 9.324219 8.945312 C 8.632812 8.945312 8.066406 8.300781 8.066406 7.515625 C 8.066406 6.730469 8.621094 6.082031 9.324219 6.082031 C 10.027344 6.082031 10.597656 6.726562 10.585938 7.515625 C 10.585938 8.300781 10.027344 8.945312 9.324219 8.945312 Z M 9.324219 8.945312 "
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default Discord;

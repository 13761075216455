import styled, { css } from 'styled-components';

interface AutoCompleteProps {
  disabled?: boolean;
}

export const Root = styled.div`
  position: relative;
  width: 100%;
`;

export const baseButtonMixin = css`
  background: #7c7f8d;
  border: none;
  padding: 0;
  font-family: 'Satoshi Complete';
  color: white;
`;

export const ValueWrapper = styled.input`
  width: 100%;
  padding-left: 12px;
  padding-right: 32px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 6px;
  border: none;
  line-height: 32px;
  color: #fff;
  font-family: 'Satoshi Complete', 'SF Pro Display';
`;

export const AutoCompleteContainer = styled.ul`
  background: #7c7f8d;
  padding: 8px 0;
  list-style-type: none;
  min-width: 300px;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  border: none;
  border-radius: 2px;
  margin: 0;
  box-sizing: border-box;
  max-height: 280px;
  overflow-y: auto;
  z-index: 10000;
`;

export const AutoCompleteItem = styled.li<AutoCompleteProps>`
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  color: white;
  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
    background-color: #646673;
  }`}
  ${(props) => (props.disabled ? 'opacity: 0.5' : ``)}
`;

export const AutoCompleteItemButton = styled.button<AutoCompleteProps>`
  ${baseButtonMixin} width: 100%;
  line-height: 32px;
  text-align: left;
  box-sizing: border-box;
  font-family: 'Satoshi Complete';
  color: white;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  ${(props) =>
    props.disabled
      ? ''
      : `&:active {
    outline: none;
    color: #fff;
  }`};
`;
export const Input = styled(ValueWrapper)`
  transition: border-color 150ms linear;

  &:focus {
    border: none;
    outline: none;
  }
`;

import { Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  onAdd: () => void;
}
const EmptyNotificationsGridOverlay = ({ onAdd }: Props) => {
  return (
    <Stack
      gap={4}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Typography>Get started by adding a new notification</Typography>
      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          textTransform: 'none',
        }}
      >
        Add notification
      </Button>
    </Stack>
  );
};

export default EmptyNotificationsGridOverlay;

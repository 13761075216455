import { useRecoilSnapshot } from 'recoil';
import { useEffect } from 'react';

export const RecoilDebugObserver = () => {
  // this sometimes has issues on Firefox and will crash the site
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug(
      '%c The following atoms were modified:',
      'background: #222; color: #bada55',
    );
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
    console.debug(
      '%c ====================',
      'background: #222; color: #bada55',
    );
  }, [snapshot]);

  return null;
};

import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { getLastMonday } from '../../util';
import { queryDateState } from '../../states';
import useLog from '../useLog';

const useEndOfDay = () => {
  const queryDate = useRecoilValue(queryDateState);
  const { fetchAPIWithLog } = useLog('useEndOfDay');

  const getEndOfDay = useCallback(
    async (instruments: string[], reversed?: boolean) => {
      const symbol = instruments.join(',');
      const startDate = '2018-10-22';
      // Have dates line up with OCC trade dates
      const endDate = getLastMonday();
      return await fetchAPIWithLog(
        `v1/twelve_series?symbol=${symbol}&interval=1day&start_date=${startDate}&end_date=${endDate}${
          reversed ? `&order=ASC` : ''
        }`,
      );
    },
    [queryDate],
  );

  return { getEndOfDay };
};

export default useEndOfDay;

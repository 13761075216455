import { styled, useTheme } from '@mui/material/styles';
import {
  SGCustomTag,
  SGCustomTagType,
  ProcessedTooltipDefinition,
} from '../../types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Typography } from '@mui/material';
import { ReactNode, CSSProperties } from 'react';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import {
  getDateFormatted,
  getQueryDate,
  levelsDateUpdated,
  prevBusinessDayOpenMarket,
} from '../../util';
import { SGKeyLevelsTable } from './custom_tags/SGKeyLevelsTable';
import { sgTooltipsState } from '../../states';

import './css/FoundersNote.scss';
import 'react-quill/dist/quill.snow.css';
import { SGGammaCurve } from './custom_tags/SGGammaCurve';
import { SGHtmlGlobalStyles } from '../shared/SGHtmlContent';
import { useRecoilValue } from 'recoil';
import * as React from 'react';
import { DomElement } from 'htmlparser2';
import { Theme } from '@mui/material/styles';
import useLog from '../../hooks/useLog';
import { HistoricalChart } from '../indices/charts/HistoricalChart';
import dayjs from 'dayjs';

type FoundersNoteProps = {
  html: string;
  title?: string;
  fullPage?: boolean;
  fromWordpress: boolean;
  id?: string;
  printTheme?: Theme;
  date?: dayjs.Dayjs;
  preview?: boolean;
};

const FoundersNoteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    sx={{ display: 'inline' }}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 250,
    fontSize: '14px',
    padding: '10px',
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  [`& .${tooltipClasses.tooltip} a`]: {
    color: theme.palette.text.secondary,
  },
}));

export const renderTooltipsInText = (
  text: string,
  sgTooltips: ProcessedTooltipDefinition[],
  typographyStyle: CSSProperties = { fontSize: '16px' },
  opts: {
    componentToDisplay?: React.ReactElement;
    uniformStyling?: boolean;
  } = {},
) => {
  if (sgTooltips.length === 0) {
    return opts.componentToDisplay ?? text;
  }
  let tooltipMatches: any = {};

  sgTooltips.forEach((def) => {
    const matches = text.match(def.regex) ?? [];
    matches.forEach((match) => (tooltipMatches[match] = def.html));
  });
  // 'tag' tooltips in text
  Object.keys(tooltipMatches).forEach((match: string) => {
    text = text
      .split(match)
      .join(`${SGCustomTag}tooltip${match}${SGCustomTag}tooltip`);
  });
  // componentToDisplay allows us to override what the tooltip displays
  // however to avoid rendering partial text we need to only render componentToDisplay when it is explicitly defined
  // and there are tooltips to render
  const onlyRenderTooltip =
    opts.componentToDisplay != null && Object.keys(tooltipMatches).length > 0;
  // read tagged tooltips and replace with tooltips component
  return text.split(`${SGCustomTag}tooltip`).map((t: string, idx: number) => {
    if (tooltipMatches[t]) {
      return (
        <FoundersNoteTooltip
          title={ReactHtmlParser(tooltipMatches[t])}
          key={`${t}${idx}`}
        >
          {opts.componentToDisplay ?? (
            <Typography
              style={{
                display: 'inline',
                textDecoration: 'underline',
                ...typographyStyle,
              }}
            >
              {t}
            </Typography>
          )}
        </FoundersNoteTooltip>
      );
    }

    return onlyRenderTooltip
      ? null
      : opts.uniformStyling
      ? opts.componentToDisplay ?? (
          <Typography style={{ display: 'inline', ...typographyStyle }}>
            {t}
          </Typography>
        )
      : t;
  }) as any;
};

export const FoundersNote = ({
  html,
  title,
  fullPage = true,
  fromWordpress,
  id,
  printTheme,
  date,
  preview = false,
}: FoundersNoteProps) => {
  const siteTheme = useTheme();
  const theme = printTheme ?? siteTheme;
  const sgTooltips = useRecoilValue(sgTooltipsState);
  const { logError } = useLog('FoundersNote');

  const titleElement = title != null && (
    <h1
      className="entry-title"
      style={{ marginBottom: '20px', textAlign: 'center' }}
    >
      {title}
    </h1>
  );

  const shouldRenderTooltip = (node: DomElement) => {
    // sometimes the immediate parent is a strong tag to highlight the link
    // so check two levels of parents
    return (
      printTheme == null &&
      node.type === 'text' &&
      !/h[1-6]/g.exec(node.parent?.name ?? '') &&
      node.parent?.name !== 'a' &&
      node.parent?.parent?.name !== 'a' &&
      node.parent?.attribs?.class !== 'founders-note-disclaimer'
    );
  };

  const parseHtmlAndReturnComponents = (str: string) => {
    if (sgTooltips.length == 0) {
      return ReactHtmlParser(str);
    }

    return ReactHtmlParser(str, {
      transform: (node) => {
        if (shouldRenderTooltip(node)) {
          return renderTooltipsInText(node.data, sgTooltips);
        }

        return undefined;
      },
    });
  };

  const checkIfKeyLevelsDataOutdated = (data: any) => {
    if (
      !preview ||
      data.type !== SGCustomTagType.SGPropKeyLevelsTable ||
      !Array.isArray(data?.data)
    ) {
      return;
    }

    const tradeDate = data.data[0]?.trade_date;
    if (tradeDate != null && !levelsDateUpdated(tradeDate)) {
      alert(
        `WARNING: You are trying to publish a note with key levels data that is out of date. The levels have a trade date of ${tradeDate} but the latest trading day is ${getDateFormatted(
          prevBusinessDayOpenMarket(getQueryDate(true)),
        )}`,
      );
    }
  };

  const renderHtmlAsReact = (): ReactNode[] => {
    const strParts = html.split(SGCustomTag);
    let ans: ReactNode[] = [];
    for (let i = 0; i < strParts.length; i++) {
      const str = strParts[i];
      if (str.startsWith('{') && str.endsWith('}')) {
        const data = JSON.parse(str);
        checkIfKeyLevelsDataOutdated(data);
        ans.push(
          <div style={{ marginTop: '10px' }} key={i}>
            {customTagTypeToComponent(data)}
          </div>,
        );
      } else {
        ans = ans.concat(parseHtmlAndReturnComponents(str));
      }
    }
    return ans;
  };

  const customTagTypeToComponent = (data: any) => {
    switch (data.type) {
      case SGCustomTagType.SGPropKeyLevelsTable:
      case SGCustomTagType.SGAddtlKeyLevelsTable:
      case SGCustomTagType.SGSupportResistanceTable:
        return (
          <SGKeyLevelsTable data={data} printTheme={printTheme} date={date} />
        );
      case SGCustomTagType.SGMacroTheme:
      case SGCustomTagType.SGWelcomeMessage:
        return ReactHtmlParser(data.data.html);
      case SGCustomTagType.SGGammaCurve:
        return <SGGammaCurve data={data.data} printTheme={printTheme} />;
      case SGCustomTagType.SGHistoricalChart:
        return <HistoricalChart initialData={data.data} selectedSym={'SPX'} />;
      default:
        return <></>;
    }
  };

  const noteStyle = {
    overflow: fullPage ? 'auto' : 'hidden',
    width: fullPage ? '100%' : 'auto',
    height: fullPage ? '100%' : 'auto',
    color: `${theme.palette.text.primary} !important`,
  };

  let renderHtml;
  try {
    renderHtml = renderHtmlAsReact();
  } catch (e) {
    logError(e, 'render');
    renderHtml = (
      <Typography fontSize="16px">
        There was an error displaying this note. Please try again later.
      </Typography>
    );
  }

  const printId = printTheme == null ? 'unprinted-note' : 'printed-note';

  return (
    <Box
      className={fromWordpress ? 'foundersNote' : 'ql-editor'}
      style={{
        maxWidth: '1100px',
        height: '100%',
        margin: fullPage ? 'auto' : 'initial',
      }}
    >
      {titleElement}
      {fromWordpress ? (
        <div
          style={{ ...noteStyle }}
          className={`foundersNoteBody ${
            fullPage ? 'fullPage' : 'homeContainer'
          }`}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      ) : (
        <Box width={1} height={1} id={printId}>
          <SGHtmlGlobalStyles theme={printTheme} id={printId} />
          {/* key below is important. it forces a re-render of the react-component when the note changes */}
          <Box width={1} height={1} key={id ?? title}>
            {renderHtml}
          </Box>
        </Box>
      )}
    </Box>
  );
};

import { alpha, useTheme } from '@mui/material/styles';
import { getDateFormatted, getUtcYMD } from 'util/shared/date';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Brush,
} from 'recharts';
import {
  ComponentHeader,
  ErrorContent,
  ExpandableContentButton,
  InfoButton,
  Loader,
  ZoomOutButton,
} from '../../shared';
import {
  occChartZoomConfigState,
  occCustomerOptionsTypeState,
  occDataState,
  occInitialDataState,
  occTransactionSizeState,
  priceDataState,
  timezoneState,
} from '../../../states';
import {
  IndexSymbol,
  OCC,
  OCCCategories,
  OCCCategory,
  OCCCustomerOptionType,
  RawOCC,
  SentimentTab,
} from '../../../types';
import {
  formatAsCompactNumber,
  formatAsCurrency,
  occCategoryIsPremium,
} from '../../../util';
import useOCC from '../../../hooks/sentiment/useOCC';
import { OCC_CUSTOMER_OPTION_NAME_MAPPING } from '../../../config/sentiment';
import useEndOfDay from 'hooks/sentiment/useEOD';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_Y2_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from 'config';
import { getZoomConfigRefArea } from 'util/shared/chart';
import useBrushZoom from 'hooks/useBrushZoom';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import { Stack } from '@mui/material';
import { OCCChartSettings } from './OCCControls';

export const OCCChart = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [data, setData] = useRecoilState(occDataState);
  const customerOptionsType = useRecoilValue(occCustomerOptionsTypeState);
  const transactionSize = useRecoilValue(occTransactionSizeState);
  const [priceData, setPriceData] = useRecoilState(priceDataState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [zoomConfig, setZoomConfig] = useRecoilState(occChartZoomConfigState);
  const [initialData, setInitialData] = useRecoilState(occInitialDataState);
  const { getOCC } = useOCC();
  const { getEndOfDay } = useEndOfDay();
  const currentTimezone = useRecoilValue(timezoneState);

  const { zoomChartConfig } = useBrushZoom<OCC>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  useEffect(() => {
    async function generatePrices() {
      setIsLoading(true);
      let data = await getEndOfDay([IndexSymbol.SPX]);
      const values = data[IndexSymbol.SPX].values;

      if (values == null) {
        return;
      }
      const ticks: Map<string, number> = new Map(
        values
          .filter((v: any) => v.close != null)
          .map((v: any) => [
            getDateFormatted(dayjs.tz(v.datetime, currentTimezone)),
            parseFloat(v.close),
          ]),
      );
      setIsLoading(false);
      setPriceData(ticks);
    }
    generatePrices();
  }, [getEndOfDay, setPriceData, currentTimezone]);

  useEffect(() => {
    async function generateOCC() {
      setIsLoading(true);
      let data = await getOCC();
      setData(data as RawOCC[]);
      setIsLoading(false);
    }
    generateOCC();
  }, [getOCC, setData]);

  useEffect(() => {
    if (data && priceData) {
      setIsLoading(true);
      // Sort occ data in descending date order and group by category
      const groupedData: Map<number, OCC[]> = data
        .filter((d) => d.trans_size === transactionSize)
        .reduce((acc: any, rawOCC: RawOCC) => {
          const epoch_millis = dayjs(rawOCC.trade_date).valueOf();
          const price = priceData.get(getDateFormatted(rawOCC.trade_date));
          const d = acc.get(epoch_millis) ?? { epoch_millis, price };
          d[rawOCC.category] = rawOCC[
            customerOptionsType as keyof RawOCC
          ] as number;
          return acc.set(epoch_millis, d);
        }, new Map<number, OCC[]>());
      const sortedData = Array.from(groupedData.values()).flat();
      sortedData.sort((a: OCC, b: OCC) => a.epoch_millis - b.epoch_millis);
      setInitialData(sortedData);
      setZoomConfig((prev) => ({ ...prev, data: sortedData }));
      setIsLoading(false);
    }
  }, [
    customerOptionsType,
    data,
    priceData,
    setInitialData,
    setZoomConfig,
    transactionSize,
  ]);

  if (initialData?.length === 0 && !isLoading) {
    return <ErrorContent />;
  }

  return (
    <Loader isLoading={isLoading}>
      <Stack
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          gap={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          <ZoomOutButton
            key="zoom"
            zoomConfig={zoomConfig}
            setZoomConfig={setZoomConfig}
            initialData={initialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: initialData.length - 1,
            }}
          />
          <InfoButton
            key={`${SentimentTab.OCC}-info`}
            articleKey={SentimentTab.OCC}
          />
          <ExpandableContentButton type={SentimentTab.OCC} />
        </Stack>
        <ChartWatermarkContainer ref={ref} size={25} offsetX={50} offsetY={50}>
          <ResponsiveContainer>
            <LineChart margin={DEFAULT_CHART_MARGINS} {...zoomChartConfig}>
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                allowDataOverflow
                label={{
                  value: 'Date',
                  fontSize: 12,
                  offset: 3,
                  position: 'insideBottom',
                  fontWeight: 600,
                }}
                dataKey="epoch_millis"
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 10 }}
                tickFormatter={getUtcYMD}
                type="number"
                tickCount={15}
              />
              <Brush
                dataKey="epoch_millis"
                tickFormatter={getUtcYMD}
                startIndex={zoomConfig.leftIdx}
                endIndex={zoomConfig.rightIdx}
                onChange={(brushIndices: any) =>
                  setZoomConfig((prev) => ({
                    ...prev,
                    leftIdx: brushIndices.startIndex,
                    rightIdx: brushIndices.endIndex,
                  }))
                }
                height={25}
                travellerWidth={15}
                stroke={theme.palette.gray}
                fill={theme.palette.background.paper}
                alwaysShowText
              />
              <YAxis
                allowDataOverflow
                yAxisId="left"
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 11 }}
                tickFormatter={(value: number) =>
                  `${
                    occCategoryIsPremium(
                      customerOptionsType as OCCCustomerOptionType,
                    )
                      ? '$'
                      : ''
                  }${formatAsCompactNumber(value)}`
                }
                type="number"
                label={{
                  value:
                    OCC_CUSTOMER_OPTION_NAME_MAPPING[
                      customerOptionsType as OCCCustomerOptionType
                    ],
                  ...DEFAULT_Y_AXIS_STYLES,
                }}
              />
              <YAxis
                allowDataOverflow
                yAxisId="right"
                orientation="right"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(value: number) =>
                  `$${formatAsCompactNumber(value)}`
                }
                tick={{ fontSize: 11 }}
                type="number"
                label={{
                  value: 'SPX Price',
                  ...DEFAULT_Y2_AXIS_STYLES,
                }}
              />
              <Tooltip
                isAnimationActive={false}
                formatter={(v: string, name: string) =>
                  name === 'Price' ||
                  occCategoryIsPremium(
                    customerOptionsType as OCCCustomerOptionType,
                  )
                    ? formatAsCurrency(v)
                    : parseFloat(v).toLocaleString()
                }
                labelFormatter={getUtcYMD}
                itemStyle={{
                  fontSize: '11px',
                  textShadow: `${theme.palette.background.paper} 1px 1px 4px, ${theme.palette.background.paper} 1px -1px 4px, ${theme.palette.background.paper} -1px 1px 4px, ${theme.palette.background.paper} -1px -1px 4px, ${theme.palette.background.paper} 2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px, ${theme.palette.background.paper} -2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px`,
                }}
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: alpha(theme.palette.background.paper, 0.5),
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              <Line
                yAxisId="right"
                type="basis"
                dataKey="price"
                name="Price"
                stroke={theme.palette.core.price}
                dot={false}
                strokeWidth={1.25}
                connectNulls
              />
              {OCCCategories.map((category) => (
                <Line
                  yAxisId="left"
                  type="basis"
                  dataKey={category}
                  name={category}
                  key={category}
                  stroke={theme.palette.sentiment.occ[category as OCCCategory]}
                  dot={false}
                  connectNulls
                  strokeWidth={1.25}
                />
              ))}
              {getZoomConfigRefArea(zoomConfig, 'left')}
            </LineChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      </Stack>
    </Loader>
  );
};

import dayjs from 'dayjs';
import { EarningsImpliedMove, RawEarningsImpliedMove } from 'types/resources';
import { ProcessedTooltipDefinition } from '../../types';
import { TooltipDefinition } from '../../components/admin/TooltipEditor';
import { getAvg } from './math';
import * as d3 from 'd3';

/** Returns level data for Real Time charts */
export const getLevelsFromDataAndFields = <Type>(
  fields: Record<string, string>,
  equity: any,
) => {
  const levelsByStrike = Object.entries(fields).reduce((map, [field, name]) => {
    const strike = equity[field as keyof Type] as number;
    const values: { field: string; name: string }[] = map.get(strike) ?? [];
    values.push({ field, name });
    return map.set(strike, values);
  }, new Map<number, { field: string; name: string }[]>());

  return Array.from(levelsByStrike.entries()).map(([strike, levels]) => ({
    field: levels.map((level) => level.field).join(', '),
    value: strike,
    name: levels.map((level) => level.name).join(', '),
  }));
};

export const processTooltips = (
  tooltipDefs: TooltipDefinition[],
): ProcessedTooltipDefinition[] => {
  const processedTooltips = tooltipDefs.map((def) => {
    const base = def.dontMatchPartialWord ? `${def.text} ` : def.text;
    let matches = [];
    if (!def.dontMatchPlural) {
      matches.push('s');
    }
    if (!def.dontMatchPunctuation) {
      matches = matches.concat([',', '/', '-', "'", '"', '.']);
    }
    return {
      regex: new RegExp(`${base}[${matches.join(`\\`)}]?`, 'i'),
      html: def.html,
    };
  });
  return processedTooltips;
};

export const getHourFromPeriod = (period: string) => {
  switch (period) {
    case 'BMO':
      return 7;
    case 'AMC':
      return 17;
    default:
      return 12;
  }
};

type EarningsBin = d3.Bin<RawEarningsImpliedMove, number>;

// Get the minimum distance and lower index between any pair of bins
function getMinBinDist(bins: EarningsBin[]): [number, number] {
  const midpoints = bins
    .map((bin) => getAvg(bin.map((e) => e.implied_move)))
    .filter(Number.isFinite); // we can have empty bins for which `getAvg` will return NaN
  let minIdxDist: [number, number] = [0, Number.POSITIVE_INFINITY];
  midpoints.slice(1).forEach((midpoint, idx) => {
    const dist = midpoint - midpoints[idx];
    if (dist < minIdxDist[1]) {
      minIdxDist = [idx, dist];
    }
  });
  return minIdxDist;
}

function mergeBins(a: EarningsBin, b: EarningsBin): EarningsBin {
  const x0 = Math.min(
    a.x0 ?? Number.POSITIVE_INFINITY,
    b.x0 ?? Number.POSITIVE_INFINITY,
  );
  const x1 = Math.max(
    a.x1 ?? Number.NEGATIVE_INFINITY,
    b.x1 ?? Number.NEGATIVE_INFINITY,
  );
  return Object.assign(a.slice().concat(b.slice()), { x0, x1 });
}

// Destrucively alters `bins` ensuring no 2 bins are closer than targetDist apart
function ensureBinDistance(bins: EarningsBin[], targetDist: number) {
  let [idx, minDist] = getMinBinDist(bins);
  while (minDist < targetDist && bins.length >= 1) {
    const merged = mergeBins(bins[idx], bins[idx + 1]);
    bins.splice(idx, 2, merged); // replace both bins with the merged one
    [idx, minDist] = getMinBinDist(bins);
  }
}

// The noon ("label") idx for the day in our day => idx mapping. 1, 5, 9, ...
export const getNoonIdx = (idx: number): number => Math.floor(idx / 4) * 4 + 1;

function getPeriodIdxShift(period: string): number {
  switch (period) {
    case 'BMO':
      return 0;
    case 'AMC':
      return 2;
    default:
      return 1;
  }
}

const MAX_BINS_PER_PERIOD = 8;
export const transformEarningsData = (
  data: RawEarningsImpliedMove[],
  allSymbols: boolean,
): EarningsImpliedMove[] => {
  // 1. Group by day & period (at most 3 periods per day, adding a 4th for spacing)
  // 2. Group each periods members into their own histogram by implied_move
  const day2index: Map<string, number> = new Map(
    [...new Set(data.map((d) => d.day)).values()]
      .sort()
      .map((day, idx) => [day, 4 * idx]),
  );
  const groupedByTime = data
    .filter(({ implied_move, inHiro }) =>
      implied_move > 0 && allSymbols ? true : inHiro,
    )
    .reduce((groups, d) => {
      // group by day first
      const idx = (day2index.get(d.day) ?? 0) + getPeriodIdxShift(d.period);
      let group = groups.get(idx) ?? [];
      group.push(d);
      return groups.set(idx, group);
    }, new Map<number, RawEarningsImpliedMove[]>());
  return [...groupedByTime.entries()]
    .map(([idx, members]: [number, RawEarningsImpliedMove[]]) => {
      // Search for bin count that doesn't space groups too closely along the y-axis (implied move %)
      let bins = d3
        .bin<RawEarningsImpliedMove, number>()
        .value((d) => d.implied_move)
        .thresholds(MAX_BINS_PER_PERIOD)(members);
      let targetDist = allSymbols ? 0.03 : 0.02;
      ensureBinDistance(bins, targetDist);
      return bins
        .map((bin) => {
          const syms = bin.map((d: RawEarningsImpliedMove) => d.sym);
          return {
            idx,
            implied_move: getAvg(bin.map((e) => e.implied_move)),
            period: bin[0]?.period,
            syms,
            bin,
            day: dayjs(bin[0]?.day).add(12, 'hours'),
          };
        })
        .filter((group) => group.bin.length > 0);
    })
    .flat();
};

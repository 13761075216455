import { StockScanner } from './StockScanner';
import useAuth from '../../hooks/auth/useAuth';
import { StrategyCompassWithLayout } from '../../components/equityhub/Compass/StrategyCompassWithLayout';

export const StockScannerWithCompass = () => {
  const { canViewCompass } = useAuth();
  if (!canViewCompass) {
    return <StockScanner />;
  }

  return <StrategyCompassWithLayout />;
};

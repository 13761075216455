import { useEffect, useState, useRef } from 'react';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import {
  GammaLevelType,
  IndexSymbol,
  IndicesContentType,
  SymSelectorSettings,
} from '../../../types';
import {
  formatAsCompactNumber,
  formatAsCurrency,
  parseSpaceDelimittedList,
} from '../../../util';
import useComboStrikes from '../../../hooks/indices/useComboStrikes';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';

interface ComboStrikesChartProps {
  hideTitle?: boolean;
  selectedSym: string;
  symSelectorSettings?: SymSelectorSettings;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
}

export const ComboStrikesChart = ({
  hideTitle,
  selectedSym,
  chartStyleOverrides,
  containerStyleOverrides,
  symSelectorSettings,
}: ComboStrikesChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [comboStrikesData, setComboStrikesData] = useState<
    { strike: number; gamma: number }[] | undefined
  >(undefined);
  const { getComboStrikes } = useComboStrikes();
  const [gammaMode, setGammaMode] = useState<GammaLevelType>(
    GammaLevelType.CURRENT,
  );

  useEffect(() => {
    async function generateChartData() {
      let data = await getComboStrikes(gammaMode, selectedSym);
      if (data.length > 0) {
        const gammaList = parseSpaceDelimittedList(data[0].strike_list, ' ');
        const strikeList = parseSpaceDelimittedList(data[0].current_list, ' ');
        setComboStrikesData(
          strikeList.map((px: number, i: number) => ({
            strike: px,
            gamma: gammaList[i],
          })),
        );
      }
    }
    generateChartData();
  }, [getComboStrikes, gammaMode, selectedSym, setComboStrikesData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        type={IndicesContentType.COMBO_STRIKES}
        title="Combo Strikes"
        expandable
        symSelectorSettings={symSelectorSettings}
        symbol={selectedSym}
        hideTitle={hideTitle}
        controllerProps={{
          buttonGroups: [
            {
              buttons: [
                { value: GammaLevelType.CURRENT, label: 'Total' },
                { value: GammaLevelType.NEXT_EXP, label: 'Next Expiration' },
              ],
              setter: setGammaMode,
              curValue: gammaMode,
            },
          ],
        }}
      />
      {comboStrikesData && (
        <ChartWatermarkContainer
          ref={ref}
          style={{ ...chartStyleOverrides }}
          size={25}
          offsetX={50}
          offsetY={40}
        >
          <ResponsiveContainer>
            <BarChart
              data={comboStrikesData}
              margin={{ ...DEFAULT_CHART_MARGINS, left: 10, right: 20 }}
              stackOffset="sign"
            >
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                dataKey="strike"
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 11 }}
                tickFormatter={(v: number) =>
                  `$${formatAsCompactNumber(v, {
                    maximumSignificantDigits: 3,
                  })}`
                }
                label={{
                  value: 'Strike',
                  ...DEFAULT_X_AXIS_STYLES,
                }}
                tickCount={10}
                type="number"
                interval="preserveStartEnd"
              />
              <YAxis
                domain={['dataMin', 'dataMax']}
                tickFormatter={(v: number) =>
                  formatAsCompactNumber(v, {
                    style: 'currency',
                    currency: 'USD',
                  })
                }
                tick={{ fontSize: 11 }}
                label={{
                  value: 'Gamma',
                  ...DEFAULT_Y_AXIS_STYLES,
                  offset: -5,
                }}
              />
              <Tooltip
                formatter={(v: string) => formatAsCurrency(v)}
                labelFormatter={(value) => `Strike: ${formatAsCurrency(value)}`}
                itemStyle={{ fontSize: '11px' }}
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              <Bar
                dataKey="gamma"
                name="Gamma"
                barSize={20}
                fill={theme.palette.indices.combos.gamma}
              />
            </BarChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      )}
    </Box>
  );
};

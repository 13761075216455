// No type => barfs on import
const jstat = require('jstat');

// TODO: Plumb this down through the middle layer
const RISK_FREE_RATE = 0.0544; // 3-mo DGS3MO as of 2024-02-08

// Fallback to a 0% dividend rate when not provided
const DEFAULT_DIV_RATE = 0.0;

const EPSILON = 1e-8;

export type IVolInit = {
  currentPrice: number;
  riskFreeRate?: number;
  dividendRate?: number;
  strike: number;
  yte: number;
};

export type BlackScholesCore = IVolInit & { isCall: boolean };

export type BlackScholesProps = BlackScholesCore & { iVol: number };

export type FindIVolProps = BlackScholesCore & { price: number };

export const getFwdPrice = (
  price: number,
  dividendRate: number | undefined,
  riskFreeRate: number | undefined,
  yte: number,
) => {
  const rfr = riskFreeRate ?? RISK_FREE_RATE; // TODO: Remove fallback when plumbed
  const divRate = dividendRate ?? DEFAULT_DIV_RATE;
  return price * Math.exp((rfr - divRate) * yte);
};

// Manaster and Koehler (1982) show the following initial guess guarantees that the Newton-Raphson converges
export const getInitialIVolGuess = ({
  currentPrice,
  dividendRate,
  riskFreeRate,
  strike,
  yte,
}: IVolInit) => {
  const fwdPrice = getFwdPrice(currentPrice, dividendRate, riskFreeRate, yte);
  return Math.sqrt((2 * Math.log(fwdPrice)) / strike);
};

// Back out implied volatility from the market option price.
// A tweaked Newton-Raphson method: http://www.appliedbusinesseconomics.com/files/gvsnr02.pdf
// Tweak: Epsilon target based on price (output) not ivol (input). Result is the same, just more ergonomic code
export const findIVol = (args: FindIVolProps) => {
  let ivGuess = getInitialIVolGuess(args);
  let priceGuess = Number.POSITIVE_INFINITY;
  let iter = 0;
  while (Math.abs(args.price - priceGuess) > EPSILON) {
    const bsm = new BlackScholes({ ...args, iVol: ivGuess });
    priceGuess = bsm.price();
    const vega = bsm.vega();
    if (++iter >= 20) {
      break; // prevent a potential infinite loop (should never happen)
    }
    ivGuess = (args.price - priceGuess + vega * ivGuess) / vega;
  }
  return ivGuess;
};

// Normal (Guassian) cumulative distribution function
export const normCDF = (n: number, mean: number = 0, sd: number = 1) =>
  jstat.normal.cdf(n, mean, sd);

// Black-Scholes-Merton
// https://www.investopedia.com/terms/b/blackscholes.asp
export class BlackScholes {
  sign: number = 1; // 1 = Call, -1 = Put
  S: number; // Current underlying price
  K: number; // Strike price
  r: number; // Risk Free Rate (annual)
  q: number; // Continuous Dividend Rate (annual)
  T: number; // Time to expiration (years)
  iv: number; // implied volatility ("sigma" in most Black-Scholes-Merton equations)
  d1: number; // memoized math: https://www.macroption.com/black-scholes-formula/
  d2: number; // same as above

  // hand-rolled memoization members
  #price: number | null = null;
  #vega: number | null = null;

  constructor({
    isCall,
    currentPrice,
    strike,
    riskFreeRate,
    dividendRate,
    yte,
    iVol,
  }: BlackScholesProps) {
    this.sign = isCall ? 1 : -1;
    this.S = currentPrice;
    this.K = strike;
    this.r = riskFreeRate ?? RISK_FREE_RATE; // TODO: Remove fallback when plumbed
    this.q = dividendRate ?? DEFAULT_DIV_RATE;
    this.T = yte;
    this.iv = iVol;

    const sigmaT = this.iv * Math.sqrt(this.T);
    this.d1 =
      (Math.log(this.S / this.K) +
        (this.r - this.q + 0.5 * this.iv ** 2) * this.T) /
      sigmaT;
    this.d2 = this.d1 - sigmaT;
  }

  // option price / premium
  price() {
    if (this.#price == null) {
      this.#price =
        this.sign *
        (this.S * Math.exp(-this.q * this.T) * normCDF(this.sign * this.d1) -
          this.K * Math.exp(-this.r * this.T) * normCDF(this.sign * this.d2));
    }
    return this.#price as number;
  }

  // Derivative of option price w.r.t. implied vol
  vega() {
    if (this.#vega == null) {
      // dN: derivative of "N" normal distribution in BSM
      const dN = (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * this.d1 ** 2);
      this.#vega = this.S * Math.sqrt(this.T) * Math.exp(-this.q * this.T) * dN;
    }
    return this.#vega as number;
  }
}

export default BlackScholes;

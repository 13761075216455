import { ReactNode } from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import { useTheme } from '@mui/material/styles';
import { Box, Dialog, DialogContent, SxProps, Theme } from '@mui/material';
import { componentIsExpandedState } from 'states';

interface ExpandableContentWrapperProps {
  children: ReactNode;
  type?: string;
  styleOverrides?: SxProps<Theme>;
  isOpenState?: RecoilState<boolean>;
}
export const ExpandableContentWrapper = ({
  children,
  type,
  styleOverrides,
  isOpenState,
}: ExpandableContentWrapperProps) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useRecoilState(
    isOpenState ?? componentIsExpandedState(type!),
  );

  return (
    <>
      {!isOpen ? (
        <Box
          sx={{
            transition: '0.5s',
            display: 'flex',
            background: theme.palette.background.paper,
            borderRadius: theme.spacing(3),
            height: '100%',
            ...styleOverrides,
          }}
          key={type}
        >
          {children}
        </Box>
      ) : (
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          fullScreen
          fullWidth
        >
          <DialogContent sx={{ ...styleOverrides }}>
            <Box
              sx={{
                p: 5,
                height: '100%',
                width: '100%',
                background: theme.palette.background.paper,
                ...styleOverrides,
              }}
            >
              {children}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

import { Box, Tab, TabScrollButton } from '@mui/material';
import { alpha, styled, SxProps, Theme, useTheme } from '@mui/material/styles';
import { TabbedControls, TabbedControlsProps } from './TabbedControls';
import TabList from '@mui/lab/TabList';
import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import { ReactElement } from 'react';

// Suppress MUI's default setting of textTransform: uppercase
const StyledTab = styled((props: any) => <Tab {...props} />)(() => ({
  textTransform: 'capitalize',
}));

const HiddenIfDisabledScrollButton = styled(TabScrollButton)({
  '&.Mui-disabled': {
    width: '15px', // serves as some light padding when disabled
  },
  overflow: 'hidden',
  transition: 'width 0.5s',
  width: 28,
});

export interface TabsProps {
  options: Map<string, string | ReactElement> | string[];
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  controlProps?: TabbedControlsProps;
  wrapperClassName?: string;
  fontSize?: string;
  defaultScrollable?: boolean;
  isFullWidth?: boolean;
  labelOpacity?: number;
  labelHoverOpacity?: number;
  selected?: string;
  tabListSx?: SxProps<Theme>;
  tabButtonSx?: SxProps<Theme>;
}

export const mapFromLabels = (labels: string[]) => {
  return new Map(labels.map((s, idx) => [`${idx}`, s]));
};

export const mapFromObj = (
  labels: Record<string, string | null | ReactElement>,
) =>
  new Map(Object.entries(labels).filter(([_k, v]) => v != null)) as Map<
    string,
    string | ReactElement
  >;

export const Tabs = ({
  options,
  onChange,
  controlProps,
  wrapperClassName,
  fontSize = '14px',
  defaultScrollable = false,
  isFullWidth = false,
  labelOpacity = 1,
  labelHoverOpacity = 1,
  selected,
  tabListSx,
  tabButtonSx,
}: TabsProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);

  if (Array.isArray(options)) {
    options = mapFromLabels(options);
  }

  const entries = [...options.entries()];

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
      className={wrapperClassName ?? ''}
    >
      <TabList
        allowScrollButtonsMobile
        onChange={onChange}
        variant={isMobile || defaultScrollable ? 'scrollable' : 'standard'}
        ScrollButtonComponent={HiddenIfDisabledScrollButton}
        sx={{
          width: isFullWidth ? '100%' : 'auto',
          ...tabListSx,
        }}
      >
        {entries.map(([value, label]) => {
          if (typeof label !== 'string') {
            return label;
          }
          const props = {
            key: value,
            label: label,
            value: value,
            sx: {
              fontSize: fontSize,
              backgroundColor:
                value === selected
                  ? alpha(theme.palette.primary.main, 0.1)
                  : 'transparent',
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
              width: isFullWidth ? '100%' : 'auto',
              maxWidth: isFullWidth ? `${(1 / entries.length) * 100}%` : 'auto',
              padding: theme.spacing(3),
              opacity: labelOpacity,
              '&:hover': {
                opacity: labelHoverOpacity,
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
              },
              ...tabButtonSx,
            },
          };
          return <StyledTab {...props} />;
        })}
      </TabList>
      {controlProps && <TabbedControls {...controlProps} />}
    </Box>
  );
};

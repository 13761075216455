import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertData, SubLevel } from 'types';
import { Alert } from 'components/core/alerts';
import { useState, useMemo, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { timezoneState, userSubLevelState } from 'states';
import useLog from '../../hooks/useLog';

interface GroupedAlertsProps {
  day: string;
  alerts: AlertData[];
  defaultExpanded: boolean;
}

export const GroupedAlerts = ({
  day,
  alerts,
  defaultExpanded,
}: GroupedAlertsProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Accordion
      key={day}
      defaultExpanded={defaultExpanded}
      onChange={(_evt, expanded: boolean) => setExpanded(expanded)}
    >
      <AccordionSummary
        sx={{ height: '40px', minHeight: '40px', paddingLeft: '30px' }}
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <Typography variant="h3" sx={{ paddingLeft: '8px' }}>
          Alerts for {day}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Box>
            {alerts.map((alert, idx) => (
              <Alert wide key={`${idx}`} alert={alert} />
            ))}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export const AlertsList = () => {
  const subscriptionLevel = useRecoilValue(userSubLevelState);
  const currentTimezone = useRecoilValue(timezoneState);
  const [alerts, setAlerts] = useState([]);
  const { fetchAPIWithLog } = useLog('AlertsList');

  useEffect(() => {
    const fetchAndSetAlerts = async () => {
      const fetchedAlerts = await fetchAPIWithLog(`v1/myAlertsByDays?days=5`);
      setAlerts(fetchedAlerts);
    };
    if (subscriptionLevel >= SubLevel.PRO) {
      fetchAndSetAlerts();
    }
  }, [subscriptionLevel]);

  const groupedAlerts: Map<string, AlertData[]> = useMemo(
    () =>
      alerts.reduce((alerts, alert: AlertData) => {
        const etTime = dayjs(alert.alert_time).tz(currentTimezone);
        const day = etTime.format('ddd, MMM D');
        const dayAlerts = alerts.get(day) ?? [];
        dayAlerts.push(alert);
        return alerts.set(day, dayAlerts);
      }, new Map()),
    [alerts],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxHeight="80vh"
      sx={{ overflowY: 'auto' }}
    >
      {[...groupedAlerts.entries()].map(([day, alerts], idx) => (
        <Box sx={{ width: '400px' }}>
          <GroupedAlerts
            day={day}
            alerts={alerts}
            defaultExpanded={idx === 0}
          />
        </Box>
      ))}
    </Box>
  );
};

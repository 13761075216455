import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedIndexState } from 'states/indices';
import { fetchAPI } from '../../util/shared/fetch';
import useLog from '../useLog';

const useOptionsRiskReversal = () => {
  const sym = useRecoilValue(selectedIndexState);
  const { fetchAPIWithLog } = useLog('useOptionsRiskReversal');

  const getOptionsRiskReversal = useCallback(async () => {
    return await fetchAPIWithLog(`v1/optionsRiskReversal?sym=${sym}`);
  }, [sym]);

  return { getOptionsRiskReversal };
};

export default useOptionsRiskReversal;

import { Navigate, useLocation } from 'react-router-dom';
import { getHasLoggedIn, isBloomberg } from '../../util';
import { useRecoilValue } from 'recoil';
import { userIsLoggedInState } from '../../states';

export const ForceAuthRoute = ({ children }: { children?: any }) => {
  const loggedIn = useRecoilValue(userIsLoggedInState);
  const location = useLocation();

  // Allow logged in users or for users that have never logged in previously,
  // let them see the free-preview experience.
  const allowAuth = loggedIn || (!isBloomberg() && !getHasLoggedIn());

  if (!allowAuth) {
    return (
      <Navigate
        to={'/login'}
        state={{ postLoginPath: `${location.pathname}${location.search}` }}
      />
    );
  }

  return children;
};

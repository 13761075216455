import { Dispatch, SetStateAction } from 'react';
import { Button, ButtonGroup, Stack } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { isMobileState } from 'states';
import { useRecoilValue } from 'recoil';

export interface ButtonGroup {
  buttons: { value: any; label: string }[];
  setter: Dispatch<SetStateAction<any>>;
  curValue: any;
}

export interface ControlsProps {
  buttonGroups: ButtonGroup[];
}

export const Controls = ({ buttonGroups }: ControlsProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Stack direction="row" gap={2} sx={{ overflowX: 'auto' }}>
      {buttonGroups.map(({ buttons, setter, curValue }, idx) => (
        <ButtonGroup key={`${idx}`} variant="outlined">
          {buttons.map(({ value, label }, idx) => (
            <Button
              size={isMobile ? 'small' : 'medium'}
              key={idx}
              sx={{
                fontFamily: 'SF Pro Display',
                fontSize: 11,
                textTransform: 'capitalize',
                padding: '6px',
                backgroundColor:
                  curValue === value
                    ? alpha(theme.palette.primary.main, 0.25)
                    : 'transparent',
                color: theme.palette.primary.main,
              }}
              onClick={() => {
                setter(value);
              }}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      ))}
    </Stack>
  );
};

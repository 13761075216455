import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ToastData, Watchlist } from '../../types';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  watchlistsState,
  playAlertAudioState,
  userDetailsState,
} from '../../states';
import { cloneDeep } from 'lodash';
import { SGModal } from '../shared/SGModal';
import { arraySpliceCopy, isBloomberg } from '../../util';
import useWatchlists from '../../hooks/useWatchlists';
import { EditWatchlist } from './EditWatchlist';

type EditWatchlistsProps = {
  onClose?: () => void;
  onChange?: (watchlists: Watchlist[]) => void;
  open?: boolean;
  modal: boolean;
  openToast?: (toastData: ToastData) => void;
};

const MAX_NUM_WATCHLISTS = 15;

export const EditWatchlists = ({
  onClose,
  open,
  modal,
  onChange,
  openToast = (_) => {},
}: EditWatchlistsProps) => {
  const userDetails = useRecoilValue(userDetailsState);
  const [watchlists, setWatchlists] = useRecoilState(watchlistsState);
  // newWatchlists handles number of watchlists + initial content but we do not update it on onChange
  // since that causes UI weirdness when updating/re-rendering an item the user is actively editing
  // so new watchlists is the number of watchlists + initial state + what we render initially
  // watchlistSaveData is what changes as the user edits
  const [newWatchlists, setNewWatchlists] = useState(watchlists ?? []);
  // watchlistSaveData will be updated with all the changes as the user edits but we do not use it to render
  const [watchlistSaveData, setWatchlistSaveData] = useState(newWatchlists);
  const [watchlistSaveLoading, setWatchlistSaveLoading] = useState(false);
  const { saveWatchlists } = useWatchlists();
  const playAlertAudio = useRecoilValue(playAlertAudioState);

  const setLocalWatchlists = (w: Watchlist[]) => {
    setNewWatchlists(w);
    setWatchlistSaveData(cloneDeep(w));
  };

  const onCloseModal = useCallback(() => {
    setLocalWatchlists(watchlists ?? []);
    if (onClose != null) {
      onClose();
    }
  }, [userDetails, newWatchlists, watchlistSaveData]);

  useEffect(() => {
    if (modal && !open && watchlists != null) {
      setLocalWatchlists(watchlists);
    }
  }, [modal, open, watchlists]);

  useEffect(() => {
    if (onChange != null) {
      onChange(watchlistSaveData);
    }
  }, [watchlistSaveData]);

  const addWatchlist = useCallback(() => {
    if (newWatchlists.length >= MAX_NUM_WATCHLISTS) {
      openToast({
        message: `You've reached the maximum of ${MAX_NUM_WATCHLISTS} watchlists`,
        type: 'error',
      });
      return;
    }

    const newWatchlist: Watchlist = {
      id: -1,
      disabledAlertCategories: [],
      soundDisabled: !playAlertAudio,
      name: 'New Watchlist',
      symbols: [],
    };
    setNewWatchlists([...newWatchlists].concat([newWatchlist]));
    setWatchlistSaveData([...watchlistSaveData].concat([newWatchlist]));
  }, [newWatchlists, watchlistSaveData]);

  const onWatchlistChange = (newWatchlist: Watchlist | null, index: number) => {
    if (newWatchlist == null) {
      // deleted this watchlist so splice it out
      setLocalWatchlists(arraySpliceCopy(watchlistSaveData, index));
      return;
    }

    const currWatchlists = [...watchlistSaveData];
    currWatchlists.splice(index, 1, newWatchlist);
    setWatchlistSaveData(currWatchlists);
  };

  const save = useCallback(async () => {
    if (watchlistSaveLoading) {
      return;
    }

    setWatchlistSaveLoading(true);
    const response = await saveWatchlists(watchlistSaveData);
    setWatchlistSaveLoading(false);
    if (Array.isArray(response.watchlists) && response.error == null) {
      setWatchlists(response.watchlists);
      setLocalWatchlists(response.watchlists);

      openToast({
        message: `Successfully saved watchlists.`,
        type: 'success',
      });
      if (onClose != null) {
        onClose();
      }
    } else {
      openToast({
        message: `There was an error saving your watchlists. Please try again.`,
        type: 'error',
      });
    }
  }, [watchlistSaveData, watchlistSaveLoading, userDetails]);

  // bloomberg users cannot edit their watchlists from our app - must use worksheets
  if (userDetails == null || isBloomberg()) {
    return null;
  }

  const body = (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        {newWatchlists.map((watchlist, i) => (
          <EditWatchlist
            key={`${watchlist.name}-${i}`}
            watchlist={watchlist}
            onChange={(newWatchlist) => onWatchlistChange(newWatchlist, i)}
            modal={modal}
          />
        ))}

        <Button
          onClick={addWatchlist}
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            marginTop: '15px',
          }}
        >
          + New Watchlist
        </Button>
      </Box>
    </Box>
  );

  return modal ? (
    <SGModal
      open={open}
      onClose={onCloseModal}
      onSave={save}
      saveLoading={watchlistSaveLoading}
      title="Edit Watchlists"
      description="Modify your watchlists. Changes won't take effect until saved."
    >
      {body}
    </SGModal>
  ) : (
    body
  );
};

import { alpha, useTheme } from '@mui/material/styles';
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
} from '@spotgamma/x-data-grid-premium';

interface IndicesTableProps {
  columns: GridColDef[];
  data: any[];
  isLoading: boolean;
}

export const IndicesTable = ({
  columns,
  data,
  isLoading,
}: IndicesTableProps) => {
  const theme = useTheme();
  const defaultContentColor = theme.palette.getContrastText(
    theme.palette.background.default,
  );

  return (
    <DataGridPremium
      loading={isLoading}
      rows={data}
      columns={columns}
      initialState={{
        pinnedColumns: { left: ['strike', 'expiration'] },
      }}
      disableColumnFilter
      disableColumnMenu
      disableAggregation
      disableRowGrouping
      disableRowSelectionOnClick
      disableMultipleRowSelection
      hideFooter
      pageSizeOptions={[5, 10, 25, 50, 100]}
      rowHeight={55}
      density="compact"
      sx={{
        border: `2px solid ${alpha(theme.palette.primary.main, 0.25)}`,
        '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-filler': {
          display: 'none',
        },
        '& .MuiDataGrid-row.Mui-selected': {
          backgroundColor: theme.palette.scannerGrid.pinnedRow,
        },
        '& .MuiDataGrid-scrollbar': {
          background: theme.palette.background.default,
        },
        '& .MuiDataGrid-pinnedRows': {
          '& .MuiDataGrid-row': {
            backgroundColor: theme.palette.scannerGrid.pinnedRow,
          },
          '& .MuiDataGrid-pinnedColumns': {
            backgroundColor: theme.palette.scannerGrid.pinnedRow,
          },
        },
        '& .MuiDataGrid-pinnedColumns': {
          backgroundColor: theme.palette.scannerGrid.pinnedColumn,
        },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: alpha(theme.palette.text.primary, 0.1),
        },
        '& .custom-group-header': {
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'center',
            height: '25px',
          },
        },
        '& .grid-header-cell': {
          ':hover': {
            backgroundColor: alpha(theme.palette.text.primary, 0.05),
          },
          border: 'none',
        },
        '& .MuiDataGrid-topContainer': {
          border: 'none',
        },
        '& .MuiDataGrid-topContainer::after': {
          backgroundColor: alpha(theme.palette.primary.main, 0.25),
          height: 2,
        },
        '& .MuiDataGrid-columnHeader': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          border: 'none',
        },
        '& .MuiDataGrid-sortIcon': {
          color: defaultContentColor,
        },
        '& .MuiDataGrid-menuIconButton': {
          color: defaultContentColor,
        },
        '& .MuiDataGrid-filterIcon': {
          color: defaultContentColor,
        },
        '& .MuiDataGrid-menuIconButton:hover': {
          backgroundColor: theme.palette.background.paper,
          color: alpha(defaultContentColor, 0.5),
        },
        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-footerContainer': {
          justifyContent: 'flex-start',
        },
        '& .MuiDataGrid-cell': {
          color: defaultContentColor,
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiDataGrid-row .MuiDataGrid-cell': {
          border: 'none',
        },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none',
          },
      }}
    />
  );
};

import { Avatar, Box, IconButton, Stack } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { Products } from '../Products';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import MenuIcon from '@mui/icons-material/Menu';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import PublicIcon from '@mui/icons-material/Public';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  isSideDrawerExpandedState,
  isMobileState,
  userDetailsState,
  expandedLinkGroupIdState,
} from '../../../states';
import { styled } from '@mui/system';
import useLog from '../../../hooks/useLog';
import CollapsibleLinkGroup from './CollapsibleLinkGroup';
import { LinkGroupId } from 'types';
import { MAIN_RESOURCES } from 'config/resources';
import { Link } from '../Link';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Discord from 'icons/Discord';
import LogoutLink from '../LogoutLink';
import { isBloomberg } from 'util/bloomberg';
import { useEffect } from 'react';
import { showNoLoginUI } from '../../../util';

export const SIDEBAR_WIDTH = 60;
export const SIDEBAR_WIDTH_OPEN = 240;

const SIDEBAR_TRANSITION_DURATION = 50;

const Drawer = styled(MuiDrawer)(({ theme, open }: any) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    overflowX: 'hidden',
    width: open ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: SIDEBAR_TRANSITION_DURATION,
    }),
    boxSizing: 'border-box',
    boxShadow: theme.shadows[2],
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    ...(open && {
      zIndex: (theme: any) => theme.zIndex.appBar + 1,
    }),
  },
}));

export function Sidebar() {
  const isMobile = useRecoilValue(isMobileState);
  const userDetails = useRecoilValue(userDetailsState);
  const [isDrawerExpanded, setIsDrawerExpanded] = useRecoilState(
    isSideDrawerExpandedState,
  );
  const setExpandedLinkGroupId = useSetRecoilState(expandedLinkGroupIdState);
  const { getTimeSignature } = useLog('Sidebar');

  const closeSidebar = () => {
    setIsDrawerExpanded(false);
  };

  useEffect(() => {
    if (isMobile) {
      closeSidebar();
      setExpandedLinkGroupId(undefined);
    }
  }, []);

  if (userDetails == null && showNoLoginUI()) {
    return null;
  }

  const handleDrawerToggle = () => {
    setIsDrawerExpanded(!isDrawerExpanded);
    setExpandedLinkGroupId(undefined);
  };

  const resourceLinks = (
    <Stack gap={2}>
      {MAIN_RESOURCES.map((resource) => (
        <Link
          to={resource.link}
          text={resource.name}
          relativeLink={false}
          key={resource.link}
          isLabelShown
        />
      ))}
      <Link to="/resources" text="All Resources" isLabelShown />
    </Stack>
  );

  const onLinkClick = () => {
    if (isMobile) {
      closeSidebar();
      setExpandedLinkGroupId(undefined);
    }
    if (!isDrawerExpanded) {
      setExpandedLinkGroupId(undefined);
    }
  };

  const socialLinks = (
    <Stack gap={2}>
      <Link
        text="X"
        isLabelShown
        to="https://twitter.com/spotgamma"
        icon={<XIcon width="24px" />}
        relativeLink={false}
        onClick={onLinkClick}
      />
      <Link
        text="Youtube"
        isLabelShown
        to="https://www.youtube.com/spotgamma"
        icon={<YouTubeIcon width="24px" />}
        relativeLink={false}
        onClick={onLinkClick}
      />
      <Link
        text="Discord"
        isLabelShown
        to="/resources/discord"
        icon={<Discord width="24px" />}
        onClick={onLinkClick}
      />
    </Stack>
  );

  const accountLinks = (
    <Stack gap={2}>
      {userDetails != null && (
        <Link
          isLabelShown
          to="/preferences"
          icon={<SettingsIcon width="24px" />}
          text="Preferences"
          onClick={onLinkClick}
        />
      )}
      {!isBloomberg() && (
        <>
          <LogoutLink onClickCallback={onLinkClick} />
          <Link
            isLabelShown
            to="https://spotgamma.com/"
            icon={<ArrowBackIcon width="24px" />}
            text="Back to SpotGamma"
            relativeLink={false}
            onClick={onLinkClick}
          />
        </>
      )}
    </Stack>
  );

  const drawerContent = [
    <Products />,
    <Stack gap={1}>
      {!isBloomberg() && (
        <CollapsibleLinkGroup
          groupId={LinkGroupId.Resources}
          title={LinkGroupId.Resources}
          icon={<TipsAndUpdatesIcon width="24px" />}
          children={resourceLinks}
        />
      )}
      <CollapsibleLinkGroup
        groupId={LinkGroupId.Social}
        title={LinkGroupId.Social}
        icon={<PublicIcon width="24px" />}
        children={socialLinks}
      />
      <CollapsibleLinkGroup
        groupId={LinkGroupId.Account}
        title={LinkGroupId.Account}
        icon={
          userDetails?.wordpress?.wp_user?.avatar_urls != null ? (
            <Avatar
              src={`url(${userDetails.wordpress.wp_user.avatar_urls![48]})`}
              sx={{ width: '20px', height: '20px' }}
            />
          ) : (
            <AccountCircleIcon width="24px" />
          )
        }
        children={accountLinks}
      />
    </Stack>,
    isDrawerExpanded && getTimeSignature(false),
  ];

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isDrawerExpanded}
      onClose={() => {
        setExpandedLinkGroupId(undefined);
        setIsDrawerExpanded(false);
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Box
        sx={{
          padding: '20px',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box
        sx={{
          padding: '15px',
          display: 'flex',
          flexDirection: 'column',
          gap: '45px',
        }}
      >
        {drawerContent}
      </Box>
    </Drawer>
  );
}

import { Box, Button, Link } from '@mui/material';
import useIntegrations from '../../hooks/integrations/useIntegrations';
import GALogger, { GALoggerCategory } from '../../util/shared/analytics';
import useToast from '../../hooks/useToast';
import ConstructionIcon from '@mui/icons-material/Construction';

export const SierraChartIntegration = () => {
  // const { getIntegrationsLinksBlock } = useIntegrations();
  const { openToast } = useToast();

  const markClick = () => {
    const logger = new GALogger(GALoggerCategory.Integrations);
    logger.logEvent('sierraChartNotifyMe');
    openToast({
      type: 'success',
      message:
        "Thank you. We'll let you know once the Sierra Chart integration is available.",
    });
  };

  return (
    <Box sx={{ marginTop: '5px' }}>
      <h1>
        We're working on this{' '}
        {<ConstructionIcon fontSize="large" sx={{ marginBottom: '-4px' }} />}
      </h1>
      <Box>
        <Box
          sx={{
            marginY: '15px',
            lineHeight: '150%',
          }}
        >
          <p>
            Our engineering team is hard at work updating and improving our
            Sierra Chart integration. <br /> If this is an integration you'd
            like to use, please click the button below and we'll let you know
            once it's available.
          </p>
        </Box>

        <Button
          variant="outlined"
          sx={{ textTransform: 'none', marginTop: '5px' }}
          onClick={() => markClick()}
        >
          Notify me when available
        </Button>
      </Box>

      {/* Commented out to enable us to add it back easily if/when we fix it */}
      {/*<p>*/}
      {/*  File URL updated: <strong>12/21/23</strong>*/}
      {/*</p>*/}
      {/*{getIntegrationsLinksBlock('SierraChart')}*/}
      {/*<br />*/}
      {/*<p>*/}
      {/*  Download the Sierra Chart Driver{' '}*/}
      {/*  <Link href="https://spotgamma.com/mp-files/sierra-chart-driver-v2.dll/">*/}
      {/*    here*/}
      {/*  </Link>*/}
      {/*  .{' '}*/}
      {/*</p>*/}
      {/*<br />*/}
      {/*<video*/}
      {/*  controls*/}
      {/*  poster="https://spotgamma.com/wp-content/uploads/2020/11/how-to-set-up-spotgamma-levels-in-sierra-chart-1.png"*/}
      {/*  src="https://spotgamma.com/wp-content/uploads/2020/11/spot-gamma-setup.mp4"*/}
      {/*></video>*/}
    </Box>
  );
};

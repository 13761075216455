import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

export const Center = ({
  horizontal = true,
  vertical = true,
  sx,
  children,
}: {
  horizontal?: boolean;
  vertical?: boolean;
  sx?: SxProps;
  children: ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: horizontal ? '100%' : undefined,
        height: vertical ? '100%' : undefined,
        alignItems: 'center',
        justifyContent: 'center',
        ...(sx ?? {}),
      }}
    >
      {children}
    </Box>
  );
};

import { useRecoilValue } from 'recoil';
import { negativeTrendColorState, positiveTrendColorState } from '../../states';
import { useTheme } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ComboSymbol } from '../../config';

type LastPriceProps = {
  lastPrice: number | undefined;
  lastClose: number | undefined;
  fontSize?: number;
  sym: string;
  onClick?: () => void;
};

export const LastPrice = ({
  lastPrice,
  lastClose,
  sym,
  onClick,
  fontSize = 13,
}: LastPriceProps) => {
  const posColor = useRecoilValue(positiveTrendColorState);
  const negColor = useRecoilValue(negativeTrendColorState);
  const theme = useTheme();

  const symLabel = sym === ComboSymbol.ES_F ? '/ES' : sym;
  const lastIsPos = (lastPrice ?? 0) >= (lastClose ?? 0);
  const color =
    lastClose == null
      ? theme.palette.hiro.lenses.price.total
      : lastIsPos
      ? posColor
      : negColor;

  const renderLastPriceChange = () => {
    if (lastPrice == null || lastClose == null) {
      return null;
    }

    const lastIsPos = (lastPrice ?? 0) >= lastClose;
    const changeAmt = Math.abs(lastPrice - lastClose).toFixed(2);
    const changeAmtPercent = (
      (parseFloat(changeAmt) / lastClose) *
      100
    ).toFixed(2);
    const secondaryFontSize = fontSize - 3;

    const changeIcon = lastIsPos ? (
      <ArrowUpwardIcon
        sx={{
          marginRight: '2px',
          marginBottom: '-2px',
          fontSize: secondaryFontSize,
        }}
      />
    ) : (
      <ArrowDownwardIcon
        sx={{
          marginRight: '2px',
          marginBottom: '-2px',
          fontSize: secondaryFontSize,
        }}
      />
    );

    return (
      <Typography
        sx={{
          color: lastIsPos ? posColor : negColor,
          marginLeft: '3px',
          fontSize: secondaryFontSize,
        }}
      >
        {changeIcon}
        {lastIsPos ? '+' : '-'}
        {changeAmt}
        &nbsp; ({changeAmtPercent}%)
      </Typography>
    );
  };

  return (
    <Box
      sx={{ cursor: onClick != null ? 'pointer' : undefined }}
      onClick={onClick}
    >
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: 0,
        }}
      >
        <Typography sx={{ color: theme.palette.text.secondary, fontSize }}>
          {symLabel}:{' '}
        </Typography>
        {lastPrice == null ? null : (
          <Typography
            sx={{
              color,
              fontSize,
            }}
          >
            {lastPrice.toFixed(2)}
          </Typography>
        )}
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: '-2px' }}>
        {renderLastPriceChange()}
      </Box>
    </Box>
  );
};

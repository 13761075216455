import dayjs from 'dayjs';

export enum ResourceType {
  EDUCATION = 'education',
  COMMUNITY = 'community',
  SUPPORT = 'support',
}

export type Resource = {
  name: string;
  link: string;
  description: string;
};

export interface DiscordUser {
  id: string;
  username: string;
  global_name: string | null;
  avatar: string;
  discriminator: string;
  public_flags: number;
  flags: number;
  banner: string | null;
  banner_color: string;
  accent_color: number;
  locale: string;
  mfa_enabled: boolean;
  premium_type: number;
  avatar_decoration: string | null;
  email: string;
  verified: boolean;
}

export interface DiscordAuth {
  error: any;
  access_token?: string;
  expires_in?: number;
  refresh_token?: string;
  scope?: string;
  token_type?: string;
}

export interface DiscordGuild {
  id: string;
  name: string;
  icon: string;
  owner: boolean;
  permissions: number;
  features: string[];
  permissions_new: string;
}

export interface RawEarningsImpliedMove {
  sym: string;
  day: string;
  utc: string;
  company_name: string;
  period: string;
  confirmed: number;
  implied_move: number;
  cv: number;
  pv: number;
  activity_factor: number;
  inHiro: boolean;
}

export interface EarningsImpliedMove {
  bin: RawEarningsImpliedMove[];
  syms: string[];
  period: string;
  implied_move: number;
  idx: number;
  day: dayjs.Dayjs;
}

export enum EARNINGS_SYMBOL_TYPE {
  IN_HIRO = 'inHiro',
  ALL = 'all',
}

export enum EarningsPeriodType {
  BMO = 'BMO',
  AMC = 'AMC',
  OTHER = 'OTHER',
}

export interface TransformedEarnings {
  period: EarningsPeriodType;
  members: EarningsImpliedMove[];
}

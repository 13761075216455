import { Stack, Typography, useTheme } from '@mui/material';
import { InfoButton } from 'components/shared';
import { WatchlistMultiSelect } from 'components/shared/WatchlistMultiSelect';
import { useRecoilState } from 'recoil';
import { scannerActiveWatchlistsIdsState } from 'states/scanners';
import ScannersSection from './ScannersSection';
import { ProductType } from 'types/shared';
import useAuth from 'hooks/auth/useAuth';

const ScannerHeader = () => {
  const theme = useTheme();
  const { hasAccessToProduct } = useAuth();
  const hasScannersAccess = hasAccessToProduct(ProductType.SCANNERS);
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    scannerActiveWatchlistsIdsState,
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <ScannersSection />

        <Stack direction="row" gap={1} alignItems="flex-start">
          <InfoButton key="scanners" articleKey="scanners" />
          <WatchlistMultiSelect
            activeWatchlistIds={activeWatchlistIds}
            setActiveWatchlistIds={setActiveWatchlistIds}
          />
        </Stack>
      </Stack>
      {!hasScannersAccess && (
        <Typography
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          (Access limited to a small number of equities)
        </Typography>
      )}
    </>
  );
};

export default ScannerHeader;

import { ProductType, RawGreeksDataMap, RawStatsDataMap } from '../../types';
import { fetchRawFormattedData, getFilteredGreeksData } from 'util/iVol';
import useLog from '../useLog';
import { useSetRecoilState } from 'recoil';
import { iVolErrorState, iVolLoadingState } from 'states';
import { useCallback } from 'react';
import useAuth from 'hooks/auth/useAuth';

const useImpliedVolatility = () => {
  const setLoading = useSetRecoilState(iVolLoadingState);
  const setError = useSetRecoilState(iVolErrorState);
  const { logError } = useLog('useImpliedVolatility');
  const { hasAccessToProduct } = useAuth();
  const hasAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);

  const getCurrentGreeksData = useCallback(
    async (ticker: string): Promise<RawGreeksDataMap | null> => {
      try {
        setLoading(true);
        const rawData: RawGreeksDataMap = await fetchRawFormattedData(
          `v1/${
            !hasAccess ? 'free_current_greeks' : 'current_greeks'
          }?sym=${encodeURIComponent(ticker)}`,
        );

        const updatedRawData: RawGreeksDataMap = getFilteredGreeksData(rawData);

        setLoading(false);
        setError(null);
        return updatedRawData;
      } catch (error) {
        setError(error);
        logError(error, 'getCurrentGreeksData');
      } finally {
        setLoading(false);
      }

      // failed to fetch scenario
      return null;
    },
    [hasAccess],
  );

  const getDailyGreeksData = useCallback(
    async (
      date: string,
      ticker: string,
      isMktOpen?: boolean,
    ): Promise<RawGreeksDataMap | null> => {
      try {
        setLoading(true);
        const rawData: RawGreeksDataMap = await fetchRawFormattedData(
          `v1/${
            !hasAccess ? 'free_daily_greeks' : 'daily_greeks'
          }?sym=${encodeURIComponent(ticker)}&date=${date}&mkt_close=${
            isMktOpen ? 0 : 1
          }`,
        );

        // filter out any expirations before given trade "date"
        const updatedRawData: RawGreeksDataMap = getFilteredGreeksData(
          rawData,
          date,
        );

        setLoading(false);
        setError(null);
        return updatedRawData;
      } catch (error) {
        setError(error);
        logError(error, 'getDailyGreeksData');
      } finally {
        setLoading(false);
      }

      // failed to fetch scenario
      return null;
    },
    [hasAccess],
  );

  const getStatisticsData = useCallback(
    async (ticker: string, date?: string): Promise<RawStatsDataMap | null> => {
      try {
        setLoading(true);
        const rawData: RawStatsDataMap = await fetchRawFormattedData(
          `v1/${
            !hasAccess ? 'free_iv_stats' : 'iv_stats'
          }?sym=${encodeURIComponent(ticker)}${date ? `&date=${date}` : ''}`,
        );

        setLoading(false);
        setError(null);
        return rawData;
      } catch (error) {
        setError(error);
        console.error(`Failed to fetch data: ${error}`);
      } finally {
        setLoading(false);
      }

      // failed to fetch scenario
      return null;
    },
    [hasAccess],
  );

  return {
    getCurrentGreeksData,
    getDailyGreeksData,
    getStatisticsData,
  };
};

export default useImpliedVolatility;

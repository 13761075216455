import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const Tape = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path
        fill="currentColor"
        d="M41 30C41 33.16 38.89 35.84 36 36.71V21C36 20.73 35.97 20.46 35.96 20.19C35.54 11.73 28.57 5 20 5C11.43 5 4 12.16 4 21V40C4 41.1 4.9 42 6 42H34C35.1 42 36 41.1 36 40V39.8C40.56 38.87 44 34.84 44 30H41ZM10.05 36H7V21C7 13.83 12.83 8 20 8C27.17 8 32.46 13.33 32.95 20.06C32.97 20.37 33 20.69 33 21V36H29.95C29.94 35.89 29.92 35.77 29.9 35.66C29.82 35.04 29.68 34.44 29.49 33.86C29.17 32.88 28.69 31.97 28.1 31.15C27.77 30.69 27.41 30.26 27.01 29.87C26.69 29.56 26.34 29.27 25.99 29C26.5 28.62 26.97 28.19 27.4 27.72C28.15 26.9 28.77 25.95 29.21 24.91C29.72 23.71 30.01 22.38 30.01 20.99V20.83C29.92 15.38 25.48 10.99 20.01 10.99C14.54 10.99 10.01 15.47 10.01 20.99C10.01 24.27 11.59 27.17 14.03 28.99C14.64 29.45 15.3 29.84 16.01 30.15V26.72C14.2 25.45 13.01 23.36 13.01 20.98C13.01 17.12 16.15 13.98 20.01 13.98C23.87 13.98 27.01 17.12 27.01 20.98C27.01 21.78 26.87 22.54 26.62 23.26C26.25 24.34 25.62 25.3 24.81 26.07C24.56 26.31 24.3 26.52 24.01 26.72V31.25L24.11 31.32C24.66 31.72 25.14 32.19 25.55 32.72C26.16 33.51 26.6 34.43 26.83 35.43C26.87 35.61 26.9 35.79 26.93 35.97H22.01V23.19C22.62 22.64 23.01 21.85 23.01 20.97C23.01 19.31 21.67 17.97 20.01 17.97C18.35 17.97 17.01 19.31 17.01 20.97C17.01 21.86 17.4 22.64 18.01 23.19V35.97H10.06L10.05 36Z"
      />
    </SvgIcon>
  );
};

export default Tape;

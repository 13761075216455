import { Box, Grid, Typography, Button } from '@mui/material';
import { darkModeTheme } from '../../theme/theme_definitions/darkModeTheme';
import useLog from '../../hooks/useLog';
import { useTheme } from '@mui/material/styles';

export interface ErrorScreenProps {
  error: Error;
  tryAgainCallback?: () => void;
}

export const ErrorScreen = ({ error, tryAgainCallback }: ErrorScreenProps) => {
  const { getTimeSignatureText } = useLog('ErrorScreen');
  const theme = useTheme();

  let stackTrace = null;
  if (error.stack != null) {
    stackTrace = (
      <Box style={{ textAlign: 'left' }}>
        {error.stack
          .split('\n')
          .slice(1)
          .map((text) => (
            <>
              {text}
              <br />
            </>
          ))}
      </Box>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        background: darkModeTheme.palette.background.default,
        minHeight: '100vh',
      }}
    >
      <Grid item xs={3} sx={{ textAlign: 'center' }}>
        <Typography
          sx={{ fontSize: '32px', display: 'block', marginBottom: '10px' }}
          color="primary"
        >
          We're Sorry
        </Typography>
        <Box sx={{ marginBottom: '20px' }}>
          <Typography
            sx={{
              fontSize: '18px',
              display: 'block',
              marginBottom: '10px',
              maxWidth: 'calc(max(200px, 40vw))',
            }}
          >
            It looks like there was an error. This error has automatically been
            reported to the SpotGamma development team and is being
            investigated. Please try again.
          </Typography>
          <Box
            sx={{
              marginTop: '10px',
            }}
          >
            {getTimeSignatureText({
              color: theme.palette.text.disabled,
              fontSize: '11px',
              display: 'block',
              margin: '3px',
            })}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} spacing={2} padding={4}>
        <Typography color="red">
          <p style={{ fontWeight: 'bold' }}>{error.toString()}</p>
          {stackTrace}
        </Typography>
      </Grid>
      {tryAgainCallback != null && (
        <Button variant="contained" onClick={tryAgainCallback}>
          Try Again
        </Button>
      )}
    </Grid>
  );
};

import { AccountDetails, UserDetailsPayload } from '../../types';
import { isBloomberg } from '../../util';
import useLog from '../useLog';
import useUserDetails from '../user/useUserDetails';

const useSavePreferences = () => {
  const { initUserDetails } = useUserDetails();
  const { fetchAPIWithLog, logError } = useLog('useSavePreferences');

  const saveWordpressPreferences = async (
    accountDetails: AccountDetails | undefined,
  ) => {
    if (isBloomberg() || accountDetails == null) {
      return {};
    }

    // save wordpress
    const payload = {
      ...accountDetails,
    };

    const memberpressData = await fetchAPIWithLog(`v1/sgMyself`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (memberpressData.code != null) {
      logError(
        'Received code from memberpress while saving preferences',
        'saveWordpressPreferences',
        { code: memberpressData.code },
      );
      return { error: 'Saving your data failed. Please try again.' };
    }
    if (memberpressData.error) {
      return { error: JSON.parse(memberpressData.error).error.message };
    } else {
      memberpressData.watchlists = memberpressData.watchlists ?? [];
      initUserDetails(memberpressData as UserDetailsPayload);
      return { data: memberpressData };
    }
  };

  return { saveWordpressPreferences };
};

export default useSavePreferences;

import { ComponentHeader, SGSelect } from '../../shared';
import { Button, ButtonGroup, CircularProgress, Stack } from '@mui/material';
import { SizeControls } from '../../shared/SizeControls';
import {
  StrategyCompassMode,
  StrategyCompassModeXMap,
  StrategyCompassModeYMap,
  StrategyCompassModeZMap,
  StrategyCompassXYZAxis,
  StrategyCompassXYZAxisReadableMap,
} from '../../../types/compass';
import { getModeLabel } from '../../../util/compass';
import { useState } from 'react';
import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import { SGTooltip } from '../../core';
import { WatchlistMultiSelect } from '../../shared';
import { useRecoilValue } from 'recoil';
import { isMobileState } from '../../../states';

type StrategyCompassControlsProps = {
  editable: boolean;
  loading: boolean;
  chartSize: { width: number; height: number };
  compassParams: CompassParams;
};

export const StrategyCompassControls = ({
  editable,
  loading,
  chartSize,
  compassParams,
}: StrategyCompassControlsProps) => {
  const [sizeOptionsOpen, setSizeOptionsOpen] = useState(false);
  const isMobile = useRecoilValue(isMobileState);

  const {
    xAxis,
    yAxis,
    setYAxis,
    setXAxis,
    mode,
    setMode,
    zAxis,
    setZAxis,
    activeWatchlistIds,
    setActiveWatchlistIds,
  } = compassParams;

  if (!editable) {
    return null;
  }

  const buttonControls = (
    <Stack direction="row">
      <WatchlistMultiSelect
        activeWatchlistIds={activeWatchlistIds}
        setActiveWatchlistIds={(val) => {
          setActiveWatchlistIds(val);
        }}
        title={'Show symbols in watchlist'}
      />

      <SizeControls open={sizeOptionsOpen} setOpen={setSizeOptionsOpen} />
    </Stack>
  );

  const selectSx = isMobile
    ? {
        '.MuiSelect-icon': {
          display: 'none !important',
        },
        '.MuiSelect-select': {
          paddingX: '10px !important',
        },
      }
    : undefined;

  return (
    <ComponentHeader
      buttonsProps={{ flexGrow: 1 }}
      buttons={
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'center' : 'space-between'}
          justifyContent={isMobile ? 'center' : 'space-between'}
          flexGrow={1}
          gap={2}
          marginX={isMobile ? 0 : '20px'}
          marginBottom={'5px'}
        >
          <Stack direction="row" alignItems="center">
            <ButtonGroup>
              <SGTooltip
                title={
                  'Plot multiple symbols on a compass, and see what trades their volatility and price metrics would suggest might be best.'
                }
              >
                <Button
                  sx={{ textTransform: 'none', fontSize: '13px' }}
                  variant={
                    mode === StrategyCompassMode.Compass
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setMode(StrategyCompassMode.Compass)}
                >
                  {getModeLabel(StrategyCompassMode.Compass)}
                </Button>
              </SGTooltip>
              <SGTooltip
                title={
                  'Plot multiple symbols on a chart using any of our metrics as x, y, and z coordinates, without any trade suggestions from those metrics.'
                }
              >
                <Button
                  sx={{ textTransform: 'none', fontSize: '13px' }}
                  variant={
                    mode === StrategyCompassMode.Freeform
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setMode(StrategyCompassMode.Freeform)}
                >
                  {getModeLabel(StrategyCompassMode.Freeform)}
                </Button>
              </SGTooltip>
            </ButtonGroup>
            {isMobile && buttonControls}
          </Stack>

          <Stack direction="row">
            {loading || chartSize.width === 0 ? (
              <CircularProgress
                size="18px"
                color="primary"
                sx={{ marginRight: '5px' }}
              />
            ) : null}

            <Stack direction="row" gap="8px">
              <SGSelect
                key="compass-mode-x"
                label="X Axis"
                multiple={false}
                value={xAxis}
                setter={setXAxis}
                options={StrategyCompassModeXMap.get(mode)!}
                optionsTextTransform={(m) =>
                  `X: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                }
                sx={selectSx}
              />
              <SGSelect
                key="compass-mode-y"
                label="Y Axis"
                multiple={false}
                value={yAxis}
                setter={setYAxis}
                options={StrategyCompassModeYMap.get(mode)!}
                optionsTextTransform={(m) =>
                  `Y: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                }
                sx={selectSx}
              />
              {mode !== StrategyCompassMode.Compass && (
                <SGSelect
                  key="compass-mode-z"
                  label="Z Axis"
                  multiple={false}
                  value={zAxis ?? StrategyCompassXYZAxis.None}
                  setter={setZAxis}
                  options={StrategyCompassModeZMap.get(mode)!}
                  optionsTextTransform={(m) =>
                    `Z: ${StrategyCompassXYZAxisReadableMap.get(m)}`
                  }
                  selectTooltipProps={{
                    title:
                      'The Z axis is displayed as the size of the dot. The larger the dot, the higher the Z, and vice versa.',
                  }}
                  sx={selectSx}
                />
              )}
            </Stack>

            {!isMobile && buttonControls}
          </Stack>
        </Stack>
      }
    />
  );
};

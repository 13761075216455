import { useTheme } from '@mui/material/styles';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { ErrorContent, Loader } from '../../shared';
import {
  currentStatsState,
  iVolErrorState,
  iVolLoadingState,
  fixedStrikeColumnDataState,
  fixedStrikeCurrentDataState,
  fixedStrikeCurrentGreeksState,
  matrixCompareDate,
  matrixCurrentDate,
  matrixExpirationRangeState,
  matrixPercentOTMRangeState,
  matrixPrevExpiryMapState,
  matrixZoomPropsState,
  matrixZoomState,
  pruneColumnsPercentState,
  pruneRowsPercentState,
  showHighlightsState,
  matrixDataModeState,
  currentStatsLookbackState,
  showHistoricalDataState,
} from 'states/iVol';
import {
  FixedStrikeZoomLevel,
  GreeksData,
  MATRIX_MODE_LABEL_MAPPING,
  MATRIX_MODE_VALUE_LABEL_MAPPING,
  MatrixColumnData,
  MatrixData,
  MatrixDataMode,
  MatrixExpirationsRange,
  MatrixPercentOTMRange,
  MatrixSkewPrem,
  MatrixTooltipInfo,
  PrunePercentRange,
  RawGreeksDataMap,
  RawStatsData,
  RawStatsDataMap,
  RawStatsObject,
  STAT_IDX,
} from 'types/impliedVol';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import { isMobileState, screenHeightState, workerState } from 'states/shared';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import {
  IVOL_STATS_KEY,
  PAGE_OFFSET_BOTTOM,
  DELTA_KEY,
  SKEW_PREM_KEY,
  buildMatrixTableData,
  convertToDaysTillExpiry,
  getExpStatsMap,
  getFilteredGreeksData,
  getIVFromGreeks,
  getTimeAdjustedIvValue,
  getTradeDateDisplayText,
  isStrikeWithinRange,
  THETA_PERCENT_KEY,
  THETA_KEY,
  DELTA_NORMED_KEY,
  DEFAULT_STATS_LOOKBACK,
} from 'util/iVol';
import poll from 'util/poll';
import {
  negativeTrendColorState,
  positiveTrendColorState,
  pricesState,
  timezoneState,
} from 'states';
import * as d3 from 'd3';
import {
  formatAsCurrency,
  formatAsPercentage,
  getCurrentDate,
  getOverrideHeader,
  isMarketOpen,
  predicateSearch,
  round,
} from 'util/shared';
import GradientLegend from './GradientLegend';
import dayjs, { Dayjs } from 'dayjs';
import usePrices from 'hooks/equityhub/usePrices';
import TableCellTooltip from './TableCellTooltip';
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  useGridApiRef,
} from '@spotgamma/x-data-grid-premium';
import { SGTooltip } from '../../core';
import { NON_PROD } from 'config';
import useAuth from 'hooks/auth/useAuth';
import { ProductType } from 'types';

interface FixedStrikeTableProps {
  selectedSym: string;
}

const isBetween = (
  strike: number | null | undefined,
  left: number,
  right: number,
): boolean => strike != null && strike > left && strike < right;

const MAX_COL_HEADERS_STRAIGHT = 45;
const EXP_COLUMN_WIDTH = 100;

export const FixedStrikeTable = ({ selectedSym }: FixedStrikeTableProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const worker = useRecoilValue(workerState);
  const apiRef = useGridApiRef();
  const containerRef = useRef<any>(null);
  const screenHeight: number = useRecoilValue(screenHeightState);
  const [windowHeight, setWindowHeight] = useState(screenHeight);

  const zoomProps = useRecoilValue(matrixZoomPropsState);

  const { getPrices } = usePrices();

  const [prices, setPrices] = useRecoilState(pricesState);
  const currentPrice = prices.get(selectedSym) as number;

  // matrix data represents each row of data
  // - expiryDate key for one row of data
  // - dynamic number of key/value pairs representing each cell of data (strike -> IV)
  const [matrixData, setMatrixData] = useRecoilState(
    fixedStrikeCurrentDataState,
  );
  // prev expiry map is the user selected dated greeks map data
  const [matrixPrevExpiryMap, setMatrixPrevExpiryMapState] = useRecoilState(
    matrixPrevExpiryMapState,
  );
  const compareDate = useRecoilValue(matrixCompareDate);
  const currentDate = useRecoilValue(matrixCurrentDate);
  const matrixZoomLevel = useRecoilValue(matrixZoomState);
  const currentTimezone = useRecoilValue(timezoneState);

  // column data contains column keys/labels for the headers
  const [matrixColumnData, setMatrixColumnData] = useRecoilState(
    fixedStrikeColumnDataState,
  );
  const setFixedStrikeCurrentGreeks = useSetRecoilState(
    fixedStrikeCurrentGreeksState,
  );

  const pruneRowsPercent = useRecoilValue(pruneRowsPercentState);
  const pruneColumnsPercent = useRecoilValue(pruneColumnsPercentState);

  const matrixExpirationRange = useRecoilValue(matrixExpirationRangeState);
  const matrixPercentOTMRange = useRecoilValue(matrixPercentOTMRangeState);
  const matrixDataMode = useRecoilValue(matrixDataModeState);

  const showHistoricalData = useRecoilValue(showHistoricalDataState);

  const showHighlights = useRecoilValue(showHighlightsState);
  const { hasAccessToProduct } = useAuth();
  const hasIVolAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);

  const loading = useRecoilValue(iVolLoadingState);
  const error = useRecoilValue(iVolErrorState);
  const { getCurrentGreeksData, getDailyGreeksData, getStatisticsData } =
    useImpliedVolatility();

  const [currentStats, setCurrentStats] = useRecoilState(currentStatsState);
  const currentStatsLookback = useRecoilValue(currentStatsLookbackState);

  const statsDataObj: RawStatsData | null =
    currentStats &&
    (currentStats[currentStatsLookback] ??
      currentStats[DEFAULT_STATS_LOOKBACK]);

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const colWidth = matrixZoomLevel === FixedStrikeZoomLevel.ZoomedOut ? 25 : 75;

  const isCompareMode = useMemo(
    () => matrixDataMode === MatrixDataMode.COMPARE_MODE,
    [matrixDataMode],
  );

  const isStatsMode = useMemo(
    () => matrixDataMode === MatrixDataMode.STATS_MODE,
    [matrixDataMode],
  );

  const isSkewPremMode = useMemo(
    () => matrixDataMode === MatrixDataMode.SKEW_PREMIUM_MODE,
    [matrixDataMode],
  );

  const updatePrices = async () => {
    const payload = await getPrices([selectedSym]);
    setPrices((prices) => new Map([...prices, ...payload]));
  };

  const getReferenceGreeksForMatrix = async (
    sym: string,
    refDate?: Dayjs,
  ): Promise<RawGreeksDataMap | null> => {
    if (!showHistoricalData) {
      return await getCurrentGreeksData(sym);
    }

    if (refDate) {
      const formattedDate: string = refDate.format('YYYY-MM-DD');

      return refDate.isSame(getCurrentDate(), 'day') && isMarketOpen()
        ? await getDailyGreeksData(formattedDate, selectedSym, true)
        : await getDailyGreeksData(formattedDate, selectedSym);
    }

    return null;
  };

  const hasValidCellValue = useCallback(
    (row: MatrixData, column: MatrixColumnData) => {
      const currentIv: number | string = Number(row[column.dataKey])
        ? Number(row[column.dataKey])
        : row[column.dataKey];

      if (isCompareMode) {
        const prevGreeks: GreeksData | undefined =
          matrixPrevExpiryMap?.[row.expiryDate]?.[Number(column.dataKey)];

        const prevIv = prevGreeks && getIVFromGreeks(prevGreeks);

        return prevIv != null && typeof currentIv === 'number';
      } else {
        return !!currentIv;
      }
    },
    [isCompareMode, matrixPrevExpiryMap],
  );

  const filteredColumns = useMemo(() => {
    return matrixColumnData.filter((matrixColumn: MatrixColumnData) => {
      if (
        !matrixColumn.numeric ||
        matrixPercentOTMRange === MatrixPercentOTMRange.all
      ) {
        return true;
      }

      // numeric headers only, aka strikes
      return isStrikeWithinRange(
        currentPrice,
        Number(matrixColumn.dataKey),
        matrixPercentOTMRange,
      );
    });
  }, [currentPrice, matrixColumnData, matrixPercentOTMRange]);

  const filteredRowsMatrixData = useMemo(() => {
    return matrixData
      ? matrixData.filter((matrixData: MatrixData) => {
          if (matrixExpirationRange === MatrixExpirationsRange.all) {
            return true;
          }

          const expirationDate = dayjs(matrixData.expiryDate);

          return expirationDate.isBefore(
            getCurrentDate().add(matrixExpirationRange, 'month'),
            'day',
          );
        })
      : [];
  }, [matrixData, matrixExpirationRange]);

  const prunedColumns = useMemo(() => {
    return pruneColumnsPercent === PrunePercentRange.none ||
      filteredColumns.length <= 5
      ? filteredColumns
      : filteredColumns.filter((matrixColumn: MatrixColumnData) => {
          if (!matrixColumn.numeric) {
            return true;
          }
          const hasValueCount = filteredRowsMatrixData.reduce(
            (acc, e) => acc + (hasValidCellValue(e, matrixColumn) ? 1 : 0),
            0,
          );
          return (
            hasValueCount / filteredRowsMatrixData.length >= pruneColumnsPercent
          );
        });
  }, [
    filteredColumns,
    filteredRowsMatrixData,
    hasValidCellValue,
    pruneColumnsPercent,
  ]);

  const prunedRows = useMemo(() => {
    return pruneRowsPercent === PrunePercentRange.none ||
      filteredRowsMatrixData.length <= 10
      ? filteredRowsMatrixData
      : filteredRowsMatrixData.filter((matrixDataRow: MatrixData) => {
          const hasValueCount = filteredColumns.reduce(
            (acc, e) => acc + (hasValidCellValue(matrixDataRow, e) ? 1 : 0),
            0,
          );

          return hasValueCount / filteredColumns.length >= pruneRowsPercent;
        });
  }, [
    filteredColumns,
    filteredRowsMatrixData,
    hasValidCellValue,
    pruneRowsPercent,
  ]);

  const [skewPremMaximasMap] = useMemo(() => {
    const maximasMap = new Map<number, MatrixSkewPrem>();

    prunedRows.forEach((matrixDataRow: MatrixData) => {
      let maxSkewPremBoundTotal = {
        strike: -Infinity,
        value: -Infinity,
      };
      let maxSkewPremBoundPut = {
        strike: -Infinity,
        value: -Infinity,
      };
      let maxSkewPremBoundCall = {
        strike: -Infinity,
        value: -Infinity,
      };

      matrixColumnData.forEach((columnData: MatrixColumnData) => {
        if (columnData.numeric && matrixDataRow[columnData.dataKey]) {
          const strike = columnData.numeric && Number(columnData.label);
          const skewPrem: number =
            matrixDataRow[`${columnData.dataKey}-${SKEW_PREM_KEY}`];
          const delta =
            matrixDataRow[`${columnData.dataKey}-${DELTA_NORMED_KEY}`];

          if (skewPrem && round(skewPrem) !== 0) {
            maxSkewPremBoundTotal = {
              strike:
                skewPrem > maxSkewPremBoundTotal.value
                  ? strike
                  : maxSkewPremBoundTotal.strike,
              value: Math.max(skewPrem, maxSkewPremBoundTotal.value),
            };

            if (delta > 0.5) {
              if (skewPrem > maxSkewPremBoundPut.value) {
                maxSkewPremBoundPut = { value: skewPrem, strike };
              }
            } else {
              if (skewPrem > maxSkewPremBoundCall.value) {
                maxSkewPremBoundCall = { value: skewPrem, strike };
              }
            }
          }
        }
      });

      maximasMap.set(matrixDataRow.expiryDate, {
        totalMax: {
          strike:
            maxSkewPremBoundTotal.value <= 0
              ? null
              : maxSkewPremBoundTotal.strike,
          value:
            maxSkewPremBoundTotal.value === -Infinity
              ? 1.0
              : maxSkewPremBoundTotal.value,
        },
        putMax: {
          strike:
            maxSkewPremBoundPut.value <= 0 ? null : maxSkewPremBoundPut.strike,
          value: Math.max(0, maxSkewPremBoundPut.value),
        },
        callMax: {
          strike:
            maxSkewPremBoundCall.value <= 0
              ? null
              : maxSkewPremBoundCall.strike,
          value: Math.max(0, maxSkewPremBoundCall.value),
        },
      });
    });

    return [maximasMap];
  }, [matrixColumnData, prunedRows, currentPrice]);

  const [minVol, maxVol, volColorBound, distanceScoreBound] = useMemo(() => {
    let minVol = Infinity;
    let maxVol = -Infinity;
    let volColorBound = -Infinity;
    let distanceScoreBound = -Infinity;

    prunedRows.forEach((matrixDataRow: MatrixData) => {
      prunedColumns.forEach((columnData: MatrixColumnData) => {
        if (columnData.numeric && matrixDataRow[columnData.dataKey]) {
          const prevGreeks: GreeksData | undefined =
            matrixPrevExpiryMap?.[matrixDataRow.expiryDate]?.[
              Number(columnData.dataKey)
            ];

          const prevIv = prevGreeks && getIVFromGreeks(prevGreeks);
          const currentIv = Number(matrixDataRow[columnData.dataKey]);
          const stats =
            matrixDataRow[`${columnData.dataKey}-${IVOL_STATS_KEY}`];

          minVol = Math.min(currentIv, minVol);
          maxVol = Math.max(currentIv, maxVol);

          if (
            currentIv &&
            stats &&
            stats[STAT_IDX.MEAN] &&
            stats[STAT_IDX.STDEV]
          ) {
            const mean = stats[STAT_IDX.MEAN];
            const stdev = stats[STAT_IDX.STDEV];

            const statsDistScore = (currentIv - mean) / stdev;

            distanceScoreBound = Math.max(
              Math.abs(statsDistScore),
              distanceScoreBound,
            );
          }

          if (prevIv != null) {
            const absoluteDiff = currentIv - prevIv;

            const fromDte = convertToDaysTillExpiry(
              currentDate.valueOf(),
              matrixDataRow.expiryDate,
            );
            const toDte = convertToDaysTillExpiry(
              compareDate.valueOf(),
              matrixDataRow.expiryDate,
            );

            const timeScaledDiff = getTimeAdjustedIvValue(
              absoluteDiff,
              fromDte,
              toDte,
            );

            volColorBound = Math.max(Math.abs(timeScaledDiff), volColorBound);
          }
        }
      });
    });

    return [
      minVol === Infinity ? 0 : minVol,
      maxVol === -Infinity ? 1.0 : maxVol,
      volColorBound === -Infinity ? 1.0 : volColorBound,
      distanceScoreBound === -Infinity ? 1.0 : distanceScoreBound,
    ];
  }, [
    prunedColumns,
    prunedRows,
    currentDate,
    compareDate,
    matrixPrevExpiryMap,
  ]);

  const getColorScale = (
    min: number,
    max: number,
    minColor: string,
    maxColor: string,
  ) =>
    d3
      .scaleSequential()
      .interpolator(d3.interpolateRgb(minColor, maxColor))
      .domain([min, max]);

  const compareModeNegativeColorScale = d3
    .scaleSequential()
    .interpolator(
      d3.interpolateRgb(
        serverNegativeTrendColor,
        theme.palette.iVol.fixedStrikeMatrix.lowValueCell,
      ),
    )
    .domain([volColorBound * -1, 0]);

  const compareModePositiveColorScale = d3
    .scaleSequential()
    .interpolator(
      d3.interpolateRgb(
        theme.palette.iVol.fixedStrikeMatrix.lowValueCell,
        serverPositiveTrendColor,
      ),
    )
    .domain([0, volColorBound]);

  const matrixDefaultBgColor = theme.palette.background.paper;

  const setRowsAndColumns = useCallback(
    (
      strikeMatrixMap: RawGreeksDataMap,
      expStatsMap: Map<number, RawStatsObject>,
      matrixPrevExpiryMap: RawGreeksDataMap | null,
    ) => {
      const { matrixData, matrixColumnData } = buildMatrixTableData(
        currentDate,
        strikeMatrixMap,
        isCompareMode,
        matrixPrevExpiryMap,
        expStatsMap,
      );

      setMatrixData(matrixData);
      setMatrixColumnData(matrixColumnData);
    },
    [isCompareMode, setMatrixColumnData, setMatrixData],
  );

  const strikes = useMemo(
    () =>
      prunedColumns
        .filter((column: MatrixColumnData) => column.numeric)
        .map((column: MatrixColumnData) => Number(column.dataKey))
        .sort((a: number, b: number) => a - b),
    [prunedColumns],
  );

  const closestPriceIndex = useMemo(() => {
    if ((strikes?.length ?? 0) === 0) {
      return null;
    }
    return predicateSearch(strikes, (s) => s <= currentPrice);
  }, [currentPrice, strikes]);

  const scrollToCurrentPrice = useCallback(() => {
    if (!closestPriceIndex || !apiRef.current) {
      return;
    }

    const gridWidth =
      apiRef.current.getRootDimensions()?.viewportInnerSize.width;
    if (!gridWidth) {
      return;
    }

    const totalWidthBeforeTarget =
      EXP_COLUMN_WIDTH + colWidth * closestPriceIndex;
    const targetColumnWidth =
      closestPriceIndex === 0 ? EXP_COLUMN_WIDTH : colWidth;
    const scrollLeftPosition =
      totalWidthBeforeTarget - gridWidth / 2 + targetColumnWidth / 2;

    apiRef.current.scroll({
      left: scrollLeftPosition,
    });
  }, [apiRef, closestPriceIndex, colWidth]);

  const handleResponse = useCallback(
    async (data: { data: RawGreeksDataMap }) => {
      if (
        data?.data &&
        statsDataObj &&
        currentDate.isSame(getCurrentDate(), 'day') &&
        !showHistoricalData
      ) {
        const expStatsMap = getExpStatsMap(statsDataObj, data.data);
        const filteredGreeks = getFilteredGreeksData(data.data);
        setRowsAndColumns(filteredGreeks, expStatsMap, matrixPrevExpiryMap);
        setFixedStrikeCurrentGreeks(filteredGreeks);
        await updatePrices();
      }
    },
    // updatePrices is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      matrixPrevExpiryMap,
      currentDate,
      setRowsAndColumns,
      statsDataObj,
      showHistoricalData,
      setFixedStrikeCurrentGreeks,
    ],
  );

  useEffect(() => {
    return poll(
      worker,
      {
        url: `v1/${
          hasIVolAccess ? 'current_greeks' : 'free_current_greeks'
        }?sym=${encodeURIComponent(selectedSym)}`,
        interval: 10_000,
        buffer: true,
        msgpack: true,
        onResponse: handleResponse,
      },
      getOverrideHeader(),
    );
  }, [worker, hasIVolAccess, handleResponse, selectedSym]);

  useLayoutEffect(() => {
    if (!loading && containerRef.current && apiRef.current) {
      setTimeout(scrollToCurrentPrice, 2500);
    }
    // scrollToCurrentPrice is removed since it can change based on strike or price differences,
    // causing an unnecessary scroll event when live-data updates occur
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    apiRef,
    containerRef,
    matrixZoomLevel,
    matrixPercentOTMRange,
    pruneColumnsPercent,
    selectedSym,
  ]);

  useEffect(() => {
    const handleResize = () => setWindowHeight(windowHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowHeight]);

  useEffect(() => {
    const fetchData = async () => {
      // create separate promises to be resolved in parallel for fetching greeks and stats
      // grab the resolved greeks and stats data
      const [stats, greeks]: [RawStatsDataMap | null, RawGreeksDataMap | null] =
        await Promise.all([
          getStatisticsData(selectedSym),
          getReferenceGreeksForMatrix(selectedSym, currentDate),
        ]);

      // handle UI data states
      if (stats && greeks) {
        const newStatsDataObj: RawStatsData | null =
          stats[currentStatsLookback] ?? stats[DEFAULT_STATS_LOOKBACK];
        const expStatsMap = getExpStatsMap(newStatsDataObj, greeks);
        let prevMap: RawGreeksDataMap | null = null;

        if (isCompareMode) {
          const formattedDate: string = compareDate.format('YYYY-MM-DD');
          // fetch the relevant greeks based on the selected date
          prevMap = await getDailyGreeksData(formattedDate, selectedSym);
          if (prevMap) {
            // update currently selected raw greeks data map
            setMatrixPrevExpiryMapState(prevMap);
          }
        }
        setRowsAndColumns(greeks, expStatsMap, prevMap);
        setFixedStrikeCurrentGreeks(greeks);
        setCurrentStats(stats);
      }

      await updatePrices();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDailyGreeksData,
    getCurrentGreeksData,
    getStatisticsData,
    selectedSym,
  ]);

  const highlightedCells: {
    shouldHighlight: boolean;
    skip: boolean;
  }[][] = useMemo(() => {
    const numRows = prunedRows.length;
    const numCols = prunedColumns.length;

    let scores: {
      row: number;
      col: number;
      score: number;
    }[] = [];

    for (let row = 1; row < numRows - 1; row++) {
      const rowData = prunedRows[row];
      for (let col = 0; col < numCols; col++) {
        const colData = prunedColumns[col];
        const currentCellValue: number = Number(rowData?.[colData.dataKey]);

        const aboveCellValue = Number(
          prunedRows[row - 1]?.[colData.dataKey] ?? NaN,
        );
        const belowCellValue = Number(
          prunedRows[row + 1]?.[colData.dataKey] ?? NaN,
        );

        const higherThanNeighbors =
          currentCellValue > aboveCellValue &&
          currentCellValue > belowCellValue;
        const lowerThanNeighbors =
          currentCellValue < aboveCellValue &&
          currentCellValue < belowCellValue;

        if (higherThanNeighbors || lowerThanNeighbors) {
          const score = Math.abs(
            aboveCellValue -
              currentCellValue +
              (belowCellValue - currentCellValue),
          );
          scores.push({
            row,
            col,
            score,
          });
        }
      }
    }

    scores.sort((a, b) => a.score - b.score);

    // Initialize a 2D array to track highlighted cells
    const highlightedMatrix: {
      shouldHighlight: boolean;
      skip: boolean;
    }[][] = Array.from({ length: numRows }, () =>
      Array.from({ length: numCols }, () => ({
        shouldHighlight: false,
        skip: false,
      })),
    );

    for (const { row, col } of scores) {
      if (highlightedMatrix[row][col].skip) {
        continue;
      }

      highlightedMatrix[row][col].shouldHighlight = true;
      if (row - 1 > 0) {
        highlightedMatrix[row - 1][col].skip = true;
      }

      if (row + 1 < numRows) {
        highlightedMatrix[row + 1][col].skip = true;
      }
    }

    return highlightedMatrix;
  }, [prunedColumns, prunedRows]);

  const getIVolDiff = useCallback(
    (
      params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
      expDate: number,
      strike: number,
    ): number | null => {
      const value = params.value;
      let result = null;

      if (isCompareMode) {
        const prevGreeks: GreeksData | undefined =
          matrixPrevExpiryMap?.[expDate]?.[strike];
        const prevIv = prevGreeks && getIVFromGreeks(prevGreeks);
        if (prevIv != null && value != null) {
          result = value - prevIv;
        }
      }

      return result;
    },
    [isCompareMode, matrixPrevExpiryMap],
  );

  const getFormattedCellLabel = useCallback(
    (value: number, maxDigits: number = 2) => {
      const percentVal = formatAsPercentage(value, false, maxDigits);

      if (matrixDataMode === MatrixDataMode.COMPARE_MODE) {
        return round(value) === 0
          ? '0%'
          : `${value > 0 ? '+' : ''}${percentVal}`;
      } else {
        return percentVal;
      }
    },
    [matrixDataMode],
  );

  // default cell styles
  const defaultCellStyles = {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.default),
    fontSize: zoomProps.fontSize,
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
  };

  const gridColumns: GridColDef[] = useMemo(
    () =>
      prunedColumns.map((column: MatrixColumnData) => {
        const widthAdjusterProp =
          column.dataKey === 'expiryDate'
            ? { width: EXP_COLUMN_WIDTH }
            : { flex: 1 };
        return {
          field: column.dataKey as string,
          headerName: column.label,
          sortable: false,
          align: 'center',
          minWidth: colWidth,
          ...widthAdjusterProp,
          renderCell: (params) => {
            // A cell either has value or not (undefined)
            // Can be either expiryDate in utcTimestamp number format or iv value
            const rawCellValue: number | undefined = params.value;
            // expiry date column case
            if (params.field === 'expiryDate') {
              return (
                <Box style={defaultCellStyles}>
                  {dayjs(rawCellValue).format('YYYY-MM-DD')}
                </Box>
              );
            }

            const columnKey = params.field;
            const row = params.row;
            const expiryDate = row.expiryDate;
            const strike = Number(column.dataKey);

            if (rawCellValue == null || rawCellValue == undefined) {
              return <Box style={defaultCellStyles} />;
            }

            const stats = row[`${columnKey}-${IVOL_STATS_KEY}`];
            const skewPrem = row[`${columnKey}-${SKEW_PREM_KEY}`];

            const iVolRounded = round(rawCellValue, 4);
            const iVolRoundedTooltip = round(rawCellValue, 6);
            const skewPremRounded = round(skewPrem);

            const iVolDiff = getIVolDiff(params, expiryDate, strike);

            const iVolDiffRounded = iVolDiff && round(iVolDiff);
            const iVolDiffRoundedTooltip = iVolDiff && round(iVolDiff, 6);

            // empty diff cell case
            if (isCompareMode && iVolDiff == null) {
              return <Box style={defaultCellStyles} />;
            }

            if (isSkewPremMode && (skewPrem == null || isNaN(skewPrem))) {
              return <Box style={defaultCellStyles} />;
            }

            let bgColor = matrixDefaultBgColor;
            let statsDistScore = null;
            let timeScaledDiff = null;

            if (isCompareMode && iVolDiff != null) {
              bgColor =
                iVolDiff >= 0
                  ? compareModePositiveColorScale(iVolDiff)
                  : compareModeNegativeColorScale(iVolDiff);

              const fromDte = convertToDaysTillExpiry(
                currentDate.valueOf(),
                expiryDate,
              );
              const toDte = convertToDaysTillExpiry(
                compareDate.valueOf(),
                expiryDate,
              );

              timeScaledDiff = getTimeAdjustedIvValue(iVolDiff, fromDte, toDte);
            } else if (isSkewPremMode) {
              const skewPremBound = skewPremMaximasMap.get(expiryDate);
              if (skewPrem && skewPremBound?.totalMax.value) {
                const colorScale =
                  skewPrem < 0
                    ? getColorScale(0, 1, bgColor, bgColor)
                    : getColorScale(
                        0,
                        skewPremBound.totalMax.value,
                        bgColor,
                        theme.palette.sgGreen,
                      );
                bgColor = colorScale(skewPrem);
              }
            } else if (isStatsMode) {
              if (stats) {
                const mean = stats[STAT_IDX.MEAN];
                const stdev = stats[STAT_IDX.STDEV];
                statsDistScore = (rawCellValue - mean) / stdev;
                const colorScale =
                  statsDistScore < 0
                    ? getColorScale(
                        distanceScoreBound * -1,
                        mean,
                        serverNegativeTrendColor,
                        bgColor,
                      )
                    : getColorScale(
                        mean,
                        distanceScoreBound,
                        bgColor,
                        serverPositiveTrendColor,
                      );
                bgColor = colorScale(statsDistScore);
              }
            } else {
              const colorScale = getColorScale(
                minVol,
                maxVol,
                bgColor,
                theme.palette.sgGreen,
              );
              bgColor = colorScale(iVolRounded);
            }

            const rowIndex = prunedRows.findIndex(
              (md: MatrixData) => md.id === params.id,
            );
            const colIndex = prunedColumns.findIndex(
              (mcd: MatrixColumnData) => mcd.dataKey === params.field,
            );

            let border = null;

            if (showHighlights && !isCompareMode) {
              const color = theme.palette.iVol.fixedStrikeMatrix.highlight;
              if (isSkewPremMode) {
                const maxSkewPrem = skewPremMaximasMap.get(expiryDate);
                const { callMax, putMax } = maxSkewPrem || {};

                const leftStrike = Number(prunedColumns[colIndex - 1]?.dataKey);
                const rightStrike = Number(
                  prunedColumns[colIndex + 1]?.dataKey,
                );

                // Determine side borders for edge cells, colIndex 0 is expirations so must start at 1
                if (
                  (colIndex === 1 &&
                    isBetween(putMax?.strike, -Infinity, strike)) ||
                  isBetween(callMax?.strike, leftStrike, strike) ||
                  isBetween(putMax?.strike, leftStrike, strike)
                ) {
                  border = { 'border-left': `4px dashed ${color}` };
                } else if (
                  (colIndex === prunedColumns.length - 1 &&
                    isBetween(callMax?.strike, strike, Infinity)) ||
                  isBetween(callMax?.strike, strike, rightStrike) ||
                  isBetween(putMax?.strike, strike, rightStrike)
                ) {
                  border = { 'border-right': `4px dashed ${color}` };
                }

                // Determine main border based on skew premium maxima
                if (strike === callMax?.strike || strike === putMax?.strike) {
                  // Only set main border if side borders aren't set
                  border = { border: `2px solid ${color}` };
                }
              } else {
                // Set border for highlighted cells outside of skew premium mode
                if (highlightedCells[rowIndex][colIndex]?.shouldHighlight) {
                  border = { border: `2px solid ${color}` };
                }
              }
            }

            const colStyles = {
              ...defaultCellStyles,
              backgroundColor: bgColor,
              ...border,
            };

            const valueLabel =
              MATRIX_MODE_VALUE_LABEL_MAPPING.get(matrixDataMode);

            const finalCellValue: number = isCompareMode
              ? iVolDiffRounded!
              : iVolRounded;

            const tooltipData: MatrixTooltipInfo[] = [];

            if (valueLabel) {
              tooltipData.push({
                label: valueLabel,
                value: getFormattedCellLabel(
                  isCompareMode ? iVolDiffRoundedTooltip! : iVolRoundedTooltip,
                  4,
                ),
              });
            }

            const modeLabel = MATRIX_MODE_LABEL_MAPPING.get(matrixDataMode);
            const modeDataValue = isCompareMode
              ? timeScaledDiff
              : isStatsMode
              ? statsDistScore
              : iVolRounded;
            const modeDisplayValue = modeDataValue
              ? isCompareMode
                ? formatAsPercentage(Number(modeDataValue.toFixed(3)))
                : isSkewPremMode
                ? skewPremRounded === 0
                  ? '$0'
                  : formatAsCurrency(skewPremRounded)
                : modeDataValue.toFixed(3)
              : null;
            const modeValueColor = isSkewPremMode
              ? theme.palette.getContrastText(theme.palette.background.default)
              : modeDataValue && modeDataValue >= 0
              ? serverPositiveTrendColor
              : serverNegativeTrendColor;

            if (modeLabel && modeDisplayValue) {
              tooltipData.push({
                label: modeLabel,
                value: modeDisplayValue,
                ...(modeValueColor && {
                  style: {
                    color: modeValueColor,
                  },
                }),
              });
            }

            const deltaValue = row[`${columnKey}-${DELTA_KEY}`];

            if (deltaValue) {
              tooltipData.push({
                label: 'Delta',
                value: `${round(deltaValue)}`,
              });
            }

            const thetatValue = row[`${columnKey}-${THETA_KEY}`];

            if (NON_PROD && thetatValue) {
              tooltipData.push({
                label: 'Theta',
                value: `${Math.abs(round(thetatValue))}`,
              });
            }

            const thetaPercentValue = row[`${columnKey}-${THETA_PERCENT_KEY}`];

            if (NON_PROD && thetaPercentValue) {
              tooltipData.push({
                label: 'Theta Percent',
                value: `${formatAsPercentage(
                  Math.abs(round(thetaPercentValue)),
                )}`,
              });
            }

            const finalCellLabel: string = isSkewPremMode
              ? skewPremRounded === 0
                ? '$0'
                : formatAsCurrency(skewPremRounded)
              : getFormattedCellLabel(finalCellValue);

            return (
              <TableCellTooltip
                expiryDate={expiryDate}
                strike={strike.toString()}
                data={tooltipData}
              >
                <Box
                  key={column.dataKey}
                  sx={{
                    ...colStyles,
                    alignItems: 'center',
                    '&:hover': {
                      transition: '0.25s',
                      transform: 'scale(1.25)',
                    },
                  }}
                >
                  {matrixZoomLevel !== FixedStrikeZoomLevel.ZoomedOut && (
                    <Typography
                      sx={{
                        fontSize: 'inherit',
                        color: theme.palette.getContrastText(bgColor),
                      }}
                    >
                      {finalCellLabel}
                    </Typography>
                  )}
                </Box>
              </TableCellTooltip>
            );
          },
        };
      }),
    [
      prunedColumns,
      colWidth,
      theme.palette,
      zoomProps.fontSize,
      getIVolDiff,
      getFormattedCellLabel,
      matrixDefaultBgColor,
      skewPremMaximasMap,
      isCompareMode,
      isStatsMode,
      isSkewPremMode,
      serverPositiveTrendColor,
      serverNegativeTrendColor,
      prunedRows,
      showHighlights,
      highlightedCells,
      matrixZoomLevel,
      compareModePositiveColorScale,
      compareModeNegativeColorScale,
      currentDate,
      compareDate,
      distanceScoreBound,
      minVol,
      maxVol,
    ],
  );

  const rowHeight = zoomProps.height;
  const headerHeight =
    zoomProps.height +
    (matrixZoomLevel === FixedStrikeZoomLevel.ZoomedOut ? 20 : 5);
  const tableHeight = Math.min(
    matrixZoomLevel === FixedStrikeZoomLevel.ZoomedOut
      ? prunedRows.length * rowHeight + headerHeight * 2
      : (prunedRows.length + 2) * rowHeight,
    windowHeight - PAGE_OFFSET_BOTTOM,
  );

  const gradientLegend = useMemo(() => {
    let min = minVol;
    let max = maxVol;
    let label = 'Implied Vol';
    let format: 'percent' | 'float' | undefined = undefined;
    let customMinLabel = undefined;
    let customMaxLabel = undefined;
    let minColor = serverNegativeTrendColor;
    let maxColor = serverPositiveTrendColor;

    switch (matrixDataMode) {
      case MatrixDataMode.COMPARE_MODE:
        min = volColorBound * -1;
        max = volColorBound;
        label = 'Implied Vol Time-Scaled Difference';
        format = 'percent';
        break;
      case MatrixDataMode.STATS_MODE:
        min = distanceScoreBound * -1;
        max = distanceScoreBound;
        label = 'Implied Vol Z-Score';
        break;
      case MatrixDataMode.SKEW_PREMIUM_MODE:
        min = 0;
        max = 1;
        minColor = matrixDefaultBgColor;
        maxColor = theme.palette.sgGreen;
        label = 'Vol Skew Premium';
        customMinLabel = 'Low';
        customMaxLabel = 'High';
        break;
      default:
        minColor = matrixDefaultBgColor;
        maxColor = theme.palette.sgGreen;
        format = 'percent';
    }

    return (
      <GradientLegend
        min={min}
        max={max}
        customMinLabel={customMinLabel}
        customMaxLabel={customMaxLabel}
        minColor={minColor}
        maxColor={maxColor}
        label={label}
        format={format}
      />
    );
  }, [
    theme.palette,
    matrixDataMode,
    minVol,
    maxVol,
    serverNegativeTrendColor,
    serverPositiveTrendColor,
    matrixDefaultBgColor,
    volColorBound,
    distanceScoreBound,
  ]);

  if (!loading && error) {
    return <ErrorContent />;
  }

  if (!loading && matrixData && prunedRows.length === 0) {
    return (
      <Stack
        gap={2}
        alignItems="center"
        justifyContent="center"
        style={{
          height: '100%',
        }}
      >
        <Stack direction="row" alignItems="center" gap={4}>
          <ListAltIcon sx={{ fontSize: 24 }} />
          <Typography sx={{ fontSize: 24, textAlign: 'center' }}>
            This table is empty.
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: 20,
            textAlign: 'center',
            color: theme.palette.sgGreen,
          }}
        >
          There is no data based on your selected filters. Try adjusting your
          table view settings to see more data.
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Grid container sx={{ marginBottom: '10px' }} display="flex">
        <Grid
          item
          xs={12}
          gap={4}
          container
          sx={{
            width: '100%',
            justifyContent: isMobile ? 'center' : 'flex-start',
          }}
        >
          <Typography
            sx={{
              color: theme.palette.getContrastText(
                theme.palette.background.default,
              ),
              fontSize: isMobile ? '10px' : '13px',
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content',
              wrap: 'nowrap',
            }}
          >
            {selectedSym} Current Price:{' '}
          </Typography>
          <SGTooltip
            PopperProps={{
              sx: {
                maxWidth: 160,
                '& .MuiTooltip-tooltip': {
                  fontSize: '12px',
                },
              },
            }}
            title="Click to view strikes closest to current price"
          >
            <Button
              variant="contained"
              color="primary"
              disableElevation
              sx={{
                fontSize: isMobile ? '10px' : '13px',
                height: '35px',
              }}
              onClick={() => {
                scrollToCurrentPrice();
              }}
              endIcon={<SearchIcon />}
            >
              {currentPrice && currentPrice?.toFixed(2)}
            </Button>
          </SGTooltip>
          <Typography
            sx={{
              color: theme.palette.getContrastText(
                theme.palette.background.default,
              ),
              fontSize: isMobile ? '10px' : '13px',
              display: 'flex',
              alignItems: 'center',
              marginRight: 5,
            }}
          >
            {getTradeDateDisplayText(currentDate, currentTimezone)}
            {isCompareMode && ` vs ${compareDate.format('YYYY-MM-DD')}`}
          </Typography>
          {gradientLegend}
        </Grid>
      </Grid>

      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Box
          ref={containerRef}
          style={{
            height: tableHeight,
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            overflowX: 'auto',
          }}
        >
          <DataGridPremium
            apiRef={apiRef}
            rows={prunedRows}
            columns={gridColumns}
            initialState={{ pinnedColumns: { left: ['expiryDate'] } }}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableColumnReorder
            disableColumnResize
            disableColumnSorting
            disableAggregation
            disableRowGrouping
            disableDensitySelector
            columnHeaderHeight={headerHeight}
            rowHeight={rowHeight}
            hideFooter
            sx={{
              // needed to prevent pinned column header from overlapping settings popup on mobile
              ...(isMobile && { zIndex: 0 }),
              overflow: 'visible',
              width: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              border: 'none',
              '& .MuiDataGrid-cell': {
                color: theme.palette.getContrastText(
                  theme.palette.background.default,
                ),
                width: '100%',
                padding: 0,
                '&:focus-within': {
                  outline: 'none', // Removes focus outline
                },
              },
              '& .MuiDataGrid-topContainer': {
                border: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                border: 'none',
              },
              '& .MuiDataGrid-row .MuiDataGrid-cell': {
                border: 'none', // Removes the border from row cells
              },
              '& .MuiDataGrid-row.Mui-hovered > .MuiDataGrid-cell': {
                overflow: 'visible', // Override Mui's "hidden" so borders show when scaling up
              },
              '& .MuiDataGrid-columnHeader': {
                border: 'none',
                backgroundColor: theme.palette.background.default,
                padding: 0,
                color: theme.palette.getContrastText(
                  theme.palette.background.default,
                ),
                '&:focus-within': {
                  outline: 'none', // Removes focus outline
                },
                // conditionally highlights strike closest to current price
                ...(closestPriceIndex &&
                  strikes[closestPriceIndex] && {
                    [`&[data-field="${strikes[closestPriceIndex]}"]`]: {
                      backgroundColor:
                        theme.palette.iVol.fixedStrikeMatrix.grayCell,
                      color: theme.palette.getContrastText(
                        theme.palette.iVol.fixedStrikeMatrix.grayCell,
                      ),
                    },
                  }),
              },
              '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-filler': {
                display: 'none',
              },
              '& .MuiDataGrid-scrollbar': {
                background: theme.palette.background.default,
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                border: 'none',
                justifyContent: 'center', // Center the title container itself
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: zoomProps.fontSize,
              },
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--pinnedLeft) .MuiDataGrid-columnHeaderTitle':
                {
                  fontSize: zoomProps.fontSize,
                  ...(matrixZoomLevel === FixedStrikeZoomLevel.ZoomedOut &&
                    prunedColumns.length > MAX_COL_HEADERS_STRAIGHT && {
                      transform: `translate(-50%, -50%) ${zoomProps.transform}`,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      overflow: 'visible',
                      whiteSpace: 'nowrap',
                    }),
                },
              '& .MuiDataGrid-columnSeparator': { display: 'none' },
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: 'none',
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: 'none',
                },
            }}
          />
        </Box>
      )}
    </>
  );
};

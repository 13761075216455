import { useCompassParams } from '../../../hooks/scanners/useCompassParams';
import { useCompassData } from '../../../hooks/scanners/useCompassData';
import { ExpandableDashboardLayout } from '../../../layouts';
import { StrategyCompass } from './StrategyCompass';
import { StockScanner } from '../../../pages';
import { StrategyCompassEditSymbols } from './StrategyCompassEditSymbols';

export const StrategyCompassWithLayout = () => {
  const compassParams = useCompassParams();
  const compassData = useCompassData({
    compassParams,
  });

  return (
    <ExpandableDashboardLayout
      mainContentComponent={
        <StrategyCompass
          compassParams={compassParams}
          compassData={compassData}
        />
      }
      secondarySidebarComponent={
        <StrategyCompassEditSymbols
          compassParams={compassParams}
          compassData={compassData}
        />
      }
      bottomDrawerComponent={<StockScanner />}
      mainContentDefaultHeight={0.6}
      bottomContentMinHeight={600}
    />
  );
};

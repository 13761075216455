import { useEffect } from 'react';
import { useRecoilCallback } from 'recoil';
import {
  activeSearchState,
  currentToastState,
  searchSuggestionsState,
} from '../states';

const useCleanup = () => {
  const resetAll = useRecoilCallback(({ reset }) => () => {
    reset(searchSuggestionsState);
    reset(activeSearchState);
    reset(currentToastState);
  });

  useEffect(() => {
    resetAll();
  }, [resetAll]);
};

export default useCleanup;

import { GammaVannaModelChart } from '../../indices';
import { GammaVannaModel } from '../../../types';
import { Button, ButtonGroup, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isMobileState } from '../../../states';
import { Theme } from '@mui/material/styles';
import useLog from '../../../hooks/useLog';

export const SGGammaCurve = ({
  data,
  printTheme,
}: {
  data: any;
  printTheme?: Theme;
}) => {
  const [symIndex, setSymIndex] = useState<number>(0);
  const isMobile = useRecoilValue(isMobileState);
  const { logError } = useLog('SGGammaCurve');

  const chartData = Array.isArray(data[symIndex])
    ? data[symIndex]
    : [data[symIndex]];

  try {
    const syms: string[] = data.map((d: any) => d.sym);
    // ButtonGroup looks weird on mobile for some reason
    const buttons = syms.map((sym: string, idx: number) => (
      <Button
        key={idx}
        onClick={() => setSymIndex(idx)}
        variant={idx === symIndex ? 'contained' : 'outlined'}
      >
        {sym}
      </Button>
    ));

    const desktopHeight = printTheme ? '575px' : '450px';
    const maxHeight = printTheme ? '675px' : '550px';
    return (
      <Box
        margin="auto"
        textAlign="center"
        width={1}
        height={1}
        maxHeight={maxHeight}
        minHeight={printTheme ? maxHeight : undefined}
      >
        {isMobile ? buttons : <ButtonGroup>{buttons}</ButtonGroup>}
        <Box
          width={'100%'}
          maxWidth={'1000px'}
          height={isMobile ? '425px' : desktopHeight}
          margin="auto"
          marginTop="25px"
          marginBottom="25px"
        >
          <GammaVannaModelChart
            model={GammaVannaModel.GAMMA}
            prefetchedData={chartData}
            expandable={false}
            withLevels={true}
            selectedSym={chartData[0].sym}
          />
          <NavLink
            to={'/indices'}
            style={{
              textDecoration: 'none',
              display: 'block',
            }}
          >
            <Typography color="primary" fontSize="14px">
              View All Indices Charts
            </Typography>
          </NavLink>
        </Box>
      </Box>
    );
  } catch (e) {
    logError(`Error displaying gamma curve: ${e}`, 'render');
    return null;
  }
};

import { useCallback } from 'react';
import { fetchAPI } from '../../util/shared/fetch';
import useLog from '../useLog';

const useFoundersNotes = () => {
  const { fetchAPIWithLog } = useLog('FoundersNotes');

  const getFoundersNotesIndex = useCallback(
    async (
      page: number,
      perPage: number,
      selectedMonth: number,
      selectedYear: number,
    ) => {
      return await fetchAPIWithLog(
        `foundersNotes?page=${page}&perPage=${perPage}&month=${selectedMonth}&year=${selectedYear}`,
      );
    },
    [],
  );

  const getFoundersNoteWithId = useCallback(async (id: string) => {
    return await fetchAPIWithLog(`foundersNotes/id?id=${id}`);
  }, []);

  const getFoundersNoteWithPreviewKey = useCallback(async (key: string) => {
    return await fetchAPIWithLog(`foundersNotes/preview?previewKey=${key}`);
  }, []);

  return {
    getFoundersNotesIndex,
    getFoundersNoteWithId,
    getFoundersNoteWithPreviewKey,
  };
};

export default useFoundersNotes;

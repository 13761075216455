import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DeltaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M12,7.77L18.39,18H5.61L12,7.77M12,4L2,20H22"></path>
    </SvgIcon>
  );
};

export default DeltaIcon;

import { Stack, Typography } from '@mui/material';

interface GridTableOverlayProps {
  icon?: JSX.Element;
  text: string;
}

const GridTableOverlay = ({ icon, text }: GridTableOverlayProps) => {
  return (
    <Stack
      gap={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {icon}
      <Typography>{text}</Typography>
    </Stack>
  );
};

export default GridTableOverlay;

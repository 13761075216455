import { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';
import { Link as MuiLink, Stack } from '@mui/material';
import { alpha, keyframes, useTheme } from '@mui/material/styles';
import { NewTag } from '../shared/Tag';
import { Lock } from '@mui/icons-material';
import { Box } from '@mui/material';
import { isMobileState } from 'states/shared';
import { useRecoilValue } from 'recoil';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { SGTooltip } from './SGTooltip';

const complexAnimation = keyframes`
  0% {
    transform: scale(0.8) translateY(0);
    opacity: 0;
  }
  10% {
    transform: scale(1.1) translateY(-10px) rotate(10deg);
    opacity: 1;
  }
  20% {
    transform: scale(1) translateY(0) rotate(-10deg);
  }
  40% {
    transform: scale(1.1) translateY(-10px) rotate(10deg);
  }
  60% {
    transform: scale(1) translateY(0) rotate(-10deg);
  }
  80% {
    transform: scale(1.05) translateY(-5px) rotate(5deg);
  }
  100% {
    transform: scale(1) translateY(0) rotate(0deg);
    opacity: 1;
  }
`;

type LinkProps = {
  to: string;
  isLabelShown: boolean;
  icon?: JSX.Element;
  text?: string;
  onClick?: MouseEventHandler<any> | undefined;
  isNew?: boolean;
  relativeLink?: boolean;
  isLocked?: boolean;
  isSpecialUnlocked?: boolean;
  styleOverrides?: React.CSSProperties;
  disableActive?: boolean;
};

export const Link = ({
  to,
  isLabelShown,
  icon,
  text,
  onClick,
  isNew,
  relativeLink = true,
  isLocked,
  isSpecialUnlocked,
  styleOverrides,
  disableActive,
}: LinkProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);

  const styling = (isActive: boolean, disableActive?: boolean) => ({
    color:
      isActive && !disableActive
        ? theme.palette.sgGreen
        : theme.palette.text.secondary,
    textDecoration: 'none',
    fontSize: '14px',
    alignItems: 'center',
    fontWeight: 500,
    ...styleOverrides,
  });

  const children = (
    <Stack direction="row" gap={3}>
      {!isLabelShown && icon ? (
        <SGTooltip title={text} placement="right">
          {icon}
        </SGTooltip>
      ) : (
        icon
      )}
      {isLabelShown && (
        <>
          {text && <span className="hoverable">{text}</span>}
          {isSpecialUnlocked ? (
            <Box
              sx={{
                color: theme.palette.sgGreen,
                // animation: `${complexAnimation} 3s ease-in-out`,
              }}
            >
              <LockOpenIcon fontSize="small" />
            </Box>
          ) : (
            isLocked && (
              <Box sx={{ color: theme.palette.text.disabled }}>
                <Lock fontSize="small" />
              </Box>
            )
          )}
          {isNew && <NewTag />}
        </>
      )}
    </Stack>
  );

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: 1,
        padding: '4px',
        '&:hover': {
          backgroundColor: alpha(theme.palette.sgGreen, 0.35),
          transform: 'scale(1.02)',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.short,
          }),
        },
      }}
    >
      {relativeLink ? (
        <NavLink
          to={to}
          onClick={onClick}
          style={({ isActive }) => styling(isActive, disableActive) as any}
          className="navigation-link"
        >
          {children}
        </NavLink>
      ) : (
        <MuiLink
          href={to}
          target={!isMobile ? '_blank' : ''}
          style={styling(false, disableActive) as any}
          onClick={onClick}
        >
          {children}
        </MuiLink>
      )}
    </Box>
  );
};

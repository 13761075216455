import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ExponentialGrowth = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path d="M32 6L36.58 10.58C36.58 10.58 24 27.18 4 27.18V31.5C24 31.5 39.42 13.42 39.42 13.42L44 18V6H32Z" />
      <path d="M24 28.327C22.1187 29.3353 20.1121 30.2645 18 31.0489V42H24V28.327Z" />
      <path d="M10 33.1295C12.0688 32.8252 14.0734 32.3456 16 31.7365V42H10V33.1295Z" />
      <path d="M26 27.1998C28.216 25.8893 30.2286 24.4941 32 23.1392V42H26V27.1998Z" />
      <path d="M34 21.5472V42H40V15.8915C39.1544 16.8188 37.0621 19.0119 34 21.5472Z" />
    </SvgIcon>
  );
};

export default ExponentialGrowth;

import { useCallback } from 'react';
import { prevBusinessDay } from '../../util';
import { fetchAPI } from '../../util/shared/fetch';
import useLog from '../useLog';

const useConcentration = () => {
  const { fetchAPIWithLog } = useLog('useConcentration');

  const getConcentrationByExp = useCallback(async (syms: string) => {
    return await fetchAPIWithLog(
      `v1/concentration?syms=${syms}&groupBy=Expiration`,
    );
  }, []);

  const getConcentrationByStrike = useCallback(async (syms: string) => {
    return await fetchAPIWithLog(
      `v1/concentration?syms=${syms}&groupBy=Strike`,
    );
  }, []);

  return { getConcentrationByExp, getConcentrationByStrike };
};

export default useConcentration;

import { isAdmin } from '../util';
import { useRecoilValue } from 'recoil';
import { userDetailsState } from '../states';
import { Page } from './shared/Page';

import { HideSupportStyle } from '../components/shared/HideSupportStyle';
import { NotificationsTable } from './components/Notifications/NotificationsTable';

export const AdminNotificationsPage = () => {
  const userDetails = useRecoilValue(userDetailsState);

  if (!isAdmin(userDetails!)) {
    return <></>;
  }

  return (
    <Page>
      <HideSupportStyle />
      <NotificationsTable />
    </Page>
  );
};

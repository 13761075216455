import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedIndexState } from '../../states/indices';
import useLog from '../useLog';

const useRealizedVol = () => {
  const rawSym = useRecoilValue(selectedIndexState);
  const { fetchAPIWithLog } = useLog('useRealizedVol');

  const getDailyCandles = useCallback(async () => {
    const start = dayjs().subtract(7, 'year').format('YYYY-MM-DD');
    const sym = encodeURIComponent(rawSym);
    return await fetchAPIWithLog(
      `v1/twelve_series?symbol=${sym}&interval=1day&start_date=${start}&order=ASC`,
    );
  }, [rawSym]);

  return { getDailyCandles };
};

export default useRealizedVol;

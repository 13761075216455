import { FREE_SYMBOLS } from 'config';
import { useCallback } from 'react';
import useLog from '../useLog';
import useAuth from 'hooks/auth/useAuth';
import { ProductType } from 'types';

const usePrices = () => {
  const { hasAccessToProduct } = useAuth();
  const hasAccess = hasAccessToProduct(ProductType.EQUITYHUB);
  const { fetchAPIWithLog } = useLog('usePrices');

  const getPrices = useCallback(
    async (symbols: string[]) => {
      const syms = (hasAccess ? symbols : FREE_SYMBOLS)
        .map(encodeURIComponent)
        .join('-');
      const obj: Record<string, string> = await fetchAPIWithLog(
        `v1/prices?syms=${syms}`,
      );
      return new Map(Object.entries(obj).map(([s, p]) => [s, parseFloat(p)]));
    },
    // fetchAPIWithLog is safe to leave out of deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAccess],
  );

  return { getPrices };
};

export default usePrices;

import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal as ModalComponent,
  Typography,
  useTheme,
} from '@mui/material';
import { Loader } from 'components/shared';
import useZendesk from 'hooks/useZendesk';
import { useRecoilState, useRecoilValue } from 'recoil';
import { openZendeskModalState, zendeskDataState } from 'states/resources';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  border: '2px solid #000',
  boxShadow: 24,
  width: '50%',
  height: '75%',
  overflow: 'auto',
};

export const ZendeskModal = () => {
  const [open, setOpen] = useRecoilState(openZendeskModalState);
  const data = useRecoilValue(zendeskDataState);
  const { isLoading } = useZendesk();
  const theme = useTheme();

  return (
    <div>
      <ModalComponent
        open={open}
        onClose={() => setOpen(false)}
        id="zendesk-modal"
      >
        <Box sx={style}>
          <Loader isLoading={isLoading}>
            <DialogTitle
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h1"
                sx={{ fontSize: '24px', color: theme.palette.primary.main }}
              >
                {data?.title}
              </Typography>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  gap: '4px',
                  flexDirection: 'column',
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: data?.body ?? '' }} />
              </Box>
            </DialogContent>
          </Loader>
        </Box>
      </ModalComponent>
    </div>
  );
};

import ReactGA from 'react-ga4';

// react-ga4 does not have an export for this for some reason
// so this is copied/pasted from the react-ga4 library
export type EventOptions = {
  action: string;
  category: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: 'beacon' | 'xhr' | 'image';
};

export enum GALoggerCategory {
  Watchlist = 'watchlist',
  FoundersNotes = 'founders_notes',
  Scanners = 'scanners',
  Integrations = 'integrations',
}

export default class GALogger {
  category: GALoggerCategory;

  constructor(category: GALoggerCategory) {
    this.category = category;
  }

  logEvent(action: string, args?: Omit<EventOptions, 'category' | 'action'>) {
    const eventName = `${this.category}_${action}`;
    console.debug(`GA logging ${eventName}`);
    ReactGA.event({
      category: this.category,
      action: eventName,
      ...(args == null ? {} : args),
    });
  }
}

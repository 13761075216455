import { ProductType } from '../types';
import { useParams } from 'react-router-dom';
import { FoundersNotes } from './components/FoundersNotes';
import { AccessProtectedPage } from './AccessProtectedPage';
import { useCallback } from 'react';

export const FoundersNotesPage = () => {
  const { previewKey } = useParams();
  const render = useCallback(() => <FoundersNotes />, []);

  return (
    <AccessProtectedPage
      overrideCheck={previewKey != null}
      productType={ProductType.FOUNDERS_NOTES}
      renderContent={render}
    />
  );
};

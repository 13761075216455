import { ProductType } from '../types';
import { AccessProtectedPage } from './AccessProtectedPage';
import { Home } from './components/Home';
import { useCallback } from 'react';

export const HomePage = () => {
  const render = useCallback(() => <Home />, []);
  return (
    <AccessProtectedPage
      productType={ProductType.HOME}
      renderContent={render}
    />
  );
};

import { StreamType, HiroLense, HiroOptionType, ProductType } from '../types';
import dayjs from 'dayjs';
import { calcOffsetMS, ET } from './shared/date';
import { getPremarketSymbols } from './hiro';
import { ONE_DAY_MS } from './shared';

export const streamTypeForLense = (lense: string, showTotal: boolean) => {
  switch (lense) {
    case HiroLense.All:
      return showTotal
        ? StreamType.FilteredTotalDelta
        : StreamType.FilteredCallDelta | StreamType.FilteredPutDelta;
    case HiroLense.NextExp:
      return showTotal
        ? StreamType.FilteredNextExpTotalDelta
        : StreamType.FilteredNextExpCallDelta |
            StreamType.FilteredNextExpPutDelta;
    case HiroLense.Retail:
      return showTotal
        ? StreamType.FilteredRetailTotalDelta
        : StreamType.FilteredRetailCallDelta |
            StreamType.FilteredRetailPutDelta;
    default:
      return 0;
  }
};

export const lenseForStreamType = (streamType: StreamType) => {
  switch (streamType) {
    case StreamType.FilteredRetailCallDelta:
    case StreamType.FilteredRetailPutDelta:
    case StreamType.FilteredRetailTotalDelta:
    case StreamType.AbsoluteRetailTotalDelta:
      return HiroLense.Retail;
    case StreamType.FilteredNextExpCallDelta:
    case StreamType.FilteredNextExpPutDelta:
    case StreamType.FilteredNextExpTotalDelta:
    case StreamType.AbsoluteNextExpTotalDelta:
      return HiroLense.NextExp;
    case StreamType.FilteredPutDelta:
    case StreamType.FilteredCallDelta:
    case StreamType.FilteredTotalDelta:
    case StreamType.AbsoluteTotalDelta:
      return HiroLense.All;
    default:
      return '';
  }
};

export const optionKeyForStreamType = (streamType: StreamType) => {
  switch (streamType) {
    case StreamType.FilteredRetailTotalDelta:
    case StreamType.FilteredNextExpTotalDelta:
    case StreamType.FilteredTotalDelta:
      return HiroOptionType.TOT;
    case StreamType.FilteredRetailCallDelta:
    case StreamType.FilteredCallDelta:
    case StreamType.FilteredNextExpCallDelta:
      return HiroOptionType.C;
    case StreamType.FilteredNextExpPutDelta:
    case StreamType.FilteredRetailPutDelta:
    case StreamType.FilteredPutDelta:
      return HiroOptionType.P;
    default:
      return '';
  }
};

export const insertIntoSortedArr = (
  arr: any,
  val: any,
  key = 'utc_time',
  opts: any = {},
) => {
  if (!Array.isArray(arr)) {
    return arr;
  }

  arr.push(val);
  let i = arr.length - 1;
  let item = arr[i];
  while (i > 0 && item[key] < arr[i - 1][key]) {
    arr[i] = arr[i - 1];
    i -= 1;
  }
  arr[i] = item;

  if (opts.replace) {
    if (i > 0) {
      if (arr[i][key] === arr[i - 1][key]) {
        arr.splice(i - 1, 1);
      }
    }
  }
  return arr;
};

export const expectedStartEndForSymbol = (
  sym: string,
  date: dayjs.Dayjs = dayjs().tz(ET),
  showPremarket: boolean,
  showPostmarket: boolean,
  productType: ProductType | undefined,
) => {
  const marketClose = date.hour(16).minute(15).second(0).millisecond(0);
  const marketOpen = date.hour(9).minute(30).second(0).millisecond(0);
  let hours = {
    start: marketOpen,
    end: marketClose,
    marketClose,
    marketOpen,
  };

  if (getPremarketSymbols(productType).has(sym)) {
    if (showPremarket) {
      hours.start = date.hour(0).minute(0).second(0).millisecond(0);
    }

    if (showPostmarket) {
      hours.end =
        date.day() === 5 // on friday, options market closes at 5pm and doesnt reopen again for futures
          ? date.hour(17).minute(0).second(0).millisecond(0)
          : date.hour(23).minute(59).second(59).millisecond(59);
    }
  }

  return hours;
};

export const splitCandle = (
  candle: number[],
): [number, number, number, number, number] => {
  let timestamp, open, high, low, close;
  // TODO: Remove when candles API is push-safe
  if (candle.length === 4) {
    [timestamp, high, low, close] = candle;
  } else {
    [timestamp, open, high, low, close] = candle;
  }
  // Discard the unused open price, which is 0 for anything other than price
  return [timestamp, open ?? 0, high, low, close];
};

// We have to calculate timezone offset for timestamps displayed in hiro since
// lightweight-charts is not timezone aware.  This means the timestamps themselves
// must be adjusted to display as though they were in the user's timezone.
// Instantiating a Dayjs object each time this calculation needs to be done is costly. So is
// calculating the timezone offset for the timezone in question. Memoize the results on a
// UTC-day basis. This is not technically sound, but since daylight savings changes occur
// Sunday 1am in the respective timezone, and nothing we support trades on
// Sunday (nyc time) this is more-or-less safe.
const _tzOffsetCache = new Map<string, number>();
export const getTzOffsetMsCached = (
  ts: dayjs.Dayjs | number,
  timezone: string,
) => {
  const ms = Number(ts);
  const day = Math.floor(ms / ONE_DAY_MS);
  const mapKey = `${timezone}-${day};`;
  let tzOffset = _tzOffsetCache.get(mapKey);
  if (tzOffset == null) {
    tzOffset = calcOffsetMS(timezone, ms);
    _tzOffsetCache.set(mapKey, tzOffset);
  }
  return tzOffset;
};

import { useEffect, useRef } from 'react';
import { Cron } from 'croner';
import { ET, getQueryDateFormatted, isMarketOpenOnDate } from '../util';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isMarketOpenState,
  todaysOpenArrState,
  userIsLoggedInState,
} from '../states';
import useLog from './useLog';

const MAX_RETRIES = 10;

export const useMarketOpenCloseListener = () => {
  const retriesRef = useRef(0);
  const setTodaysOpen = useSetRecoilState(todaysOpenArrState);
  const { fetchAPIWithLog } = useLog('useMarketCallbacks');
  const isLoggedIn = useRecoilValue(userIsLoggedInState);
  const setIsMarketOpen = useSetRecoilState(isMarketOpenState);

  const toggleMarketOpen = (val: boolean) => {
    const isOpen = val && isMarketOpenOnDate();
    setIsMarketOpen(isOpen);
    return isOpen;
  };

  const onMarketOpen = async () => {
    const data = await fetchAPIWithLog('v1/futures/mostRecentMarketOpen');

    if (!Array.isArray(data) || data.length === 0) {
      // the reason this is not a state is that this gets triggered on load in the useeffect,
      // with a state where retries = 0, and its state context is
      // then used when using setTimeout (and not refreshed when state is then set later),
      // so if this was a state instead of a ref it would never increment and always be 0
      if (retriesRef.current < MAX_RETRIES) {
        retriesRef.current = retriesRef.current + 1;
        // retry every 3 minutes up to MAX_RETRIES till this no longer errors
        setTimeout(() => onMarketOpen(), 3 * 60_000);
      }
      return;
    }
    setTodaysOpen(data);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    // the below is only false if it's premarket on a market open day
    // in which case the cron will trigger on market open
    if (getQueryDateFormatted(true) === getQueryDateFormatted(false)) {
      onMarketOpen();
    }

    // this fires M-F at 9:30AM ET
    const openJob = Cron('30 9 * * 1-5', { timezone: ET }, () => {
      if (toggleMarketOpen(true)) {
        setTimeout(onMarketOpen, 10_000);
      }
    });

    // this fires M-F at 4:00PM ET
    const closeJob = Cron('00 16 * * 1-5', { timezone: ET }, () => {
      toggleMarketOpen(false);
    });

    return () => {
      openJob.stop();
      closeJob.stop();
    };
  }, [isLoggedIn]);
};

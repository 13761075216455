import { GridSortModel } from '@spotgamma/x-data-grid-premium';
import { TAPE_FETCH_BATCH_SIZE } from 'config/tape';
import { STREAM_HOST_URL } from 'config/shared';
import useLog from 'hooks/useLog';
import { Filter, RawOptionFeedContract } from 'types/tape';
import { getFiltersForPayload, getOptionFeedContract } from 'util/tape';
import { encodeURIJson } from 'util/shared';

const useTapeContracts = () => {
  const { fetchAPIWithLog } = useLog('useTapeContracts');

  const fetchContracts = async (
    filters: Filter[],
    sorting: GridSortModel,
    offset = 0,
    size = TAPE_FETCH_BATCH_SIZE,
  ): Promise<RawOptionFeedContract[]> => {
    try {
      const response = await fetchAPIWithLog(
        `sg/tns_contracts?filters=${encodeURIJson(
          getFiltersForPayload(filters),
        )}&sorting=${encodeURIJson(sorting)}&offset=${offset}&limit=${size}`,
        { host: STREAM_HOST_URL },
      );

      return (response as RawOptionFeedContract[]).map(getOptionFeedContract);
    } catch (err) {
      console.error(err);
      throw new Error(
        'Failed to fetch contracts data. Try again or contact us if the issue persists.',
      );
    }
  };

  return {
    fetchContracts,
  };
};

export default useTapeContracts;

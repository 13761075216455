import { SxProps, Theme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

interface ComponentHeaderProps {
  title?: string | JSX.Element;
  buttons: JSX.Element;
  styleProps?: SxProps<Theme>;
  buttonsProps?: SxProps<Theme>;
}

export const ComponentHeader = ({
  title,
  buttons,
  styleProps,
  buttonsProps,
}: ComponentHeaderProps) => {
  return (
    <Stack
      gap={1}
      sx={{
        mb: 2,
        ...styleProps,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          gap={3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {typeof title === 'string' ? (
            <Typography
              variant="h2"
              color="text.primary"
              textTransform="capitalize"
              fontWeight={500}
            >
              {title}
            </Typography>
          ) : (
            title
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            ...(buttonsProps ?? {}),
          }}
        >
          {buttons}
        </Box>
      </Box>
    </Stack>
  );
};

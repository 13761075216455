import { atom } from 'recoil';
import { UserNotification } from 'types/notifications';

export const userNotificationsState = atom<UserNotification[]>({
  key: 'notifications-userNotificationsState',
  default: [],
});

export const unseenUserNotificationsState = atom<number>({
  key: 'notifications-unseenUserNotificationsState',
  default: 0,
});

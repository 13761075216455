import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import {
  tnsActiveWatchlistsIdsState,
  tnsColumnOrderState,
  tnsColumnSizesState,
  tnsColumnsVisibilityState,
  tnsNewFilterState,
  tnsFilterPanelContainerActiveTab,
  tnsFilterPanelContainerOpenState,
  tnsActiveCustomFilterState,
  tnsSavedFiltersState,
  tnsFlowLiveState,
  tnsColumnsSortModelState,
  tnsFlowSummaryExpandedState,
  tnsDailyOverviewExpandedState,
  tnsContractsColumnsVisibilityState,
  tnsContractsColumnsSortModelState,
  tnsContractsColumnOrderState,
  tnsContractsColumnSizesState,
} from 'states/tape';
import OptionsFeed from './Tape';
import { Stack } from '@mui/material';
import DailyFlowOverview from './summary/DailyFlowOverview';

const TapeContainer = () => {
  const isMobile = useRecoilValue(isMobileState);
  const tnsFlowSummaryExpanded = useRecoilValue(tnsFlowSummaryExpandedState);
  const tnsDailyOverviewExpanded = useRecoilValue(
    tnsDailyOverviewExpandedState,
  );

  let minHeight = 1150;
  if (tnsFlowSummaryExpanded) {
    minHeight += 200;
  }
  if (tnsDailyOverviewExpanded) {
    minHeight += 200;
  }

  return (
    <Stack
      sx={{
        height: isMobile ? '85vh' : '100%',
        minHeight: isMobile ? minHeight - 200 : minHeight,
        paddingBottom: '60px',
        width: '100%',
        gap: 3,
      }}
    >
      <DailyFlowOverview />
      <OptionsFeed
        tnsFlowLiveState={tnsFlowLiveState}
        filterActiveTabState={tnsFilterPanelContainerActiveTab}
        newFilterItemsState={tnsNewFilterState}
        savedFiltersState={tnsSavedFiltersState}
        activeCustomFilterState={tnsActiveCustomFilterState}
        columnSortModel={tnsColumnsSortModelState}
        contractsSortModelState={tnsContractsColumnsSortModelState}
        contractsColumnOrderState={tnsContractsColumnOrderState}
        contractsColumnSizingState={tnsContractsColumnSizesState}
        filterPanelProps={{
          openState: tnsFilterPanelContainerOpenState,
        }}
        columnVisibilityState={tnsColumnsVisibilityState}
        contractsColumnVisibilityState={tnsContractsColumnsVisibilityState}
        columnOrderState={tnsColumnOrderState}
        columnSizingState={tnsColumnSizesState}
        activeWatchlistIdsState={tnsActiveWatchlistsIdsState}
      />
    </Stack>
  );
};

export default TapeContainer;

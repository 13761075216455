import { ComboSymbol, CONTENT_TYPE_LABEL_MAP } from 'config';
import {
  IndicesContentType,
  IndexSymbol,
  HomeQuadrantTabId,
  EarningsCalendarEntry,
} from 'types';
import { getDefaultSymOptions } from './indices';
import { FUTURES_DEFAULT_SYM_OPTIONS } from 'config/home';

const FUTURE_ADJUSTED_KEYS = new Set([
  'callwallstrike',
  'putwallstrike',
  'max_g_strike',
  'l1',
  'l2',
  'l3',
  'l4',
  'c1',
  'c2',
  'c3',
  'c4',
  'zero_g_strike',
  'upx',
  'topabs_strike',
  'cws',
  'pws',
  'upx',
  'keyd',
  'keyg',
  'maxfs',
  'putctrl',
  'sig_high',
  'sig_low',
]);

export const convertLevelsToFutures = (keyLevelsData: any) => {
  let obj: any = {};
  let futuresDiff = keyLevelsData?.futuresDiff ?? 0;
  futuresDiff = parseFloat(futuresDiff.toFixed(2));

  if (futuresDiff === 0) {
    return keyLevelsData;
  }

  for (const key of Object.keys(keyLevelsData)) {
    let val = keyLevelsData[key];
    if (
      FUTURE_ADJUSTED_KEYS.has(key) ||
      FUTURE_ADJUSTED_KEYS.has(key.toLowerCase())
    ) {
      val += futuresDiff;
    }
    obj[key] = val;
  }

  return obj;
};

export const getDefaultLabelForContent = (id: string): string => {
  switch (id) {
    case 'live_es_futures_chart':
    case HomeQuadrantTabId.LIVE_PRICES_CHART:
      return CONTENT_TYPE_LABEL_MAP.get(HomeQuadrantTabId.LIVE_PRICES_CHART)!;

    case 'combo_strikes_chart':
    case IndicesContentType.COMBO_STRIKES:
      return CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.COMBO_STRIKES)!;

    case 'absolute_gamma_chart':
    case IndicesContentType.GAMMA_LEVELS:
      return CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.GAMMA_LEVELS)!;

    case 'gamma_model_chart':
    case IndicesContentType.GAMMA_MODEL:
      return CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.GAMMA_MODEL)!;

    default:
      return CONTENT_TYPE_LABEL_MAP.get(id)!;
  }
};

export const getDefaultSymForContent = (id: string): string | undefined => {
  switch (id) {
    // older keys
    case 'combo_strikes_chart':
    case 'absolute_gamma_chart':
    case 'gamma_model_chart':
    // updated keys
    case IndicesContentType.GAMMA_MODEL:
    case IndicesContentType.COMBO_STRIKES:
    case IndicesContentType.GAMMA_LEVELS:
      return IndexSymbol.SPX;
    case 'live_es_futures_chart':
    case HomeQuadrantTabId.LIVE_PRICES_CHART:
      return ComboSymbol.ES_F;
    default:
      return undefined;
  }
};

export const getDefaultSymOptionsForContent = (
  id: string,
): string[] | undefined => {
  switch (id) {
    case 'combo_strikes_chart':
    case IndicesContentType.COMBO_STRIKES:
      return getDefaultSymOptions(IndicesContentType.COMBO_STRIKES);

    case 'absolute_gamma_chart':
    case IndicesContentType.GAMMA_LEVELS:
      return getDefaultSymOptions(IndicesContentType.GAMMA_LEVELS);

    case 'gamma_model_chart':
    case IndicesContentType.GAMMA_MODEL:
      return getDefaultSymOptions(IndicesContentType.GAMMA_MODEL);

    case 'live_es_futures_chart':
    case HomeQuadrantTabId.LIVE_PRICES_CHART:
      return FUTURES_DEFAULT_SYM_OPTIONS;
    default:
      return undefined;
  }
};

export const earningsEntryForSym = (
  earningsCalendar: Map<string, EarningsCalendarEntry[]> | undefined | null,
  sym: string,
) => {
  if (earningsCalendar == null) {
    return undefined;
  }

  for (const entryArr of earningsCalendar.values()) {
    const entry = entryArr.find((entry) => entry.sym === sym);
    if (entry != null) {
      return entry;
    }
  }

  return undefined;
};

import { useRecoilValue } from 'recoil';
import {
  latestAlertsState,
  unseenAlertCountState,
  windowIsVisibleState,
} from '../../states';
import { useRef, useState, useEffect, useCallback } from 'react';
import { Box, Button, ButtonGroup, IconButton } from '@mui/material';
import { Alert, onClick } from '../core/alerts';
import useAlerts from '../../hooks/alerts/useAlerts';
import { NewTag } from '../shared';
import dayjs from 'dayjs';
import { hexToRGBA } from '../../util';
import { useTheme } from '@mui/material/styles';
import { AlertData, ProductType, ShowingAlertType } from '../../types';
import { useNavigate } from 'react-router-dom';
import useLog from '../../hooks/useLog';
import EditIcon from '@mui/icons-material/Edit';
import { EditAlertsSettings } from '../core/alerts/EditAlertsSettings';
import useToast from '../../hooks/useToast';
import useAuth from 'hooks/auth/useAuth';

export const AlertsTabBody = () => {
  const latestAlerts = useRecoilValue(latestAlertsState);
  const alertCount = useRecoilValue(unseenAlertCountState);
  const [alerts, setAlerts] = useState<any[]>([]);
  const lastAlertId = useRef<string | null | undefined>(null);
  const theme = useTheme();
  const { markLatestAlertsSeen, shouldShowAlert } = useAlerts();
  const navigate = useNavigate();
  const { hasAccessToProduct } = useAuth();
  const hasHiroAccess = hasAccessToProduct(ProductType.HIRO);
  const windowIsVisible = useRecoilValue(windowIsVisibleState);
  const [showingType, setShowingType] = useState(ShowingAlertType.All);
  const { openToast } = useToast();

  const [showEditAlertsModal, setShowEditAlertsModal] = useState(false);
  const { fetchAPIWithLog } = useLog('AlertTabBody');

  const fetchAndSetAlertsForToday = async () => {
    let fetchedAlerts: any[];
    if (lastAlertId.current != null) {
      fetchedAlerts = await fetchAPIWithLog(
        `v1/myAlerts?limit=100&alertID=${lastAlertId.current ?? 0}`,
      );
    } else {
      fetchedAlerts = await fetchAPIWithLog(`v1/myAlertsByDays?days=1`);
    }
    if (!Array.isArray(fetchedAlerts) || fetchedAlerts.length === 0) {
      return;
    }
    // When fetchedAlerts is empty, this will mark alerts as having been fetched, but empty by setting null to undefined
    lastAlertId.current = fetchedAlerts[0]?.id;
    setAlerts((alerts) => [...fetchedAlerts, ...alerts]);
  };

  useEffect(() => {
    if (!windowIsVisible) {
      return;
    }
    // we can rely on alertCount because the alerts icon is polling and updating in the background
    // no need to double poll
    if (
      alertCount > 0 ||
      (alerts.length === 0 && lastAlertId.current !== undefined)
    ) {
      fetchAndSetAlertsForToday();
    }
  }, [alerts, alertCount, windowIsVisible]);

  useEffect(() => {
    if (windowIsVisible) {
      markLatestAlertsSeen(alerts);
    }
  }, [alerts, windowIsVisible]);

  const now = dayjs().valueOf();
  const newCutoff = 3 * 60 * 1000; // 3 mins

  const onClickCb = useCallback(
    (alert: AlertData) => {
      onClick(alert, hasHiroAccess, navigate);
    },
    [hasHiroAccess, navigate],
  );

  // bottom padding because of support button
  return (
    <Box sx={{ paddingBottom: '75px' }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 15 }}>
          <ButtonGroup fullWidth>
            <Button
              sx={{ textTransform: 'none' }}
              variant={
                showingType === ShowingAlertType.All ? 'contained' : 'outlined'
              }
              onClick={() => setShowingType(ShowingAlertType.All)}
            >
              All
            </Button>
            <Button
              sx={{ textTransform: 'none' }}
              variant={
                showingType === ShowingAlertType.Watchlists
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => setShowingType(ShowingAlertType.Watchlists)}
            >
              Watchlist
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            justifyContent: 'right',
            gap: '5px',
            marginBottom: '10px',
            flexGrow: 1,
          }}
        >
          <IconButton
            aria-label="edit alert settings"
            onClick={(_evt) => {
              setShowEditAlertsModal(true);
            }}
            color="primary"
            sx={{ width: '40px', height: '40px' }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      </Box>

      <Box>
        {(Array.isArray(latestAlerts) ? latestAlerts : []).map((alert) => {
          if (!shouldShowAlert(alert, showingType)) {
            return null;
          }

          const isNew = now - dayjs(alert.alert_time).valueOf() <= newCutoff;
          const backgroundColor = isNew
            ? hexToRGBA(theme.palette.primary.main, 0.2)
            : undefined;

          return (
            <Box
              key={alert.id}
              display="flex"
              justifyContent="space-between"
              sx={{
                backgroundColor,
                padding: '4px',
                paddingRight: '8px',
                cursor: 'pointer',
              }}
              onClick={() => onClickCb(alert)}
            >
              <Alert
                alert={alert}
                flatAndVisible={true}
                onClickDisabled={true}
              />
              {isNew && <NewTag />}
            </Box>
          );
        })}
      </Box>
      <EditAlertsSettings
        onClose={() => setShowEditAlertsModal(false)}
        open={showEditAlertsModal}
        modal={true}
        // toast needs to be displayed after the modal disappears
        openToast={(toastData) => setTimeout(() => openToast(toastData), 1000)}
      />
    </Box>
  );
};

import {
  fetchAPI,
  fetchRawAPI,
  getAuthHeader,
  getPollingHeader,
} from './shared/fetch';
import PollingWorker from 'PollingWorker';
import { decode } from '@msgpack/msgpack';
import { PollOpts } from '../types/poll';
import { FetchAuxOptions } from '../types';

const DEFAULT_POLL_INTERVAL = 20_000; // 20s

// A helper/wrapper around the PollingWorker that abstracts away message ID
// matching as well as  removing message handlers and clearing associated
// intervals on unmount while handling race conditions.
//
// Usage: useEffect(() => poll(worker, opts), [worker, ...]);
export const poll = (
  worker: PollingWorker,
  opts: PollOpts,
  auxOpts?: FetchAuxOptions,
) => {
  if (worker == null) {
    return;
  }
  let id: number | null = null;
  let unsubscribed = false;
  const interval = opts.interval ?? DEFAULT_POLL_INTERVAL;

  const onMessage = (msg: any) => {
    // Handle unsubscribe race and ignore messages that aren't ours.
    if (
      unsubscribed ||
      id == null ||
      msg?.data?.id !== id ||
      msg?.data?.type === 'RPC'
    ) {
      return;
    }
    opts.onResponse(msg.data);
    if (worker.setAuthHeader) {
      // locally sometimes get an error where this method doesnt exist
      worker.setAuthHeader(getAuthHeader());
    }
  };

  const unsubscribe = () => {
    worker?.removeEventListener('message', onMessage);
    if (id != null) {
      worker?.clearPoller(id);
    }
    id = null;
    unsubscribed = true;
  };

  if (worker == null) {
    return unsubscribe;
  }

  const pollOnInit = async () => {
    try {
      const resp = await fetchRawAPI(opts.url, {
        ...(auxOpts ?? {}),
        ...getPollingHeader(interval),
      });

      const responseData = {
        status: resp.status,
        headers: Object.fromEntries(resp.headers.entries()),
      };

      if (opts.buffer) {
        const arrayBuffer = await resp.arrayBuffer();
        const data = opts.msgpack ? decode(arrayBuffer) : arrayBuffer;
        return opts.onResponse({
          data,
          ...responseData,
        });
      }

      const json = await resp.json();
      opts.onResponse({
        json,
        ...responseData,
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Kickoff the polling, waiting for the return ID from setPoller to save
  const startPolling = async () => {
    if (!worker.setPoller) {
      // locally sometimes get an error where this method doesnt exist
      return;
    }

    worker.setAuthHeader(getAuthHeader());

    // cannot have functions as part of opts passed into polling worker
    // this doesnt work great with nested objects, if we introduce those and need them
    // in pollingworker, we will want to revisit this
    const serializedOpts = Object.keys(opts).reduce((result: any, key) => {
      const val = (opts as any)[key];
      if (['string', 'number', 'boolean'].includes(typeof val)) {
        result[key] = val;
      }
      return result;
    }, {});

    id = await (opts.buffer
      ? worker.setBufferPoller(
          opts.url,
          interval,
          auxOpts ?? {},
          serializedOpts,
        )
      : worker.setPoller(opts.url, interval, auxOpts ?? {}, serializedOpts));

    // If we detect that we attempted to unsubscribe before setPoller returned.
    // make sure we follow through and rerun unsubcribe so we don't leave a poller running
    if (unsubscribed) {
      unsubscribe();
    }
  };
  startPolling();
  worker.addEventListener('message', onMessage);
  if (!opts.noPollOnInit) {
    pollOnInit();
  }
  return unsubscribe;
};
export default poll;

import { calculateDistanceToWalls, zeroOneBound } from '../../util/compass';
import {
  CompassChartData,
  CompassSymbolData,
  StrategyCompassMode,
} from '../../types/compass';
import {
  CompassDataResponse,
  ErrorResponse,
  ScannerColumnKey,
} from '../../types';
import { isErrorResponse } from '../../util';
import useToast from '../useToast';
import useLog from '../useLog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { unpricedEquitiesState } from '../../states';
import { CompassParams } from './useCompassParams';
import { compassHiddenSymbolsState } from '../../states/scanners';

const MAX_COMPASS_SYMS = 75;

type useCompassDataProps = {
  compassParams: CompassParams;
};

export type CompassData = {
  loading: boolean;
  fetchData: (syms: string[]) => void;
  data: Map<string, CompassSymbolData>;
  chartData: CompassChartData[];
  visibleChartData: CompassChartData[];
};

export const useCompassData = ({
  compassParams,
}: useCompassDataProps): CompassData => {
  const { mode, xAxisDataKey, yAxisDataKey, zAxisDataKey, syms } =
    compassParams;

  const [loading, setLoading] = useState(false);
  const [fetchedDataArr, setFetchedDataArr] = useState<CompassDataResponse[]>(
    [],
  );
  const equities = useRecoilValue(unpricedEquitiesState);
  const hiddenSymbols = useRecoilValue(compassHiddenSymbolsState);

  const { openToast } = useToast();
  const { fetchAPIWithLog } = useLog('StrategyCompass');

  useEffect(() => {
    setFetchedDataArr((oldArr) => oldArr.filter((d) => syms.includes(d.sym)));
  }, [syms]);

  const fetchData = useCallback(
    async (newSyms: string[] = []) => {
      if (newSyms.length === 0) {
        return;
      } else if (newSyms.length + syms.length > MAX_COMPASS_SYMS) {
        const remaining = MAX_COMPASS_SYMS - syms.length;
        openToast({
          type: 'warning',
          message: `You can only display up to ${MAX_COMPASS_SYMS} symbols at once. 
        Please delete some symbols using the settings icon in the toolbar above before adding more symbols.`,
        });
        if (remaining <= 0) {
          return;
        }

        newSyms = newSyms.slice(0, remaining);
      }

      setLoading(true);
      const fetchedData = (await fetchAPIWithLog(
        `v1/compass?syms=${encodeURIComponent(newSyms.join(','))}&x=${
          mode === StrategyCompassMode.Compass ? 'price' : 'skew'
        }`,
      )) as CompassDataResponse[] | ErrorResponse;
      setLoading(false);

      if (
        isErrorResponse(fetchedData) ||
        !Array.isArray(fetchedData) ||
        fetchedData.length === 0
      ) {
        openToast({
          message:
            'There was an error fetching compass data. Please try again.',
          type: 'error',
        });
        return;
      }
      setFetchedDataArr((oldArr) => [...oldArr, ...fetchedData]);
    },
    [equities, mode, syms],
  );

  const data = useMemo(() => {
    return new Map(
      fetchedDataArr.map((symData) => {
        const sym = symData.sym;
        const equity = equities.get(sym);
        const price = parseFloat(symData.price);

        let compassSymData: CompassSymbolData = {
          price,
          sym,
          bollingerBand: symData.bollingerBand,
          rsi: parseFloat(symData.rsi) / 100,
        };

        if (equity != null) {
          compassSymData = {
            ...compassSymData,
            equity,
            ivPct: equity.iv_pct,
            ivRank: equity.iv_rank,
            skewRank: equity.skew_rank,
            distanceBetweenWalls: calculateDistanceToWalls(
              price,
              equity.cws,
              equity.pws,
            ),
            nextExpCallVolumePercent: equity[ScannerColumnKey.nextExpCallVol],
            nextExpPutVolumePercent: equity[ScannerColumnKey.nextExpPutVol],
            nextExpDeltaPercent: equity[ScannerColumnKey.nextExpDelta],
            nextExpGammaPercent: equity[ScannerColumnKey.nextExpGamma],
            garchRank: equity[ScannerColumnKey.garchRank],
            oneMonthIv: equity[ScannerColumnKey.iv30],
            oneMonthRv: equity[ScannerColumnKey.rv30],
          };
        }

        return [sym, compassSymData];
      }),
    );
  }, [fetchedDataArr, equities]);

  const chartData: CompassChartData[] = useMemo(() => {
    return [...data.keys()].flatMap((sym) => {
      const symData = data.get(sym)!;
      const unroundedY = symData[yAxisDataKey]!;
      const unroundedX = symData[xAxisDataKey]!;
      const unroundedZ =
        zAxisDataKey == null ? undefined : symData[zAxisDataKey]!;
      if (unroundedY == null || unroundedX == null) {
        return [];
      }

      return [
        {
          x: zeroOneBound(unroundedX)!,
          y: zeroOneBound(unroundedY)!,
          z: unroundedZ == null ? undefined : zeroOneBound(unroundedZ)!,
          unroundedX,
          unroundedY,
          unroundedZ,
          sym,
          symData,
          hidden: hiddenSymbols.has(sym),
        },
      ];
    });
  }, [data, mode, xAxisDataKey, yAxisDataKey, zAxisDataKey, hiddenSymbols]);

  const visibleChartData = useMemo(
    () => chartData.filter((d) => !d.hidden),
    [chartData],
  );

  return { loading, fetchData, data, chartData, visibleChartData };
};

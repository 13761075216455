import { Stack, Typography, useTheme } from '@mui/material';

interface EditorTitleProps {
  title: string;
  icon?: JSX.Element;
}

const QuadrantEditorTitle = ({ title, icon }: EditorTitleProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={3}>
      {icon}
      <Typography
        sx={{
          color: theme.palette.text.secondary,
          fontSize: {
            xs: 14,
            sm: 18,
          },
        }}
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default QuadrantEditorTitle;

import {
  IconButton,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { v4 as uuidv4 } from 'uuid';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TableChartIcon from '@mui/icons-material/TableChart';
import {
  QuadrantId,
  QuadrantRowAction,
  QuadrantTab,
  QuadrantTabCategory,
} from 'types';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ArticleIcon from '@mui/icons-material/Article';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ListIcon from '@mui/icons-material/List';
import { SvgIconComponent } from '@mui/icons-material';
import { useState } from 'react';
import SymSelector from './SymSelector';

interface QuadrantEditorRowProps {
  tab: QuadrantTab;
  positionIndex: number;
  action: QuadrantRowAction;
  onRemoveTab?: (tabId: string) => void;
  onAddTab?: (quadrantId: QuadrantId, newTab: QuadrantTab) => void;
  onMoveTab?: (tabId: string, newPosition: number) => void;
  onUpdateTabSym?: (tabId: string, sym: string) => void;
}

const CATEGORY_ICON_MAP: Record<QuadrantTabCategory, SvgIconComponent> = {
  [QuadrantTabCategory.CHART]: ShowChartIcon,
  [QuadrantTabCategory.TABLE]: TableChartIcon,
  [QuadrantTabCategory.TEXT_CONTENT]: ArticleIcon,
  [QuadrantTabCategory.LIST]: ListIcon,
};

const QuadrantEditorRow = ({
  action,
  tab,
  positionIndex,
  onMoveTab,
  onAddTab,
  onRemoveTab,
  onUpdateTabSym,
}: QuadrantEditorRowProps) => {
  const theme = useTheme();
  const Icon = CATEGORY_ICON_MAP[tab.category];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSymChange = (event: SelectChangeEvent) => {
    onUpdateTabSym?.(tab.id, event.target.value as string);
  };

  const handleOpenAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle the selection of a quadrant to add the tab to
  const handleQuadrantSelect = (quadrantId: QuadrantId) => {
    onAddTab?.(quadrantId, {
      ...tab,
      id: uuidv4(),
    });
    handleClose();
  };

  const removeBtn = (
    <IconButton
      size="small"
      onClick={() => onRemoveTab?.(tab.id)}
      sx={{ color: theme.palette.error.main }}
    >
      <RemoveCircleIcon />
    </IconButton>
  );

  const addBtn = (
    <>
      <IconButton
        onClick={handleOpenAdd}
        aria-label="add new tab"
        sx={{ color: theme.palette.success.main }}
      >
        <AddIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(Object.values(QuadrantId) as QuadrantId[]).map((qId: QuadrantId) => (
          <MenuItem
            key={qId}
            onClick={() => handleQuadrantSelect(qId as QuadrantId)}
          >
            Add to Quadrant {qId}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  const symSelector = tab.sym && tab.symOptions && onUpdateTabSym && (
    <SymSelector
      value={tab.sym}
      options={tab.symOptions}
      onChange={handleSymChange}
    />
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
    >
      <Stack direction="row" alignItems="center" gap={3}>
        {action === 'remove' ? removeBtn : addBtn}
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon sx={{ color: theme.palette.text.secondary }} />
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                sm: 14,
              },
            }}
          >
            {tab.label}
          </Typography>
        </Stack>
      </Stack>

      {action === 'remove' && (
        <Stack direction="row" alignItems="center" gap={1}>
          {symSelector}
          <IconButton
            size="small"
            sx={{
              color: theme.palette.text.secondary,
            }}
            onClick={() => onMoveTab?.(tab.id, positionIndex - 1)}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            size="small"
            sx={{
              color: theme.palette.text.secondary,
            }}
            onClick={() => onMoveTab?.(tab.id, positionIndex + 1)}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default QuadrantEditorRow;

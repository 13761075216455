import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import { Chip, Stack, Typography, Button } from '@mui/material';
import { CompassData } from '../../../hooks/scanners/useCompassData';
import { useTheme } from '@mui/material/styles';
import { displayValue, shadeColor } from '../../../util';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  compassHiddenSymbolsState,
  compassHoveredSymbolState,
} from '../../../states/scanners';
import { isMobileState } from '../../../states';

type StrategyCompassEditSymbolsProps = {
  compassData: CompassData;
  compassParams: CompassParams;
};

export const StrategyCompassEditSymbols = ({
  compassParams,
  compassData,
}: StrategyCompassEditSymbolsProps) => {
  const { chartData, visibleChartData } = compassData;
  const {
    setSyms,
    syms,
    zAxisDataKey,
    xAxis,
    yAxis,
    zAxis,
    setActiveWatchlistIdsState,
  } = compassParams;

  const theme = useTheme();

  const [hiddenSymbols, setHiddenSymbols] = useRecoilState(
    compassHiddenSymbolsState,
  );
  const setHoveredSymbol = useSetRecoilState(compassHoveredSymbolState);
  const isMobile = useRecoilValue(isMobileState);

  const toggleHiddenSymbols = (sym: string) => {
    if (hiddenSymbols.has(sym)) {
      showSymbols([sym]);
    } else {
      hideSymbols([sym]);
    }
  };

  const hideSymbols = (syms: string[]) => {
    setHiddenSymbols(new Set([...hiddenSymbols].concat(syms)));
  };

  const showSymbols = (syms: string[]) => {
    const setArr = [...hiddenSymbols].filter((s) => !syms.includes(s));
    setHiddenSymbols(new Set(setArr));
  };

  const deleteSym = (sym: string) => {
    setSyms(syms.filter((s) => s !== sym));
    setHiddenSymbols(
      (hiddenSyms) => new Set([...hiddenSyms].filter((s) => s !== sym)),
    );
  };

  const deleteAllSyms = () => {
    setSyms([]);
    setHiddenSymbols(new Set());
    setActiveWatchlistIdsState([]);
  };

  if (chartData.length === 0) {
    return null;
  }

  return (
    <Stack
      sx={{
        marginBottom: '20px',
        width: 1,
        overflowY: 'auto',
        maxHeight: isMobile ? '300px' : undefined,
        marginTop: isMobile ? '25px' : undefined,
      }}
      gap="6px"
    >
      <Stack direction="row" justifyContent={'space-between'}>
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={() =>
            visibleChartData.length === 0
              ? showSymbols(syms)
              : hideSymbols(syms)
          }
          disabled={chartData.length === 0}
        >
          {visibleChartData.length === 0 ? 'Show All' : 'Hide All'}
        </Button>
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={deleteAllSyms}
        >
          Clear All
        </Button>
      </Stack>

      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between !important',
          marginBottom: '10px',
          paddingX: '12px',
        }}
      >
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
          }}
        >
          Symbol
        </Typography>
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
            paddingLeft: '3px',
          }}
        >
          Price
        </Typography>
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
            paddingLeft: '5px',
          }}
        >
          X
        </Typography>
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
          }}
        >
          Y
        </Typography>
        {zAxisDataKey != null && (
          <Typography
            sx={{
              width: '50px',
              textDecoration: 'underline',
            }}
          >
            Z
          </Typography>
        )}
      </Stack>

      {chartData.map((data) => {
        const chipLabel = (
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              height: '32px !important',
              paddingTop: '6px',
            }}
          >
            <Typography sx={{ textAlign: 'center', width: '50px' }}>
              {data.sym}
            </Typography>
            <Typography sx={{ textAlign: 'center', width: '50px' }}>
              {data.symData.price.toFixed(2)}
            </Typography>
            <Typography sx={{ textAlign: 'center', width: '50px' }}>
              {displayValue(data.unroundedX, xAxis)}
            </Typography>
            <Typography sx={{ textAlign: 'center', width: '50px' }}>
              {displayValue(data.unroundedY, yAxis)}
            </Typography>
            {data.unroundedZ != null && (
              <Typography sx={{ textAlign: 'center', width: '50px' }}>
                {displayValue(data.unroundedZ, zAxis!)}
              </Typography>
            )}
          </Stack>
        );

        const backgroundColor = hiddenSymbols.has(data.sym)
          ? theme.palette.compass.disabledChipBgColor
          : theme.palette.compass.editChipBgColor;
        return (
          <Chip
            label={chipLabel}
            onDelete={() => deleteSym(data.sym)}
            onMouseEnter={() => setHoveredSymbol(data.sym)}
            onMouseLeave={() => setHoveredSymbol(undefined)}
            onClick={() => toggleHiddenSymbols(data.sym)}
            sx={{
              backgroundColor,
              height: '32px !important',
              justifyContent: 'space-between !important',
              '.MuiChip-label': {
                width: 1,
              },
              ':hover': {
                cursor: 'pointer',
                backgroundColor: shadeColor(backgroundColor, -20),
              },
            }}
          />
        );
      })}
    </Stack>
  );
};

import { uniq } from 'lodash';
import { Watchlist } from '../../types';

export const selectedWatchlistsSymbols = (
  watchlists: Watchlist[] | undefined,
  selectedIds: (number | null)[],
) => {
  if (watchlists == null || selectedIds.length === 0) {
    return [];
  }

  const selectedWatchlists = watchlists.filter((w) =>
    selectedIds.includes(w.id),
  );
  return uniq(selectedWatchlists.flatMap((w) => w.symbols));
};

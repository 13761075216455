import { Box, Typography } from '@mui/material';
import { ProductType, TimeSeriesData, TrendingData } from '../../types';
import { alpha, useTheme } from '@mui/material/styles';
import OutboundIcon from '@mui/icons-material/Outbound';
import { Sparklines, SparklinesCurve } from '@jrwats/react-sparklines';
import * as d3 from 'd3';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import {
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
} from '../../states';
import { useSetSym } from '../../hooks';

interface WatchlistAndTrendingProps {
  type: ProductType;
  hiroData?: TrendingData | null;
  ehData?: TimeSeriesData | null;
  onClickClear?: () => void;
  onClick?: () => void;
  instrument: string;
  isBlurred?: boolean;
  graphDimensions?: {
    w: number;
    h: number;
  };
}

export const StockTrendingCard = ({
  hiroData = null,
  ehData = null,
  type,
  instrument,
  onClickClear,
  onClick,
  graphDimensions,
  isBlurred,
}: WatchlistAndTrendingProps) => {
  const theme = useTheme();
  const stocks = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const isMobile = useRecoilValue(isMobileState);
  const positiveTrendColor = useRecoilValue(positiveTrendColorState);
  const negativeTrendColor = useRecoilValue(negativeTrendColorState);
  const { setSym } = useSetSym();

  const data = type === ProductType.HIRO ? hiroData : ehData;
  const onClickHiro = useCallback(() => {
    setSym(hiroData?.instrument ?? instrument, type);
  }, [hiroData, setSym, type]);
  const onClickEh = useCallback(() => {
    setSym(ehData?.symbol ?? instrument, type);
  }, [ehData?.symbol, setSym, type]);
  const onCardClick =
    onClick ?? (type === ProductType.HIRO ? onClickHiro : onClickEh);
  let posNegColor, key, outboundIcon, price, priceOrSignalDelta;

  if (data == null) {
    price = '';
    priceOrSignalDelta = '';
    posNegColor = '#cccccc';
    key = `-trending-${instrument}`;
  } else if (type !== ProductType.HIRO) {
    price = ehData?.price && d3.format('.5s')(ehData?.price);
    priceOrSignalDelta =
      ehData?.priceDelta && !isNaN(ehData?.priceDelta)
        ? d3.format('($.2f')(ehData?.priceDelta)
        : '';
    key = `equityhub-trending-${instrument}`;
    let outboundColor, transform;
    if (ehData?.priceDelta != null) {
      posNegColor =
        ehData?.priceDelta > 0 ? positiveTrendColor : negativeTrendColor;
      outboundColor = ehData?.priceDelta > 0 ? 'success' : 'error';
      transform = ehData?.priceDelta > 0 ? 'rotate(0deg)' : 'rotate(180deg)';
    } else {
      posNegColor = '#aaaaaaa';
      outboundColor = 'disabled';
      transform = 'rotate(90deg)';
    }

    outboundIcon = (
      <OutboundIcon
        width="16px"
        color={outboundColor as any}
        style={{ transform }}
      />
    );
  } else {
    const signal = stocks.get(instrument)?.signal;
    priceOrSignalDelta =
      signal == null ? null : d3.format('.4s')(signal).replace(/G/, 'B');
    price = d3.format('($.2f')(hiroData!.price);
    posNegColor =
      hiroData!.currentDaySignal > 0 ? positiveTrendColor : negativeTrendColor;
    key = `hiro-trending-${instrument}`;
    outboundIcon = (
      <OutboundIcon
        width="16px"
        style={{
          transform:
            hiroData!.currentDaySignal > 0 ? 'rotate(0deg)' : 'rotate(180deg)',
          color:
            hiroData!.currentDaySignal > 0
              ? positiveTrendColor
              : negativeTrendColor,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        ...(isMobile
          ? { width: '200px' }
          : {
              width: '30%',
              [theme.breakpoints.down(1920)]: { width: '45%' },
            }),
        height: '80px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        cursor: 'pointer',
        overflow: 'hidden',
        padding: 0,
        alignContent: 'stretch',
        justifyContent: 'flex-end',
      }}
      key={key}
    >
      {(ehData?.values != null || hiroData?.hiro_timeseries != null) && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 0,
            marginBottom: '-200%',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end',
            filter: isBlurred ? 'blur(2px)' : 'none',
          }}
        >
          <Sparklines
            data={
              type === ProductType.HIRO
                ? hiroData!.hiro_timeseries
                : ehData?.values
            }
            width={graphDimensions?.w ?? 180}
            height={graphDimensions?.h ?? 70}
          >
            <SparklinesCurve color={posNegColor} />
          </Sparklines>
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          justifyContent: 'space-between',
          position: 'relative',
          background: `linear-gradient(0.25turn, ${
            theme.palette.background.paper
          }, ${alpha(theme.palette.background.paper, 0.5)}, ${alpha(
            theme.palette.background.paper,
            0.25,
          )})`,
          py: '5px',
          pr: '5px',
        }}
        onClick={onCardClick}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            gap: '1px',
            position: 'relative',
            zIndex: 1,
            mb: '4px',
            ml: '8px',
          }}
        >
          <Typography
            noWrap
            component="div"
            sx={{
              color: theme.palette.text.primary,
              lineHeight: '20px',
              fontSize: 'max(min(20px, 1vw), 16px)', // min 16px growing to a max of 20px depending on screen width
              fontWeight: 700,
              maxWidth: 'max(90px, 5vw)',
              filter: isBlurred ? 'blur(4px)' : 'none',
            }}
          >
            {instrument}
          </Typography>
          {(ehData != null || hiroData != null) && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  filter: isBlurred ? 'blur(3px)' : 'none',
                }}
              >
                {outboundIcon}
                <Typography
                  noWrap
                  component="div"
                  sx={{
                    color: posNegColor,
                    lineHeight: '14px',
                    fontSize: '14px',
                    fontWeight: 500,
                    filter: isBlurred ? 'blur(3px)' : 'none',
                  }}
                >
                  {type === ProductType.HIRO ? priceOrSignalDelta : price}
                </Typography>
              </Box>
              <Typography
                noWrap
                component="div"
                sx={{
                  color: theme.palette.text.primary,
                  lineHeight: '12px',
                  fontSize: '12px',
                  fontWeight: 400,
                  mt: '4px',
                  filter: isBlurred ? 'blur(3px)' : 'none',
                }}
              >
                {type === ProductType.HIRO ? price : priceOrSignalDelta}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {onClickClear && (
        <CloseIcon
          sx={{
            color: theme.palette.gray[100],
            borderRadius: '10px',
            padding: '2px',
            width: '17px',
            height: '17px',
            '&:hover': {
              backgroundColor: theme.palette.error.main,
              color: theme.palette.gray[100],
            },
            position: 'relative',
            marginTop: '-74px',
            right: '4px',
          }}
          onClick={onClickClear!}
        />
      )}
    </Box>
  );
};

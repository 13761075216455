import { useCallback } from 'react';
import useLog from '../useLog';

const useModels = () => {
  const { fetchAPIWithLog } = useLog('useModels');

  const getModels = useCallback(
    async (models: string[], sym: string, limit?: number) => {
      const params = new URLSearchParams({
        sym,
        models: models.join(','),
        limit: (limit ?? models.length).toString(),
      });
      return await fetchAPIWithLog(`v2/curve?${params.toString()}`);
    },
    [],
  );

  return { getModels };
};

export default useModels;

import * as d3 from 'd3';
import { OIEntity } from '../../types';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Stack, Typography } from '@mui/material';
import { SGTooltip } from '../../components/core';
import { useMemo } from 'react';
import { predicateSearch } from '../../util';
import useLog from '../../hooks/useLog';

type TraceVolSliderProps = {
  statsData: any;
  gammaAtLastPriceUninverted: number | undefined;
  parquetKey: OIEntity;
  width: number;
  height: number;
  fontSize?: number;
  suppress?: boolean;
};

const MEAN_KEY = 'vol_10m_mean';
const BIN_MEAN_KEY = 'mean';
const BIN_MAX_KEY = 'max';
const BIN_MIN_KEY = 'min';

const interpolateBucket = (
  arr: any[],
  idxA: number,
  idxB: number,
  targetGammaMean: number,
) => {
  const fraction =
    (targetGammaMean - arr[idxA][BIN_MEAN_KEY]) /
    (arr[idxB][BIN_MEAN_KEY] - arr[idxA][BIN_MEAN_KEY]);
  return d3.interpolate(arr[idxA][MEAN_KEY], arr[idxB][MEAN_KEY])(fraction);
};

export const TraceVolGauge = ({
  statsData,
  gammaAtLastPriceUninverted,
  parquetKey,
  width,
  height,
  fontSize = 12,
  suppress = false,
}: TraceVolSliderProps) => {
  const { nonProdDebugLog } = useLog('TraceVolGauge');

  const meanArr: { mean: number; vol_10m_mean: number }[] | null =
    useMemo(() => {
      const gammaVol = statsData?.['gamma_vol'];
      const volData =
        gammaVol?.['90']?.[`${parquetKey}_gamma`] ??
        gammaVol?.['60']?.[`${parquetKey}_gamma`];
      if (volData == null || volData.length < 1) {
        return null;
      }

      // First find any buckets that don't have strictly increasing vol_10m_mean values and combine the buckets
      let arr = Object.keys(volData)
        .sort((a, b) => (parseFloat(a) > parseFloat(b) ? 1 : -1))
        .map((key) => ({ ...volData[key] }));
      for (let i = arr.length - 1; i > 0; --i) {
        if (arr[i][MEAN_KEY] > arr[i - 1][MEAN_KEY]) {
          continue;
        }
        arr[i - 1][BIN_MEAN_KEY] =
          (arr[i][BIN_MEAN_KEY] + arr[i - 1][BIN_MEAN_KEY]) / 2;
        arr[i - 1][MEAN_KEY] = (arr[i][MEAN_KEY] + arr[i - 1][MEAN_KEY]) / 2;
        arr[i - 1][BIN_MAX_KEY] = Math.max(
          arr[i][BIN_MAX_KEY],
          arr[i - 1][BIN_MAX_KEY],
        );
        arr[i - 1][BIN_MIN_KEY] = Math.min(
          arr[i][BIN_MIN_KEY],
          arr[i - 1][BIN_MIN_KEY],
        );
        arr[i] = null; // Mark for removal
      }
      arr = arr.filter((e) => e != null);

      // Extend our percentiles with one "phantom" linearly interpolated bucket on the low end
      // Then adjust our last bucket to use the "max" of the bucket as though it was "mean"
      const firstBinMin = arr[0][BIN_MIN_KEY];
      const firstVolMean = interpolateBucket(arr, 0, 1, firstBinMin);
      arr[arr.length - 1][BIN_MEAN_KEY] = arr[arr.length - 1][BIN_MAX_KEY];
      arr.unshift({ mean: firstBinMin, vol_10m_mean: firstVolMean });
      nonProdDebugLog('normalized stats array: ', arr);
      return arr;
    }, [statsData]);

  const stabilityPercentile = useMemo(() => {
    if (gammaAtLastPriceUninverted == null || meanArr == null) {
      return null;
    }

    const binMeans = meanArr.map((o) => o[BIN_MEAN_KEY]);
    let idxA = predicateSearch(
      binMeans,
      (s) => s <= gammaAtLastPriceUninverted,
    );
    const idxB = Math.min(idxA + 1, binMeans.length - 1);
    idxA = Math.max(0, idxA);
    const interpolate = d3.interpolate(
      meanArr[idxA][MEAN_KEY],
      meanArr[idxB][MEAN_KEY],
    );

    // If idxA == idxB, the fraction doesn't matter, just make sure we don't divide by zero.
    const fraction =
      (gammaAtLastPriceUninverted - binMeans[idxA]) /
      (idxA === idxB ? 1 : binMeans[idxB] - binMeans[idxA]);
    const volMean = interpolate(fraction);

    const volMax = meanArr[meanArr.length - 1][MEAN_KEY];
    const volMin = meanArr[0][MEAN_KEY];
    const volPercentile = (volMean - volMin) / (volMax - volMin);
    nonProdDebugLog(
      'stats: using last directional gamma uninverted',
      gammaAtLastPriceUninverted,
    );
    nonProdDebugLog(
      'stats: interpolated vol mean, min, max, stability percentile',
      volMean,
      volMin,
      volMax,
      1 - volPercentile,
    );
    // stability is inverted vol
    return 1 - volPercentile;
  }, [meanArr, gammaAtLastPriceUninverted, parquetKey]);

  if (stabilityPercentile == null) {
    return null;
  }

  const opts = suppress ? { opacity: 0.3 } : {};
  return (
    <SGTooltip title="This is a proprietary, forward-looking metric measuring the likelihood of large movement over the next 60 minutes, with higher values corresponding to a lower likelihood of significant price movement. This is applicable between 9:30am and 3:30pm ET.">
      <Stack
        direction="column"
        alignItems="center"
        height={height + 3}
        sx={{ ...opts, marginY: '-5px' }}
      >
        <Gauge
          width={width}
          height={height}
          value={stabilityPercentile * 100}
          startAngle={-90}
          endAngle={90}
          valueMin={0}
          valueMax={100}
          text={({ value }) => (value != null ? `${Math.round(value)}%` : '')}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: fontSize + 2,
              transform: 'translate(0px, -6px)',
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: theme.palette.text.disabled,
            },
            marginTop: '-5px',
            marginBottom: '-2px',
          })}
        />
        <Typography sx={{ fontSize, marginTop: '-3px' }}>Stability</Typography>
      </Stack>
    </SGTooltip>
  );
};

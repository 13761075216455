import { useEffect, useRef } from 'react';
import { ALL_LENSES, LENSE_DISPLAY_TYPES } from '../../../config';
import { insertIntoSortedArr, rollingSums } from '../../../util';
import { HiroUpdatingType, PriceCandle } from '../../../types';

export const useStreamingRefs = (
  enabled: boolean,
  showTotal: boolean,
  sym: string,
  rollingSeconds: number,
) => {
  const dataRefs: any = {
    prices: useRef({ [sym]: [] }),
    all: {
      TOT: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
        bottomCandles: useRef({ [sym]: null }),
      },
      C: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
      },
      P: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
      },
    },
    nextExp: {
      TOT: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
        bottomCandles: useRef({ [sym]: null }),
      },
      C: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
      },
      P: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
      },
    },
    retail: {
      TOT: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
        bottomCandles: useRef({ [sym]: null }),
      },
      C: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
      },
      P: {
        lastRollingSeconds: useRef({ [sym]: rollingSeconds }),
        unrolledCandles: useRef({ [sym]: null }),
        rolledCandles: useRef({ [sym]: null }),
      },
    },
  };

  const hiroStreamingRefs: any = {
    chartUpdatingEnabled: useRef(false),
    socket: useRef(null),
    socketRetries: useRef(0),
    currentSymbol: useRef(sym),
    rollingSeconds: useRef(rollingSeconds),
  };

  useEffect(() => {
    hiroStreamingRefs.rollingSeconds.current = rollingSeconds;
  }, [rollingSeconds]);

  const resetPricesRefs = (symbol: string = sym) => {
    dataRefs.prices.current = { [symbol]: [] };
  };

  const resetCandlesRefs = (symbol: string = sym, nulls = true) => {
    for (const lense of ALL_LENSES) {
      for (const type of LENSE_DISPLAY_TYPES) {
        dataRefs[lense][type].unrolledCandles.current = {
          [symbol]: nulls ? null : [],
        };
        dataRefs[lense][type].rolledCandles.current = {
          [symbol]: nulls ? null : 0,
        };
        if (dataRefs[lense][type].bottomCandles) {
          dataRefs[lense][type].bottomCandles.current = {
            [symbol]: [],
          };
        }
      }
    }
  };

  const getUnrolledCandles = (lense: string, option: string) => {
    return dataRefs?.[lense]?.[option]?.unrolledCandles?.current?.[sym] ?? null;
  };

  const getBottomCandles = (lense: string) => {
    return dataRefs[lense].TOT.bottomCandles.current[sym];
  };

  const getRolledCandles = (
    lense: string,
    option: string,
    rollingSeconds: number,
  ) => {
    // If we failed to get data, bail and return an empty array.
    if (dataRefs?.[lense]?.[option] == null) {
      return [];
    }
    if (
      dataRefs?.[lense]?.[option]?.rolledCandles?.current?.[sym]?.length &&
      dataRefs?.[lense]?.[option]?.lastRollingSeconds.current?.[sym] ===
        rollingSeconds
    ) {
      return dataRefs?.[lense]?.[option]?.rolledCandles?.current?.[sym];
    }

    return calculateRolledCandles(lense, option, rollingSeconds);
  };

  const calculateRolledCandles = (
    lense: string,
    option: string,
    rollingSeconds: number,
  ) => {
    const unrolledCandles = getUnrolledCandles(lense, option);
    if (!unrolledCandles?.length) {
      return [];
    }

    const rolledCandles = rollingSums(
      unrolledCandles,
      rollingSeconds,
      HiroUpdatingType.STREAMING,
    );
    dataRefs[lense][option].rolledCandles.current[sym] = rolledCandles;
    dataRefs[lense][option].lastRollingSeconds.current[sym] = rollingSeconds;
    return rolledCandles;
  };

  const isDataLoadedForLenses = (lenses: string[]) => {
    for (const lense of lenses) {
      if (showTotal) {
        if (getUnrolledCandles(lense, 'TOT') == null) {
          return false;
        }
        continue;
      }

      if (
        getUnrolledCandles(lense, 'C') == null ||
        getUnrolledCandles(lense, 'P') == null
      ) {
        return false;
      }
    }
    return true;
  };

  const getPrices = () => {
    return dataRefs.prices.current[sym];
  };

  const initUnrolledCandle = (
    lense: string,
    option: string,
    symbol: string,
  ) => {
    dataRefs[lense][option].unrolledCandles.current[symbol] = [];
  };

  const insertCandleIntoUnrolledCandles = (
    candle: PriceCandle | null,
    lense: string,
    option: string,
  ) => {
    const unrolled = getUnrolledCandles(lense, option);
    if (unrolled == null || !candle) {
      return;
    }

    insertIntoSortedArr(unrolled, candle, 'time', {
      replace: true,
    });
  };

  const insertBottomCandle = (candle: any, lense: string) => {
    if (!dataRefs[lense].TOT.bottomCandles?.current) {
      return;
    }

    insertIntoSortedArr(
      dataRefs[lense].TOT.bottomCandles?.current[sym],
      candle,
      'time',
      { replace: true },
    );
  };

  const resetAllRefs = () => {
    resetCandlesRefs();
    resetPricesRefs();

    hiroStreamingRefs.chartUpdatingEnabled.current = false;
  };

  return {
    hiroStreamingRefs,
    dataRefs,
    resetCandlesRefs,
    resetPricesRefs,
    getRolledCandles,
    getUnrolledCandles,
    calculateRolledCandles,
    isDataLoadedForLenses,
    getPrices,
    initUnrolledCandle,
    insertCandleIntoUnrolledCandles,
    resetAllRefs,
    getBottomCandles,
    insertBottomCandle,
  };
};

import {
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { PopperInfo } from 'types/shared';

interface InfoPopperProps {
  contents: PopperInfo[];
  sx?: SxProps<Theme>;
}

const InfoPopper = ({ contents, sx }: InfoPopperProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popper' : undefined;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <IconButton
          size="small"
          color="primary"
          aria-describedby={id}
          onClick={handleClick}
          sx={{ ...sx }}
        >
          <InfoIcon sx={{ fontSize: 16 }} />
        </IconButton>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          sx={{ zIndex: 10001, maxWidth: 270 }}
          placement="bottom"
        >
          <Stack
            sx={{
              gap: 3,
              border: 1,
              borderColor: alpha(theme.palette.primary.main, 0.5),
              borderRadius: 2,
              p: 4,
              bgcolor: 'background.paper',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              overflow: 'auto',
              maxHeight: 500,
            }}
          >
            {contents?.map(({ label, description }) => (
              <Stack key={label ?? description}>
                {label && (
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      color: theme.palette.primary.main,
                    }}
                  >
                    {label}:
                  </Typography>
                )}
                <Typography sx={{ fontSize: 12 }}>{description}</Typography>
              </Stack>
            ))}
          </Stack>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default InfoPopper;

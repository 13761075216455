import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, Dispatch, SetStateAction, useCallback } from 'react';

interface PopupProps {
  setDisplayPopup: Dispatch<SetStateAction<boolean>>;
}

const PROMO_KEY = 'seen_academy_popup:v2';

export const Popup = ({ setDisplayPopup }: PopupProps) => {
  const theme = useTheme();

  const dismissPopup = useCallback(() => {
    localStorage.setItem(PROMO_KEY, 'true');
    setDisplayPopup(false);
  }, [localStorage, setDisplayPopup]);

  const onEnroll = useCallback(() => {
    dismissPopup();
    window.open(
      'https://academy.spotgamma.com/course/trading-tech-stocks',
      '_blank',
    );
  }, [dismissPopup]);

  useEffect(() => {
    // Disable popup
    let hasSeenPopup = true; // localStorage.getItem(PROMO_KEY) === 'true';
    setDisplayPopup(!hasSeenPopup);
  }, [setDisplayPopup, localStorage]);

  return (
    <Dialog open={true}>
      <DialogTitle>You Have Until 11:59 p.m. Tonight!</DialogTitle>
      <Box sx={{ display: 'flex', flexDirection: 'column', py: 4, px: 6 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: '16px',
          }}
        >
          "Trading Tech Stocks Mini Bootcamp - AI Focus"
        </Typography>
        <List dense={true} sx={{ color: theme.palette.common.white, flex: 1 }}>
          {[
            '2 hours, 2 trade studies, 2 experts, unlimited potential',
            'Weds, June 28th, 10am - 12pm EST',
            '$99 for SpotGamma Subscribers ($299 Value)',
          ].map((item) => {
            return (
              <ListItem>
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      color: theme.palette.common.white,
                      fontSize: '14px',
                      lineHeight: '16px',
                    },
                  }}
                  primary={item}
                />
              </ListItem>
            );
          })}
          <ListItem>
            <Typography
              sx={{
                color: theme.palette.warning.light,
                fontSize: '14px',
                lineHeight: '16px',
              }}
            >
              Use Code TECHBC99 for the $99 price and save $200!
            </Typography>
          </ListItem>
          <ListItem>
            <Typography
              sx={{
                color: theme.palette.text.disabled,
                fontSize: '14px',
                lineHeight: '16px',
                fontStyle: 'italic',
              }}
            >
              A recording will be provided for all participants
            </Typography>
          </ListItem>
        </List>
      </Box>
      <DialogActions>
        <Button color="info" onClick={dismissPopup}>
          Dismiss
        </Button>
        <Button onClick={onEnroll} autoFocus>
          Enroll Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

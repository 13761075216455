import { createChart, IChartApi } from 'lightweight-charts';
import { isMobileState, isSideDrawerExpandedState } from 'states';
import { useRecoilValue } from 'recoil';
import { useImperativeHandle } from 'react';
import { Box } from '@mui/system';
import {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useTheme } from '@mui/material/styles';

export const Context = createContext(undefined as any);

const Chart = forwardRef((props: any, ref) => {
  const [container, setContainer] = useState(false);
  const handleRef = useCallback(setContainer, [setContainer]);

  return (
    <Box ref={handleRef} flexGrow={1} height={1.0} width={1.0}>
      {container && (
        <ChartContainer ref={ref} {...props} container={container} />
      )}
    </Box>
  );
});

const getOpts = (container: { clientHeight: number; clientWidth: number }) => {
  const { clientHeight, clientWidth } = container || {
    clientHeight: 100,
    clientWidth: 100,
  };

  return {
    width: clientWidth,
    height: clientHeight,
  };
};

export const ChartContainer = forwardRef((props: any, ref) => {
  const onTooltip = props.onTooltip;
  const { children, container, layout, ...rest } = props;
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const [toggleRender, setToggleRender] = useState<boolean>(false);
  const sidebarDrawerExpanded = useRecoilValue(isSideDrawerExpandedState);

  useEffect(() => {
    setToggleRender(!toggleRender);
  }, [sidebarDrawerExpanded]);

  const onTooltipCallback = (param: any) => {
    if (onTooltip == null) {
      return;
    }

    let hidden =
      param.point === undefined ||
      !param.time ||
      param.point.x < 0 ||
      param.point.x > container.clientWidth ||
      param.point.y < 0 ||
      param.point.y > container.clientHeight;
    onTooltip({ hidden, param, container });
  };

  const chartApiRef = useRef({
    _api: null as IChartApi | null,
    api() {
      if (!this._api) {
        this._api = createChart(container, {
          layout: {
            ...layout,
            fontFamily: "'SF Pro Display'",
            type: 'solid',
            color: 'transparent',
          },
          grid: {
            vertLines: { color: theme.palette.hiro.grid.lineColor },
            horzLines: { color: theme.palette.hiro.grid.lineColor },
          },
          ...rest,
          ...getOpts(container),
        });
        this._api.timeScale().fitContent();
        if (onTooltip != null) {
          this._api.subscribeCrosshairMove(onTooltipCallback);
        }
      }
      return this._api;
    },
    free() {
      if (this._api) {
        this._api.remove();
        this._api = null;
      }
    },
    removeSeries(series: any) {
      if (this._api) {
        this._api.removeSeries(series);
      }
    },
  });

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current;
    currentRef.api();
  }, []);

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current;
    currentRef.api().applyOptions(rest);
  }, []);

  useImperativeHandle(ref, () => chartApiRef.current.api(), []);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    const currentRef = chartApiRef.current;
    currentRef.api().applyOptions({ layout });
  }, [layout]);

  const handleResize = () => {
    chartApiRef.current.api().applyOptions({ ...getOpts(container) });
  };

  const resizeOnEvent = () => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  };

  useLayoutEffect(resizeOnEvent, [handleResize]);

  return (
    <Context.Provider value={chartApiRef.current}>{children}</Context.Provider>
  );
});

export default Chart;

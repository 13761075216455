import { Box, Stack, Typography } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
  GridColDef,
  GridColumnHeaderParams,
  gridDateComparator,
  gridNumberComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from '@spotgamma/x-data-grid-premium';
import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  timezoneState,
} from 'states';
import {
  dayjs,
  formatAsCompactNumber,
  formatAsCurrency,
  formatAsPercentage,
  getDateFormatted,
  isOutsideTradingHours,
  nullsToEndComparator,
  valOrNa,
} from 'util/shared';
import {
  Aggressor,
  AggressorLabels,
  OptionFlag,
  OptionSaleType,
  OptionSaleTypeLabels,
  OptionsFeedColumnKey,
  OptionsFeedContractColumnKey,
  OptionTradeSide,
  OptionTradeSideLabels,
} from 'types/tape';
import { getTransactionSentiment } from 'util/tape';
import { SGTooltip } from 'components/core';
import { Earnings } from 'types';
import { blue, lightBlue, red } from '@mui/material/colors';
import { ColorMode } from 'theme';
import StockIcon from 'components/StockIcon';

interface UseOptionsFeedColumnsProps {
  blurredRowIds?: string[];
  earningsList?: Earnings[];
}

export const useTapeColumns = ({
  blurredRowIds,
  earningsList,
}: UseOptionsFeedColumnsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultHeaderTitleStyles = {
    fontSize: isMobile ? 12 : 14,
    color: theme.palette.sgGreen,
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'normal',
    lineHeight: 'normal',
  };

  const getColHeaderStyles = useCallback(
    (_params: GridColumnHeaderParams) => ({
      ...defaultHeaderTitleStyles,
      color: theme.palette.primary.main,
    }),
    [defaultHeaderTitleStyles, theme.palette.primary.main],
  );

  const getBlurredStyles = (params: GridRenderCellParams) =>
    blurredRowIds?.includes(params.id as string) ? { filter: 'blur(3px)' } : {};

  // Helper function to create base column configuration
  const createBaseColumn = (
    field: string,
    headerName: string,
    minWidth: number,
    additionalConfig = {},
    cellSx = {},
    tooltip?: string,
  ): GridColDef => ({
    field,
    headerName,
    headerClassName: 'grid-header-cell',
    minWidth,
    description: tooltip,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Box>
        <SGTooltip title={tooltip}>
          <Typography
            sx={{
              ...getColHeaderStyles(params),
              ...cellSx,
            }}
          >
            {headerName}
          </Typography>
        </SGTooltip>
      </Box>
    ),
    ...additionalConfig,
  });

  // Helper function for number columns
  const createNumberColumn = (
    field: string,
    headerName: string,
    minWidth: number,
    formatFn?: (value: any) => string,
    additionalConfig = {},
    cellSx: SxProps<Theme> = {},
    tooltip?: string,
  ) => {
    return createBaseColumn(field, headerName, minWidth, {
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          sx={{
            ...getBlurredStyles(params),
            ...cellSx,
            whiteSpace: 'nowrap',
          }}
        >
          {formatFn
            ? formatFn(params.value)
            : valOrNa(params.value?.toLocaleString())}
        </Typography>
      ),
      ...additionalConfig,
      cellSx,
      tooltip,
    });
  };

  // Helper function for Greek columns (Delta, Gamma, Vega)
  const createGreekColumn = (
    field: string,
    headerName: string,
    cellSx = {},
    tooltip?: string,
  ) => {
    return createNumberColumn(
      field,
      headerName,
      70,
      (value) => valOrNa(value ? formatAsCompactNumber(value) : ''),
      { sortingOrder: ['desc', null] },
      cellSx,
      tooltip,
    );
  };

  // Helper function for Notional columns
  const createNotionalColumn = (
    field: string,
    headerName: string,
    cellSx: SxProps<Theme> = {},
    tooltip = undefined,
  ) => {
    return createNumberColumn(
      field,
      headerName,
      80,
      (value) => valOrNa(value ? formatAsCompactNumber(value) : ''),
      { sortingOrder: ['desc', null] },
      cellSx,
      tooltip,
    );
  };

  const columns: GridColDef[] = [
    // Time Column
    createBaseColumn(
      OptionsFeedColumnKey.Time,
      'Time',
      90,
      {
        type: 'dateTime',
        getSortComparator: nullsToEndComparator(gridDateComparator),
        valueGetter: (value: bigint) =>
          value ? dayjs.utc(parseInt(value.toString())).toDate() : null,
        renderCell: (params: GridRenderCellParams) => {
          const date = dayjs(params?.value).utc();
          const tsForUser = date.tz(currentTimezone);
          const formattedTime = tsForUser.isSame(
            dayjs().tz(currentTimezone),
            'day',
          )
            ? tsForUser.format('HH:mm:ss')
            : tsForUser.format('MM-DD, HH:mm:ss');

          return (
            <Typography
              sx={{ whiteSpace: 'normal', ...getBlurredStyles(params) }}
            >
              {valOrNa(formattedTime)}
              {isOutsideTradingHours(date) && (
                <SGTooltip title="Extended Trading Hours">
                  <sub style={{ fontSize: 8 }}>ETH</sub>
                </SGTooltip>
              )}
            </Typography>
          );
        },
      },
      undefined,
      'Timestamp for when the contract was traded',
    ),

    // Underlying Column
    createBaseColumn(
      OptionsFeedColumnKey.Underlying,
      'Symbol',
      90,
      {
        type: 'string',
        getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
        renderCell: (params: GridRenderCellParams) => (
          <Stack
            sx={{
              gap: 3,
              flexDirection: 'row',
              alignItems: 'center',
              ...getBlurredStyles(params),
            }}
          >
            <StockIcon symbol={params.value} />
            <Typography>
              {valOrNa(params.value?.replace(/^.*\|/, ''))}
              {earningsList?.find((e) => e.sym === params.value) && (
                <SGTooltip title="Earnings within 3 weeks from now">
                  <sup style={{ fontSize: 8, color: red[600] }}>E</sup>
                </SGTooltip>
              )}
            </Typography>
          </Stack>
        ),
      },
      undefined,
      'The ticker listed on the options contract',
    ),

    // Trade Side Column
    createBaseColumn(
      OptionsFeedColumnKey.TradeSide,
      'Side',
      70,
      {
        type: 'singleSelect',
        valueOptions: Object.values(OptionTradeSide),
        getOptionLabel: (value: OptionTradeSide) =>
          OptionTradeSideLabels[value],
        getSortComparator: nullsToEndComparator(gridNumberComparator),
        renderCell: (params: GridRenderCellParams) => (
          <Typography sx={getBlurredStyles(params)}>
            {valOrNa(
              OptionTradeSideLabels[
                params.value as OptionTradeSide
              ]?.toUpperCase(),
            )}
          </Typography>
        ),
      },
      undefined,
      'Where the contract traded in relation to the bid-ask spread. The bid is the highest price buyers are offering for a contract, the ask is the lowest price sellers are selling a contract. Mid indicates a price between the Bid an the Ask.',
    ),

    // Aggressor Column
    createBaseColumn(
      OptionsFeedColumnKey.Aggressor,
      'Buy/Sell',
      70,
      {
        type: 'singleSelect',
        valueOptions: [Aggressor.BUY, Aggressor.SELL],
        getOptionLabel: (value: Aggressor) => AggressorLabels[value],
        renderCell: (params: GridRenderCellParams) => {
          const sentiment = getTransactionSentiment(params.row);
          return (
            <Typography
              sx={{
                ...getBlurredStyles(params),
                color:
                  sentiment === 'bullish'
                    ? serverPositiveTrendColor
                    : sentiment === 'bearish'
                    ? serverNegativeTrendColor
                    : 'inherit',
                fontWeight: 600,
              }}
            >
              {valOrNa(params.value.toString().toUpperCase())}
            </Typography>
          );
        },
      },
      undefined,
      'Determines whether the order was to buy or sell the option; green values indicate bought calls or sold puts, red values indicate sold calls or bought puts',
    ),

    // C/P Column
    createBaseColumn(
      OptionsFeedColumnKey.IsPut,
      'C/P',
      70,
      {
        type: 'string',
        getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
        valueFormatter: (value: boolean) =>
          valOrNa(value === true ? 'PUT' : 'CALL'),
        renderCell: (params: GridRenderCellParams) => {
          const sentiment = getTransactionSentiment(params.row);
          return (
            <Typography
              sx={{
                ...getBlurredStyles(params),
                color:
                  sentiment === 'bullish'
                    ? serverPositiveTrendColor
                    : sentiment === 'bearish'
                    ? serverNegativeTrendColor
                    : 'inherit',
                fontWeight: 600,
              }}
            >
              {valOrNa(params.value === true ? 'PUT' : 'CALL')}
            </Typography>
          );
        },
      },
      {},
      'Identifies whether the option was a call or a put.',
    ),

    // Strike Column
    createNumberColumn(
      OptionsFeedColumnKey.Strike,
      'Strike',
      80,
      undefined,
      {},
      {},
      'The strike price for the listed options trade',
    ),

    // Expiration Column
    createBaseColumn(
      OptionsFeedColumnKey.Expiry,
      'Expiration',
      95,
      {
        type: 'date',
        getSortComparator: nullsToEndComparator(gridDateComparator),
        valueGetter: (value: bigint) =>
          value ? dayjs.utc(parseInt(value.toString())).toDate() : null,
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            sx={{ whiteSpace: 'normal', ...getBlurredStyles(params) }}
          >
            {valOrNa(
              params.value && getDateFormatted(dayjs(params.value).utc()),
            )}
          </Typography>
        ),
      },
      {},
      'The expiration date for the listed options contract',
    ),

    // Volume Column
    createNumberColumn(
      OptionsFeedColumnKey.DailyVolCumsum,
      'Volume',
      60,
      (value) => valOrNa(formatAsCompactNumber(value)),
      { sortingOrder: ['desc', null] },
      {},
      'The daily volume of options contracts that have traded for that specific strike and expiration.',
    ),

    // OI Column
    createNumberColumn(
      OptionsFeedColumnKey.PrevOi,
      'OI',
      60,
      (value) => value && formatAsCompactNumber(value),
      { sortingOrder: ['desc', null] },
      {},
      "The prior day's total number of open options contracts for the ticker at that specific strike and expiration.",
    ),

    // Premium Column
    createNumberColumn(
      OptionsFeedColumnKey.Premium,
      'Premium',
      70,
      (value) => value && `$${formatAsCompactNumber(value)}`,
      { sortingOrder: ['desc', null] },
      {},
      'The full sale price of the listed trade; this is calculated from the size field multiplied by the option price multiplied by 100',
    ),

    // Size Column
    createNumberColumn(
      OptionsFeedColumnKey.Size,
      'Size',
      60,
      undefined,
      { sortingOrder: ['desc', null] },
      {},
      'The number of contracts traded as part of the order; each contract corresponds to 100 deliverable shares of the underlying ticker.',
    ),

    // Spot Column
    createNumberColumn(
      OptionsFeedColumnKey.StockPrice,
      'Spot',
      70,
      (value) => value && formatAsCurrency(value),
      {},
      {},
      'The price of the underlying asset at the time of the listed trade',
    ),

    // Bid Column
    createNumberColumn(
      OptionsFeedColumnKey.Bid,
      'Bid',
      70,
      undefined,
      {},
      {},
      'The highest price market buyers are offering for an options contract',
    ),

    // Ask Column
    createNumberColumn(
      OptionsFeedColumnKey.Ask,
      'Ask',
      70,
      undefined,
      {},
      {},
      'The lowest price sellers are selling an options contract',
    ),

    // Option Price Column
    createNumberColumn(
      OptionsFeedColumnKey.Price,
      'Option Price',
      80,
      (value) => value && formatAsCurrency(value),
      {},
      {},
      'For the listed trade, the price of the option for one deliverable share; this is multplied by 100, then multiplied by the size to determine the total premium for the trade',
    ),

    // Flags Column
    createBaseColumn(
      OptionsFeedColumnKey.Flags,
      'Flags',
      90,
      {
        sortable: false,
        valueGetter: ({ isBlock, isSpread, isSweep, isCross }: OptionFlag) => {
          const flags: OptionSaleType[] = [];
          if (isBlock) {
            flags.push(OptionSaleType.BLOCK);
          }
          if (isSweep) {
            flags.push(OptionSaleType.SWEEP);
          }
          if (isSpread) {
            flags.push(OptionSaleType.SPREAD);
          }
          if (isCross) {
            flags.push(OptionSaleType.CROSS);
          }
          return flags;
        },
        renderCell: (params: GridRenderCellParams) =>
          params.value?.length !== 0 ? (
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              {params.value?.map((v: OptionSaleType) => (
                <Box
                  sx={{
                    paddingX: '8px',
                    backgroundColor:
                      theme.colorMode === ColorMode.LIGHT
                        ? lightBlue[300]
                        : blue[500],
                    borderRadius: 12,
                    height: 19,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{ ...getBlurredStyles(params), fontSize: 10 }}
                  >
                    {valOrNa(OptionSaleTypeLabels[v])}
                  </Typography>
                </Box>
              ))}
            </Stack>
          ) : null,
      },
      {},
      `Specific attributes of interest for the trade.
Sweep: A large option order that has been segmented into multiple smaller orders
Cross: This flag indicates when the buy and sell orders match exactly for a specific trade, meaning they cancel eachother out 
Block:Privately negotiated options trade, typically large and executed part from public markets.
Multileg: The trade is part of a larger trade involving two or more options at once.`,
    ),

    // IV Column
    createNumberColumn(
      OptionsFeedColumnKey.IVol,
      'IV',
      80,
      (value) => value && formatAsPercentage(value),
      {},
      {},
      'The Implied Volatility for the specific trade, based on the strike, expiration, and spot price.',
    ),

    // Greeks Columns
    createGreekColumn(
      OptionsFeedColumnKey.Delta,
      'Delta',
      {},
      'The delta for the specific trade, based on the strike, expiration, and spot price.',
    ),
    createGreekColumn(
      OptionsFeedColumnKey.Gamma,
      'Gamma',
      {},
      'The gamma for the specific trade, based on the strike, expiration, and spot price. Gamma measures the rate of change of delta with respect to spot price.',
    ),
    createGreekColumn(
      OptionsFeedColumnKey.Vega,
      'Vega',
      {
        paddingRight: '4px',
      },
      "The vega for the specific trade, based on the strike, expiration, and spot price. Vega measures the sensitivity of an option's price to changes in volatility.",
    ),
  ];

  const contractColumns: GridColDef[] = [
    // Reuse base columns
    columns.find((col) => col.field === OptionsFeedColumnKey.Underlying)!,
    columns.find((col) => col.field === OptionsFeedColumnKey.IsPut)!,
    columns.find((col) => col.field === OptionsFeedColumnKey.Strike)!,
    createBaseColumn(OptionsFeedColumnKey.Expiry, 'Expiration', 90, {
      type: 'date',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: string) => (value ? dayjs(value).toDate() : null),
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ whiteSpace: 'normal', ...getBlurredStyles(params) }}>
          {valOrNa(params.value && getDateFormatted(dayjs(params.value)))}
        </Typography>
      ),
    }),

    // Contract specific columns
    createNumberColumn(
      OptionsFeedContractColumnKey.TotalPremium,
      'Total Premium',
      80,
      (value) => value && `$${formatAsCompactNumber(value)}`,
      { sortingOrder: ['desc', null] },
    ),
    createNumberColumn(
      OptionsFeedContractColumnKey.TotalVolume,
      'Total Volume',
      80,
      (value) => valOrNa(value ? formatAsCompactNumber(value) : ''),
      { sortingOrder: ['desc', null] },
    ),
    createNotionalColumn(
      OptionsFeedContractColumnKey.DeltaNotional,
      'Delta Notional',
    ),
    createNotionalColumn(
      OptionsFeedContractColumnKey.GammaNotional,
      'Gamma Notional',
    ),
    createNotionalColumn(
      OptionsFeedContractColumnKey.VegaNotional,
      'Vega Notional',
      { paddingRight: '4px' },
    ),
  ];

  return {
    columns,
    contractColumns,
  };
};

import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  Popper,
  Stack,
  Switch,
  useTheme,
} from '@mui/material';
import Tape from 'components/tape/Tape';
import {
  FullscreenBottomDrawerButton,
  Tabs,
  WatchlistMultiSelect,
} from 'components/shared';
import { AppcuesInfoButton } from 'components/shared/AppcuesInfoButton';
import { HIRO_TAB_LABEL_MAPPING, HIRO_TABS, HiroTab } from 'config';
import { useSetSym } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import TuneIcon from '@mui/icons-material/Tune';
import {
  bottomDrawerExpandedState,
  hiroActiveWatchlistsIdsState,
  hiroTnsActiveTabState,
  hiroTnsColumnOrderState,
  hiroTnsColumnSizesState,
  hiroTnsColumnsVisibilityState,
  hirotnsNewFilterState,
  hiroTnsFilterPanelOpenState,
  isMobileState,
  sidebarExpandedState,
  hirotnsActiveCustomFilterState,
  hirotnsSavedFiltersState,
  hirotnsFlowLiveState,
  hirotnsColumnSortModel,
  hiroChartTimeRangeState,
  hiroTnsChartTimeInSyncState,
  userDetailsState,
  hiroContractsColumnsVisibilityState,
  hiroContractsColumnsSortModelState,
  hiroContractsColumnOrderState,
  hiroContractsColumnSizesState,
  isInstitutionalLocalState,
} from 'states';
import { STOCK_SCREENER_FLOW_CONTENT_ID, updateSearch } from 'util/shared';
import { StockScreener } from './HiroTable';
import { FilterConfig, HiroTapeView } from 'types/tape';
import {
  GridFilterAltIcon,
  GridToolbarColumnsButton,
} from '@spotgamma/x-data-grid-premium';
import { useEffect, useState } from 'react';
import useTapeFilters from 'hooks/optionsFeed/useTapeFilters';
import useToast from 'hooks/useToast';
import { SGTooltip } from 'components/core';
import DataAgreementContainer from 'components/tape/DataAgreementContainer';
import { ProductType } from 'types/shared';
import useAuth from 'hooks/auth/useAuth';

const HiroBottomQuadrantTabs = () => {
  const theme = useTheme();
  const { openToast } = useToast();
  const isMobile = useRecoilValue(isMobileState);
  const userDetails = useRecoilValue(userDetailsState);
  const isInstitutionalLocal = useRecoilValue(isInstitutionalLocalState);

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    hiroActiveWatchlistsIdsState,
  );
  const [filterPanelOpen, setFilterPanelOpen] = useRecoilState(
    hiroTnsFilterPanelOpenState,
  );
  const hiroTimeRange = useRecoilValue(hiroChartTimeRangeState);
  const [hiroTnsChartTimeInSync, setHiroTnsChartTimeInSync] = useRecoilState(
    hiroTnsChartTimeInSyncState,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { hasAccessToProduct, productsWithAccess } = useAuth();
  const hasTapeAccess = hasAccessToProduct(ProductType.TAPE);

  const [hiroTnsLiveFlow, setHiroTnsLiveFlow] =
    useRecoilState(hirotnsFlowLiveState);
  const bottomDrawerExpanded = useRecoilValue(bottomDrawerExpandedState);
  const hiroChartExpanded = useRecoilValue(sidebarExpandedState);
  const setSavedFilters = useSetRecoilState(hirotnsSavedFiltersState);

  const { fetchSavedFilters } = useTapeFilters();

  const [panelBtnEl, setPanelBtnEl] = useState<HTMLButtonElement | null>(null);

  const getTab = () => {
    const queryTab = searchParams.get('tab') ?? '';

    // If there's a valid query tab
    if (HIRO_TABS.has(queryTab)) {
      // Reset to StockScreener if user tries to access FlowData without permission
      if (
        !hasTapeAccess &&
        (queryTab === HiroTab.FlowData || queryTab === HiroTab.ContractData)
      ) {
        return HiroTab.StockScreener;
      }
      return queryTab;
    }

    // Default based on access
    return hasTapeAccess ? HiroTab.FlowData : HiroTab.StockScreener;
  };

  const tab = getTab();

  const { sym } = useSetSym();

  useEffect(() => {
    async function fetchFilters() {
      try {
        const myFilters: FilterConfig[] = await fetchSavedFilters(true); // fetch only noSym:true filters on hiro
        setSavedFilters(myFilters);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      }
    }

    if (userDetails != null && productsWithAccess.has(ProductType.TAPE)) {
      fetchFilters();
    }
  }, []);

  const onTnsChartTimeSyncToggle = (_e: any, checked: boolean) => {
    setHiroTnsChartTimeInSync(checked);
  };

  const handleSettingsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const settingsOpen = Boolean(anchorEl);
  const settingsId = settingsOpen ? 'toolbar-settings' : undefined;

  const dynamicToolbarComponents = new Map<string, JSX.Element>([
    [
      'hiro-tns-filter-panel-btn',
      <Button
        variant="outlined"
        disabled={
          !(
            userDetails?.isInstitutional === false ||
            isInstitutionalLocal === false
          )
        }
        size="small"
        startIcon={<GridFilterAltIcon />}
        sx={{
          textTransform: 'capitalize',
          fontSize: {
            xs: 12,
            md: 14,
          },
          backgroundColor: filterPanelOpen
            ? theme.palette.button.default
            : 'inherit',
          ':hover': {
            backgroundColor: filterPanelOpen
              ? theme.palette.button.hover
              : 'inherit',
          },
        }}
        onClick={() => setFilterPanelOpen((prev) => !prev)}
      >
        Filters
      </Button>,
    ],
    [
      'hiro-tns-flow-pause-btn',
      <Button
        variant="outlined"
        disabled={
          !(
            userDetails?.isInstitutional === false ||
            isInstitutionalLocal === false
          )
        }
        size="small"
        startIcon={
          hiroTnsLiveFlow ? (
            <PauseCircleFilledRoundedIcon />
          ) : (
            <PlayCircleFilledRoundedIcon />
          )
        }
        sx={{
          textTransform: 'capitalize',
          fontSize: {
            xs: 12,
            md: 14,
          },
        }}
        onClick={() => setHiroTnsLiveFlow((prev) => !prev)}
      >
        {hiroTnsLiveFlow ? 'Live Flow' : 'Paused Flow'}
      </Button>,
    ],
    [
      'hiro-tns-chart-sync-toggle',
      <SGTooltip title="Sync the hiro time range zoom with the tape data so they remain aligned">
        <FormControlLabel
          disabled={
            !(
              userDetails?.isInstitutional === false ||
              isInstitutionalLocal === false
            )
          }
          sx={{
            marginLeft: 0,
            '& .MuiFormControlLabel-label': {
              color: theme.palette.primary.main,
              textAlign: 'center',
              fontSize: { xs: 12, md: 14 },
            },
          }}
          control={
            <Switch
              size={isMobile ? 'small' : 'medium'}
              sx={{
                '& .MuiSwitch-track': {
                  opacity: 0.25,
                  backgroundColor: theme.palette.success.main,
                },
                '&.Mui-checked .MuiSwitch-track': {
                  opacity: 1,
                },
              }}
              color="success"
              checked={hiroTnsChartTimeInSync}
              onChange={onTnsChartTimeSyncToggle}
            />
          }
          label="Sync Time"
          labelPlacement={isMobile ? 'top' : 'start'}
        />
      </SGTooltip>,
    ],
    [
      'hiro-tns-columns-panel-btn',
      <GridToolbarColumnsButton
        ref={setPanelBtnEl}
        slotProps={{
          button: {
            sx: {
              textTransform: 'capitalize',
              fontSize: {
                xs: 12,
                md: 14,
              },
            },
          },
        }}
      />,
    ],
  ]);

  const fixedOptions = [
    ...(isMobile
      ? [
          tab !== HiroTab.StockScreener ? (
            <Box id="hiro-tns-columns-panel-btn" />
          ) : null,
          tab !== HiroTab.StockScreener ? (
            <>
              <SGTooltip title="Settings">
                <IconButton
                  aria-describedby={settingsId}
                  onClick={handleSettingsOpen}
                  size="medium"
                  color="primary"
                >
                  <TuneIcon />
                </IconButton>
              </SGTooltip>
              <Popper
                id={settingsId}
                open={settingsOpen}
                anchorEl={anchorEl}
                placement="bottom-start"
                sx={{
                  zIndex: 1000,
                }}
              >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                  <Stack
                    sx={{
                      width: '100%',
                      height: 'auto',
                      padding: '16px',
                      textAlign: 'center',
                      backgroundColor: theme.palette.background.paper,
                      boxShadow: theme.palette.shadows.default,
                      borderRadius: theme.spacing(3),
                      gap: '12px',
                    }}
                  >
                    <Box id="hiro-tns-filter-panel-btn">
                      {dynamicToolbarComponents.get(
                        'hiro-tns-filter-panel-btn',
                      )}
                    </Box>
                    {tab === HiroTab.FlowData ? (
                      <Box id="hiro-tns-flow-pause-btn">
                        {dynamicToolbarComponents.get(
                          'hiro-tns-flow-pause-btn',
                        )}
                      </Box>
                    ) : null}
                    <Box id="hiro-tns-chart-sync-toggle">
                      {dynamicToolbarComponents.get(
                        'hiro-tns-chart-sync-toggle',
                      )}
                    </Box>
                  </Stack>
                </ClickAwayListener>
              </Popper>
            </>
          ) : null,
        ]
      : [
          tab !== HiroTab.StockScreener ? (
            <Box id="hiro-tns-filter-panel-btn" sx={{ marginX: '4px' }}>
              {dynamicToolbarComponents.get('hiro-tns-filter-panel-btn')}
            </Box>
          ) : null,
          tab === HiroTab.FlowData ? (
            <Box id="hiro-tns-flow-pause-btn" sx={{ marginX: '4px' }}>
              {dynamicToolbarComponents.get('hiro-tns-flow-pause-btn')}
            </Box>
          ) : null,
          tab !== HiroTab.StockScreener ? (
            <Box id="hiro-tns-columns-panel-btn" />
          ) : null,
          tab !== HiroTab.StockScreener ? (
            <Box id="hiro-tns-chart-sync-toggle" sx={{ marginX: '4px' }}>
              {dynamicToolbarComponents.get('hiro-tns-chart-sync-toggle')}
            </Box>
          ) : null,
        ]),
    <FullscreenBottomDrawerButton />,
    ...(tab === HiroTab.StockScreener
      ? [
          <WatchlistMultiSelect
            activeWatchlistIds={activeWatchlistIds}
            setActiveWatchlistIds={setActiveWatchlistIds}
          />,
        ]
      : []),
    ...(tab === HiroTab.StockScreener
      ? [<AppcuesInfoButton articleKey={STOCK_SCREENER_FLOW_CONTENT_ID} />]
      : []),
  ];

  const renderOptionsFeed = (viewType: HiroTapeView) =>
    hasTapeAccess ? (
      <Box
        sx={{
          transition: '0.5s',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          overflowX: 'auto',
          height: '100%',
          maxHeight: isMobile ? '500px' : '100%',
        }}
      >
        {userDetails?.isInstitutional === false ||
        isInstitutionalLocal === false ? (
          <Tape
            hiroView={viewType}
            contractsSortModelState={hiroContractsColumnsSortModelState}
            contractsColumnOrderState={hiroContractsColumnOrderState}
            contractsColumnSizingState={hiroContractsColumnSizesState}
            tnsFlowLiveState={hirotnsFlowLiveState}
            columnSortModel={hirotnsColumnSortModel}
            activeCustomFilterState={hirotnsActiveCustomFilterState}
            filterActiveTabState={hiroTnsActiveTabState}
            filterPanelProps={{
              openState: hiroTnsFilterPanelOpenState,
              noSym: true,
              panelView: bottomDrawerExpanded ? 'default' : 'modal',
              currentSym: sym,
              hiroTimeRange,
              hiroTnsChartTimeInSync,
            }}
            newFilterItemsState={hirotnsNewFilterState}
            savedFiltersState={hirotnsSavedFiltersState}
            columnVisibilityState={hiroTnsColumnsVisibilityState}
            contractsColumnVisibilityState={hiroContractsColumnsVisibilityState}
            columnOrderState={hiroTnsColumnOrderState}
            columnSizingState={hiroTnsColumnSizesState}
            activeWatchlistIdsState={hiroActiveWatchlistsIdsState}
            customGridSlotProps={{
              panel: {
                anchorEl: panelBtnEl,
                placement: isMobile
                  ? 'bottom-end'
                  : !hiroChartExpanded && !bottomDrawerExpanded
                  ? 'left-end'
                  : bottomDrawerExpanded
                  ? 'bottom-end'
                  : 'top-end',
              },
              toolbar: {
                flowLiveState: hirotnsFlowLiveState,
                filterPanelOpenState: hiroTnsFilterPanelOpenState,
                disableToolbar: true,
                customPositionedControllers: [
                  {
                    elementId: 'hiro-tns-columns-panel-btn',
                    component: dynamicToolbarComponents.get(
                      'hiro-tns-columns-panel-btn',
                    ),
                  },
                ],
              },
            }}
          />
        ) : (
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DataAgreementContainer />
          </Stack>
        )}
      </Box>
    ) : null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        width: 1.0,
        minHeight: hasTapeAccess ? (isMobile ? '500px' : '700px') : 0,
      }}
    >
      <TabContext value={tab}>
        <Tabs
          options={
            new Map(
              Array.from(HIRO_TAB_LABEL_MAPPING.entries()).filter(([hTab]) =>
                !hasTapeAccess
                  ? hTab !== HiroTab.FlowData && hTab !== HiroTab.ContractData
                  : true,
              ),
            )
          }
          onChange={(_evt, tab: string) => {
            setSearchParams(updateSearch({ tab }));
          }}
          controlProps={{ options: [], fixedOptions }}
          fontSize={isMobile ? '12px' : '14px'}
        />
        {hasTapeAccess && (
          <>
            <TabPanel
              key={HiroTab.FlowData}
              value={HiroTab.FlowData as string}
              sx={{ overflowY: 'hidden' }}
            >
              {renderOptionsFeed(HiroTab.FlowData)}
            </TabPanel>
            <TabPanel
              key={HiroTab.ContractData}
              value={HiroTab.ContractData as string}
              sx={{ overflowY: 'hidden' }}
            >
              {renderOptionsFeed(HiroTab.ContractData)}
            </TabPanel>
          </>
        )}
        <TabPanel
          key={HiroTab.StockScreener}
          value={HiroTab.StockScreener as string}
          sx={{ overflow: 'hidden' }}
        >
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <StockScreener />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default HiroBottomQuadrantTabs;

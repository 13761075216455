import { FoundersNoteData } from '../types/foundersNotes';
import dayjs from 'dayjs';

export const parseNoteJson = (
  noteJson: any,
  timezone: string,
): FoundersNoteData => {
  const date = dayjs(noteJson.date).tz(timezone);
  const dateDisplayString = date.format('dddd, MMMM DD, YYYY [at] h:mm a z');

  return {
    key: noteJson.key,
    title: noteJson.title,
    html: noteJson.html ? noteJson.html : null,
    date: date,
    dateDisplayString: dateDisplayString,
    wordpress: noteJson.wordpress,
    id: noteJson.id,
    freePreviewKey: noteJson.freePreviewKey,
  };
};

import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Stack,
  alpha,
  useTheme,
} from '@mui/material';
import {
  ScannerButton,
  ScannerSection,
} from 'components/equityhub/EquityhubTable/Scanners';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SCANNER_LIST, SG_SPECIAL_SCANNERS } from 'config';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isMobileState, watchlistsState } from 'states';
import { Watchlist } from 'types';
import {
  activeScannerState,
  scannerActiveWatchlistsIdsState,
} from 'states/scanners';
import useActiveScanner from 'hooks/scanners/useActiveScanner';

const ScannersSection = () => {
  const isMobile = useRecoilValue(isMobileState);
  const [otherBtnAnchorSettingsEl, setOtherBtnAnchorSettingsEl] =
    useState<any>(null);
  const theme = useTheme();
  const [openWatchlists, setOpenWatchlists] = useState(false);
  const [openOtherScanners, setOpenOtherScanners] = useState(false);
  const watchlists = useRecoilValue(watchlistsState);
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    scannerActiveWatchlistsIdsState,
  );

  const { activeScanner, setActiveScanner } =
    useActiveScanner(activeScannerState);

  const specialScanners = SG_SPECIAL_SCANNERS.map((scanner) => (
    <ScannerButton
      label={scanner.label}
      scanner={scanner.scanner}
      key={`scanner-${scanner.label.name}`}
      hideTooltip
      activeScanner={activeScanner}
      setActiveScanner={setActiveScanner}
    />
  ));

  const otherScannersBtn = (
    <Button
      size="small"
      sx={{
        textTransform: 'capitalize',
        fontSize: isMobile ? 13 : 13,
        color: theme.palette.text.primary,
      }}
      onClick={(event) => {
        setOtherBtnAnchorSettingsEl(event.currentTarget);
        setOpenOtherScanners((previousOpen) => !previousOpen);
      }}
      endIcon={
        openOtherScanners ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
      }
    >
      Other
    </Button>
  );

  const allSymbolsBtn = (
    <Button
      size="small"
      sx={{
        textTransform: 'capitalize',
        fontSize: isMobile ? 13 : 13,
        color: theme.palette.text.primary,
      }}
      onClick={(_event) => {
        setActiveScanner(undefined);
      }}
    >
      All Symbols
    </Button>
  );

  return (
    <>
      <Stack direction="row" gap={3} sx={{ flexWrap: 'wrap' }}>
        {specialScanners}
        {allSymbolsBtn}
        {otherScannersBtn}
      </Stack>

      <Popper
        id="watchlist-scanners"
        open={openWatchlists}
        placement="bottom"
        style={{ zIndex: 100 }}
      >
        <ClickAwayListener onClickAway={() => setOpenWatchlists(false)}>
          <Box
            sx={{
              ...(isMobile ? { maxWidth: '100%' } : { maxWidth: '500px' }),
              height: 'auto',
              backgroundColor: theme.palette.background.paper,
              marginTop: '10px',
              display: 'flex',
              padding: '16px',
              boxShadow: `0px 0px 4px ${theme.palette.background.default}`,
              borderRadius: theme.spacing(3),
              border: `2px solid ${alpha(theme.palette.text.primary, 0.15)}`,
              gap: theme.spacing(4),
            }}
          >
            {watchlists?.map((watchlist: Watchlist) => (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: isMobile ? 12 : 13,
                  borderWidth: 2,
                  textTransform: 'none',
                  backgroundColor: activeWatchlistIds.includes(
                    watchlist.id as number,
                  )
                    ? alpha(theme.palette.primary.main, 0.25)
                    : null,
                  ':hover': {
                    borderWidth: 2,
                  },
                }}
                onClick={() => {
                  setActiveWatchlistIds((prev) =>
                    prev.includes(watchlist.id as number)
                      ? prev.filter((wId) => wId !== watchlist.id)
                      : [...prev, watchlist.id as number],
                  );
                  setActiveScanner(undefined);
                }}
              >
                {watchlist.name}
              </Button>
            ))}
          </Box>
        </ClickAwayListener>
      </Popper>
      <Popper
        id="equity-scanners"
        open={openOtherScanners}
        anchorEl={otherBtnAnchorSettingsEl}
        placement="bottom"
        style={{ zIndex: 100 }}
      >
        <ClickAwayListener onClickAway={() => setOpenOtherScanners(false)}>
          <Box
            sx={{
              ...(isMobile ? { maxWidth: '100%' } : { maxWidth: '500px' }),
              height: 'auto',
              backgroundColor: theme.palette.background.paper,
              marginTop: '10px',
              flexDirection: 'column',
              display: 'flex',
              padding: '16px',
              boxShadow: `0px 0px 4px ${theme.palette.background.default}`,
              borderRadius: theme.spacing(3),
              border: `2px solid ${alpha(theme.palette.text.primary, 0.15)}`,
              gap: theme.spacing(4),
            }}
          >
            {SCANNER_LIST.map((scanner) => (
              <ScannerSection
                key={scanner.label.name}
                label={scanner.label}
                scanners={scanner.scanners}
                hideTooltip
                activeScanner={activeScanner}
                setActiveScanner={setActiveScanner}
              />
            ))}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default ScannersSection;

import { Stack, Typography } from '@mui/material';
import InfoPopper from 'components/shared/InfoPopper';

interface SummaryCardWrapperProps {
  title: string;
  description?: string;
  icon?: JSX.Element;
  children: JSX.Element;
}

const SummaryCardWrapper = ({
  title,
  description,
  icon,
  children,
}: SummaryCardWrapperProps) => {
  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        {icon}
        <Typography
          sx={{
            color: 'text.secondary',
            fontSize: { xs: 10, md: 12 },
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        {description && <InfoPopper contents={[{ description }]} />}
      </Stack>

      {children}
    </Stack>
  );
};

export default SummaryCardWrapper;

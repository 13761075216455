import { getUtcYMD } from 'util/shared/date';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useRecoilState } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import {
  expConcentrationChartZoomConfigState,
  selectedConcentrationIndicesState,
} from '../../../states/indices';
import {
  ExpirationConcentration,
  GroupedExpirationConcentration,
  IndexSymbols,
  IndicesContentType,
  SentimentTab,
  SymSelectorSettings,
} from '../../../types';
import {
  formatAsCompactNumber,
  formatAsCurrency,
  getZoomConfigRefArea,
  updateBrushZoomConfig,
} from '../../../util';
import useConcentration from '../../../hooks/indices/useConcentration';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import {
  ExpandableContentButton,
  InfoButton,
  Loader,
  SGSelect,
  ZoomOutButton,
} from 'components/shared';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import useBrushZoom from 'hooks/useBrushZoom';

interface ExpConcentrationChartProps {
  isIndices?: boolean;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  selectedSym: string;
  symSelectorSettings?: SymSelectorSettings;
}

export const ExpConcentrationChart = ({
  selectedSym,
  symSelectorSettings,
  isIndices,
  chartStyleOverrides,
  containerStyleOverrides,
}: ExpConcentrationChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [concentrationData, setConcentrationData] = useState<
    GroupedExpirationConcentration[] | undefined
  >(undefined);
  const { getConcentrationByExp } = useConcentration();
  const [indexSymbols, setIndexSymbols] = useRecoilState(
    selectedConcentrationIndicesState,
  );
  const [zoomConfig, setZoomConfig] = useRecoilState(
    expConcentrationChartZoomConfigState,
  );

  const { zoomChartConfig } = useBrushZoom<GroupedExpirationConcentration>(
    zoomConfig,
    setZoomConfig,
    'Expiration',
    concentrationData ?? [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function generateChartData() {
      setIsLoading(true);
      let data: ExpirationConcentration[] = await getConcentrationByExp(
        isIndices ? (selectedSym as string) : indexSymbols.join('-'),
      );
      let groupByTradeDate = new Map<string, GroupedExpirationConcentration>();
      data.forEach(({ Expiration, Type, delta }) => {
        let accum = groupByTradeDate.get(Expiration) || {
          Expiration,
          put: 0,
          call: 0,
        };
        accum[Type === 'put' ? 'put' : 'call'] += delta;
        groupByTradeDate.set(Expiration, accum);
      });
      const values = [...groupByTradeDate.values()]
        .filter((d) => d.put !== 0 || d.call !== 0)
        .sort((a: any, b: any) => a.Expiration.localeCompare(b.Expiration));
      setConcentrationData(values);
      updateBrushZoomConfig(zoomConfig, values, setZoomConfig);
      setIsLoading(false);
    }
    if (indexSymbols.length > 0 || isIndices) {
      generateChartData();
    }
  }, [
    indexSymbols,
    getConcentrationByExp,
    setZoomConfig,
    isIndices,
    selectedSym,
  ]);

  return (
    <Loader isLoading={isLoading}>
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          gap: '8px',
          ...containerStyleOverrides,
        }}
      >
        {isIndices ? (
          <IndicesHeader
            symbol={selectedSym}
            symSelectorSettings={symSelectorSettings}
            type={IndicesContentType.EXP_CONCENTRATION}
            title="Expiration Concentration"
            expandable
            customController={
              <ZoomOutButton
                zoomConfig={zoomConfig}
                setZoomConfig={setZoomConfig}
                initialData={concentrationData ?? []}
                overrideDefault={{
                  leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
                  rightIdx: concentrationData
                    ? concentrationData.length - 1
                    : 0,
                }}
              />
            }
          />
        ) : (
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
            justifyContent="flex-end"
          >
            <SGSelect
              key="exp-concentration-chart"
              label="Select an index"
              value={indexSymbols}
              setter={setIndexSymbols}
              options={Array.from(IndexSymbols)}
            />
            <ZoomOutButton
              zoomConfig={zoomConfig}
              setZoomConfig={setZoomConfig}
              initialData={concentrationData ?? []}
              overrideDefault={{
                leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
                rightIdx: concentrationData ? concentrationData.length - 1 : 0,
              }}
            />
            <InfoButton
              key={`${SentimentTab.ExpirationConcentration}-info`}
              articleKey={SentimentTab.ExpirationConcentration}
            />
            <ExpandableContentButton
              type={SentimentTab.ExpirationConcentration}
            />
          </Stack>
        )}

        {concentrationData && (
          <ChartWatermarkContainer
            ref={ref}
            size={25}
            offsetX={50}
            offsetY={40}
            style={{ ...chartStyleOverrides }}
          >
            <ResponsiveContainer>
              <BarChart
                {...zoomChartConfig}
                margin={{
                  ...DEFAULT_CHART_MARGINS,
                  right: 65,
                  left: 10,
                  top: 15,
                }}
                stackOffset="sign"
              >
                <CartesianGrid
                  strokeDasharray="1 10"
                  stroke={theme.palette.gray}
                />
                <XAxis
                  dataKey="Expiration"
                  domain={['dataMin', 'dataMax']}
                  tick={{ fontSize: 11 }}
                  label={{
                    value: 'Expiration',
                    fontSize: 12,
                    offset: 3,
                    position: 'insideBottom',
                    fontWeight: 600,
                  }}
                  tickFormatter={getUtcYMD}
                  tickCount={10}
                  interval="preserveStartEnd"
                />
                <Brush
                  dataKey="Expiration"
                  tickFormatter={getUtcYMD}
                  startIndex={zoomConfig.leftIdx}
                  endIndex={zoomConfig.rightIdx}
                  onChange={(brushIndices: any) =>
                    setZoomConfig((prev) => ({
                      ...prev,
                      leftIdx: brushIndices.startIndex,
                      rightIdx: brushIndices.endIndex,
                    }))
                  }
                  height={20}
                  travellerWidth={15}
                  stroke={theme.palette.gray}
                  fill={theme.palette.background.paper}
                  alwaysShowText
                />
                <YAxis
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={(v: string) =>
                    `${formatAsCompactNumber(v, {
                      maximumSignificantDigits: 3,
                      style: 'currency',
                      currency: 'USD',
                    })}`
                  }
                  tick={{ fontSize: 11 }}
                  label={{
                    value: 'Delta Notional',
                    ...DEFAULT_Y_AXIS_STYLES,
                    offset: -3,
                  }}
                />
                <Tooltip
                  isAnimationActive={false}
                  formatter={(v: string) => formatAsCurrency(v, true)}
                  itemStyle={{ fontSize: '11px' }}
                  contentStyle={{
                    color: theme.palette.text.primary,
                    border: 'none',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.palette.shadows.paperBoxShadow,
                  }}
                  labelFormatter={getUtcYMD}
                  separator=": "
                />
                <Bar
                  dataKey="put"
                  stackId="delta"
                  name="Put"
                  barSize={20}
                  fill={theme.palette.core.put}
                />
                <Bar
                  dataKey="call"
                  stackId="delta"
                  name="Call"
                  barSize={20}
                  fill={theme.palette.core.call}
                />
                {getZoomConfigRefArea(zoomConfig)}
              </BarChart>
            </ResponsiveContainer>
          </ChartWatermarkContainer>
        )}
      </Stack>
    </Loader>
  );
};

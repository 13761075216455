import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
} from '@spotgamma/x-data-grid-premium';
import {
  defaultColumnScannerVisibility,
  EQUITYHUB_UPSELL,
  scannerFields,
} from 'config';
import { EquityScannerGrid } from 'components/stock_scanner/EquityScannerGrid';
import {
  EquityTableType,
  useEquityGridColumns,
} from 'components/equityhub/EquityhubTable/useEquityScannerGridColumns';
import { UpsellModal } from 'components/shared';
import * as d3 from 'd3';
import {
  activeSearchState,
  currentToastState,
  equitiesFetchLoadingState,
  equityQuantilesState,
  equitySymbolsState,
  freeSymbolState,
  pricesState,
  searchHandlerState,
  searchSuggestionsData,
  selectedEquitySymbolState,
  synthesizedEquitiesState,
  unpricedEquitiesState,
  watchlistsState,
} from 'states';
import {
  Equity,
  HistoricalEquity,
  ProductType,
  Scanner,
  SearchHandlerFunction,
} from 'types';
import { minVolumeThresholdFilter } from 'util/shared';
import usePrices from 'hooks/equityhub/usePrices';
import {
  getColumnsForScanner,
  getEquitiesForScanner,
  hasKeyEquityFields,
} from 'util/equityhub';
import useCleanup from 'hooks/useCleanup';
import useSetSym from 'hooks/hiro/useSetSym';
import {
  activeScannerState,
  scannerActiveWatchlistsIdsState,
  scannerColumnOrderState,
  scannerColumnsVisibilityState,
  scannerDataState,
  scannerFilterModelState,
  scannerPinnedColumnsState,
  scannerSortModelState,
  useDefaultColumnsState,
} from 'states/scanners';
import useActiveScanner from 'hooks/scanners/useActiveScanner';
import useAuth from 'hooks/auth/useAuth';

export const EquityStockScanner = () => {
  useCleanup();
  const theme = useTheme();
  const equities = useRecoilValue(synthesizedEquitiesState);
  const setToast = useSetRecoilState(currentToastState);
  const setEquityQuantiles = useSetRecoilState(equityQuantilesState);
  const setPrices = useSetRecoilState(pricesState);
  const [pricesLoading, setPricesLoading] = useState<boolean>(false);
  const equitiesLoading = useRecoilValue(equitiesFetchLoadingState);
  const unpricedEquities = useRecoilValue(unpricedEquitiesState);
  const watchlists = useRecoilValue(watchlistsState);
  const activeWatchlistIds = useRecoilValue(scannerActiveWatchlistsIdsState);
  const [dpiRange, setDPIRange] = useState<number[] | undefined>(undefined);
  const useDefaultColumns = useRecoilValue(useDefaultColumnsState);
  const { canViewCompass, hasAccessToProduct } = useAuth();
  const hasScannersAccess = hasAccessToProduct(ProductType.SCANNERS);
  const equityInstruments = useRecoilValue(equitySymbolsState);
  const setSelectedSymbol = useSetRecoilState(selectedEquitySymbolState);
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const setColumnVisibilityModel = useSetRecoilState(
    scannerColumnsVisibilityState,
  );

  const navigate = useNavigate();
  const setActiveSearch = useSetRecoilState(activeSearchState);

  const { activeScanner, setActiveScanner } =
    useActiveScanner(activeScannerState);
  const { getPrices } = usePrices();
  const { setSym } = useSetSym();
  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  const liveEhSymbol = useRecoilValue(freeSymbolState);
  const [scannerData, setScannerData] = useRecoilState(scannerDataState);

  const { columns: gridCols, columnGroups: gridColGroups } =
    useEquityGridColumns(
      theme,
      dpiRange,
      EquityTableType.StockScreener,
      !hasScannersAccess
        ? [...equities.values()]
            .filter((e) => !hasKeyEquityFields(e))
            .map((e) => e.sym)
        : undefined,
    );

  useEffect(() => {
    const data = [...equities.values()];
    const mFactorArray = data
      .map((c: Equity | HistoricalEquity) => c.activity_factor)
      .sort(d3.ascending);
    const vFactorArray = data
      .map((c: Equity | HistoricalEquity) => c.position_factor)
      .sort(d3.ascending);
    const minVFactor = d3.quantile(mFactorArray, 0.1);
    const maxVFactor = d3.quantile(mFactorArray, 0.9);
    const minMFactor = d3.quantile(vFactorArray, 0.1);
    const maxMFactor = d3.quantile(vFactorArray, 0.9);
    const dpiArray = data.map((d: Equity | HistoricalEquity) => d.dpi);

    setDPIRange([Math.min(...dpiArray), Math.max(...dpiArray)]);
    setEquityQuantiles({
      positionMin: minVFactor!,
      positionMax: maxVFactor!,
      activityMin: minMFactor!,
      activityMax: maxMFactor!,
    });
  }, [equities]);

  useEffect(() => {
    if (canViewCompass) {
      return;
    }

    // search suggestions data handler
    const suggestionsHandler: SearchHandlerFunction = (val: string): void => {
      const sym = val.toUpperCase();
      const ehSym = unpricedEquities.has(sym) ? sym : undefined;
      setSelectedSymbol(ehSym);
      setSym(sym, ProductType.SCANNERS);
      setActiveSearch(sym);
      if (hasScannersAccess) {
        navigate(`/${ProductType.EQUITYHUB}?sym=${sym}`);
      } else {
        setUpsellOpen(true);
      }
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [
    unpricedEquities,
    setSelectedSymbol,
    setSym,
    setActiveSearch,
    setSuggestionsHandler,
    canViewCompass,
  ]);

  useEffect(() => {
    if (!hasScannersAccess && liveEhSymbol != null) {
      setSym(liveEhSymbol, ProductType.SCANNERS);
      setActiveScanner(undefined);
      setActiveSearch(liveEhSymbol);
    }
  }, [liveEhSymbol, hasScannersAccess, setSym]);

  useEffect(() => {
    // search suggestions data
    setSuggestionsData(equityInstruments);
  }, [equityInstruments, setSuggestionsData]);

  useEffect(() => {
    async function fetchVisiblePriceData() {
      try {
        setPricesLoading(true);
        // Just fetch all prices. It's a small payload
        const newPrices: Map<string, number> = await getPrices([]);
        setPrices((prices) => new Map([...prices, ...newPrices]));
      } catch (err) {
        console.error(err);
        setToast({
          message:
            'Something went wrong while fetching equities price data. Try again or contact us if the issue persists.',
          type: 'error',
          duration: 10000,
        });
      } finally {
        setPricesLoading(false);
      }
    }
    fetchVisiblePriceData();
  }, [getPrices, setPrices, unpricedEquities]);

  useEffect(() => {
    if (useDefaultColumns) {
      setColumnVisibilityModel(
        activeScanner
          ? getColumnsForScanner(activeScanner)
          : defaultColumnScannerVisibility,
      );
    }
  }, [useDefaultColumns, activeScanner]);

  useEffect(() => {
    // filters data by search/scanner
    let resultData = [...equities.values()];

    if (activeWatchlistIds.length > 0) {
      const activeWatchlists =
        watchlists?.filter((w) =>
          activeWatchlistIds.includes(w.id as number),
        ) ?? [];

      const syms: Set<string> = new Set(resultData.map((w) => w.sym));
      const watchlistSyms = new Set(activeWatchlists.flatMap((w) => w.symbols));

      resultData = [...watchlistSyms]
        .filter((s) => syms.has(s))
        .map((s) => equities.get(s) as Equity);
    }

    if (activeScanner) {
      // Apply default filters of (call vol > 5k) and (total options vol > 10k)
      resultData = getEquitiesForScanner(activeScanner, resultData);
      if (activeScanner !== Scanner.CROSS_ASSET_SUMMARY) {
        resultData = resultData.filter(minVolumeThresholdFilter);
      }
    }

    setScannerData(
      hasScannersAccess
        ? resultData
        : resultData.slice().sort((a, b) => {
            const aFull = hasKeyEquityFields(a) ? 1 : 0;
            const bFull = hasKeyEquityFields(b) ? 1 : 0;
            return bFull - aFull;
          }),
    );
  }, [
    activeScanner,
    activeWatchlistIds,
    hasScannersAccess,
    watchlists,
    equities,
  ]);

  const setSelectedRow = (row: Equity) => {
    const newSym = row.sym;
    setSelectedSymbol(newSym);
    setSym(newSym, ProductType.SCANNERS);
  };

  const isRowDisabled = (row: Equity) => {
    return !hasScannersAccess && !row.live;
  };

  const onRowClick = (
    params: GridRowParams,
    _event: MuiEvent,
    _details: GridCallbackDetails,
  ) => {
    const row = params.row as Equity;
    if (isRowDisabled(row)) {
      setUpsellOpen(true);
    } else {
      setSelectedRow(row);
    }
  };

  return (
    <Box
      sx={{
        width: 'fit-content',
        maxWidth: '100%',
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <EquityScannerGrid
        columns={gridCols}
        data={scannerData}
        columnGroups={gridColGroups}
        onRowClick={onRowClick}
        loading={equitiesLoading || pricesLoading}
        activeScanner={activeScanner}
        useDefaultColumnState={useDefaultColumnsState}
        columnVisibilityModelState={scannerColumnsVisibilityState}
        scannerPinnedColumnsState={scannerPinnedColumnsState}
        scannerSortModelState={scannerSortModelState}
        filterModelState={scannerFilterModelState}
        activeWatchlistIdsState={scannerActiveWatchlistsIdsState}
        columnOrderState={scannerColumnOrderState}
        defaultColumnOrder={scannerFields}
      />
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={EQUITYHUB_UPSELL.title}
        subtitle={EQUITYHUB_UPSELL.subtitle}
        items={EQUITYHUB_UPSELL.items}
      />
    </Box>
  );
};

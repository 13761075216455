import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Link,
  Paper,
  Popper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  isMobileState,
  latestAlertsState,
  unseenAlertCountState,
  userDetailsState,
  workerState,
} from '../../../states';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ShowingAlertType, SubLevel } from '../../../types';
import { Alert } from './Alert';
import useAlerts from '../../../hooks/alerts/useAlerts';
import poll from 'util/poll';
import { UpsellModal } from '../../shared';
import { HIRO_UPSELL } from '../../../config';
import { getSubscriptionLevel } from '../../../util';
import { SGTooltip } from '../SGTooltip';

const ALERT_COUNT_INTERVAL = 10_000;

export const AlertsDropdown = () => {
  const theme = useTheme();
  const alertCount = useRecoilValue(unseenAlertCountState);
  const latestAlerts = useRecoilValue(latestAlertsState);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const isMobile = useRecoilValue(isMobileState);
  const userDetails = useRecoilValue(userDetailsState);
  const { markLatestAlertsSeen, playAlertSound, shouldShowAlert } = useAlerts();
  const [showingType, setShowingType] = useState(ShowingAlertType.All);
  const [upsellOpen, setUpsellOpen] = useState(false);
  const hasAlertsAccess = getSubscriptionLevel(userDetails) >= SubLevel.PRO;

  useEffect(() => {
    if (alertCount > 0) {
      playAlertSound();
    }
  }, [alertCount]);

  const icon =
    alertCount > 0 ? (
      <Badge badgeContent={alertCount} color="error">
        <NotificationsActiveIcon sx={{ fontSize: '20px' }} color="primary" />
      </Badge>
    ) : (
      <NotificationsNoneIcon
        sx={{ fontSize: '20px', color: theme.palette.gray }}
      />
    );

  const handleClosePopper = () => setOpen(false);

  const popperElement = hasAlertsAccess && (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      placement="top-end"
      style={{ zIndex: 100 }}
    >
      <ClickAwayListener onClickAway={handleClosePopper}>
        <Paper
          elevation={8}
          sx={{
            overflowY: 'auto',
            maxHeight: isMobile ? '50vh' : '80vh',
          }}
        >
          <Box sx={{ width: '100%', paddingLeft: '8px' }}>
            <ButtonGroup fullWidth>
              <Button
                sx={{ textTransform: 'none' }}
                variant={
                  showingType === ShowingAlertType.All
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => setShowingType(ShowingAlertType.All)}
              >
                All
              </Button>
              <Button
                sx={{ textTransform: 'none' }}
                variant={
                  showingType === ShowingAlertType.Watchlists
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => setShowingType(ShowingAlertType.Watchlists)}
              >
                Watchlist
              </Button>
            </ButtonGroup>
          </Box>

          {(Array.isArray(latestAlerts) ? latestAlerts : []).map((alert) => {
            if (!shouldShowAlert(alert, showingType)) {
              return null;
            }

            return (
              <Box key={alert.id} onClick={handleClosePopper}>
                <Alert alert={alert} />
              </Box>
            );
          })}
          <Box>
            <Paper
              sx={{ marginBottom: '2px', cursor: 'pointer' }}
              elevation={10}
            >
              <Box
                sx={{ padding: '12px', textAlign: 'center' }}
                fontWeight="fontWeightBold"
              >
                <Link
                  component={RouterLink}
                  to="allMyAlerts"
                  onClick={handleClosePopper}
                >
                  See all
                </Link>
              </Box>
            </Paper>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );

  return (
    <>
      <Link
        onClick={() => {
          if (!hasAlertsAccess) {
            return setUpsellOpen(true);
          }
          setOpen((prev) => !prev);
          markLatestAlertsSeen();
        }}
        ref={anchorRef}
        color={'primary'}
        sx={{
          backgroundColor: theme.palette.background.default,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SGTooltip title="Alerts">{icon}</SGTooltip>
      </Link>
      {popperElement}
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={HIRO_UPSELL.title}
        subtitle={
          userDetails != null
            ? HIRO_UPSELL.upgrade_subtitle
            : HIRO_UPSELL.subtitle
        }
        items={HIRO_UPSELL.items}
      />
    </>
  );
};

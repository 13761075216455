import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingsPopout from '../../shared/SettingsPopout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import {
  currentStatsState,
  iVolLoadingState,
  iVolErrorState,
  showEconomicEventsState,
  showForwardIVsState,
  showTermStructureIVStatsState,
  termStructureDataState,
  termStructureDateState,
  termStructureDisplayTypeState,
  termStructureSelectedDailyGreeksState,
  termStructureUsedLineColorsState,
  termStructureVisibleChartLinesState,
  currentStatsLookbackState,
} from 'states/iVol';
import { useCallback, useMemo } from 'react';
import {
  getCurrentDate,
  getDefaultSlotProps,
  getQueryDate,
  isBusinessDay,
  isMarketOpenOnDate,
  nextBusinessDay,
  prevBusinessDayOpenMarket,
} from 'util/shared';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import {
  DailyGreeks,
  ExpirationsDisplayType,
  IVolSettingsProps,
  RawGreeksDataMap,
  RawStatsData,
  TermStructure,
} from 'types';
import { TimeFramePill } from '../TimeFramePill';
import {
  MIN_QUERY_DATE,
  extractTermStructureData,
  getNextAvailableColor,
  getTermStructureKeyFromLabel,
  mergeObjectLists,
} from 'util/iVol';
import { timezoneState } from 'states';
import StatsLookbackSettings from '../StatsLookbackSetting';

const TermStructureControls = ({ selectedSym }: IVolSettingsProps) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useRecoilState(
    termStructureDateState,
  );
  const [visibleChartLines, setVisibleChartLines] = useRecoilState(
    termStructureVisibleChartLinesState,
  );

  const currentStats = useRecoilValue(currentStatsState);

  const currentStatsLookback = useRecoilValue(currentStatsLookbackState);

  const defaultSlotProps = getDefaultSlotProps(theme);
  const [showEconomicEvents, setShowEconomicEvents] = useRecoilState(
    showEconomicEventsState,
  );

  const availableColors = Object.values(theme.palette.iVol.termStructure);
  const [termStructureUsedLineColors, setTermStructureUsedLineColors] =
    useRecoilState(termStructureUsedLineColorsState);

  const [showForwardIVs, setShowForwardIVs] =
    useRecoilState(showForwardIVsState);

  const [showTermStructureIVStats, setShowTermStructureIVStats] =
    useRecoilState(showTermStructureIVStatsState);

  const [
    termStructureSelectedDailyGreeks,
    setTermStructureSelectedDailyGreeks,
  ] = useRecoilState(termStructureSelectedDailyGreeksState);

  const setTermStructureData = useSetRecoilState(termStructureDataState);

  const [selectedDisplayType, setSelectedDisplayType] = useRecoilState(
    termStructureDisplayTypeState,
  );

  const currentTimezone = useRecoilValue(timezoneState);

  const loading = useRecoilValue(iVolLoadingState);
  const error = useRecoilValue(iVolErrorState);
  const { getDailyGreeksData, getCurrentGreeksData } = useImpliedVolatility();
  const maxQueryDate = getQueryDate(true);

  const onStatsLookbackChange = (newStatsData: RawStatsData) => {
    let finalData: TermStructure[] = [];
    termStructureSelectedDailyGreeks.forEach((dg: DailyGreeks) => {
      const rawGreeks: RawGreeksDataMap = dg.data;
      const tradeDate = dg.tradeDate;
      const key: string = tradeDate.format('YYYY-MM-DD');

      if (rawGreeks) {
        const transformedTermStructureData: TermStructure[] =
          extractTermStructureData(rawGreeks, tradeDate, key, newStatsData);

        finalData = mergeObjectLists(
          finalData,
          transformedTermStructureData,
          getTermStructureKeyFromLabel(selectedDisplayType),
        );
      }
    });

    setTermStructureData(finalData);
  };

  const onAddNewTimeFrame = useCallback(
    async (dateToAdd: Dayjs) => {
      const tradeDate = dateToAdd.tz(currentTimezone);
      const formattedDate: string = tradeDate.format('YYYY-MM-DD');
      const rawData: RawGreeksDataMap | null = tradeDate.isSame(
        getCurrentDate(),
        'day',
      )
        ? await getCurrentGreeksData(selectedSym)
        : await getDailyGreeksData(formattedDate, selectedSym);

      if (rawData && currentStats) {
        const transformedTermStructureData: TermStructure[] =
          extractTermStructureData(
            rawData,
            dateToAdd,
            formattedDate,
            currentStats[currentStatsLookback],
          );

        const color =
          getNextAvailableColor(termStructureUsedLineColors, availableColors) ??
          theme.palette.primary.main;

        setTermStructureSelectedDailyGreeks((prev: DailyGreeks[]) => [
          ...prev,
          {
            key: formattedDate,
            tradeDate: tradeDate.isSame(getCurrentDate(), 'day')
              ? getCurrentDate(currentTimezone)
              : tradeDate,
            data: rawData,
            color:
              getNextAvailableColor(
                termStructureUsedLineColors,
                availableColors,
              ) ?? theme.palette.primary.main,
          },
        ]);
        setTermStructureUsedLineColors((prev: string[]) => [...prev, color]);
        setVisibleChartLines((prevData: string[]) => [
          ...prevData,
          formattedDate,
        ]);
        setTermStructureData((prevData: TermStructure[] | undefined) =>
          mergeObjectLists(
            prevData ?? [],
            transformedTermStructureData,
            getTermStructureKeyFromLabel(selectedDisplayType),
          ),
        );
        // automatically change date picker to prev business day with an open market
        if (
          prevBusinessDayOpenMarket(dateToAdd).isAfter(
            prevBusinessDayOpenMarket(MIN_QUERY_DATE),
            'day',
          )
        ) {
          setSelectedDate(prevBusinessDayOpenMarket(dateToAdd));
        }
      }
    },
    [
      currentStats,
      currentStatsLookback,
      getDailyGreeksData,
      selectedSym,
      selectedDisplayType,
      termStructureUsedLineColors,
      setTermStructureUsedLineColors,
      setTermStructureData,
      setVisibleChartLines,
      setTermStructureSelectedDailyGreeks,
      setSelectedDate,
    ],
  );

  const updatedTermStructureData = (
    dailyGreeksArr: DailyGreeks[],
    displayType: ExpirationsDisplayType,
  ) => {
    if (currentStats) {
      let finalData: TermStructure[] = [];
      dailyGreeksArr.forEach((dailyGreeks: DailyGreeks) => {
        const transformedTermStructureData: TermStructure[] =
          extractTermStructureData(
            dailyGreeks.data,
            dailyGreeks.tradeDate,
            dailyGreeks.key,
            currentStats[currentStatsLookback],
          );

        finalData = mergeObjectLists(
          finalData,
          transformedTermStructureData,
          getTermStructureKeyFromLabel(displayType),
        );
      });
      setTermStructureData(finalData);
    }
  };

  const onToggleLineVisiblity = useCallback(
    (key: string, toShow: boolean) => {
      const updatedVisibleLines: string[] = toShow
        ? [...visibleChartLines, key]
        : visibleChartLines.filter((line: string) => line !== key);
      setVisibleChartLines(updatedVisibleLines);

      const updatedDailyGreeksArr = termStructureSelectedDailyGreeks.filter(
        (dailyGreeks: DailyGreeks) =>
          updatedVisibleLines.includes(dailyGreeks.key),
      );

      updatedTermStructureData(updatedDailyGreeksArr, selectedDisplayType);
    },
    [visibleChartLines, selectedDisplayType, setVisibleChartLines],
  );

  const updateTermStructureData = (
    greeks: DailyGreeks[],
    displayType: ExpirationsDisplayType,
  ) => {
    let finalData: TermStructure[] = [];

    greeks.forEach((dailyGreeks: DailyGreeks) => {
      const key: string = dailyGreeks.tradeDate.format('YYYY-MM-DD');

      const rawData: RawGreeksDataMap | null = dailyGreeks.data;
      const transformedTermStructureData: TermStructure[] =
        rawData && currentStats
          ? extractTermStructureData(
              rawData,
              dailyGreeks.tradeDate,
              key,
              currentStats[currentStatsLookback],
            )
          : [];

      finalData = mergeObjectLists(
        finalData,
        transformedTermStructureData,
        getTermStructureKeyFromLabel(displayType),
      );
    });
    setTermStructureData(finalData);
  };

  const onChangeXDisplayType = (displayType: ExpirationsDisplayType) => {
    updateTermStructureData(termStructureSelectedDailyGreeks, displayType);
    setSelectedDisplayType(displayType);
  };

  const onDeleteTimeFrame = (key: string) => {
    const updatedgreeks = termStructureSelectedDailyGreeks.filter(
      (dailyGreeks: DailyGreeks) => dailyGreeks.key !== key,
    );
    const updatedUsedColors = updatedgreeks.map((dg: DailyGreeks) => dg.color);

    setTermStructureUsedLineColors(updatedUsedColors);
    setTermStructureSelectedDailyGreeks(updatedgreeks);
    updateTermStructureData(updatedgreeks, selectedDisplayType);
  };

  const reachedMaximumDates = useMemo(
    () => termStructureSelectedDailyGreeks.length === availableColors.length,
    [termStructureSelectedDailyGreeks],
  );

  const isSelectedDateValid = useMemo(() => {
    return (
      selectedDate &&
      selectedDate.isValid() &&
      isBusinessDay(selectedDate) &&
      selectedDate.isAfter(prevBusinessDayOpenMarket(MIN_QUERY_DATE), 'day') &&
      selectedDate.isBefore(nextBusinessDay(maxQueryDate), 'day') &&
      !termStructureSelectedDailyGreeks.some((dailyGreeks: DailyGreeks) =>
        dailyGreeks.tradeDate.isSame(selectedDate, 'day'),
      )
    );
  }, [maxQueryDate, selectedDate, termStructureSelectedDailyGreeks]);

  const datePickerHelpText = useMemo(() => {
    if (reachedMaximumDates) {
      return 'Maximum number of dates used';
    }
    if (error) {
      return 'Failed to fetch data for selected date. Try again!';
    } else if (selectedDate.isBefore(MIN_QUERY_DATE, 'day')) {
      return `No data available for ${selectedDate.format(
        'YYYY-MM-DD',
      )}. Try another date!`;
    }
    return null;
  }, [error, reachedMaximumDates, selectedDate]);

  return (
    <Stack sx={{ textAlign: 'left' }} spacing={5}>
      <ButtonGroup
        aria-label="outlined secondary button group"
        sx={{ alignSelf: 'left', flexWrap: 'wrap' }}
      >
        {Object.values(ExpirationsDisplayType).map(
          (type: ExpirationsDisplayType) => (
            <Button
              key={type}
              sx={{
                fontFamily: 'SF Pro Display',
                backgroundColor:
                  selectedDisplayType === type
                    ? alpha(theme.palette.secondary.main, 0.25)
                    : 'transparent',
                '&:hover': {
                  color: alpha(theme.palette.secondary.main, 1),
                  borderColor: alpha(theme.palette.secondary.main, 1),
                },
                fontSize: 14,
                textTransform: 'none',
                width: '150px',
              }}
              onClick={() => {
                onChangeXDisplayType(type);
              }}
            >
              {type}
            </Button>
          ),
        )}
      </ButtonGroup>
      <FormControl component="fieldset">
        <FormGroup
          aria-label="form-control"
          sx={{
            width: '300px',
            justifyContent: 'space-between',
          }}
          row
        >
          <FormControlLabel
            sx={{
              marginLeft: 0,
            }}
            control={
              <Switch
                sx={{
                  '& .MuiSwitch-track': {
                    opacity: 0.25,
                    backgroundColor: theme.palette.success.main,
                  },
                  '&.Mui-checked .MuiSwitch-track': {
                    opacity: 1,
                  },
                }}
                color="success"
                checked={showForwardIVs}
                onChange={(_e, checked: boolean) => setShowForwardIVs(checked)}
              />
            }
            label="Forward IV Adj."
            labelPlacement={
              selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                ? 'top'
                : 'start'
            }
          />
          <FormControlLabel
            sx={{
              marginLeft: 0,
            }}
            control={
              <Switch
                sx={{
                  '& .MuiSwitch-track': {
                    opacity: 0.25,
                    backgroundColor: theme.palette.success.main,
                  },
                  '&.Mui-checked .MuiSwitch-track': {
                    opacity: 1,
                  },
                }}
                color="success"
                checked={showTermStructureIVStats}
                onChange={(_e, checked: boolean) =>
                  setShowTermStructureIVStats(checked)
                }
              />
            }
            label="Statistics"
            labelPlacement={
              selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                ? 'top'
                : 'start'
            }
          />
          {selectedDisplayType === ExpirationsDisplayType.ExpirationDate && (
            <FormControlLabel
              sx={{
                marginLeft: 0,
              }}
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-track': {
                      opacity: 0.25,
                      backgroundColor: theme.palette.success.main,
                    },
                    '&.Mui-checked .MuiSwitch-track': {
                      opacity: 1,
                    },
                  }}
                  color="success"
                  checked={showEconomicEvents}
                  onChange={(_e, checked: boolean) =>
                    setShowEconomicEvents(checked)
                  }
                />
              }
              label="Economic Events"
              labelPlacement="top"
            />
          )}
        </FormGroup>
      </FormControl>

      {showTermStructureIVStats && (
        <StatsLookbackSettings onLookbackChange={onStatsLookbackChange} />
      )}

      <Typography sx={{ fontSize: '12px', marginBottom: '4px' }}>
        Add Additional Dates to Compare
      </Typography>
      <Stack direction="row" spacing={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['day']}
            label="From Date"
            value={selectedDate}
            disabled={reachedMaximumDates}
            onChange={(newValue: Dayjs | null) => {
              if (newValue) {
                setSelectedDate(newValue);
              }
            }}
            shouldDisableDate={(date: Dayjs) =>
              termStructureSelectedDailyGreeks.some(
                (dailyGreeks: DailyGreeks) =>
                  dailyGreeks.tradeDate.isSame(date, 'day'),
              ) ||
              !isBusinessDay(date) ||
              date.isBefore(MIN_QUERY_DATE, 'day') ||
              !isMarketOpenOnDate(date)
            }
            format="YYYY-MM-DD"
            minDate={MIN_QUERY_DATE}
            maxDate={maxQueryDate}
            slotProps={{
              ...defaultSlotProps,
              textField: {
                ...defaultSlotProps.textField,
                style: {
                  width: '180px',
                  marginBottom: '12px',
                  fontSize: '14px',
                },
                helperText: datePickerHelpText,
                error: error != null,
              },
            }}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          size="small"
          disabled={loading || !isSelectedDateValid || reachedMaximumDates}
          sx={{
            width: '45px',
            height: '37px',
            marginLeft: '20px',
            fontFamily: 'Satoshi Complete',
            fontSize: 14,
            textTransform: 'capitalize',
          }}
          onClick={() => {
            onAddNewTimeFrame(selectedDate);
          }}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: theme.palette.getContrastText(
                  theme.palette.background.default,
                ),
              }}
            />
          ) : (
            'Add'
          )}
        </Button>
      </Stack>
      {termStructureSelectedDailyGreeks.length > 0 ? (
        <Divider
          style={{
            borderColor: theme.palette.getContrastText(
              theme.palette.background.default,
            ),
          }}
        />
      ) : null}
      <List
        style={{
          marginTop: 0,
        }}
      >
        {termStructureSelectedDailyGreeks.map((dailyGreeks: DailyGreeks) => {
          return (
            <TimeFramePill
              key={dailyGreeks.key}
              identifier={dailyGreeks.key}
              date={dailyGreeks.tradeDate}
              visibleLines={visibleChartLines}
              onToggleLineVisiblity={onToggleLineVisiblity}
              onDelete={onDeleteTimeFrame}
              icon={<ShowChartIcon />}
              bgColor={dailyGreeks.color}
            />
          );
        })}
      </List>
    </Stack>
  );
};

export const TermStructureChartSettings = ({
  selectedSym,
}: IVolSettingsProps) => {
  return (
    <SettingsPopout
      title="Term Structure Settings"
      popperID="term-structure-controls"
      placement="bottom-end"
    >
      <TermStructureControls selectedSym={selectedSym} />
    </SettingsPopout>
  );
};

import { useSetSym } from '../hiro';
import { useMemo } from 'react';
import {
  CompassSymbolDataKey,
  convertAxisToDataKey,
  ENABLED_MODES,
  StrategyCompassMode,
  StrategyCompassModeXMap,
  StrategyCompassModeYMap,
  StrategyCompassModeZMap,
  StrategyCompassXYZAxis,
} from '../../types/compass';
import { isValidCompassSymbol } from '../../util/compass';
import { useRecoilState, useRecoilValue } from 'recoil';
import { watchlistsState } from '../../states';
import { selectedWatchlistsSymbols } from '../../util/shared/watchlists';
import { compassActiveWatchlistsIdsState } from '../../states/scanners';

export type CompassParams = {
  xAxis: StrategyCompassXYZAxis;
  setXAxis: (axis: StrategyCompassXYZAxis) => void;
  yAxis: StrategyCompassXYZAxis;
  setYAxis: (axis: StrategyCompassXYZAxis) => void;
  zAxis: StrategyCompassXYZAxis | undefined;
  setZAxis: (axis: StrategyCompassXYZAxis) => void;
  xAxisDataKey: CompassSymbolDataKey;
  yAxisDataKey: CompassSymbolDataKey;
  zAxisDataKey: CompassSymbolDataKey | undefined;
  mode: StrategyCompassMode;
  setMode: (mode: StrategyCompassMode) => void;
  syms: string[];
  setSyms: (syms: string[]) => void;
  sym: string;
  editable: boolean;
  searchParams: URLSearchParams;
  setActiveWatchlistIds: (activeWatchlistIds: number[]) => void;
  setActiveWatchlistIdsState: (activeWatchlistIds: number[]) => void;
  activeWatchlistIds: number[];
};

export const useCompassParams = (): CompassParams => {
  const { getParam, searchParams, setParams, sym } = useSetSym();

  const [activeWatchlistIds, setActiveWatchlistIdsState] = useRecoilState(
    compassActiveWatchlistsIdsState,
  );

  const watchlists = useRecoilValue(watchlistsState);

  const mode = useMemo(() => {
    const param = getParam('mode');
    return ENABLED_MODES.includes(param as StrategyCompassMode)
      ? (param as StrategyCompassMode)
      : StrategyCompassMode.Compass;
  }, [searchParams]);

  const xAxis = useMemo(() => {
    const param = getParam(`${mode}_x`);
    const acceptableX = StrategyCompassModeXMap.get(mode)!;
    if (
      param != null &&
      acceptableX.includes(param as StrategyCompassXYZAxis) &&
      param !== StrategyCompassXYZAxis.None
    ) {
      return param as StrategyCompassXYZAxis;
    }

    return acceptableX[0];
  }, [searchParams, mode]);

  const yAxis = useMemo(() => {
    const param = getParam(`${mode}_y`);
    const acceptableY = StrategyCompassModeYMap.get(mode)!;
    if (
      param != null &&
      acceptableY.includes(param as StrategyCompassXYZAxis) &&
      param !== StrategyCompassXYZAxis.None
    ) {
      return param as StrategyCompassXYZAxis;
    }

    return acceptableY[0];
  }, [searchParams, mode]);

  const zAxis = useMemo(() => {
    if (mode === StrategyCompassMode.Compass) {
      return undefined;
    }
    const param = getParam(`${mode}_z`);
    const acceptableZ = StrategyCompassModeZMap.get(mode)!;
    if (
      param != null &&
      acceptableZ.includes(param as StrategyCompassXYZAxis) &&
      param !== StrategyCompassXYZAxis.None
    ) {
      return param as StrategyCompassXYZAxis;
    }

    return undefined;
  }, [searchParams, mode]);

  const xAxisDataKey = useMemo(() => convertAxisToDataKey(xAxis), [xAxis]);

  const yAxisDataKey = useMemo(() => convertAxisToDataKey(yAxis), [yAxis]);

  const zAxisDataKey = useMemo(
    () =>
      zAxis == null || zAxis === StrategyCompassXYZAxis.None
        ? undefined
        : convertAxisToDataKey(zAxis),
    [zAxis],
  );

  const setXAxis = (newX: StrategyCompassXYZAxis) => {
    setParams({ [`${mode}_x`]: newX });
  };

  const setYAxis = (newY: StrategyCompassXYZAxis) => {
    setParams({ [`${mode}_y`]: newY });
  };

  const setZAxis = (newZ: StrategyCompassXYZAxis) => {
    setParams({ [`${mode}_z`]: newZ });
  };

  const setMode = (newMode: StrategyCompassMode) => {
    setParams({ mode: newMode });
  };

  const syms = useMemo(() => {
    const param = getParam('syms');
    return param == null ? [] : param.split(',').filter(isValidCompassSymbol);
  }, [searchParams]);

  const setSyms = (newSyms: string[]) => {
    setParams({
      syms: [...new Set(newSyms.filter(isValidCompassSymbol))].join(','),
    });
  };

  const setActiveWatchlistIds = (ids: number[]) => {
    const idsRemoved = activeWatchlistIds.filter((id) => !ids.includes(id));
    const idsAdded = ids.filter((id) => !activeWatchlistIds.includes(id));

    const watchlistSymsAdded = selectedWatchlistsSymbols(watchlists, idsAdded);
    const watchlistSymsRemoved = selectedWatchlistsSymbols(
      watchlists,
      idsRemoved,
    );

    setSyms(
      syms
        .concat(watchlistSymsAdded)
        .filter((s) => !watchlistSymsRemoved.includes(s)),
    );

    setActiveWatchlistIdsState(ids);
  };

  return {
    xAxis,
    yAxis,
    zAxis,
    mode,
    setXAxis,
    setYAxis,
    setZAxis,
    setMode,
    syms,
    setSyms,
    sym,
    xAxisDataKey,
    yAxisDataKey,
    zAxisDataKey,
    editable: true, // will be used later
    searchParams,
    setActiveWatchlistIds,
    setActiveWatchlistIdsState,
    activeWatchlistIds,
  };
};

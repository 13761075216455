import { Box, Link } from '@mui/material';
import { HiroChartDisplay } from 'components/hiro/HiroChart/HiroChartDisplay';
import useHiroList from 'hooks/hiro/useHiroList';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { freeSymbolState } from 'states';

export const ZeroHedgeHiro = () => {
  const { fetchHiroStocks } = useHiroList();

  const sym = useRecoilValue(freeSymbolState);

  useEffect(() => {
    fetchHiroStocks();
  }, []);

  return (
    <Box
      sx={{
        width: '300px',
        height: '300px',
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FAFAFA',
        paddingBottom: '6px',
        overflow: 'hidden',
      }}
    >
      <HiroChartDisplay sym={sym} />
      <Link
        underline="hover"
        fontSize={11}
        href="https://zerohedge.com/spotgamma?utm_source=ZeroHedge%20Widget&utm_medium=ZeroHedge%20premium%20applet&utm_campaign=ZeroHedge%20Premium%20Offering"
      >
        Free trial at ZeroHedge.com/SpotGamma
      </Link>
    </Box>
  );
};

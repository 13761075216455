import dayjs, { Dayjs } from 'dayjs';
import { atom, selector } from 'recoil';
import { businessDaysAdd, getClosestBusinessDay } from '../util';

export const showAllEarningsSymbolsState = atom<boolean>({
  key: 'resources-setShowAllEarningsSymbolsState',
  default: false,
});

export const earningsChartStartDateState = atom<Dayjs>({
  key: 'resources-earningsChartStartDateState',
  default: getClosestBusinessDay(),
});

export const earningsChartEndDateState = atom<Dayjs>({
  key: 'resources-earningsChartEndDateState',
  default: selector({
    key: 'resources-default/earningsChartEndDateState',
    get: ({ get }) => {
      const startDate = get(earningsChartStartDateState);
      return businessDaysAdd(dayjs(startDate, 'YYYY-MM-DD'), 4);
    },
  }),
});

export const zendeskDataState = atom<
  { body: string; title: string; url: string } | undefined
>({
  key: 'resources-zendeskDataState',
  default: undefined,
});

export const openZendeskModalState = atom<boolean>({
  key: 'resources-openZendeskModalState',
  default: false,
});

export interface AlertData {
  id: string;
  category: number;
  symbol: string; // symbol
  alert_time: string; // ISO DateTime string
  payload: any | null;
  is_seen: boolean;
  is_visited: boolean;
}

// this must match what is in the middle layer in helpers/alerts
// prettier-ignore
export enum AlertCategory {
  HIRO_TOP_SIGNAL      = 1,      // "sell" signal
  HIRO_BOTTOM_SIGNAL   = 1 << 1, // "buy" signal
  HIRO_NEG_FLOW       = 1 << 2,
  HIRO_POS_FLOW        = 1 << 3,
  HIRO_FLOW_CLOSE_SELL = 1 << 4,
  HIRO_FLOW_CLOSE_BUY  = 1 << 5,
  CALL_WALL            = 1 << 16,
  PUT_WALL             = 1 << 17,
  ONE_PERCENT_CALL_WALL = 1 << 18,
  ONE_PERCENT_PUT_WALL = 1 << 19
}

// this must match what is in the middle layer in helpers/alerts
const DisabledAlertCategories = new Set([
  AlertCategory.HIRO_FLOW_CLOSE_BUY,
  AlertCategory.HIRO_FLOW_CLOSE_SELL,
  AlertCategory.HIRO_TOP_SIGNAL,
  AlertCategory.HIRO_BOTTOM_SIGNAL,
]);

export const AllExposedAlertCategories = Object.keys(AlertCategory)
  .filter((key) => Number.isNaN(Number(key)))
  .map((c: any) => AlertCategory[c] as any)
  .filter((c) => !DisabledAlertCategories.has(c)) as AlertCategory[];

export function getDesc(alertCategory: AlertCategory) {
  switch (alertCategory) {
    case AlertCategory.HIRO_TOP_SIGNAL:
    case AlertCategory.HIRO_BOTTOM_SIGNAL:
      return 'HIRO Volatility';
    case AlertCategory.HIRO_NEG_FLOW:
    case AlertCategory.HIRO_POS_FLOW:
      return 'HIRO Flow';
    case AlertCategory.HIRO_FLOW_CLOSE_BUY:
    case AlertCategory.HIRO_FLOW_CLOSE_SELL:
      return 'Close H-Flow';
    case AlertCategory.CALL_WALL:
      return 'Call Wall Breached';
    case AlertCategory.PUT_WALL:
      return 'Put Wall Breached';
    case AlertCategory.ONE_PERCENT_CALL_WALL:
      return 'Within 1% of Call Wall';
    case AlertCategory.ONE_PERCENT_PUT_WALL:
      return 'Within 1% of Put Wall';
  }
}

export let alertCategoryDescriptions: any = {};
for (const category of AllExposedAlertCategories) {
  const desc = getDesc(category as AlertCategory);
  if (desc == null) {
    continue;
  }
  alertCategoryDescriptions[desc] = alertCategoryDescriptions[desc] ?? [];
  alertCategoryDescriptions[desc].push(category);
}

export enum ShowingAlertType {
  All,
  Watchlists,
}

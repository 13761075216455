import React, { useContext, useLayoutEffect, useRef } from 'react';
import { SeriesContext } from './Series';

const PriceLine = (props) => {
  const parent = useContext(SeriesContext);
  const { children, ...rest } = props;
  const context = useRef({
    api() {
      if (this.priceLine != null) {
        return this.priceLine;
      }
      this.priceLine = parent.api().createPriceLine(rest);
      return this.priceLine;
    },
    destroy() {
      if (this.priceLine != null) {
        if (parent?.removePriceLine != null) {
          parent.removePriceLine(this.priceLine);
        }
        this.priceLine = null;
      }
    },
  });

  useLayoutEffect(() => {
    const api = context.current.api();
    api.applyOptions(rest);
  }, [rest]);

  useLayoutEffect(() => {
    const currentRef = context.current;
    currentRef.api();
    return () => {
      currentRef.destroy();
    };
  }, []);

  return <>{children}</>;
};

export default PriceLine;

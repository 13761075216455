import React, { useState, useCallback, useEffect } from 'react';
import {
  alpha,
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import SummaryCardWrapper from './SummaryCardWrapper';
import { useRecoilState, useRecoilValue } from 'recoil';
import SGAccordion from 'components/SGAccordion';
import TopNamesList from './cards/TopNamesList';
import useTapeFeed from 'hooks/optionsFeed/useTapeFeed';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  TnsFlowHighlights,
  TopNameItem,
  BasicHighlight,
  PremiumHighlight,
  MoverHighlight,
} from 'types/tape';
import poll from 'util/poll';
import {
  dayjs,
  formatAsCompactNumber,
  formatAsCurrency,
  getDateFormatted,
  getOverrideHeader,
} from 'util/shared';
import useToast from 'hooks/useToast';
import {
  negativeTrendColorState,
  positiveTrendColorState,
  workerState,
} from 'states';
import { tnsDailyOverviewExpandedState } from 'states/tape';
import { STREAM_HOST_URL } from 'config/shared';
import { red } from '@mui/material/colors';

const DividerElement: React.FC = () => {
  const theme = useTheme();
  return (
    <Divider
      flexItem
      orientation="vertical"
      sx={{ borderColor: alpha(theme.palette.text.secondary, 0.35) }}
    />
  );
};

const LoadingOverlay: React.FC = () => (
  <Stack
    sx={{
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      overflowX: 'auto',
      width: '100%',
      gap: 4,
    }}
  >
    <ListLoadingOverlay />
    <DividerElement />
    <ListLoadingOverlay />
    <DividerElement />
    <ListLoadingOverlay />
    <DividerElement />
    <ListLoadingOverlay />
  </Stack>
);

const ListLoadingOverlay: React.FC = () => (
  <Stack sx={{ gap: 2, width: '100%', alignItems: 'center' }}>
    <Skeleton variant="text" sx={{ width: 80 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
  </Stack>
);

const DailyFlowOverview: React.FC = () => {
  const theme = useTheme();
  const { openToast } = useToast();
  const [expanded, setExpanded] = useRecoilState(tnsDailyOverviewExpandedState);
  const worker = useRecoilValue(workerState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [flowHighlightsData, setFlowHighlights] =
    useState<TnsFlowHighlights | null>(null);
  const negColor = useRecoilValue(negativeTrendColorState);
  const posColor = useRecoilValue(positiveTrendColorState);
  const [showMore, setShowMore] = useState(false);

  const { fetchFlowHighlights } = useTapeFeed();

  const canSeeMore =
    flowHighlightsData &&
    Object.values(flowHighlightsData).some((list) => list.length > 5);

  const handleResponse = useCallback(async (response: any) => {
    if (response.error) {
      setError(response.error);
    } else if (response?.json != null) {
      setFlowHighlights(response.json);
      setError(null);
    }
  }, []);

  // Initial Data Fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const res = await fetchFlowHighlights();
        setFlowHighlights(res);
      } catch (err: any) {
        console.error(err);
        setError(err.message);
        openToast({
          message: err.message,
          type: 'error',
          duration: 7000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Polling for Updates
  useEffect(() => {
    return poll(
      worker,
      {
        url: `sg/tns_highlights`,
        interval: 30_000, // poll every 30s
        onResponse: handleResponse,
      },
      {
        host: STREAM_HOST_URL,
        ...getOverrideHeader(),
      },
    );
  }, [worker, handleResponse]);

  const mapBasicHighlightToTopNameItem = (
    highlight: BasicHighlight,
  ): TopNameItem => ({
    underlying: highlight.underlying || 'N/A',
    values: [{ label: 'Volume', value: formatAsCompactNumber(highlight.val) }],
  });

  const mapPremiumHighlightToTopNameItem = (
    highlight: PremiumHighlight,
  ): TopNameItem => ({
    underlying: highlight.underlying || 'N/A',
    values: [
      {
        label: 'Premium',
        value: `$${formatAsCompactNumber(highlight.premium)}`,
      },
      {
        label: 'Expiry',
        value: getDateFormatted(dayjs(highlight.expiry)),
      },
      { label: 'Strike', value: `${formatAsCurrency(highlight.strike)}` },
      { label: 'Type', value: highlight.is_put ? 'Put' : 'Call' },
    ],
  });

  const mapMoverHighlightToTopNameItem = (
    highlight: MoverHighlight,
  ): TopNameItem => {
    const lastClose = highlight.last_close;
    const close = highlight.close;

    // Calculate percentage change, handling division by zero
    const changePercent =
      lastClose !== 0 ? ((close - lastClose) / lastClose) * 100 : 0;

    // Format the percentage to two decimal places and append '%'
    const formattedChangePercent = `${changePercent.toFixed(2)}%`;

    return {
      underlying: highlight.underlying || 'N/A',
      values: [
        {
          label: 'Last Close',
          value: `${formatAsCurrency(lastClose)}`,
        },
        { label: 'Price', value: `${formatAsCurrency(close)}` },
        {
          label: 'Change %',
          value: formattedChangePercent,
          color: changePercent < 0 ? negColor : posColor,
        },
      ],
    };
  };

  if (loading || flowHighlightsData == null) {
    return expanded ? (
      <LoadingOverlay />
    ) : (
      <Skeleton
        variant="rectangular"
        sx={{ width: '100%', height: 48, borderRadius: 2 }}
      />
    );
  }

  if (error != null) {
    return <Typography sx={{ color: red[600] }}>{error}</Typography>;
  }

  return (
    <SGAccordion
      hideSummaryWhenExpanded
      title="Market Highlights"
      icon={<TrendingUpIcon sx={{ color: theme.palette.primary.main }} />}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <Stack sx={{ gap: 2 }}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            overflowX: 'auto',
            gap: 3,
          }}
        >
          {/* Top Options Volume */}
          <SummaryCardWrapper
            title="Top Options Volume"
            description="Tickers trading with the highest options volume since the prior day close."
            icon={
              <BarChartIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
            }
          >
            <TopNamesList
              items={flowHighlightsData.volume.map(
                mapBasicHighlightToTopNameItem,
              )}
              nameHeader="Ticker"
              maxItems={showMore ? undefined : 5}
            />
          </SummaryCardWrapper>

          <DividerElement />

          {/* Top Daily Gamma Notional */}
          <SummaryCardWrapper
            title="Top Daily Gamma Notional"
            description="The tickers with the highest daily gamma notional traded since the prior day close."
            icon={
              <ShowChartIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
            }
          >
            <TopNamesList
              items={flowHighlightsData.gamma.map(
                mapBasicHighlightToTopNameItem,
              )}
              nameHeader="Ticker"
              maxItems={showMore ? undefined : 5}
            />
          </SummaryCardWrapper>

          <DividerElement />

          {/* Top Daily Movers */}
          <SummaryCardWrapper
            title="Top Daily Movers"
            description="Tickers with the largest daily moves between yesterday's close and current price."
            icon={
              <SwapVertIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
            }
          >
            <TopNamesList
              items={flowHighlightsData.movers.map(
                mapMoverHighlightToTopNameItem,
              )}
              nameHeader="Ticker"
              maxItems={showMore ? undefined : 5}
            />
          </SummaryCardWrapper>

          <DividerElement />

          {/* Largest Daily Trades */}
          <SummaryCardWrapper
            title="Largest Daily Trades"
            description="The largest trades that have taken place  since the prior day close, based on premium traded."
            icon={
              <MonetizationOnIcon
                sx={{ fontSize: 16, color: 'text.secondary' }}
              />
            }
          >
            <TopNamesList
              items={flowHighlightsData.premium.map(
                mapPremiumHighlightToTopNameItem,
              )}
              nameHeader="Ticker"
              maxItems={showMore ? undefined : 5}
            />
          </SummaryCardWrapper>
        </Stack>

        {/* Expansion and Collapse Button */}
        {canSeeMore && (
          <Button
            size="small"
            onClick={() => setShowMore(!showMore)}
            startIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            sx={{
              alignSelf: 'flex-end',
              textTransform: 'none',
              fontSize: { xs: '0.75rem', md: '0.875rem' },
              padding: '4px 8px',
            }}
          >
            {showMore ? 'See Less' : 'See More'}
          </Button>
        )}
      </Stack>
    </SGAccordion>
  );
};

export default DailyFlowOverview;

import { useRecoilState } from 'recoil';
import { SxProps } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { sidebarExpandedState } from '../../states';
import { ChartSizingButton } from './ChartSizingButton';

export const SecondarySidebarButton = ({
  sx,
  withText,
  onClick,
}: {
  sx?: SxProps;
  withText?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  const [chartExpanded, setChartExpanded] =
    useRecoilState(sidebarExpandedState);

  return (
    <ChartSizingButton
      onClick={() => {
        setChartExpanded(!chartExpanded);
        onClick != null && onClick();
      }}
      icon={chartExpanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      title={chartExpanded ? 'Close Sidebar' : 'Open Sidebar'}
      withText={withText}
      buttonSx={sx}
    />
  );
};

import { ProductType } from '../types';
import { ImpliedVol } from './components/ImpliedVol';
import { AccessProtectedPage } from './AccessProtectedPage';
import { useCallback } from 'react';
import useSetSym from 'hooks/hiro/useSetSym';
import { useRecoilValue } from 'recoil';
import { freeSymbolState, isMobileState } from '../states';

export const ImpliedVolPage = () => {
  const isMobile = useRecoilValue(isMobileState);
  const render = useCallback(() => <ImpliedVol />, []);
  const freeSym = useRecoilValue(freeSymbolState);
  const { getSym } = useSetSym();
  const sym = getSym(ProductType.IMPLIED_VOL);

  return (
    <AccessProtectedPage
      productType={ProductType.IMPLIED_VOL}
      renderContent={render}
      // override default page upsell if no sym param is present OR sym is already set to free sym of day
      overrideCheck={!sym || sym === freeSym}
      wrapperSx={{
        height: `calc(100vh - ${isMobile ? '195px' : '120px'})`,
      }}
    />
  );
};

import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedIndexState } from '../../states/indices';
import useLog from '../useLog';

const useTilt = () => {
  const { fetchAPIWithLog } = useLog('useTilt');

  const getTilt = useCallback(async (sym: string) => {
    return await fetchAPIWithLog(`v1/tilt?sym=${sym}`);
  }, []);

  return { getTilt };
};

export default useTilt;

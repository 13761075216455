import { Stack, Typography } from '@mui/material';

export const EmptyGridOverlay = () => {
  return (
    <Stack
      gap={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Typography>No Equities</Typography>
    </Stack>
  );
};

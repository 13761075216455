import { useCallback } from 'react';
import useLog from '../useLog';

const useEquityPCRatio = () => {
  const { fetchAPIWithLog } = useLog('useEquityPCRatio');

  const getEquityPCRatio = useCallback(async () => {
    return await fetchAPIWithLog(`v1/equityPutCallRatio`);
  }, []);

  return { getEquityPCRatio };
};

export default useEquityPCRatio;

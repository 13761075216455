import { CONTENT_TYPE_LABEL_MAP } from 'config';
import {
  IndexSymbol,
  IndicesContentType,
  Quadrant,
  QuadrantId,
  QuadrantTabCategory,
} from 'types';

export const SUPPRESSED_SYMS_SIV = new Set(['NDX', 'RUT']);

export const SUPPRESSED_SYMS_OPTIONS_RISK_REVERSAL = new Set(['IWM', 'RUT']);

export const ALL_INDICES_SYMS = Object.values(IndexSymbol);

export const isContentSuppressed = (
  contentType: IndicesContentType,
  sym: string,
) =>
  (contentType === IndicesContentType.VOLFORECAST_MODEL &&
    SUPPRESSED_SYMS_SIV.has(sym)) ||
  (contentType === IndicesContentType.OPTIONS_RISK_REVERSAL_CHART &&
    SUPPRESSED_SYMS_OPTIONS_RISK_REVERSAL.has(sym));

export const getDefaultSymOptions = (contentType: IndicesContentType) =>
  ALL_INDICES_SYMS.filter(
    (iSymbol: IndexSymbol) => !isContentSuppressed(contentType, iSymbol),
  );

export const DEFAULT_INDICES_QUADRANT_MAP = new Map<QuadrantId, Quadrant>([
  [
    QuadrantId.TOP_LEFT,
    {
      tabs: [
        {
          id: IndicesContentType.GAMMA_MODEL,
          contentId: IndicesContentType.GAMMA_MODEL,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.GAMMA_MODEL)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_MODEL),
        },
        {
          id: IndicesContentType.DELTA_MODEL,
          contentId: IndicesContentType.DELTA_MODEL,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.DELTA_MODEL)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.DELTA_MODEL),
        },
        {
          id: IndicesContentType.VANNA_MODEL,
          contentId: IndicesContentType.VANNA_MODEL,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.VANNA_MODEL)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.VANNA_MODEL),
        },
      ],
    },
  ],
  [
    QuadrantId.TOP_RIGHT,
    {
      tabs: [
        {
          id: IndicesContentType.GAMMA_LEVELS,
          contentId: IndicesContentType.GAMMA_LEVELS,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.GAMMA_LEVELS)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_LEVELS),
        },
        {
          id: IndicesContentType.COMBO_STRIKES,
          contentId: IndicesContentType.COMBO_STRIKES,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.COMBO_STRIKES)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.COMBO_STRIKES),
        },
        {
          id: IndicesContentType.TILT,
          contentId: IndicesContentType.TILT,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.TILT)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.TILT),
        },
        {
          id: IndicesContentType.EXP_CONCENTRATION,
          contentId: IndicesContentType.EXP_CONCENTRATION,
          label: CONTENT_TYPE_LABEL_MAP.get(
            IndicesContentType.EXP_CONCENTRATION,
          )!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(
            IndicesContentType.EXP_CONCENTRATION,
          ),
        },
      ],
    },
  ],
  [
    QuadrantId.BOTTOM_LEFT,
    {
      tabs: [
        {
          id: IndicesContentType.VOLFORECAST_MODEL,
          contentId: IndicesContentType.VOLFORECAST_MODEL,
          label: CONTENT_TYPE_LABEL_MAP.get(
            IndicesContentType.VOLFORECAST_MODEL,
          )!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(
            IndicesContentType.VOLFORECAST_MODEL,
          ),
        },
        {
          id: IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
          contentId: IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
          label: CONTENT_TYPE_LABEL_MAP.get(
            IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
          )!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(
            IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
          ),
        },
        {
          id: IndicesContentType.VOLATILITY,
          contentId: IndicesContentType.VOLATILITY,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.VOLATILITY)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.VOLATILITY),
        },
        {
          id: IndicesContentType.MAX_REAL_VOL,
          contentId: IndicesContentType.MAX_REAL_VOL,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.MAX_REAL_VOL)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.MAX_REAL_VOL),
        },
        {
          id: IndicesContentType.HIST_RETURNS,
          contentId: IndicesContentType.HIST_RETURNS,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.HIST_RETURNS)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.HIST_RETURNS),
        },
      ],
    },
  ],
  [
    QuadrantId.BOTTOM_RIGHT,
    {
      tabs: [
        {
          id: IndicesContentType.ZERO_DTE,
          contentId: IndicesContentType.ZERO_DTE,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.ZERO_DTE)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.ZERO_DTE),
        },
        {
          id: IndicesContentType.OI_VOLUME,
          contentId: IndicesContentType.OI_VOLUME,
          label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.OI_VOLUME)!,
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.OI_VOLUME),
        },
      ],
    },
  ],
]);

'use client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  IconButton,
  Stack,
  Divider,
  DialogActions,
  SxProps,
  Theme,
  useTheme,
  alpha,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type CTA = {
  title: string;
  onClick: () => void;
  style?: SxProps<Theme>;
};

interface PromoModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  subtitle: string;
  body: string;
  actions: CTA[];
}

const PromoModal = ({
  open,
  setOpen,
  title,
  subtitle,
  body,
  actions,
}: PromoModalProps) => {
  const theme = useTheme();
  const handleModalClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={(_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'backdropClick') {
          return;
        }
        handleModalClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box />
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: 16, md: 20 },
              color: 'primary.main',
            }}
          >
            {title}
          </Typography>
          <IconButton size="small" onClick={handleModalClose} color="primary">
            <CloseIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ borderColor: alpha(theme.palette.primary.main, 0.35) }} />
      <DialogContent>
        <Stack sx={{ pt: 1, gap: 3, alignItems: 'center' }}>
          {/* Main Content */}
          <Stack
            sx={{
              gap: 3,
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                color: 'text.secondary',
                fontWeight: 600,
                fontSize: { xs: 16, md: 18 },
              }}
            >
              {subtitle}
            </Typography>

            <Stack sx={{ gap: 0.5, width: '100%', alignItems: 'center' }}>
              <Typography
                sx={{ fontWeight: 500, fontSize: { xs: 14, md: 16 } }}
              >
                {body}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {actions.map((action: CTA) => (
          <Button
            key={action.title}
            variant="contained"
            size="large"
            onClick={action.onClick}
            sx={{
              fontSize: { xs: 14, md: 16 },
              textTransform: 'none',
              fontWeight: 600,
              ...action.style,
            }}
          >
            {action.title}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default PromoModal;

import { SGTooltip } from './SGTooltip';
import { Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { currentProductTypeState, isMobileState } from '../../states';
import {
  readableProductType,
  getAppcues,
  tutorialFlowIdForLocation,
} from '../../util';
import { useSearchParams } from 'react-router-dom';

export const TutorialButton = ({ button }: { button: boolean }) => {
  const currentProductType = useRecoilValue(currentProductTypeState);
  const isMobile = useRecoilValue(isMobileState);
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  const tutorialFlowId = tutorialFlowIdForLocation(
    currentProductType,
    searchParams,
  );

  if (getAppcues() == null || tutorialFlowId == null) {
    return null;
  }

  return (
    <SGTooltip
      title={`Start a quick tutorial on how to use ${readableProductType(
        currentProductType,
      )}`}
      placement="right"
    >
      {button ? (
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontSize: isMobile ? '11px' : '12px',
            height: '30px',
            marginTop: '-4px',
            width: isMobile ? '50px' : '56px',
            minWidth: isMobile ? '50px' : '56px',
          }}
          onClick={() => {
            getAppcues().show(tutorialFlowId);
          }}
        >
          Tutorial
        </Button>
      ) : (
        <Typography
          sx={{
            textTransform: 'none',
            whiteSpace: 'nowrap',
            fontSize: '12px',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          Tutorial
        </Typography>
      )}
    </SGTooltip>
  );
};

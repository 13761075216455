export function getRollingMeans(
  values: number[],
  windowSize: number,
): number[] {
  let endIdx = 0;
  let rollingSum = 0;
  for (; endIdx < windowSize; ++endIdx) {
    rollingSum += values[endIdx];
  }
  const rollingMeans = Array(values.length - windowSize + 1);
  rollingMeans[0] = rollingSum / windowSize;
  for (; endIdx < values.length; ++endIdx) {
    const removedIdx = endIdx - windowSize;
    rollingSum = rollingSum - values[removedIdx] + values[endIdx];
    rollingMeans[removedIdx + 1] = rollingSum / windowSize;
  }
  return rollingMeans;
}

const TRADING_DAYS_PER_YR = 251;
const VOL_MULTIPLIER = Math.sqrt(TRADING_DAYS_PER_YR);

/** Returns rolling standard deviation * sqrt(251 == num trading days)
 * standard deviation = square root of sum of (daily return - average daily return)^2 / number of trading days
 * volatility = standard deviation of daily returns * square root of number of trading days
 */
export function computeVolatility(
  values: number[],
  windowSize: number,
): number[] {
  const rollingMean = getRollingMeans(values, windowSize);
  return rollingMean.map((mean, i) => {
    let sum = 0;
    for (let j = i; j < i + windowSize; j++) {
      sum += Math.pow(values[j] - mean, 2);
    }
    return Math.sqrt(sum / windowSize) * VOL_MULTIPLIER;
  });
}

export function logReturns(values: number[]) {
  let returns = [];
  returns.push(0);
  for (let i = 1; i < values.length; i++) {
    let current = values[i];
    let previous = values[i - 1];
    returns.push(Math.log(current / previous));
  }
  return returns;
}

import { useCallback } from 'react';
import { GammaLevelType } from '../../types';
import useLog from '../useLog';

const useComboStrikes = () => {
  const { fetchAPIWithLog } = useLog('useComboStrikes');

  const getComboStrikes = useCallback(async (exp: string, sym: string) => {
    return await fetchAPIWithLog(
      `v2/comboLevels?sym=${sym}${
        exp === GammaLevelType.NEXT_EXP ? `&model=next_exp` : ''
      }`,
    );
  }, []);

  return { getComboStrikes };
};

export default useComboStrikes;

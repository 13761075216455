import {
  Notification,
  NotificationCateogry,
  UserNotification,
} from 'types/notifications';

export const NOTIFICATION_CATEGORY_MAP = new Map([
  ['1', NotificationCateogry.Webinar],
  ['2', NotificationCateogry.FoundersNotes],
  ['3', NotificationCateogry.SystemUpdate],
  ['4', NotificationCateogry.General],
]);

export const REVERSE_NOTIFICATION_CATEGORY_MAP = new Map(
  Array.from(NOTIFICATION_CATEGORY_MAP.entries()).map(([key, value]) => [
    value,
    key,
  ]),
);

export const getNotification = (notificationObj: any): Notification => ({
  id: notificationObj.id,
  category:
    NOTIFICATION_CATEGORY_MAP.get(notificationObj.category) ??
    NotificationCateogry.General,
  message: notificationObj.message,
  title: notificationObj.title,
  timeCreated: notificationObj.time_created,
  timeUpdated: notificationObj.last_updated,
  timeToPush: notificationObj.time_to_push,
  isPushed: notificationObj.is_pushed,
  eventTime: notificationObj.event_time,
  expiry: notificationObj.expiry,
});

export const getUserNotification = (
  notificationObj: any,
): UserNotification => ({
  ...getNotification(notificationObj),
  isSeen: notificationObj.is_seen,
  isVisited: notificationObj.is_visited,
});

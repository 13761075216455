import { Page } from './shared/Page';
import { Equityhub } from './components/Equityhub';

export const EquityHubPage = () => {
  return (
    <Page>
      <Equityhub />
    </Page>
  );
};

import {
  Box,
  Typography,
  IconButton,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import useToast from 'hooks/useToast';
import useLog from '../../hooks/useLog';
import { SGTooltip } from '../core';
import { convertLevelsToFutures } from '../../util/home';
import { useRecoilState } from 'recoil';
import { selectedSymLevelsState } from 'states';
import { dayjs, nextBusinessDay } from 'util/shared';

const SYM2FUTURE = new Map([
  ['SPX', '/ES'],
  ['NDX', '/NQ'],
  ['RUT', '/RTY'],
]);

const SYM2SCRIPTFUTURE = new Map([
  ['SPX', ['ES1!', 'MES1!']],
  ['NDX', ['NQ1!', 'MNQ1!']],
  ['RUT', ['RTY1!', 'M2K1!']],
]);

const ORDERED_FIELDS = [
  'dollarSym',
  'sym',
  'callWallStr',
  'putWallStr',
  'maxGStrike',
  'l1',
  'l2',
  'l3',
  'l4',
  'c1',
  'c2',
  'c3',
  'c4',
  'sig',
  'sig5',
  'zeroG',
];

export const HomeKeyLevels = ({ data }: { data: any }) => {
  const [selectedSymTab, setSelectedSymTab] = useRecoilState(
    selectedSymLevelsState,
  );
  const theme = useTheme();
  const { openToast } = useToast();
  const { logError } = useLog('HomeKeyLevels');

  if (!Array.isArray(data)) {
    return <></>;
  }

  try {
    const symList = (
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {data.map((d) => (
          <Box
            key={d.sym}
            sx={{
              backgroundColor:
                selectedSymTab === d.sym
                  ? theme.palette.button.default
                  : 'none',
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.text.secondary}`,
              borderRadius: 4,
              paddingY: 0,
              paddingX: '9px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.button.hover,
              },
              display: 'flex',
              height: 27,
              alignItems: 'center',
            }}
            onClick={() => setSelectedSymTab(d.sym)}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: 10,
                  sm: 12,
                },
              }}
            >
              {d.sym}
            </Typography>
          </Box>
        ))}
      </Stack>
    );

    const selectedData = data.find((d) => d.sym === selectedSymTab);
    if (selectedData == null) {
      return (
        <Typography>There was an error displaying the key levels.</Typography>
      );
    }

    const levelIdKeysToLabels = {
      C2: 'Combo 2',
      C1: 'Combo 1',
      L1: 'Large Gamma 1',
      callwallstrike: 'Call Wall',
      C3: 'Combo 3',
      L2: 'Large Gamma 2',
      C4: 'Combo 4',
      zero_g_strike: 'Zero Gamma',
      max_g_strike: 'Volatility Trigger',
      L3: 'Large Gamma 3',
      L4: 'Large Gamma 4',
      putwallstrike: 'Put Wall',
    };

    const levelKeysOrder = Object.keys(levelIdKeysToLabels).sort(
      (a, b) => (selectedData[b] ?? -1) - (selectedData[a] ?? -1),
    );

    // TODO: Remove esDiff backwards compatible key when push-safe
    const futuresDiff = selectedData.futuresDiff ?? selectedData.esDiff ?? null;
    const fontSize = '14px';

    const tableHeaders = (
      <TableRow>
        <TableCell colSpan={1}>
          <span
            style={{
              color: theme.palette.text.primary,
              fontWeight: 900,
              fontSize: fontSize,
            }}
          >
            {selectedData.sym}
          </span>
        </TableCell>
        {futuresDiff != null && (
          <TableCell colSpan={1}>
            <span
              style={{
                color: theme.palette.text.primary,
                fontWeight: 900,
                fontSize: fontSize,
              }}
            >
              {SYM2FUTURE.get(selectedData.sym)}
            </span>
          </TableCell>
        )}
        <TableCell colSpan={1}>
          <span
            style={{
              color: theme.palette.text.primary,
              fontWeight: 900,
              fontSize: fontSize,
            }}
          >
            Level ID
            {/* 0-width space aids TradingView parsing ('\n' are stripped))*/}
            {'\u{200B}'}
          </span>
        </TableCell>
      </TableRow>
    );

    const tableRows = levelKeysOrder.map((key) => {
      const val = selectedData[key];
      if (val == null || isNaN(val) || val === 0) {
        return null;
      }
      return (
        <TableRow
          key={key}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell>
            <span
              style={{ color: theme.palette.text.primary, fontSize: fontSize }}
              key={key}
            >
              {val}
            </span>
          </TableCell>

          {futuresDiff != null && (
            <TableCell>
              <span
                style={{
                  color: theme.palette.text.primary,
                  fontSize: fontSize,
                }}
                key={key}
              >
                {Math.round(val + futuresDiff)}
              </span>
            </TableCell>
          )}

          <TableCell>
            <span
              style={{ color: theme.palette.text.primary, fontSize: fontSize }}
              key={key}
            >
              {(levelIdKeysToLabels as any)[key]}
              {/* 0-width space aids TradingView parsing ('\n' are stripped))*/}
              {'\u{200B}'}
            </span>
          </TableCell>
        </TableRow>
      );
    });

    const getTVObjData = (levelsObj: any, ticker: string) => {
      return {
        dollarSym: `$${ticker}`,
        sym: ticker,
        callWallStr: levelsObj.callwallstrike,
        putWallStr: levelsObj.putwallstrike,
        maxGStrike: levelsObj.max_g_strike,
        l1: levelsObj['L1'],
        l2: levelsObj['L2'],
        l3: levelsObj['L3'],
        l4: levelsObj['L4'],
        c1: levelsObj['C1'],
        c2: levelsObj['C2'],
        c3: levelsObj['C3'],
        c4: levelsObj['C4'],
        sig: levelsObj.sig,
        sig5: levelsObj['sig5'],
        zeroG: levelsObj.zero_g_strike,
      };
    };

    const copyAllKeyLevels = () => {
      const resultStrings: string[] = data.map((levelsObj) => {
        const tvDataObj: Record<string, string | number> = getTVObjData(
          levelsObj,
          levelsObj.sym,
        );
        return ORDERED_FIELDS.map((k) => tvDataObj[k] as string).join(', ');
      });

      const futuresStrings = data
        .filter((levelsObj) => levelsObj.futuresDiff != null)
        .map((levelsObj) => convertLevelsToFutures(levelsObj))
        .map((levelsObj) => {
          return SYM2SCRIPTFUTURE.get(levelsObj.sym)
            ?.map((futuresLabel: string) => {
              const tvDataObj: Record<string, string | number> = getTVObjData(
                levelsObj,
                futuresLabel,
              );
              return ORDERED_FIELDS.map((k) => tvDataObj[k]).join(', ');
            })
            .join(', ');
        });

      const textToCopy = `${resultStrings.join(', ')}, ${futuresStrings.join(
        ', ',
      )}`;

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          openToast({
            message: 'Successfully copied key levels to clipboard.',
            type: 'success',
            duration: 3000,
          });
        })
        .catch(() => {
          openToast({
            message:
              'There was an error copying the key levels table. Please try again.',
            type: 'error',
            duration: 3000,
          });
        });
    };

    return (
      <Box overflow="auto">
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          padding={1}
        >
          {symList}
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              sx={{
                fontSize: {
                  xs: 10,
                  sm: 12,
                },
                color: theme.palette.text.secondary,
              }}
            >
              {nextBusinessDay(dayjs.utc(data?.[0]?.keyLevelTradeDate)).format(
                'YYYY-MM-DD',
              )}
            </Typography>
            <IconButton onClick={copyAllKeyLevels} color="primary">
              <SGTooltip title="Copy All">
                <CopyAllIcon />
              </SGTooltip>
            </IconButton>
          </Stack>
        </Stack>

        <TableContainer component={Paper}>
          <Table
            id="keyLevelsTable"
            size="small"
            sx={{
              'th, td': { border: 0 },
              '& .MuiTableCell-sizeSmall': { padding: '1.5px', border: 0 },
            }}
            aria-label="a dense table"
          >
            <TableHead>{tableHeaders}</TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  } catch (e) {
    logError(e, 'render');
    return null;
  }
};

import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
  CircularProgress,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface SymSelectorProps {
  value: string;
  options: string[];
  onChange: (event: SelectChangeEvent) => void;
  loading?: boolean;
}

const SymSelector = ({
  value,
  options,
  onChange,
  loading,
}: SymSelectorProps) => {
  const theme = useTheme();

  return (
    <FormControl size="small">
      <Select
        labelId="sym-select-small-label"
        id="sym-select"
        placeholder="SYM"
        value={value}
        label="SYM"
        onChange={onChange}
        disabled={loading}
        sx={{
          borderRadius: 4,
          height: 35,
          backgroundColor: theme.palette.button.default,
          '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary, // Ensuring the icon matches the text color
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Remove default border
          },
          '&:hover': {
            backgroundColor: theme.palette.button.hover,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Ensure no border in focused state
          },
        }}
        IconComponent={ExpandMoreIcon}
        displayEmpty
        renderValue={
          loading
            ? () => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />
                  Updating Symbol...
                </Box>
              )
            : undefined
        }
      >
        {options.map((symOption: string) => (
          <MenuItem key={symOption} value={symOption}>
            {symOption}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SymSelector;

import { styled } from '@mui/system';

export const NavigationSubheader = styled('div')({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  color: '#989A9E',
  textTransform: 'uppercase',
  marginTop: '12px',
  marginBottom: '12px',
});

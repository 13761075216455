import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { Controls } from 'components/shared';
import { useRecoilState } from 'recoil';
import {
  earningsChartEndDateState,
  earningsChartStartDateState,
  showAllEarningsSymbolsState,
} from 'states/resources';
import SettingsPopout from 'components/shared/SettingsPopout';
import { getDefaultSlotProps } from 'util/shared';

export const EarningsChartControls = () => {
  const theme = useTheme();
  const defaultSlotProps = getDefaultSlotProps(theme);
  const [showAllSymbols, setShowAllSymbols] = useRecoilState(
    showAllEarningsSymbolsState,
  );
  const [startDate, setStartDate] = useRecoilState(earningsChartStartDateState);
  const [endDate, setEndDate] = useRecoilState(earningsChartEndDateState);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'center',
        zIndex: 100000,
      }}
    >
      <Controls
        buttonGroups={[
          {
            buttons: [
              { value: false, label: 'Top Names' },
              { value: true, label: 'All Symbols' },
            ],
            setter: setShowAllSymbols,
            curValue: showAllSymbols,
          },
        ]}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['day']}
            label="Start Date"
            value={startDate}
            onChange={(newValue: Dayjs | null) => {
              if (newValue?.isValid()) {
                setStartDate(newValue);
              }
            }}
            format="YYYY-MM-DD"
            slotProps={{
              ...defaultSlotProps,
              textField: {
                ...defaultSlotProps.textField,
                style: {
                  width: '150px',
                  fontSize: '14px',
                },
              },
            }}
          />
          <DatePicker
            views={['day']}
            label="End Date"
            value={endDate}
            onChange={(newValue: Dayjs | null) => {
              if (newValue?.isValid()) {
                setEndDate(newValue);
              }
            }}
            format="YYYY-MM-DD"
            slotProps={{
              ...defaultSlotProps,
              textField: {
                ...defaultSlotProps.textField,
                style: {
                  width: '150px',
                  fontSize: '14px',
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export const EarningsChartSettings = () => {
  return (
    <SettingsPopout
      title="Earnings Chart"
      popperID="earnings-chart-controls"
      placement="bottom-end"
      sx={{ width: '400px', zIndex: 100000 }}
    >
      <EarningsChartControls />
    </SettingsPopout>
  );
};

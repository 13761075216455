import { Page } from './shared/Page';
import { Hiro } from './components/Hiro';

export const HiroPage = () => {
  return (
    <Page>
      <Hiro />
    </Page>
  );
};

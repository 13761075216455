export enum NotificationCateogry {
  Webinar = 'Webinar',
  FoundersNotes = `Founder's Notes`,
  SystemUpdate = 'System Update',
  General = 'General',
}

export interface Notification {
  id: string;
  category: NotificationCateogry;
  title: string;
  message?: string;
  timeCreated: string;
  timeUpdated: string;
  timeToPush: string;
  isPushed: boolean;
  eventTime?: string;
  expiry?: string;
}

export interface UserNotification extends Notification {
  isSeen: boolean;
  isVisited?: boolean;
}

import { Stack, Typography } from '@mui/material';

export const EmptyResultsOverlay = () => {
  return (
    <Stack
      gap={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Typography>No Matching Results</Typography>
    </Stack>
  );
};

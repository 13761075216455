import { SxProps, Theme } from '@mui/material';
import { Scanner, ValueFormat } from 'types';
import {
  FlowSumCategory,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  OptionsFeedColumnVisibility,
  OptionsFeedContractColumnKey,
} from 'types/tape';

export const DEFAULT_TNS_FIELDS = Object.values(OptionsFeedColumnKey);

export const TAPE_MAX_TOTAL_ROWS = 800;
export const TAPE_FETCH_BATCH_SIZE = 100; // Number of rows to fetch per batch
export const TAPE_CONTRACTS_TOTAL_ROWS = 1000; // max number of contracts

export const CONTRACT_DATA_COLUMN_KEYS = [
  OptionsFeedColumnKey.Underlying,
  OptionsFeedColumnKey.IsPut,
  OptionsFeedColumnKey.Strike,
  OptionsFeedColumnKey.Expiry,
  OptionsFeedColumnKey.DailyVolCumsum,
  OptionsFeedColumnKey.Premium,
  OptionsFeedColumnKey.Delta,
  OptionsFeedColumnKey.Gamma,
  OptionsFeedColumnKey.Vega,
];

export const DEFAULT_TAPE_COL_VISIBILITY = Object.values(
  OptionsFeedColumnKey,
).reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {} as OptionsFeedColumnVisibility);

export const DEFAULT_TAPE_CONTRACTS_COL_VISIBILITY =
  CONTRACT_DATA_COLUMN_KEYS.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as OptionsFeedColumnVisibility);

export const TAPE_UNMODIFIABLE_FIELDS = [
  OptionsFeedColumnKey.Time,
  OptionsFeedColumnKey.Underlying,
];

export enum TAPE_DEFAULT_FILTER_ID {
  Symbols = 'symbols',
  MinExp = 'min_expiration',
  MaxExp = 'max_exp',
  Expirations = 'expirations',
  Premium = 'premium',
  Aggressor = 'aggressor',
  Side = 'side',
  IsPut = 'is_put',
  MinStrike = 'min_strike',
  MaxStrike = 'max_strike',
  MinVol = 'min_vol',
  MaxVol = 'max_vol',
  MinSize = 'min_size',
  MaxSize = 'max_size',
  MinOptPrice = 'min_opt_price',
  MaxOptPrice = 'max_opt_price',
  MinSpotPrice = 'min_spot_price',
  MaxSpotPrice = 'max_spot_price',
  MinBid = 'min_bid',
  MaxBid = 'max_bid',
  MinAsk = 'min_ask',
  MaxAsk = 'max_ask',
  MinOI = 'min_oi',
  MaxOI = 'max_oi',
  MinDelta = 'min_delta',
  MaxDelta = 'max_delta',
  MinGamma = 'min_gamma',
  MaxGamma = 'max_gamma',
  MinVega = 'min_vega',
  MaxVega = 'max_vega',
  MinIV = 'min_iv',
  MaxIV = 'max_iv',
  MinDateTime = 'min_date_time',
  MaxDateTime = 'max_date_time',
  IsBlock = 'is_block',
  IsSpread = 'is_spread',
  IsSweep = 'is_sweep',
  IsCross = 'is_cross',
}

export enum TAPE_FILTER_CONJUNCTION_ID {
  SaleType = 'saleType',
}

const getColumnSize = (key: OptionsFeedColumnKey) => {
  switch (key) {
    case OptionsFeedColumnKey.Time:
      return 90;
    case OptionsFeedColumnKey.Underlying:
      return 90;
    case OptionsFeedColumnKey.TradeSide:
      return 70;
    case OptionsFeedColumnKey.Aggressor:
      return 70;
    case OptionsFeedColumnKey.IsPut:
      return 70;
    case OptionsFeedColumnKey.Strike:
      return 80;
    case OptionsFeedColumnKey.Expiry:
      return 105;
    case OptionsFeedColumnKey.DailyVolCumsum:
      return 60;
    case OptionsFeedColumnKey.PrevOi:
      return 60;
    case OptionsFeedColumnKey.Premium:
      return 70;
    case OptionsFeedColumnKey.Size:
      return 60;
    case OptionsFeedColumnKey.StockPrice:
      return 85;
    case OptionsFeedColumnKey.Bid:
      return 70;
    case OptionsFeedColumnKey.Ask:
      return 70;
    case OptionsFeedColumnKey.Price:
      return 80;
    case OptionsFeedColumnKey.Flags:
      return 90;
    case OptionsFeedColumnKey.IVol:
      return 80;
    case OptionsFeedColumnKey.Delta:
      return 60;
    case OptionsFeedColumnKey.Gamma:
      return 60;
    case OptionsFeedColumnKey.Vega:
      return 60;
    default:
      return 75;
  }
};

export const DEFAULT_TAPE_COL_SIZES = Object.values(
  OptionsFeedColumnKey,
).reduce((acc, key) => {
  acc[key] = getColumnSize(key);
  return acc;
}, {} as OptionsFeedColumnSizes);

export const DEFAULT_TAPE_CONTRACT_COL_SIZES = CONTRACT_DATA_COLUMN_KEYS.reduce(
  (acc, key) => {
    acc[key] = getColumnSize(key);
    return acc;
  },
  {} as OptionsFeedColumnSizes,
);

export enum TapeFilterTab {
  NewFilter = 'New Filter',
  SavedFilters = 'Saved Filters',
}

export enum TapeDatagridTab {
  FlowData = 'Flow Data',
  ContractData = 'Contract Data',
}

export const DEFAULT_PANEL_STYLES: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  paddingTop: '6px',
  paddingX: '16px',
  paddingBottom: '24px',
  gap: '16px',
  borderRadius: '8px',
  minWidth: 330,
  maxWidth: 330,
  height: '100%',
};

export const DEFAULT_TNS_SYM = '*';
export const DEFAULT_TNS_SYMBOLS = [DEFAULT_TNS_SYM];

export const TAPE_SCANNERS = [
  {
    label: 'VRP',
    value: Scanner.VOL_RISK_PREMIUM,
  },
  {
    label: 'Squeeze',
    value: Scanner.SQUEEZE,
  },
];

// expected categories
export const FLOW_SUM_CATEGORIES: FlowSumCategory[] = [
  'volume',
  'premium',
  'delta',
  'gamma',
  'vega',
];

export const FLOW_SUM_CATEGORIES_VALUE_FORMAT: Map<
  FlowSumCategory,
  ValueFormat
> = new Map([
  ['delta', 'currency'],
  ['gamma', 'currency'],
  ['vega', 'currency'],
  ['premium', 'currency'],
  ['volume', 'number'],
]);

export const FLOW_SUM_CATEGORY_DESCRIPTION: Record<FlowSumCategory, string> = {
  delta:
    'The absolute cumulative delta traded for the entirety of the flow data, based on the user-defined filters. Delta measures the change in options price based on the change in the price of the underlying asset.',
  vega: "The absolute cumulative vega traded for the entirety of the flow data, based on the user-defined filters. Vega measures the sensitivity of an option's price to changes in volatility.",
  gamma:
    'The absolute cumulative gamma traded for the entirety of the flow data, based on the user-defined filters. Gamma measures the rate of change of delta with respect to spot price.',
  volume:
    'The total number of options contracts traded in the below flow data, based on the user-defined filters.',
  premium:
    'The total amount of options premium traded in the below flow data, based on the user-defined filters.',
};

type ColumnKey = OptionsFeedColumnKey | OptionsFeedContractColumnKey;

// Define the tooltips without const assertions
const tooltips: [ColumnKey, string][] = [
  // Regular column tooltips
  [OptionsFeedColumnKey.Time, 'Timestamp for when the contract was traded'],
  [
    OptionsFeedColumnKey.Underlying,
    'The ticker listed on the options contract',
  ],
  [
    OptionsFeedColumnKey.TradeSide,
    'Where the contract traded in relation to the bid-ask spread. The bid is the highest price buyers are offering for a contract, the ask is the lowest price sellers are selling a contract. Mid indicates a price between the Bid an the Ask.',
  ],
  [
    OptionsFeedColumnKey.Aggressor,
    'Determines whether the order was to buy or sell the option; green values indicate bought calls or sold puts, red values indicate sold calls or bought puts',
  ],
  [
    OptionsFeedColumnKey.IsPut,
    'Identifies whether the option was a call or a put.',
  ],
  [
    OptionsFeedColumnKey.Strike,
    'The strike price for the listed options trade',
  ],
  [
    OptionsFeedColumnKey.Expiry,
    'The expiration date for the listed options contract',
  ],
  [
    OptionsFeedColumnKey.DailyVolCumsum,
    'The daily volume of options contracts that have traded for that specific strike and expiration.',
  ],
  [
    OptionsFeedColumnKey.PrevOi,
    "The prior day's total number of open options contracts for the ticker at that specific strike and expiration.",
  ],
  [
    OptionsFeedColumnKey.Premium,
    'The full sale price of the listed trade; this is calculated from the size field multiplied by the option price multiplied by 100',
  ],
  [
    OptionsFeedColumnKey.Size,
    'The number of contracts traded as part of the order; each contract corresponds to 100 deliverable shares of the underlying ticker.',
  ],
  [
    OptionsFeedColumnKey.StockPrice,
    'The price of the underlying asset at the time of the listed trade',
  ],
  [
    OptionsFeedColumnKey.Bid,
    'The highest price market buyers are offering for an options contract',
  ],
  [
    OptionsFeedColumnKey.Ask,
    'The lowest price sellers are selling an options contract',
  ],
  [
    OptionsFeedColumnKey.Price,
    'For the listed trade, the price of the option for one deliverable share; this is multplied by 100, then multiplied by the size to determine the total premium for the trade',
  ],
  [
    OptionsFeedColumnKey.Flags,
    `Specific attributes of interest for the trade.
Sweep: A large option order that has been segmented into multiple smaller orders
Cross: This flag indicates when the buy and sell orders match exactly for a specific trade, meaning they cancel eachother out 
Block:Privately negotiated options trade, typically large and executed part from public markets.
Multileg: The trade is part of a larger trade involving two or more options at once.`,
  ],
  [
    OptionsFeedColumnKey.IVol,
    'The Implied Volatility for the specific trade, based on the strike, expiration, and spot price.',
  ],
  [
    OptionsFeedColumnKey.Delta,
    'The delta for the specific trade, based on the strike, expiration, and spot price.',
  ],
  [
    OptionsFeedColumnKey.Gamma,
    'The gamma for the specific trade, based on the strike, expiration, and spot price. Gamma measures the rate of change of delta with respect to spot price.',
  ],
  [
    OptionsFeedColumnKey.Vega,
    "The vega for the specific trade, based on the strike, expiration, and spot price. Vega measures the sensitivity of an option's price to changes in volatility.",
  ],

  // Contract column tooltips
  [
    OptionsFeedContractColumnKey.TotalPremium,
    'The full premium associated with the options contracted',
  ],
  [
    OptionsFeedContractColumnKey.TotalVolume,
    'The daily volume of options trades that have traded for that specific strike and expiration.',
  ],
  [
    OptionsFeedContractColumnKey.DeltaNotional,
    'The absolute delta notional for the specific contract, based on the strike, expiration, and spot price.',
  ],
  [
    OptionsFeedContractColumnKey.GammaNotional,
    'The absolute gamma notional for the specific contract, based on the strike, expiration, and spot price. Gamma measures the rate of change of delta with respect to spot price.',
  ],
  [
    OptionsFeedContractColumnKey.VegaNotional,
    "The absolute vega notional for the specific contract, based on the strike, expiration, and spot price. Vega measures the sensitivity of an option's price to changes in volatility.",
  ],
];

export const TAPE_COLUMN_TOOLTIPS_MAP = new Map<ColumnKey, string>(tooltips);

export const TAPE_UPSELL = {
  title: 'Unlock Unlimited Tape Access',
  subtitle: "With a SpotGamma Alpha subscription, you'll get:",
  upgrade_subtitle: "Upgrade to a SpotGamma Alpha subscription, you'll get:",
  items: [
    'Real-time charts incorporating all options trades',
    'Personalized watchlist and trending names',
    'Highly actionable alerts for options and equity traders',
  ],
};

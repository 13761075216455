import { FoundersNoteTable } from './FoundersNoteTable';
import { SGCustomTagType } from '../../../types';
import {
  businessDaysAdd,
  formatAsCompactNumber,
  getDateFormatted,
  getQueryDateFormatted,
  levelsDateUpdated,
  sigHighLow,
  ET,
} from '../../../util';
import { Theme } from '@mui/material/styles';
import useLog from '../../../hooks/useLog';
import { useRecoilValue } from 'recoil';
import { todaysOpenArrState } from '../../../states';
import { isNumber } from 'lodash';
import { convertLevelsToFutures } from '../../../util/home';
import dayjs from 'dayjs';

type SGKeyLevelsTableProps = {
  data: any;
  printTheme?: Theme;
  updatedAt?: string;
  date?: dayjs.Dayjs;
};

export const SGKeyLevelsTable = ({
  data,
  printTheme,
  updatedAt,
  date,
}: SGKeyLevelsTableProps) => {
  const { logError } = useLog('SGKeyLevelsTable');
  const todaysOpenArr = useRecoilValue(todaysOpenArrState);
  const dataArr = [...data.data];
  date = date?.tz(ET);

  try {
    let rowHeaders: string[] = [],
      levelNames: string[] = [],
      indexToKey: string[] = [],
      rows: string[][] = [];

    const keysNeedingDollar: Set<string> = new Set([
      'upx',
      'max_g_strike',
      'gamma_not',
      'callwallstrike',
      'putwallstrike',
      'topabs_strike',
      'zero_g_strike',
      'sig_high',
      'sig_low',
    ]);
    const keysNeedingFormat: Set<string> = new Set([
      'calls_OI',
      'puts_OI',
      'calls_vol',
      'puts_vol',
      'gamma_tilt',
      'gamma_not',
      'gamma_index',
      'rr',
    ]);
    // if there is a key here and the symbol is not in the Set for the key it will be ignored even if there is a value
    const blanks: Map<string, Set<string>> = new Map([
      ['gamma_index', new Set(['SPX', 'SPY'])],
      ['sig5', new Set(['/ES', 'SPX'])],
      ['sig', new Set(['/ES', 'SPX', 'SPY'])],
      ['sig_high', new Set(['/ES', 'SPX', 'SPY'])],
      ['sig_low', new Set(['/ES', 'SPX', 'SPY'])],
    ]);
    const keysAsPercents: Set<string> = new Set(['sig', 'sig5']);

    if (data.type === SGCustomTagType.SGPropKeyLevelsTable) {
      const spxData = dataArr.find((d: any) => d.sym === 'SPX');
      if (spxData?.futuresDiff != null) {
        dataArr.splice(0, 0, {
          ...convertLevelsToFutures(spxData),
          sym: '/ES',
        });
      }

      rowHeaders = [''].concat(dataArr.map((d: any) => d.sym));
      levelNames = [
        'Reference Price',
        'SG Gamma Index™',
        'SG Implied 1-Day Move',
        'SG Implied 5-Day Move',
        'SG Implied 1-Day Move High',
        'SG Implied 1-Day Move Low',
        'SG Volatility Trigger™',
        'Absolute Gamma Strike',
        'Call Wall',
        'Put Wall',
        'Zero Gamma Level',
      ];
      // index in levelNames array matches key in data
      indexToKey = [
        'upx',
        'gamma_index',
        'sig',
        'sig5',
        'sig_high',
        'sig_low',
        'max_g_strike',
        'topabs_strike',
        'callwallstrike',
        'putwallstrike',
        'zero_g_strike',
      ];
    } else if (data.type === SGCustomTagType.SGAddtlKeyLevelsTable) {
      rowHeaders = [''].concat(dataArr.map((d: any) => d.sym));
      levelNames = [
        'Gamma Tilt',
        'Gamma Notional (MM)',
        '25 Delta Risk Reversal',
        'Call Volume',
        'Put Volume',
        'Call Open Interest',
        'Put Open Interest',
      ];
      // index in levelNames array matches key in data
      indexToKey = [
        'gamma_tilt',
        'gamma_not',
        'rr',
        'calls_vol',
        'puts_vol',
        'calls_OI',
        'puts_OI',
      ];
    } else if (data.type === SGCustomTagType.SGSupportResistanceTable) {
      rowHeaders = ['Key Support & Resistance Strikes'];
      const excludeSyms = ['IWM', 'RUT'];

      rows = rows.concat(
        dataArr.map((d: any) => {
          if (excludeSyms.includes(d.sym)) {
            return [];
          }
          return [`${d.sym} Levels: [${d.L1}, ${d.L2}, ${d.L3}, ${d.L4}]`];
        }),
      );

      rows = rows.concat(
        dataArr.map((d: any) => {
          if (excludeSyms.includes(d.sym)) {
            return [];
          }

          if (d.sym === 'SPX' && d.levels_with_pct != null) {
            const comboStr = JSON.parse(d.levels_with_pct)
              .map((c_arr: number[]) => `(${c_arr[0]},${c_arr[1].toFixed(2)})`)
              .join(', ');
            return [`${d.sym} Combos: [${comboStr}]`];
          }

          return [`${d.sym} Combos: [${d.C1}, ${d.C2}, ${d.C3}, ${d.C4}]`];
        }),
      );
    } else {
      return null;
    }

    if (rows.length == 0) {
      rows = levelNames
        .map((level: string, i: number) => {
          let res = [`${level}:`];

          const key = indexToKey[i];
          const toNum = (val: string | number) =>
            typeof val === 'string' ? parseFloat(val) : val;
          const stringTransform = (
            key: string,
            d: any,
            val?: string | number,
          ) => {
            if (
              val == null ||
              val === '' ||
              blanks.get(key)?.has(d.sym) === false
            ) {
              return '';
            }

            if (typeof val === 'string' && isNaN(toNum(val))) {
              // allow us to return text directly that isnt a number
              // for example, message to user telling them to check after open for sig 1d high/low
              return val;
            }

            let ans: string | number = isNumber(val) ? val : parseFloat(val);
            if (keysAsPercents.has(key)) {
              ans = `${parseFloat(`${ans * 100}`).toFixed(2)}%`;
            }
            if (keysNeedingFormat.has(key)) {
              const num = toNum(ans);
              ans =
                num < 1 && num > 0
                  ? num.toFixed(3)
                  : formatAsCompactNumber(ans, { minimumFractionDigits: 2 });
            }
            if (keysNeedingDollar.has(key)) {
              let sign = '';
              if (`${ans}`[0] === '-') {
                // must be a non-breaking hyphen, https://en.wikipedia.org/wiki/Wikipedia:Non-breaking_hyphen
                sign = '\u{2011}';
                ans = `${ans}`.slice(1);
              }
              ans = `${sign}$${ans}`;
            }

            return `${ans}`;
          };

          const dataToAdd = dataArr.map((d: any) => {
            let val = d[key];
            if (key === 'gamma_tilt') {
              val = d['atm_g_calls'] / Math.abs(d['atm_g_puts']);
            } else if (key === 'gamma_index') {
              val = (d['atm_g_calls'] + d['atm_g_puts']) / 100000000;
            } else if (key === 'sig_high' || key === 'sig_low') {
              let highLow;
              const tradeDate = dayjs.utc(
                dataArr.find((d: any) => d.sym === 'SPX')?.trade_date,
              );
              // trade date is prior close, so we want the open of the next trading day
              const nextTradeDate = businessDaysAdd(tradeDate, 1);
              const tradeDateNextOpenStr = getDateFormatted(nextTradeDate);

              if (levelsDateUpdated(getDateFormatted(tradeDate))) {
                // we should only set high/low using todaysOpenArr if we are looking at a note
                // from the latest trading day as well as levels from the latest trading day
                highLow = sigHighLow(d, todaysOpenArr, tradeDateNextOpenStr);
              } else if (d.open == null) {
                // old notes that do not have this field will not have data in the am or pm note
                return '';
              } else if (
                d.open.date === getDateFormatted(date ?? nextTradeDate)
              ) {
                // AM notes published pre market open will have the open of the last trading day
                // only use this for implied move calculation if it's from today's open, which is usually only PM notes
                highLow = sigHighLow(d, [d.open], tradeDateNextOpenStr);
              }

              if (highLow == null) {
                if (tradeDateNextOpenStr === getQueryDateFormatted(true)) {
                  val = 'After open';
                } else {
                  // for notes not from today, d.open is only set in the PM note
                  // since we dont have the open when the am note goes out
                  val = 'In PM note';
                }
              } else {
                val = highLow[key].toFixed(2);
              }
            }

            return stringTransform(key, d, val);
          });
          return res.concat(dataToAdd);
        })
        .filter(
          (row) => row.slice(1).find((v) => v != null && v !== '') != null,
        ); // filter out any empty rows
    }

    return (
      <FoundersNoteTable
        rows={rows}
        rowHeaders={rowHeaders}
        printTheme={printTheme}
        updatedAt={updatedAt}
      />
    );
  } catch (e) {
    logError(e, 'render');
    return null;
  }
};

enum EnvType {
  Prod = 1,
  Beta = 0,
}

/**
 * Token types available for request.
 */
enum TokenType {
  /**
   * All tokens
   */
  All = 0,

  /**
   * access token
   */
  AccessToken = 1,

  /**
   * ID token
   */
  IdToken = 2,
}
class OAuth2SignOnParams {
  /**
   * REQUIRED. Identifier for the application.
   */
  clientId: string = '';

  /**
   * ONLY REQUIRED in getTokens(). This is issued by BSSO during registration, used when requesting tokens from token endpoint.
   */
  clientSecret: string = '';

  /**
   * REQUIRED. List of identifiers used to specify what access privileges are being requested. BSSO currently only support 'openid' and 'profile' for
   * App Portal.
   */
  scopes: Array<string> = [];

  /**
   * REQUIRED. The URI to redirect back to after authentication is completed. Only Urls registered with the client id in BSSO is
   * allowed.
   */
  redirectURI: string = '';

  /**
   * RECOMMENDED in signOn(), OPTIONAL in getTokens(). Opaque value used to maintain state between the request and the callback.
   */
  state: string = '';

  /**
   * OPTIONAL. String value used to associate a Client session with an ID Token, and to mitigate replay attacks. The value is passed through
   * unmodified from the Authentication Request to the ID Token. Sufficient entropy MUST be present in the nonce values used to
   * prevent attackers from guessing values. If no value specified, API will generate one internally.
   */
  nonce: string = '';

  /**@hidden
   * OPTIONAL. BSSO Environment type (optional), default value is Prod.
   */
  envType: EnvType = EnvType.Prod;

  /**
   * OPTIONAL. Requested token type (optional), default is all available tokens.
   */
  tokenType: TokenType = TokenType.All;

  /**
   * OPTIONAL. Specifies whether to use cached tokens if not expired. This parameter is only used in getTokens(). Default value is false.
   */
  noCache: boolean = false;
  useHfn: boolean = false;
}

class CryptoUtils {
  static newGUID() {
    const chars = '0123456789ABCDEF'.split('');
    const uuid = [];
    const rnd = Math.random;
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (rnd() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r & 0xf];
      }
    }
    return uuid.join('');
  }
  static base64Encode(input: string) {
    return btoa(input);
  }
  static base64Decode(input: string) {
    return atob(input);
  }
  static base64UrlEncode(input: string) {
    const b64 = this.base64Encode(input);
    return b64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }
  static base64UrlDecode(input: string) {
    let b64 = input.replace(/-/g, '+').replace(/_/g, '/');
    switch (b64.length % 4) {
      case 0:
        break;
      case 2:
        b64 += '==';
        break;
      case 3:
        b64 += '=';
        break;
      default:
        throw new Error('Invalid base64 string');
    }
    return this.base64Decode(b64);
  }
  static generateRandomString(length: any) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  static async generateCodeChallenge(code_verifier: any) {
    const sha = await this.SHA256(code_verifier);
    const shaString = String.fromCharCode.apply(null, sha);
    const code_challenge = this.base64UrlEncode(shaString);
    return code_challenge;
  }
  static async SHA256(message: any) {
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray;
  }
}

const TokenType$1 = {
  All: 0,
  AccessToken: 1,
  IdToken: 2,
};

class DOMUtils {
  static addHiddenIFrame(iframeId: any, srcdoc: any) {
    let iframe = document.getElementById(iframeId) as any;
    if (iframe) {
      DOMUtils.removeHiddenIframe(iframe);
      iframe = null;
    }

    if (
      document.documentElement &&
      window.navigator.userAgent.indexOf('MSIE 5.0') === -1
    ) {
      const ifr = document.createElement('iframe');
      if (srcdoc !== undefined) {
        ifr.srcdoc = srcdoc;
      }
      ifr.setAttribute('id', iframeId);
      ifr.style.visibility = 'hidden';
      ifr.style.position = 'absolute';
      ifr.style.width = ifr.style.height = '0';
      ifr.style.border = '0';
      ifr.setAttribute('sandbox', 'allow-scripts allow-same-origin');
      iframe = document.getElementsByTagName('body')[0].appendChild(ifr);
    } else if (document.body && document.body.insertAdjacentHTML) {
      document.body.insertAdjacentHTML(
        'beforeend',
        "<iframe name='" +
          iframeId +
          "' id='" +
          iframeId +
          "' style='display:none'></iframe>",
      );
      iframe = document.getElementById(iframeId) as any;
    }
    return iframe;
  }

  static removeHiddenIframe(iframe: any) {
    document.body.removeChild(iframe);
  }
}

class BssoUtils {
  static getBssoEnvType(envType: any) {
    if (envType === undefined) {
      return EnvType.Prod;
    }
    return envType;
  }
  static getBssoHost(envType: any) {
    let host = 'bsso';
    switch (envType) {
      case EnvType.Beta:
        host = 'bssobeta';
        break;
      case EnvType.Prod:
        host = 'bsso';
        break;
    }
    return host + '.blpprofessional.com';
  }
}

class BssoBridge {
  _useHfn = false;
  async signOnImplicitAsync(oauthCodeParams: any, envType: any) {
    const html = `
    <html>
      <head>
        <script>
          function signOn() {
            const oauthParams = ${JSON.stringify(oauthCodeParams)};

            const clientId = "${oauthCodeParams.client_id}";
            const envType = ${envType};
            const _signOn = ${
              this._useHfn
                ? 'window.bb.apps.sso.oauth2SignInImplicit_'
                : 'window.bb.apps.auth.oauth2.signOnImplicit_'
            };
            const onSuccess = (url) => { 
              var event = new CustomEvent("oauth-url-received", { detail: {url: url }});
              window.parent.document.dispatchEvent(event);
            };
            const onError = (errCode, errMsg) => {
              console.error(errCode, errMsg);
              throw new Error(errMsg);
            };

            try {
              _signOn(onSuccess, onError, clientId, oauthParams, envType);
            } catch (err) {
              console.error("ERROR:> " + err.toString());
            }
          }

          signOn();
        <\/script>
      </head>
      <body></body>
    </html>`;
    return new Promise(function (resolve, reject) {
      let resolved = false;
      let iframe: any;
      document.addEventListener('oauth-url-received', function (e: any) {
        resolved = true;
        resolve(e.detail.url);
        DOMUtils.removeHiddenIframe(iframe);
      });
      iframe = DOMUtils.addHiddenIFrame('bbauth', html);
      if (!iframe || !iframe.contentWindow) {
        throw new Error('failed to access auth iframe');
      }
      setTimeout(() => {
        if (!resolved) {
          reject(new Error('Oauth timeout'));
          DOMUtils.removeHiddenIframe(iframe);
        }
      }, 5000); // 5 sec timeout in case this never returns
    });
  }
}

const logger = console;
let OAuth2$1 = class OAuth2 {
  _useHfn;
  _bssoBridge;
  _pendingRequests = {};
  constructor() {
    this._useHfn = false;
    this._bssoBridge = new BssoBridge();
  }
  async getOauthPKCEParams(signOnParams: OAuth2SignOnParams): Promise<any> {
    this._useHfn = signOnParams.useHfn;
    this._bssoBridge._useHfn = signOnParams.useHfn;

    OAuth2$1.validateSignOnParamsGetTokens(signOnParams);
    try {
      return await this.getOauthParamsFromBsso(signOnParams);
    } catch (err) {
      logger.error(`Get tokens failed: ${err}`);
      return null;
    }
  }
  async getOauthParamsFromBsso(signOnParams: any) {
    const nonce = signOnParams.nonce || CryptoUtils.generateRandomString(20);
    const codeVerifier = CryptoUtils.generateRandomString(43);
    const codeChallenge = await CryptoUtils.generateCodeChallenge(codeVerifier);
    const oauthCodeParam = {
      client_id: signOnParams.clientId,
      scope: signOnParams.scopes.join(' '),
      response_type: 'code',
      redirect_uri: signOnParams.redirectURI,
      state: signOnParams.state,
      nonce: nonce,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
    };
    const codeReqUrl = await this._bssoBridge.signOnImplicitAsync(
      oauthCodeParam,
      BssoUtils.getBssoEnvType(signOnParams.envType),
    );
    logger.info(`Got code response: ${codeReqUrl}`);
    // const codeResponse =
    //   OAuthCodeResponse.BuildFromUrlString(codeResponseString);
    const oauthTokenParams = {
      grant_type: 'authorization_code',
      code_req_url: codeReqUrl,
      client_id: signOnParams.clientId,
      client_secret: signOnParams.clientSecret,
      redirect_uri: signOnParams.redirectURI,
      code_verifier: codeVerifier,
    };
    return oauthTokenParams;
  }

  static validateSignOnParamsGetTokens(signOnParams: any) {
    if (!signOnParams.clientId) {
      throw new Error('signOnParams.clientId is empty');
    }
    if (!signOnParams.scopes) {
      logger.info(
        'signOnParams.scopes is empty, use default ["openid", "profile"]',
      );
      signOnParams.scopes = ['openid', 'profile'];
    }
    if (!signOnParams.redirectURI) {
      throw new Error('signOnParams.redirectURI is empty');
    }
    if (signOnParams.envType === undefined) {
      logger.info('signOnParam.envType is empty, use default EnvType.Prod');
      signOnParams.envType = EnvType.Prod;
    }
    if (signOnParams.tokenType === undefined) {
      signOnParams.tokenType = TokenType$1.All;
    }
  }
  static GetCacheKey(name: any, scopes: any, clientId: any) {
    return (
      (clientId || '') + '|' + name + '|' + OAuth2$1.NormalizeScopes(scopes)
    );
  }
  static NormalizeScopes(scopes: string[]) {
    if (!scopes || !(scopes.length > 0) || !scopes.filter) {
      return '';
    }
    scopes = scopes.filter((s) => s && s.trim).map((s) => s.trim());
    return scopes.join(' ');
  }
};

class OAuth2 {
  _useHfn = false;
  _pkceImpl = new OAuth2$1();
  constructor() {}
  async getOauthPKCEParams(signOnParams: any) {
    return await this._pkceImpl.getOauthPKCEParams(signOnParams);
  }

  cancelOauthLogin() {
    DOMUtils.removeHiddenIframe(document.getElementById('bbauth'));
  }
}
class GlobalAuthentication {
  OAuth2;
  constructor() {
    this.OAuth2 = new OAuth2();
  }
}
var index = (function () {
  (window as any).BB = window.BB || {};
  (window as any).BB.Apps = window.BB.Apps || {};
  window.BB.Apps.Auth = window.BB.Apps.Auth || new GlobalAuthentication();
  return window.BB.Apps.Auth;
})();

export { EnvType, OAuth2SignOnParams, TokenType, DOMUtils, index as default };

declare global {
  interface AppPortalWeb {
    Auth: IGlobalAuthentication;
  }
}

export declare interface IOAuth2 {
  getOauthPKCEParams(signOnParams: OAuth2SignOnParams): Promise<any>;
  cancelOauthLogin(): void;
}

export declare interface IGlobalAuthentication {
  OAuth2: IOAuth2;
}

import { IndexGrouping, IndexSymbol, IndicesContentType } from '../types/index';

export const INDICES_MAPPING: Record<IndexGrouping, Set<string>> = {
  [IndexGrouping.NASDAQ]: new Set([IndexSymbol.QQQ, IndexSymbol.NDX]),
  [IndexGrouping.SP]: new Set([IndexSymbol.SPY, IndexSymbol.SPX]),
  [IndexGrouping.RUSSEL]: new Set([IndexSymbol.RUT, IndexSymbol.IWM]),
};

export const INDICES_FIELD_NAME_MAPPING = {
  upx: 'Last Closing',
  callwallstrike: 'Call Wall',
  putwallstrike: 'Put Wall',
  max_g_strike: 'Vol Trigger',
  topabs_strike: 'Largest Gamma',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C4: 'C4',
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
};

export const INDICES_NAMES = [
  { symbol: IndexSymbol.SPX, name: 'S&P 500' },
  { symbol: IndexSymbol.SPY, name: 'SPDR S&P 500 ETF Trust' },
  { symbol: IndexSymbol.NDX, name: 'Nasdaq-100' },
  { symbol: IndexSymbol.QQQ, name: 'Invesco QQQ Trust Series 1' },
  { symbol: IndexSymbol.RUT, name: 'Russell 2000 Index' },
  { symbol: IndexSymbol.IWM, name: 'iShares Russell 2000 ETF' },
];

export enum IndicesContentGroup {
  GREEKS = 'Greeks',
  VOLATILITY = 'Volatility',
  OPEN_INTEREST = 'Open Interest',
}

export const DEFAULT_CONTENTS_MAP: Map<
  IndicesContentGroup,
  IndicesContentType[]
> = new Map([
  [
    IndicesContentGroup.GREEKS,
    [
      IndicesContentType.GAMMA_MODEL,
      IndicesContentType.DELTA_MODEL,
      IndicesContentType.VANNA_MODEL,
      IndicesContentType.GAMMA_LEVELS,
      IndicesContentType.COMBO_STRIKES,
      IndicesContentType.TILT,
      IndicesContentType.EXP_CONCENTRATION,
    ],
  ],
  [
    IndicesContentGroup.VOLATILITY,
    [
      IndicesContentType.VOLFORECAST_MODEL,
      IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
      IndicesContentType.MAX_REAL_VOL,
      IndicesContentType.HIST_RETURNS,
      IndicesContentType.VOLATILITY,
    ],
  ],
  [
    IndicesContentGroup.OPEN_INTEREST,
    [IndicesContentType.ZERO_DTE, IndicesContentType.OI_VOLUME],
  ],
]);

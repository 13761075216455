import { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  ListItemText,
  Button,
  Box,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import { useContent } from 'hooks/admin';
import { AdminContentEditor } from './AdminContentEditor';
import { AdminContentCategory, AdminWrittenContent } from '../../types';
import { useTheme } from '@mui/material/styles';
import { Loader } from 'components/shared';
import { FoundersNoteReviews } from './FoundersNoteReviews';
import { getEnv } from '../../util';
import { NavLink } from 'react-router-dom';

export const linkContent = async (
  title: string | undefined | null,
  freePreviewKey: string | undefined | null,
) => {
  if (freePreviewKey == null) {
    return alert('Error copying link');
  }

  const link = `${
    window.location.host
  }/foundersNotes/preview/${encodeURIComponent(freePreviewKey!)}`;
  await navigator.clipboard.writeText(link);
  alert(`Copied free link to '${title ?? 'note'}' to your clipboard.`);
};

export const AdminContentList = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [contentList, setContentList] = useState<AdminWrittenContent[]>([]);
  const { fetchContentList, deleteContent } = useContent();
  const [showingEditor, setShowingEditor] = useState(false);
  const [showingReviews, setShowingReviews] = useState(false);
  const [editingContent, setEditingContent] =
    useState<AdminWrittenContent | null>(null);

  const adminCategories = Object.values(AdminContentCategory);
  const [draftsCategory, setDraftsCategory] = useState<AdminContentCategory>(
    adminCategories[0],
  );
  const [publishedCategory, setPublishedCategory] =
    useState<AdminContentCategory>(adminCategories[0]);

  const fetchIndex = async () => {
    setLoading(true);
    const response = await fetchContentList(draftsCategory, publishedCategory);
    setLoading(false);
    if (response.error) {
      return alert('Error loading the list of content');
    }
    setContentList(response as AdminWrittenContent[]);
  };

  useEffect(() => {
    fetchIndex();
  }, [draftsCategory, publishedCategory]);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  const copyContent = (content: AdminWrittenContent) => {
    let newCopy = { ...content };
    newCopy.key = null;
    newCopy.title = null;
    newCopy.id = null;
    newCopy.copiedFromKey = content.key;
    setEditingContent(newCopy);
    setShowingEditor(true);
  };
  const deleteContentWithConfirmation = async (
    content: AdminWrittenContent,
  ) => {
    if (
      window.confirm(
        `Are you sure you want to delete '${content.title}'? ${
          content.published ? 'THIS CONTENT IS ALREADY PUBLISHED!' : ''
        }`,
      )
    ) {
      setLoading(true);
      const response = await deleteContent(content.key!, content.published!);
      if (response.error != null) {
        alert('Error deleting this content. Please try again');
      }
      setLoading(false);
      fetchIndex();
    }
  };

  const editContent = (content: AdminWrittenContent) => {
    setEditingContent(content);
    setShowingEditor(true);
  };

  const drafts = contentList.filter((content) => !content.published);
  const published = contentList.filter((content) => content.published);

  const list = [drafts, published].map((items, index) => {
    return (
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {index == 0 ? `Drafts` : `Published`}
            <Select
              value={index === 0 ? draftsCategory : publishedCategory}
              label="Category"
              onChange={async (e) =>
                index === 0
                  ? setDraftsCategory(e.target.value as AdminContentCategory)
                  : setPublishedCategory(e.target.value as AdminContentCategory)
              }
              sx={{
                marginLeft: '30px',
                border: `0.5px solid ${theme.palette.text.disabled}`,
                fontSize: '12px',
                height: '35px',
                color: theme.palette.text.secondary,
              }}
            >
              {adminCategories.map((cat) => {
                return (
                  <MenuItem value={cat} key={cat}>
                    {cat}
                  </MenuItem>
                );
              })}
            </Select>
          </ListSubheader>
        }
      >
        {items.length == 0 && (
          <ListItem>
            <ListItemText>Nothing here yet</ListItemText>
          </ListItem>
        )}
        {items.map((content) => {
          return (
            <ListItem
              disablePadding
              secondaryAction={
                <Box>
                  {content.published && (
                    <Button
                      color="secondary"
                      sx={{ fontSize: '13px' }}
                      onClick={async () =>
                        await linkContent(content.title, content.freePreviewKey)
                      }
                    >
                      Free Link
                    </Button>
                  )}
                  <Button
                    sx={{ fontSize: '13px' }}
                    onClick={() => copyContent(content)}
                  >
                    Copy
                  </Button>
                  <Button
                    color="error"
                    sx={{ fontSize: '13px' }}
                    onClick={() => deleteContentWithConfirmation(content)}
                  >
                    Delete
                  </Button>
                </Box>
              }
            >
              <ListItemButton onClick={() => editContent(content)}>
                <ListItemText
                  primary={content.title}
                  secondary={
                    getEnv() === 'development' ? content.key : undefined
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  });

  return (
    <Box width={'100%'} height={'100%'} display="flex" flexDirection="column">
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          gap="6px"
          pb="6px"
        >
          {!showingEditor && !showingReviews && (
            <Button
              onClick={() => {
                setEditingContent(null);
                setShowingEditor(!showingEditor);
              }}
              size="small"
              sx={{ fontSize: '14px' }}
            >
              + Create
            </Button>
          )}
          {!showingEditor && !showingReviews && (
            <Button
              onClick={async () => {
                setShowingReviews(true);
              }}
              size="small"
              sx={{ fontSize: '14px' }}
            >
              View Reviews
            </Button>
          )}
          {editingContent != null && !showingEditor && !showingReviews && (
            <Button
              onClick={() => {
                setShowingEditor(true);
              }}
              size="small"
              sx={{ fontSize: '14px' }}
            >
              Resume Last Edit
            </Button>
          )}
        </Box>
        <NavLink
          to="/admin/notifications"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          SG Notifications
        </NavLink>
      </Stack>

      {showingEditor ? (
        <AdminContentEditor
          editContent={editingContent}
          onClose={(content) => {
            setEditingContent(content);
            setShowingEditor(false);
            fetchIndex();
          }}
        />
      ) : showingReviews ? (
        <FoundersNoteReviews
          onClose={() => {
            setShowingReviews(false);
            fetchIndex();
          }}
        />
      ) : (
        list
      )}
    </Box>
  );
};

import { useTheme } from '@mui/material/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { isMobileState } from '../../states';
import { BookmapIntegration } from './BookmapIntegration';
import { ESignalIntegration } from './eSignalIntegration';
import { EdgeProIntegration } from './EdgeProIntegration';
import { JigsawIntegration } from './JigsawIntegration';
import { NinjaTraderIntegration } from './NinjaTraderIntegration';
import { SierraChartIntegration } from './SierraChartIntegration';
import { ThinkOrSwimIntegration } from './ThinkOrSwimIntegration';
import { TradingViewIntegration } from './TradingViewIntegration';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from 'components/shared';
import useIntegrations from '../../hooks/integrations/useIntegrations';
import { TrendSpiderIntegration } from './TrendSpiderIntegration';

export const TabPanelWrapper = (props: any) => {
  const { children, ...rest } = props;
  return (
    <TabPanel {...rest}>
      <Box
        sx={{
          transition: '0.5s',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '100%',
        }}
      >
        {children}
      </Box>
    </TabPanel>
  );
};

enum Integration {
  TradingView = 'trading_view',
  TrendSpider = 'trend_spider',
  ThinkOrSwim = 'thinkorswim',
  Bookmap = 'bookmap',
  NinjaTrader = 'ninja_trader',
  Jigsaw = 'jigsaw',
  ESignal = 'e_signal',
  SierraChart = 'sierra_chart',
  EdgePro = 'edge_pro',
}

const TAB_VALS: Set<string> = new Set(
  Object.values(Integration).map((s) => s as string),
);

const getEntry = (label: string, component: React.ReactNode) => ({
  label,
  component,
});
const INTEGRATIONS = new Map([
  [
    Integration.TradingView,
    getEntry('TradingView', <TradingViewIntegration />),
  ],
  [
    Integration.ThinkOrSwim,
    getEntry('thinkorswim', <ThinkOrSwimIntegration />),
  ],
  [
    Integration.TrendSpider,
    getEntry('TrendSpider', <TrendSpiderIntegration />),
  ],
  [Integration.Bookmap, getEntry('Bookmap', <BookmapIntegration />)],
  [
    Integration.NinjaTrader,
    getEntry('NinjaTrader', <NinjaTraderIntegration />),
  ],
  [Integration.Jigsaw, getEntry('Jigsaw', <JigsawIntegration />)],
  [Integration.ESignal, getEntry('eSignal', <ESignalIntegration />)],
  [
    Integration.SierraChart,
    getEntry('Sierra Chart', <SierraChartIntegration />),
  ],
  [Integration.EdgePro, getEntry('EdgePro', <EdgeProIntegration />)],
]);

const LABELS = new Map(
  [...INTEGRATIONS.entries()].map(([k, v]) => [k, v.label]),
);
export const Integrations = () => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const [searchParams, setSearch] = useSearchParams();
  const tabQuery = searchParams.get('tab') ?? '';
  const tab = TAB_VALS.has(tabQuery)
    ? tabQuery
    : (Integration.TradingView as string);
  const { getIntegrationsDisclaimer } = useIntegrations();

  return (
    <Box
      sx={{
        transition: '0.5s',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        ...(isMobile ? { minHeight: '450px' } : {}),
      }}
    >
      <TabContext value={tab}>
        <Tabs
          options={LABELS}
          onChange={(_event: React.SyntheticEvent, tab) => setSearch({ tab })}
          fontSize="14px"
        />
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            overflowX: 'auto',
            ...(isMobile ? { maxHeight: 'calc(100% - 150px)' } : {}),
          }}
        >
          {[...INTEGRATIONS.entries()].map(([value, { component }]) => (
            <TabPanelWrapper key={value as string} value={value as string}>
              {value !== Integration.SierraChart && getIntegrationsDisclaimer()}
              {component}
            </TabPanelWrapper>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};

const TICKER_RT = 'TICKER_RT';
const SECURITY_NAME_REALTIME = 'SECURITY_NAME_REALTIME';
const LAST_PRICE = 'LAST_PRICE';
const INDUSTRY_SECTOR = 'INDUSTRY_SECTOR_RT';
const PREV_CLOSE_VALUE = 'PREV_CLOSE_VALUE_REALTIME';
const OPEN = 'OPEN';
const BID = 'BID';
const BID_SIZE = 'BID_SIZE';
const ASK = 'ASK';
const ASK_SIZE = 'ASK_SIZE';
const VOLUME = 'VOLUME';
const AVG_VOLUME = '100_DAY_AVG_VOLUME_AT_TIME_RT';
const HIGH = 'HIGH';
const LOW = 'LOW';
const PRICE_52_WEEK_HIGH = 'PRICE_52_WEEK_HIGH_RT';
const PRICE_52_WEEK_LOW = 'PRICE_52_WEEK_LOW_RT';
const RT_PX_CHG_NET_1D = 'RT_PX_CHG_NET_1D';
const RT_PX_CHG_PCT_1D = 'RT_PX_CHG_PCT_1D';
const PARSEKEYABLE_DESCRIPTION_RT = 'PARSEKEYABLE_DESCRIPTION_RT';
const MARKET_SECTOR_DESCTIPTION_RT = 'MARKET_SECTOR_DESCTIPTION_RT';

export const MktFields = {
  TICKER_RT,
  SECURITY_NAME_REALTIME,
  LAST_PRICE,
  INDUSTRY_SECTOR,
  PREV_CLOSE_VALUE,
  OPEN,
  BID,
  BID_SIZE,
  ASK,
  ASK_SIZE,
  VOLUME,
  AVG_VOLUME,
  HIGH,
  LOW,
  PRICE_52_WEEK_HIGH,
  PRICE_52_WEEK_LOW,
  RT_PX_CHG_NET_1D,
  RT_PX_CHG_PCT_1D,
  PARSEKEYABLE_DESCRIPTION_RT,
  MARKET_SECTOR_DESCTIPTION_RT,
};

import {
  BottomCandlesType,
  HiroChartSettings,
  HiroLense,
  ProductType,
  ValToMarkerKey,
  ValueToMarker,
} from '../types';

export const HIRO_CHART_UPDATE_DELAY = (1.5 + Math.random() * 2.5) * 1000;

export const VAL2MARK: ValueToMarker = {
  '1': { label: '1m', seconds: 60 },
  '2': { label: '5m', seconds: 300 },
  '3': { label: '10m', seconds: 600 },
  '4': { label: '30m', seconds: 1_800 },
  '5': { label: '1h', seconds: 3_600 },
  '6': { label: '4h', seconds: 14_400 },
  '7': { label: '1d', seconds: 86_400 },
  '8': { label: '\u{221e}', seconds: Number.POSITIVE_INFINITY }, // infinity
};

export const MARK_KEYS = Object.keys(VAL2MARK);

export const MARKS = MARK_KEYS.map((k) => ({
  value: parseInt(k),
  label: VAL2MARK[k as ValToMarkerKey].label,
}));

export const ROLLING_SECONDS_TO_LABEL = new Map(
  MARK_KEYS.map((k) => [
    VAL2MARK[k as ValToMarkerKey].seconds,
    VAL2MARK[k as ValToMarkerKey].label,
  ]),
);

interface LenseCfg {
  // TODO: Import the below type/enum from MUI
  check:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  label: string;
  style: number; // TODO: Use lightweight-charts enum
}

// keys must match the relevant keys for chart lenses in theme.ts
export const LenseToCfg: { [value in HiroLense]: LenseCfg } = {
  all: {
    check: 'secondary',
    label: 'All',
    style: 0, // solid
  },
  nextExp: {
    check: 'primary',
    label: 'Next Expiry',
    style: 0, // solid
  },
  retail: {
    check: 'error',
    label: 'Retail',
    style: 0, // solid
  },
};

export const LENSE_LABELS: { [value in HiroLense]: string } = {
  all: 'All Trades',
  nextExp: 'Next Expiry',
  retail: 'Retail',
};

export const TRENDING_LIMIT = 10;

export enum ComboSymbol {
  ES_F = 'S&P ES=F',
  NDX = 'Nasdaq',
  RUT = 'Russell 2k',
  SPX = 'S&P 500',
  MAG7 = 'Mag7',
  SPX_EQUITIES = 'S&P Equities',
}
export const COMBINED_SIGNALS: Set<string> = new Set(
  Object.values(ComboSymbol),
);

export const COMBO_SYMS = [
  { symbol: ComboSymbol.SPX as string, name: 'Combined SPX + SPY + XSP' },
  {
    symbol: ComboSymbol.ES_F as string,
    name: 'All combined /ES S&P futures',
  },
  { symbol: ComboSymbol.NDX as string, name: 'Combined NDX + QQQ' },
  { symbol: ComboSymbol.RUT as string, name: 'Combined RUT + IWM' },
];

export const ALL_PREMARKET_SYMBOLS = [
  ComboSymbol.ES_F,
  ComboSymbol.SPX,
  ComboSymbol.SPX_EQUITIES,
  'SPX',
  'VIX',
];

export const PREMARKET_SYMBOLS_PER_PRODUCT = new Map<ProductType, Set<string>>([
  [
    ProductType.HIRO,
    new Set(
      // we do not have premarket hiro for this symbol, but we have premarket prices, which we only use in TRACE
      ALL_PREMARKET_SYMBOLS.filter((s) => s !== ComboSymbol.SPX_EQUITIES),
    ),
  ],
  [ProductType.TRACE, new Set(ALL_PREMARKET_SYMBOLS)],
]);

export const COMBO_SYMS_TO_INDEX_SYMBOL = {
  [ComboSymbol.SPX as string]: 'SPX',
  [ComboSymbol.RUT as string]: 'RUT',
  [ComboSymbol.NDX as string]: 'NDX',
  [ComboSymbol.SPX_EQUITIES as string]: 'SPX',
};

export const HIRO_UPSELL = {
  title: 'Unlock Unlimited HIRO Access',
  subtitle: "With a SpotGamma Alpha subscription, you'll get:",
  upgrade_subtitle: "Upgrade to a SpotGamma Alpha subscription, you'll get:",
  items: [
    'Real-time charts incorporating all options trades',
    'Personalized watchlist and trending names',
    'Highly actionable alerts for options and equity traders',
  ],
};

export const DefaultChartSettings: HiroChartSettings = {
  showExtendedHours: true,
  showChartTooltip: false,
  showAlerts: true,
  sumWindow: VAL2MARK['7'].seconds,
  selectedLenses: {
    all: 1,
    nextExp: 0,
    retail: 0,
  },
  optionType: 'total',
  showSpotgammaLevels: true,
  usePolling: false,
  bottomCandlesType: BottomCandlesType.ABSOLUTE_DELTA,
  candleDurationSecs: 5,
};

export const DEFAULT_HIRO_SYMBOL = ComboSymbol.SPX;

export const ALL_LENSES = Object.keys(LENSE_LABELS);

export const LENSE_DISPLAY_TYPES = ['TOT', 'C', 'P'];

export enum HiroTab {
  StockScreener = 'stock_screener',
  FlowData = 'flow_data',
  ContractData = 'contract_data',
}

export const HIRO_TAB_LABEL_MAPPING = new Map([
  [HiroTab.FlowData, 'Flow Data'],
  [HiroTab.ContractData, 'Contracts Data'],
  [HiroTab.StockScreener, 'Stock Screener'],
]);

export const HIRO_TABS: Set<string> = new Set(
  Object.values(HiroTab).map((s) => s as string),
);

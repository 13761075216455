import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Notification, NotificationCateogry } from 'types/notifications';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridColumnHeaderParams,
  gridDateComparator,
  GridEventListener,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridSlots,
  gridStringOrNumberComparator,
  GridToolbarContainer,
  NoRowsOverlayPropsOverrides,
  useGridApiContext,
} from '@spotgamma/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  dayjs,
  defaultGridTableSlots,
  getDefaultGridTableSlotProps,
  getDefaultGridTableStyles,
  nullsToEndComparator,
  valOrNa,
} from 'util/shared';
import EmptyNotificationsGridOverlay from './EmptyNotificationsGridOverlay';
import { useLog } from 'hooks';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentToastState, isMobileState, timezoneState } from 'states';
import SegmentRoundedIcon from '@mui/icons-material/SegmentRounded';
import {
  getNotification,
  REVERSE_NOTIFICATION_CATEGORY_MAP,
} from 'util/notifications';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { Dayjs } from 'dayjs';
import MDEditor from '@uiw/react-md-editor';
import UserNotificationsDropdown from './UserNotificationsDropdown';

const isKeyboardEvent = (event: any): event is React.KeyboardEvent => {
  return !!event.key;
};

interface EditToolbarProps {
  onAdd: () => void;
}

const EditToolbar = (props: EditToolbarProps) => {
  const { onAdd } = props;

  return (
    <GridToolbarContainer sx={{ padding: '5px' }}>
      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          textTransform: 'none',
        }}
      >
        Add notification
      </Button>
      <UserNotificationsDropdown placement="bottom-start">
        <Button
          startIcon={<SegmentRoundedIcon />}
          sx={{
            textTransform: 'none',
          }}
        >
          View User Notifications
        </Button>
      </UserNotificationsDropdown>
    </GridToolbarContainer>
  );
};

const CustomEditDateComponent = (props: GridRenderEditCellParams) => {
  const theme = useTheme();
  const currentTimezone = useRecoilValue(timezoneState);
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = useRef<any>();

  useEnhancedEffect(() => {
    if (hasFocus) {
      ref?.current.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (value: Dayjs | null) => {
    const newValue = dayjs.utc(value).toDate(); // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        defaultValue={value ? dayjs(value) : null}
        timezone={currentTimezone}
        ref={ref}
        onChange={handleValueChange}
        format="YYYY-MM-DD, hh:mm:ss a"
        slotProps={{
          rightArrowIcon: {
            sx: {
              color: theme.palette.text.primary,
            },
          },
          leftArrowIcon: {
            sx: {
              color: theme.palette.text.primary,
            },
          },
          switchViewIcon: {
            sx: {
              color: theme.palette.text.primary,
            },
          },
          textField: {
            style: {
              width: '100%',
              fontSize: '14px',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

const CustomEditMessageComponent = (props: GridRenderEditCellParams) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const apiRef = useGridApiContext();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { id, value: valueProp, field } = props;
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleValueChange = (value?: string) => {
    setValue(value);
    apiRef.current.setEditCellValue({ id, field, value, debounceMs: 200 });
  };

  const handleClose = () => setOpen(false);

  return (
    <Stack width="100%" height="100%" alignItems="center">
      <Button
        sx={{ textTransform: 'none', width: '100%', height: '100%' }}
        onClick={() => setOpen(true)}
      >
        Open Editor
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontSize: {
                  xs: 14,
                  md: 16,
                },
              }}
            >
              Edit Notification Message
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box>
            <MDEditor
              value={value}
              onChange={handleValueChange}
              textareaProps={{
                placeholder: 'Please enter Markdown text',
              }}
            />
            <Typography
              sx={{ fontSize: 12, color: theme.palette.text.secondary }}
            >
              Changes are automatically applied but not stored until you hit the
              save button from Actions.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

interface RowNotification extends Notification {
  isNew?: boolean;
}

export const NotificationsTable = () => {
  const theme = useTheme();
  const setToast = useSetRecoilState(currentToastState);
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<RowNotification[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<RowNotification | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const { fetchAPIWithLog } = useLog('admin-notifications');

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const data = await fetchAPIWithLog(`admin/notifications`);
      setRows(data.map(getNotification) as RowNotification[]);
    } catch (err) {
      setToast({
        message:
          'Something went wrong while fetching notifications. Try again!',
        type: 'error',
      });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (
      (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) ||
      params.reason === GridRowEditStopReasons.rowFocusOut
    ) {
      event.defaultMuiPrevented = true;
    } else if (params.reason !== GridRowEditStopReasons.enterKeyDown) {
      return;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const openDeleteConfirm = () => setDeleteConfirmOpen(true);
  const closeDeleteConfirm = () => setDeleteConfirmOpen(false);

  const handleDeleteClick = (id: GridRowId) => () => {
    const row = rows.find((r) => r.id === id)!;

    setRowToDelete(row);
    openDeleteConfirm();
  };

  const handleDeleteConfirm = async () => {
    if (rowToDelete) {
      setDeleteLoading(true);
      const response = await fetchAPIWithLog(`admin/notification`, {
        method: 'DELETE',
        body: JSON.stringify({
          ids: [rowToDelete?.id],
        }),
      });
      setDeleteLoading(false);
      if (response.error) {
        console.error(response.error);
        setToast({
          message: 'Failed to delete this row. Try again!',
          type: 'error',
        });
      } else {
        setRows(rows.filter((row) => row.id !== rowToDelete?.id));
        setToast({
          message: 'Successfully deleted!',
          type: 'success',
        });
      }
    }

    setRowToDelete(null);
    closeDeleteConfirm();
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const nowString = dayjs().tz(currentTimezone).toISOString();

  const handleAddClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        notificationId: 0, // temporary id to satisfy typescript, not used to pass into db
        title: '',
        message: '',
        category: NotificationCateogry.General,
        timeCreated: nowString,
        timeUpdated: nowString,
        timeToPush: nowString,
        isPushed: false,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'title' },
    }));
  };

  const handleProcessRowUpdateError = useCallback((error: string) => {
    console.error(error);
    setToast({ message: error, type: 'error' });
  }, []);

  const processRowUpdate = useCallback(
    async (
      newRow: GridRowModel,
      _oldRow: GridRowModel,
    ): Promise<RowNotification> => {
      setLoading(true);
      let rowNotification = { ...newRow, isNew: false } as RowNotification;

      // handle notification api call
      const response = await fetchAPIWithLog(`admin/notification`, {
        method: newRow.isNew ? 'PUT' : 'PATCH',
        body: JSON.stringify({
          id: newRow.id,
          title: newRow.title,
          message: newRow.message,
          category: parseInt(
            REVERSE_NOTIFICATION_CATEGORY_MAP.get(newRow.category) as string,
          ),
          timeToPush: newRow.timeToPush.toISOString(),
          expiry: newRow.expiry ? newRow.expiry.toISOString() : null,
        }),
      });

      if (response.error) {
        console.error(response.error);
        setToast({
          message: `Failed to ${
            newRow.isNew ? 'create' : 'update'
          } the row. Try again!`,
          type: 'error',
        });
      } else {
        const rowData = response.data && getNotification(response.data);
        rowNotification = { ...rowData, isNew: false } as RowNotification;
        setRows((prevRows) =>
          prevRows.map((row) => (row.id === newRow.id ? rowNotification : row)),
        );
        setToast({
          message: `Successfully ${
            newRow.isNew ? 'created' : 'updated'
          } the row!`,
          type: 'success',
        });
      }

      setLoading(false);
      return rowNotification;
    },
    [],
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getColHeaderStyles = useCallback(
    (_params: GridColumnHeaderParams) => ({
      fontSize: isMobile ? 12 : 14,
      color: theme.palette.sgGreen,
      textTransform: 'capitalize',
      textAlign: 'right',
      whiteSpace: 'normal',
      lineHeight: 'normal',
    }),
    [isMobile, theme.palette.sgGreen],
  );

  const renderMessageEditInputCell: GridColDef['renderCell'] = (params) => {
    return <CustomEditMessageComponent {...params} />;
  };

  const renderDateEditInputCell: GridColDef['renderCell'] = (params) => {
    return <CustomEditDateComponent {...params} />;
  };

  const renderDateCell = (params: GridRenderCellParams) => {
    if (params?.value == null) {
      return null;
    }
    const date = dayjs(params.value).utc();
    return (
      <Typography
        sx={{
          whiteSpace: 'normal',
          fontSize: {
            xs: 12,
            md: 14,
          },
        }}
      >
        {valOrNa(date != null && getTimeFormatted(date, currentTimezone))}
      </Typography>
    );
  };

  const renderRegularCell = (params: GridRenderCellParams) => {
    return (
      <Typography
        sx={{
          fontSize: {
            xs: 12,
            md: 14,
          },
        }}
      >
        {valOrNa(params.value)}
      </Typography>
    );
  };

  const getTimeFormatted = (date: dayjs.Dayjs, currentTimezone: string) =>
    date.tz(currentTimezone).format('YYYY-MM-DD, hh:mm:ss a z');

  const columns: GridColDef[] = [
    {
      headerName: 'Title',
      field: 'title',
      editable: true,
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      flex: 1,
      type: 'string',
      getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
      renderCell: renderRegularCell,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>Title</Typography>
      ),
    },
    {
      headerName: 'Category',
      field: 'category',
      editable: true,
      headerClassName: 'grid-header-cell',
      minWidth: 120,
      flex: 1,
      type: 'singleSelect',
      valueOptions: Object.values(NotificationCateogry),
      getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
      renderCell: renderRegularCell,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>Category</Typography>
      ),
    },
    {
      headerName: 'Message',
      field: 'message',
      editable: true,
      headerClassName: 'grid-header-cell',
      minWidth: 200,
      flex: 1,
      type: 'string',
      getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
      renderEditCell: renderMessageEditInputCell,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <MDEditor.Markdown
              source={valOrNa(params?.value)}
              style={{
                fontSize: isMobile ? 12 : 14,
                background: 'transparent',
              }}
            />
          </Box>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>Message</Typography>
      ),
    },
    {
      headerName: 'Created',
      field: 'timeCreated',
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      flex: 1,
      type: 'dateTime',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: string) => {
        return value ? dayjs.utc(value).toDate() : null;
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = dayjs(params?.value).utc();
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              fontSize: {
                xs: 12,
                md: 14,
              },
            }}
          >
            {valOrNa(date != null && getTimeFormatted(date, currentTimezone))}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>Created</Typography>
      ),
    },
    {
      headerName: 'Last Updated',
      field: 'timeUpdated',
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      flex: 1,
      type: 'dateTime',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: string) => {
        return value ? dayjs.utc(value).toDate() : null;
      },
      renderCell: renderDateCell,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>
          Last Updated
        </Typography>
      ),
    },
    {
      headerName: 'Push Time',
      field: 'timeToPush',
      editable: true,
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      flex: 1,
      type: 'dateTime',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: string) => {
        return value ? dayjs.utc(value).toDate() : null;
      },
      renderCell: renderDateCell,
      renderEditCell: renderDateEditInputCell,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>
          Push Time
        </Typography>
      ),
    },
    {
      headerName: 'Expires',
      field: 'expiry',
      editable: true,
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      flex: 1,
      type: 'dateTime',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: string) => {
        return value ? dayjs.utc(value).toDate() : null;
      },
      renderCell: renderDateCell,
      renderEditCell: renderDateEditInputCell,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>Expires</Typography>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerClassName: 'grid-header-cell',
      width: 100,
      cellClassName: 'actions',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={{ ...getColHeaderStyles(params) }}>Actions</Typography>
      ),
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ] as GridColDef[];

  return (
    <>
      <Paper
        style={{
          height: '100%',
          minHeight: 450,
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <DataGridPremium
          rows={rows}
          columns={columns}
          loading={loading}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          hideFooter
          rowHeight={75}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          disableColumnFilter
          disableChildrenFiltering
          disableAggregation
          disableRowGrouping
          slots={{
            ...defaultGridTableSlots,
            noRowsOverlay:
              EmptyNotificationsGridOverlay as GridSlots['noRowsOverlay'],
            toolbar: EditToolbar as GridSlots['toolbar'],
          }}
          slotProps={{
            ...getDefaultGridTableSlotProps(theme),
            toolbar: { setRows, setRowModesModel, onAdd: handleAddClick },
            noRowsOverlay: {
              onAdd: handleAddClick,
            } as NoRowsOverlayPropsOverrides,
          }}
          sx={{
            ...getDefaultGridTableStyles(theme),
            '& .MuiInputAdornment-root': {
              svg: {
                color: theme.palette.text.primary,
              },
            },
            width: '100%',
            maxWidth: '100%',
            minHeight: 450,
            backgroundColor: 'paper',
          }}
        />
      </Paper>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
        maxWidth="xs"
        open={deleteConfirmOpen}
        onClose={closeDeleteConfirm}
        fullScreen={isMobile}
      >
        <DialogTitle>Delete Notification</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Are you sure you want to delete{' '}
            {rowToDelete?.title
              ? `the notification with title ${rowToDelete.title}`
              : 'this notification'}
            ? This action is irreversible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirm}>Cancel</Button>
          <Button autoFocus color="error" onClick={handleDeleteConfirm}>
            {deleteLoading ? <CircularProgress /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Stack, Typography, Divider, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { MatrixTooltipInfo } from 'types';

interface Props {
  expiryDate: number;
  strike: string;
  data: MatrixTooltipInfo[];
}

const CellInfoContainer = ({ expiryDate, strike, data }: Props) => {
  const theme = useTheme();

  return (
    <Stack spacing={3} padding={1} width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Typography
          sx={{
            fontSize: 11,
          }}
        >
          Expiration Date
        </Typography>
        <Typography
          sx={{
            fontSize: 11,
          }}
        >
          {dayjs(expiryDate).format('YYYY-MM-DD')}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Typography
          sx={{
            fontSize: 11,
          }}
        >
          Strike
        </Typography>
        <Typography
          sx={{
            fontSize: 11,
          }}
        >
          {strike}
        </Typography>
      </Stack>

      <Divider
        flexItem
        sx={{
          marginTop: 2,
          borderColor: theme.palette.primary.main,
        }}
      />

      {data &&
        data.length > 0 &&
        data.map((info: MatrixTooltipInfo) => (
          <Stack direction="row" justifyContent="space-between">
            <Typography
              sx={{
                fontSize: 11,
                color: theme.palette.sgGreen,
              }}
            >
              {info.label}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 'bold',
                ...info.style,
              }}
            >
              {info.value}
            </Typography>
          </Stack>
        ))}
    </Stack>
  );
};

export default CellInfoContainer;

import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { appRefreshCounterState } from 'states';
import { isBloomberg } from '../util';

// bloomberg cannot use window.location.reload as it will crash the app
// so mimic that behavior for bloomberg by setting a key in App.tsx
// and incrementing it here to manually cause React to refresh the app
const useRefresh = () => {
  const [appRefreshCounter, setAppRefreshCounter] = useRecoilState(
    appRefreshCounterState,
  );

  const triggerRefresh = useCallback(() => {
    if (!isBloomberg()) {
      return window.location.reload();
    }
    setAppRefreshCounter(appRefreshCounter + 1);
  }, [appRefreshCounter]);

  return { triggerRefresh };
};

export default useRefresh;

import { Box, Typography } from '@mui/material';
import { Loader } from './Loader';

export const ErrorContent = ({
  loading = false,
  content,
}: {
  loading?: boolean;
  content?: string;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        verticalAlign: 'center',
        alignContent: 'center',
      }}
    >
      <Loader isLoading={loading}>
        <Typography variant="h2" gutterBottom color="text.primary">
          {content ? content : 'Failed to get data.'}
        </Typography>
      </Loader>
    </Box>
  );
};

import { useCallback } from 'react';
import useLog from '../useLog';

const useOCC = () => {
  const { fetchAPIWithLog } = useLog('useOCC');
  const getOCC = useCallback(async () => {
    return await fetchAPIWithLog(`v2/occ`);
  }, []);

  return { getOCC };
};

export default useOCC;

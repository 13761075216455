import {
  IconButton,
  Popover,
  Box,
  alpha,
  Stack,
  Typography,
  List,
  ListItemButton,
  CircularProgress,
  Checkbox,
  Divider,
  Button,
  useTheme,
} from '@mui/material';
import { GridRenderCellParams } from '@spotgamma/x-data-grid-premium';
import useWatchlists from 'hooks/useWatchlists';
import { useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { isMobileState, watchlistsState, currentToastState } from 'states';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Watchlist } from 'types';

const RowWatchlistBtn = (params: GridRenderCellParams) => {
  const theme = useTheme();
  const rowData = params.row;
  const isMobile = useRecoilValue(isMobileState);
  const [watchlists, setWatchlists] = useRecoilState(watchlistsState);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const setToast = useSetRecoilState(currentToastState);

  const { saveWatchlists } = useWatchlists();

  const [loadingWatchlists, setLoadingWatchlists] = useState<{
    [key: number]: boolean;
  }>({});

  const handleRowWatchlistClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${rowData.id}-row-watchlist-popover` : undefined;

  const handleToggleRowWatchlists = async (
    watchlistIds: number[],
    symbol: string,
    checked: boolean,
  ) => {
    const ids = new Set(watchlistIds);
    const newWatchlists =
      watchlists?.map((w) => {
        if (!ids.has(w.id as number)) {
          return w;
        }
        const symbols = checked
          ? w.symbols.filter((s) => s !== symbol)
          : Array.from(new Set([...w.symbols, symbol]));
        return { ...w, symbols };
      }) ?? [];

    try {
      setLoadingWatchlists((prev) =>
        watchlistIds.reduce((acc, id) => ({ ...acc, [id]: true }), prev),
      );
      const response = await saveWatchlists(newWatchlists);
      if (Array.isArray(response.watchlists)) {
        setWatchlists(response.watchlists);
      }
    } catch (err) {
      setToast({
        message:
          'Something went wrong while fetching equities price data. Try again or contact us if the issue persists.',
        type: 'error',
        duration: 10000,
      });
    } finally {
      setLoadingWatchlists((prev) =>
        watchlistIds.reduce((acc, id) => ({ ...acc, [id]: false }), prev),
      );
    }
  };

  return (
    <>
      <IconButton
        aria-describedby={rowData.id}
        onClick={handleRowWatchlistClick}
      >
        {watchlists?.some((w: Watchlist) => w.symbols.includes(rowData.sym)) ? (
          <VisibilityIcon sx={{ color: theme.palette.text.primary }} />
        ) : (
          <VisibilityOutlinedIcon
            sx={{ color: alpha(theme.palette.text.primary, 0.5) }}
          />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={{
          zIndex: 1000,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            ...(isMobile ? { maxWidth: '100%' } : { maxWidth: '500px' }),
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            boxShadow: `0px 0px 4px ${theme.palette.background.default}`,
            borderRadius: theme.spacing(3),
            border: `2px solid ${alpha(theme.palette.text.primary, 0.15)}`,
            minWidth: 280,
            maxWidth: isMobile ? 280 : 380,
          }}
        >
          <Stack sx={{ paddingLeft: '9px' }}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontSize: 13,
                color: theme.palette.primary.main,
              }}
            >
              Add or remove from watchlists
            </Typography>
          </Stack>

          <List>
            {watchlists && watchlists.length > 0 ? (
              watchlists.map((watchlist) => {
                const checked = watchlist.symbols.includes(rowData.sym);
                const isLoading = loadingWatchlists[watchlist.id as number];

                return (
                  <ListItemButton
                    key={watchlist.id}
                    dense
                    onClick={() =>
                      handleToggleRowWatchlists(
                        [watchlist.id as number],
                        rowData.sym,
                        checked,
                      )
                    }
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Checkbox edge="start" checked={checked} />
                        <Typography>{watchlist.name}</Typography>
                      </>
                    )}
                  </ListItemButton>
                );
              })
            ) : (
              <Stack direction="row" alignItems="center">
                <SentimentDissatisfiedIcon
                  sx={{
                    color: theme.palette.text.primary,
                    fontSize: 18,
                  }}
                />
                <Typography sx={{ paddingLeft: '9px' }}>
                  No watchlists found.
                </Typography>
              </Stack>
            )}
          </List>

          <Divider
            sx={{ borderColor: theme.palette.primary.main, marginBottom: 3 }}
          />

          <Stack direction="row" gap={2} sx={{ width: '100%' }}>
            <Button
              startIcon={<RemoveIcon />}
              sx={{
                textTransform: 'none',
                fontSize: {
                  xs: 11,
                  sm: 12,
                },
                width: '50%',
              }}
              onClick={() =>
                handleToggleRowWatchlists(
                  watchlists?.map((w) => w.id as number) ?? [],
                  rowData.sym,
                  true,
                )
              }
            >
              Remove from all
            </Button>

            <Button
              startIcon={<AddIcon />}
              sx={{
                textTransform: 'none',
                fontSize: {
                  xs: 11,
                  sm: 12,
                },
                width: '50%',
              }}
              onClick={() =>
                handleToggleRowWatchlists(
                  watchlists?.map((w) => w.id as number) ?? [],
                  rowData.sym,
                  false,
                )
              }
            >
              Add to all
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default RowWatchlistBtn;

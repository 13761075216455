import {
  DarkPoolSectorAverage,
  OCCCustomerOptionType,
  RawDarkPoolIndicator,
} from '../types/sentiment';
import { getAvg } from './shared';

export const generateDarkPool = (data: RawDarkPoolIndicator[]) => {
  let sector_dpis: Record<string, number[]> = {};
  let sector_avg_dpis: Record<string, number[]> = {};

  data.forEach((d) => {
    const dpis = d.dpi ? d.dpi.split(',').map(Number) : null;
    const five_day_length = dpis ? Math.min(dpis.length - 1, 5) : 0;
    const five_dpis = dpis ? dpis.slice(0, five_day_length) : [];
    const avg_dpi = five_dpis ? getAvg(five_dpis) : undefined;
    const dpi = dpis ? dpis[0] : undefined;
    const sector = d.sect ? d.sect.split(',')[0] : 'n/a';

    if (sector in sector_avg_dpis) {
      avg_dpi && sector_avg_dpis[sector].push(getAvg(five_dpis));
      dpi && sector_dpis[sector].push(dpi);
    } else if (five_dpis && dpi) {
      sector_avg_dpis[sector] = [getAvg(five_dpis)];
      sector_dpis[sector] = [dpi];
    }
  });

  const totalSectorAvgDpi: Map<string, number> = new Map(
    Object.entries(sector_avg_dpis).map(([sector, values]) => [
      sector,
      getAvg(values),
    ]),
  );

  const totalSectorDpi: Map<string, number> = new Map(
    Object.entries(sector_dpis).map(([sector, values]) => [
      sector,
      getAvg(values),
    ]),
  );

  return { totalSectorDpi, totalSectorAvgDpi };
};

export const filterOutType = (d: DarkPoolSectorAverage) => {
  return (
    Object.entries(d)
      .filter(([field, _val]) => field !== 'type')
      .map(([_, value]) => value) as number[]
  ).filter((v) => !isNaN(v));
};

export const occCategoryIsPremium = (category: OCCCustomerOptionType) =>
  category.includes('premium');

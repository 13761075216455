import { isMobileState } from '../../states';
import { Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { forwardRef } from 'react';

type FoundersNoteWrapperProps = {
  children: React.ReactNode;
  theme?: Theme;
};

export const FoundersNoteWrapper = forwardRef(
  ({ children, theme }: FoundersNoteWrapperProps, ref) => {
    const siteTheme = useTheme();
    theme = theme ?? siteTheme;
    const isMobile = useRecoilValue(isMobileState);

    return (
      <Box
        sx={{
          flexGrow: '2',
          minHeight: '100%',
          overflow: 'auto',
          padding: isMobile ? '8px' : '25px',
          color: `${theme.palette.text.primary} !important`,
          backgroundColor: `${theme.palette.background.default} !important`,
        }}
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);

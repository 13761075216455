import { Box, Typography } from '@mui/material';
import { Loader } from 'components/shared';
import { SGHtmlContent } from 'components/shared/SGHtmlContent';
import useHomeContent from 'hooks/home/useHomeContent';
import { useEffect, useState } from 'react';

const AtSpotgamma = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [welcomeMsgData, setWelcomeMsgData] = useState<any>();

  const { getAllData } = useHomeContent();

  const fetchData = async () => {
    try {
      setLoading(true);
      setErrored(false);
      const rawData = await getAllData();
      setWelcomeMsgData(rawData?.welcomeMessage);
    } catch (err) {
      setErrored(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Loader isLoading={loading}>
      {errored ? (
        <Typography
          sx={{
            fontSize: '16px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={fetchData}
        >
          There was a network error. Click here to retry.
        </Typography>
      ) : (
        <Box>
          <SGHtmlContent
            html={welcomeMsgData?.html}
            sx={{
              width: '100%',
              paddingRight: '10px',
              paddingLeft: 0,
              flex: 1,
              overflow: 'auto',
            }}
          />
        </Box>
      )}
    </Loader>
  );
};

export default AtSpotgamma;

import {
  HiroOverview,
  HiroTimeseries,
  HiroUpdatingType,
  HiroLense,
  PriceData,
  PriceLineData,
  PriceLineKey,
  RawHiroAndPrice,
  RawSpotgammaLevels,
  RawTrending,
  SigHighLowData,
  ProductType,
  FetchAuxOptions,
} from '../types';
import { merge } from 'lodash';
import {
  calcOffsetMS,
  ET,
  fetchAPI,
  getDateFormatted,
  getQueryDateFormatted,
  prevBusinessDayOpenMarket,
} from './shared';
import dayjs from 'dayjs';
import {
  ALL_PREMARKET_SYMBOLS,
  ComboSymbol,
  PREMARKET_SYMBOLS_PER_PRODUCT,
} from '../config';
import { getTzOffsetMsCached } from './streaming';

export const hasValue = (v: RawHiroAndPrice[] | undefined | null) =>
  v!.length > 0;

export const groupTrendingData = (
  data: RawTrending,
  stocks: Map<string, HiroOverview>,
) => {
  return Object.entries(data ?? {})
    .filter(([_inst, val]) => val.all?.length > 0)
    .map(([instrument, { all }]) => {
      let sum = 0;
      let cumulativeSignals = all.map((s) => {
        sum += s.mid_signal;
        return sum;
      });
      const lastIdx = all.length - 1;

      return {
        currentDaySignal: stocks.get(instrument)?.signal || 0,
        instrument,
        price: all[lastIdx].stock_price,
        hiro_timeseries: cumulativeSignals,
      };
    });
};

export const initHiroData = () => {
  return Object.values(HiroLense).reduce(
    (agg, l) => ({ ...agg, [l]: { TOT: [], P: [], C: [] } }),
    {},
  ) as HiroTimeseries;
};

const DEFAULT_PARAMS = {
  all: '1',
  nextExp: '1',
  retail: '1',
};

export const getHiroUrlParams = (opts: any, start?: string, end?: string) => {
  const params = new URLSearchParams(merge({ ...DEFAULT_PARAMS }, opts));
  if (start != null) {
    params.append('start', start);
  }
  if (end != null) {
    params.append('end', end);
  }
  return params;
};

export const getSelectedLenses = (selectedLenseMap: {
  [value in HiroLense]: number;
}) => {
  return Object.entries(selectedLenseMap)
    .filter(([k, v]) => !!v)
    .map(([k, v]) => k);
};

// Preferable to calling setData on the TV Series.
export function update(chartRef: any, latest: any[]) {
  if (chartRef == null || latest.length === 0) {
    return;
  }
  for (const update of latest) {
    chartRef.update(update);
  }
}

export function rollingSums(
  entries: any[],
  duration: any,
  type: HiroUpdatingType,
) {
  let sum = 0;
  const newEntries = [];
  let i = 0; // start
  let j = 0; //end
  let lastSum = 0;
  const getValueForEntry = (entry: any) => {
    return type === HiroUpdatingType.POLLING ? entry.value : entry.close;
  };

  // NOTE: These initial entries are "lies".  They aren't "rolling" until we
  // have enough data.
  for (
    ;
    j < entries.length && entries[j].time - entries[i].time < duration;
    ++j
  ) {
    const entry = entries[j];
    sum += getValueForEntry(entry);
    if (type === HiroUpdatingType.POLLING) {
      newEntries.push({ ...entry, value: sum });
    } else {
      newEntries.push({
        ...entry,
        open: lastSum,
        high: entry.high + lastSum,
        low: entry.low + lastSum,
        close: sum,
      });
      lastSum = sum;
    }
  }

  for (; j < entries.length; ++j) {
    while (i < j && entries[j].time - entries[i].time >= duration) {
      sum -= getValueForEntry(entries[i++]);
      lastSum = sum;
    }
    sum += getValueForEntry(entries[j]);
    if (type === HiroUpdatingType.POLLING) {
      newEntries.push({ ...entries[j], value: sum });
    } else {
      newEntries.push({
        ...entries[j],
        open: lastSum,
        high: entries[j].high + lastSum,
        low: entries[j].low + lastSum,
        close: sum,
      });
      lastSum = sum;
    }
  }
  return newEntries;
}

export const getPriceLines = (
  sgData: RawSpotgammaLevels | undefined,
  theme: any,
  sigData?: SigHighLowData,
): { [K in PriceLineKey]: PriceLineData } => {
  return {
    'Last Closing': {
      value: sgData?.upx,
      color: theme.palette.hiro.priceLines.upx,
    },
    'Hedge Wall': {
      value: sgData?.maxfs,
      color: theme.palette.hiro.priceLines.maxfs,
    },
    'Key Gamma Strike': {
      value: sgData?.keyg,
      color: theme.palette.hiro.priceLines.keyg,
    },
    'Key Delta Strike': {
      value: sgData?.keyd,
      color: theme.palette.hiro.priceLines.keyd,
    },
    'Call Wall': {
      value: sgData?.cws,
      color: theme.palette.hiro.priceLines.cws,
    },
    'Put Wall': {
      value: sgData?.pws,
      color: theme.palette.hiro.priceLines.pws,
    },
    ...(sigData != null
      ? {
          'SG Implied 1d Move High': {
            value: sigData.sig_high,
            color: theme.palette.hiro.priceLines.sig_high,
          },
          'SG Implied 1d Move Low': {
            value: sigData.sig_low,
            color: theme.palette.hiro.priceLines.sig_low,
          },
        }
      : {}),
  };
};

export const getSgData = async (
  endDate: dayjs.Dayjs,
  sym: string,
  auxiliaryOptions?: FetchAuxOptions,
) => {
  const syms = encodeURIComponent(sym);
  // Levels are stored early in the AM and refer to yesterday as the trade
  // date. i.e. Tuesday's levels are from Monday's `trade_date`
  const levelsDate = getDateFormatted(dayjs(endDate).tz(ET).subtract(1, 'day'));
  const data = await fetchAPI(
    `v3/equitiesBySyms?syms=${syms}&date=${levelsDate}`,
    auxiliaryOptions,
  );

  return data[sym];
};

export const toEntries = (data: any, key: any, timezone: any) => {
  const utcOffset =
    data.length > 0 ? calcOffsetMS(timezone, data[0].utc_time) : 0;

  return data.map((d: any) => toEntry(d, key, utcOffset));
};

export const toEntry = (data: any, key: any, utcOffset: any) => ({
  time: (data.utc_time + utcOffset) / 1000,
  value: data[key],
});

export function toKeyedEntry(k: any, utcOffset: any) {
  return (data: any) => toEntry(data, k, utcOffset);
}

export function pruneLatest(
  data: any,
  prev: any,
  latest: any,
  timeKey: string = 'utc_time',
) {
  return dedupe(prev.length === 0 ? data : prev, latest, timeKey);
}

export function dedupe(prev: any, latest: any, timeKey: string = 'utc_time') {
  // It's possible we have "duplicate" data points when fetching our latest batch.  Deduplicate them if so
  const last = prev[prev.length - 1][timeKey];
  let i = 0;
  while (i < latest.length && latest[i][timeKey] <= last) {
    ++i;
  }
  return latest.slice(i);
}

export const getLast = (data: any) => data.slice(data.length - 1);

export const levelsDateUpdated = (levelsDate: string | undefined) => {
  // use ET here because getQueryDate() uses ET by default
  // shouldnt matter what timezone we use as long as they are consistent
  return (
    levelsDate != null &&
    dayjs
      .tz(levelsDate, ET)
      .isBetween(
        prevBusinessDayOpenMarket(dayjs(getQueryDateFormatted(true))),
        dayjs(),
        'day',
        '[]',
      )
  );
};

const SPX_SYMS = new Set(['SPX', ComboSymbol.SPX]);
const ES_SYMS = new Set([ComboSymbol.ES_F, '/ES']);
const isSpxSym = (s: string) => SPX_SYMS.has(s) || ES_SYMS.has(s);

export function sigHighLow(
  sgData: any,
  todaysOpenArr: PriceData[] | undefined,
  tradeDate: string = getQueryDateFormatted(true),
): SigHighLowData | undefined {
  const sig = sgData?.sig;
  if (sig == null) {
    return undefined;
  }

  const symTodaysOpen = todaysOpenArr?.find(
    (o) =>
      o.date === tradeDate &&
      (o.sym === sgData.sym || (isSpxSym(sgData.sym) && isSpxSym(o.sym))),
  );
  if (symTodaysOpen == null) {
    return undefined;
  }

  const futuresDiffToAdd =
    ES_SYMS.has(sgData.sym) && sgData.futuresDiff != null
      ? sgData.futuresDiff
      : 0;

  return {
    sig_high: (1 + sig) * symTodaysOpen.price + futuresDiffToAdd,
    sig_low: (1 - sig) * symTodaysOpen.price + futuresDiffToAdd,
  };
}

export const utcMsToTzSecs = (ms: dayjs.Dayjs | number, tz: string) => {
  const offset = getTzOffsetMsCached(ms, tz);
  return Math.floor((Number(ms) + offset) / 1000);
};

export const getPremarketSymbols = (
  productType: ProductType | null | undefined,
) => {
  if (productType == null) {
    return new Set(ALL_PREMARKET_SYMBOLS);
  }

  return (
    PREMARKET_SYMBOLS_PER_PRODUCT.get(productType) ??
    new Set(ALL_PREMARKET_SYMBOLS)
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { alpha, Theme, useTheme } from '@mui/material/styles';
import { isMobileState, sgTooltipsState } from '../../../states';
import { useRecoilValue } from 'recoil';
import { renderTooltipsInText } from '../FoundersNote';
import { Stack, Typography } from '@mui/material';

type FoundersNoteTableProps = {
  rows: string[][];
  rowHeaders: string[];
  printTheme?: Theme;
  updatedAt?: string;
};

export const FoundersNoteTable = ({
  rows,
  rowHeaders,
  printTheme,
  updatedAt,
}: FoundersNoteTableProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const defaultTheme = useTheme();
  const theme = printTheme ?? defaultTheme;
  const sgTooltips = useRecoilValue(sgTooltipsState);
  const tooltipOverrides: Map<string, string> = new Map([
    // do not include : or TM
    ['SG Volatility Trigger', 'Volatility Trigger'],
    ['25 Delta Risk Reversal', '25D Risk Reversal'],
    ['Gamma Notional (MM)', 'Gamma Notional'],
    ['SG Call Wall', 'Call Wall'],
    ['SG Put Wall', 'Put Wall'],
  ]);

  const headerBgColor = alpha(theme.palette.primary.main, 0.25);
  const dividerColor = alpha(theme.palette.primary.main, 0.35);
  const firstLastCellSx = {};
  const cellSx = { padding: '0px 5px' };

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: `1px solid ${dividerColor}`,
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: headerBgColor,
              '& > th': {
                borderBottom: `1px solid ${dividerColor}`,
              },
            }}
          >
            {rowHeaders.map((header, index) => {
              return (
                <TableCell
                  key={`founders-note-table-${header}-${index}`}
                  sx={
                    index === 0 || index === rowHeaders.length - 1
                      ? firstLastCellSx
                      : cellSx
                  }
                  align={index > 0 ? 'right' : undefined}
                >
                  <Stack
                    gap={1}
                    direction={isMobile ? 'column' : 'row'}
                    alignItems="baseline"
                    sx={{
                      minWidth: '65px',
                      justifyContent: index > 0 ? 'flex-end' : 'normal',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'inline',
                        fontSize: '0.85rem',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {header}
                      {updatedAt && index === 0 && (
                        <span
                          style={{
                            color: theme.palette.text.secondary,
                            fontSize: 11,
                          }}
                        >
                          {' '}
                          {updatedAt}
                        </span>
                      )}
                    </Typography>
                  </Stack>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '& > td': {
                    borderBottom: `1px solid ${dividerColor}`,
                  },
                }}
              >
                {row.map((label, index) => {
                  const labelWithoutColon = label
                    .replaceAll(':', '')
                    .replaceAll('™', '');
                  const labelComponent = (
                    <Typography
                      style={{
                        display: 'inline',
                        fontSize: '0.85rem',
                        color: theme.palette.text.primary,
                      }}
                    >
                      {label}
                    </Typography>
                  );
                  return (
                    <TableCell
                      align={index > 0 ? 'right' : undefined}
                      sx={
                        index === 0 || index === row.length - 1
                          ? firstLastCellSx
                          : cellSx
                      }
                      key={index}
                    >
                      {index === 0
                        ? renderTooltipsInText(
                            tooltipOverrides.get(labelWithoutColon) ??
                              labelWithoutColon,
                            sgTooltips,
                            {},
                            {
                              componentToDisplay: labelComponent,
                              uniformStyling: true,
                            },
                          )
                        : labelComponent}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

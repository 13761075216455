import { useCallback } from 'react';
import { DiscordAuth, DiscordGuild, DiscordUser } from 'types/resources';
import { UserDetailsPayload } from '../../types';
import useLog from '../useLog';
import useUserDetails from '../user/useUserDetails';
import useAuth from '../auth/useAuth';

const useDiscord = () => {
  const { initUserDetails } = useUserDetails();
  const { setToken } = useAuth();
  const { fetchAPIWithLog } = useLog('useDiscord');

  const getDiscordUser = useCallback(
    async ({
      code,
      redir,
    }: {
      code: string;
      redir: string;
    }): Promise<{
      error: string;
      user?: DiscordUser;
      guilds?: DiscordGuild[];
      auth?: DiscordAuth;
    }> => {
      return await fetchAPIWithLog(
        `discord_login_callback?code=${code}&redir=${redir}`,
      );
    },
    [],
  );

  const updateMemberpressDiscord = useCallback(async (discordId: string) => {
    const payload = {
      meta: {
        mepr_discord_username: discordId,
      },
    };
    return saveDiscordAccountPayload(payload);
  }, []);

  const saveDiscordAccountPayload = async (payload: any) => {
    const memberpressData = await fetchAPIWithLog(`v1/sgMyself`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (memberpressData.code != null) {
      setToken(null);
    }

    initUserDetails(memberpressData as UserDetailsPayload);
    return memberpressData;
  };

  const updateDiscordRole = useCallback(
    async ({
      memberpressId,
      discordToken,
    }: {
      discordToken: string;
      memberpressId?: number;
    }) => {
      const url = `discord/updateRole?auth=${discordToken}&userid=${memberpressId}`;
      return await fetchAPIWithLog(url, { method: 'POST' });
    },
    [],
  );

  return { updateMemberpressDiscord, getDiscordUser, updateDiscordRole };
};

export default useDiscord;

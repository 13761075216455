import { atom } from 'recoil';

export const bssoTokenState = atom<string | undefined>({
  key: 'auth-bssoTokenState',
  default: undefined,
});

export const bbgMarketSessionState = atom<any>({
  key: 'shared-bbgMarketSessionState',
  default: null,
});

export const streamingPricesOverrideForSymState = atom<string | null>({
  key: 'hiro-streamingPricesOverrideForSymState',
  default: null,
});

export const appRefreshCounterState = atom<number>({
  key: 'shared-appRefreshCounterState',
  default: 0,
});

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingsPopout from '../../shared/SettingsPopout';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {
  currentStatsLookbackState,
  currentStatsState,
  iVolErrorState,
  iVolLoadingState,
  showVolSkewIVStatsState,
  volSkewDTESelectorState,
  volSkewDataState,
  volSkewDateState,
  volSkewDisplayTypeState,
  volSkewExpirationsState,
  volSkewSelectedDatedGreeksState,
  volSkewSelectedExpirationsState,
  volSkewSelectedRawGreeksState,
  volSkewUsedLineColorsState,
  volSkewVisibleChartLinesState,
} from 'states/iVol';
import { useCallback, useMemo, useState } from 'react';
import {
  getCurrentDate,
  getDefaultSlotProps,
  getQueryDate,
  isBusinessDay,
  isMarketOpenOnDate,
  nextBusinessDay,
  prevBusinessDay,
} from 'util/shared';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import {
  DatedGreeks,
  IVolSettingsProps,
  RawGreeksDataMap,
  RawGreeksObject,
  RawStatsData,
  StrikeDisplayType,
  VolSkew,
} from 'types';
import {
  MIN_QUERY_DATE,
  SKEW_VIEW_TOOLTIPS,
  VSKEW_DEFAULT_DTE,
  convertToDaysTillExpiry,
  extractVolSkewData,
  findClosestExpiration,
  getExpirationLabel,
  getNextAvailableColor,
  getSnapshotTime,
  getStatsFromExp,
  getVolSkewKey,
  getVolSkewKeyFromLabel,
  mergeObjectLists,
} from 'util/iVol';
import { TimeFramePill } from '../TimeFramePill';
import { isMobileState } from 'states';
import StatsLookbackSettings from '../StatsLookbackSetting';

const MAX_TIME_FRAMES = 5;

const VolSkewControls = ({ selectedSym }: IVolSettingsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const defaultSlotProps = getDefaultSlotProps(theme);
  const [selectedDate, setSelectedDate] = useRecoilState(volSkewDateState);
  const [expirations, setExpirations] = useRecoilState(volSkewExpirationsState);
  const [selectedDisplayType, setSelectedDisplayType] = useRecoilState(
    volSkewDisplayTypeState,
  );
  const setVolSkewData = useSetRecoilState(volSkewDataState);
  const [selectedRawData, setSelectedRawData] = useRecoilState(
    volSkewSelectedRawGreeksState,
  );
  const [selectedExpirations, setSelectedExpirations] = useRecoilState(
    volSkewSelectedExpirationsState,
  );
  const [visibleChartLines, setVisibleChartLines] = useRecoilState(
    volSkewVisibleChartLinesState,
  );
  const [volSkewSelectedDatedGreeks, setVolSkewSelectedDatedGreeks] =
    useRecoilState(volSkewSelectedDatedGreeksState);

  const [showVolSkewIVStats, setShowVolSkewIVStats] = useRecoilState(
    showVolSkewIVStatsState,
  );

  const availableColors = Object.values(theme.palette.iVol.volSkew);
  const [volSkewUsedLineColors, setVolSkewUsedLineColors] = useRecoilState(
    volSkewUsedLineColorsState,
  );

  const currentStatsLookback = useRecoilValue(currentStatsLookbackState);

  const currentStats = useRecoilValue(currentStatsState);
  const statsDataObj: RawStatsData | null =
    currentStats && currentStats[currentStatsLookback];

  // this recoil state is needed to prevent popout from closing and let ClickAwayListener know
  const setOpen = useSetRecoilState(volSkewDTESelectorState);
  const [expirySelectorOpen, setExpirySelectorOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setExpirySelectorOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setExpirySelectorOpen(true);
  };

  const loading = useRecoilValue(iVolLoadingState);
  const error = useRecoilValue(iVolErrorState);
  const { getCurrentGreeksData, getDailyGreeksData } = useImpliedVolatility();

  const maxQueryDate = getQueryDate(true);

  const updatedVolSkewData = (
    datedGreeks: DatedGreeks[],
    displayType: StrikeDisplayType,
  ) => {
    let finalData: VolSkew[] = [];
    datedGreeks.forEach((datedGreeks: DatedGreeks) => {
      const transformedVolSkewData: VolSkew[] = extractVolSkewData(
        datedGreeks.greeksMap[Number(datedGreeks.expiryDate)],
        datedGreeks.key,
        datedGreeks.statsMap,
      );

      finalData = mergeObjectLists(
        finalData,
        transformedVolSkewData,
        getVolSkewKeyFromLabel(displayType),
      );
    });
    setVolSkewData(finalData);
  };

  const onChangeXDisplayType = (displayType: StrikeDisplayType) => {
    updatedVolSkewData(volSkewSelectedDatedGreeks, displayType);
    setSelectedDisplayType(displayType);
  };

  const onResetToDefault = async () => {
    const rawCurrentGreeksData: RawGreeksDataMap | null =
      await getCurrentGreeksData(selectedSym);

    if (rawCurrentGreeksData && statsDataObj) {
      const snapTime = getSnapshotTime(rawCurrentGreeksData);
      const expirations = Object.keys(rawCurrentGreeksData);
      const defaultExpiration: string = findClosestExpiration(
        snapTime,
        expirations,
        VSKEW_DEFAULT_DTE,
      );
      const tradeDate = dayjs(snapTime);
      const timeFrameKey = getVolSkewKey(tradeDate, defaultExpiration);
      const strikeMap = rawCurrentGreeksData[Number(defaultExpiration)];

      // retrieve the corresponding tte to access the right stats entry
      const statsMap = getStatsFromExp(
        statsDataObj,
        rawCurrentGreeksData,
        defaultExpiration,
      );

      const finalData = extractVolSkewData(strikeMap, timeFrameKey, statsMap);

      const color =
        getNextAvailableColor([], availableColors) ??
        theme.palette.primary.main;

      setExpirations(expirations);
      setSelectedExpirations([]);
      setVolSkewSelectedDatedGreeks([
        {
          key: timeFrameKey,
          tradeDate,
          expiryDate: defaultExpiration,
          greeksMap: rawCurrentGreeksData,
          statsMap,
          color,
        },
      ]);
      setSelectedDate(tradeDate);
      setVisibleChartLines([timeFrameKey]);
      setVolSkewData(finalData);
      setVolSkewUsedLineColors([color]);
    }
  };

  const onAddNewTimeFrame = useCallback(() => {
    let newSelectedDatedGreeks: DatedGreeks[] = [];
    let finalData: VolSkew[] = [];
    let newlyUsedColors: string[] = [...volSkewUsedLineColors];

    selectedExpirations.forEach((expirationDateUtc: string) => {
      const timeFrameKey: string = getVolSkewKey(
        selectedDate,
        expirationDateUtc,
      );

      const strikeMap: RawGreeksObject =
        selectedRawData[Number(expirationDateUtc)];

      if (statsDataObj) {
        // retrieve the corresponding tte to fetch the right stats entry
        const statsMap = getStatsFromExp(
          statsDataObj,
          selectedRawData,
          expirationDateUtc,
        );

        const transformedVolSkewData: VolSkew[] = extractVolSkewData(
          strikeMap,
          timeFrameKey,
          statsMap,
        );

        const color =
          getNextAvailableColor(newlyUsedColors, availableColors) ??
          theme.palette.primary.main;

        newlyUsedColors = [...newlyUsedColors, color];
        // updated selected dated greeks to include new expiration
        newSelectedDatedGreeks = [
          ...newSelectedDatedGreeks,
          {
            key: timeFrameKey,
            tradeDate: selectedDate,
            expiryDate: expirationDateUtc,
            greeksMap: selectedRawData,
            statsMap,
            color,
          },
        ];

        // updated volSkew data
        finalData = mergeObjectLists(
          finalData,
          transformedVolSkewData,
          getVolSkewKeyFromLabel(selectedDisplayType),
        );
      }
    });

    setVolSkewData((prevData: VolSkew[] | undefined) => {
      return mergeObjectLists(
        prevData ?? [],
        finalData,
        getVolSkewKeyFromLabel(selectedDisplayType),
      );
    });
    setSelectedExpirations([]);
    setVolSkewSelectedDatedGreeks((prevData: DatedGreeks[]) => [
      ...prevData,
      ...newSelectedDatedGreeks,
    ]);
    setVisibleChartLines((prevData: string[]) => [
      ...prevData,
      ...newSelectedDatedGreeks.map(
        (datedGreeks: DatedGreeks) => datedGreeks.key,
      ),
    ]);
    setVolSkewUsedLineColors(newlyUsedColors);
  }, [
    selectedDate,
    volSkewUsedLineColors,
    selectedDisplayType,
    selectedExpirations,
    selectedRawData,
    currentStats,
    setSelectedExpirations,
    setVisibleChartLines,
    setVolSkewUsedLineColors,
    setVolSkewData,
    setVolSkewSelectedDatedGreeks,
  ]);

  const onDeleteTimeFrame = useCallback(
    (key: string) => {
      const updatedDatedGreeks = volSkewSelectedDatedGreeks.filter(
        (datedGreeks: DatedGreeks) => datedGreeks.key !== key,
      );
      const updatedUsedColors = updatedDatedGreeks.map(
        (dg: DatedGreeks) => dg.color,
      );

      setVolSkewUsedLineColors(updatedUsedColors);
      setVolSkewSelectedDatedGreeks(updatedDatedGreeks);
      updatedVolSkewData(updatedDatedGreeks, selectedDisplayType);
    },
    [
      setVolSkewData,
      setVolSkewSelectedDatedGreeks,
      selectedDisplayType,
      volSkewSelectedDatedGreeks,
    ],
  );

  const onToggleLineVisiblity = useCallback(
    (key: string, toShow: boolean) => {
      const updatedVisibleLines: string[] = toShow
        ? [...visibleChartLines, key]
        : visibleChartLines.filter((line: string) => line !== key);
      setVisibleChartLines(updatedVisibleLines);

      const updatedDatedGreeks = volSkewSelectedDatedGreeks.filter(
        (datedGreeks: DatedGreeks) =>
          updatedVisibleLines.includes(datedGreeks.key),
      );

      updatedVolSkewData(updatedDatedGreeks, selectedDisplayType);
    },
    [
      visibleChartLines,
      volSkewSelectedDatedGreeks,
      selectedDisplayType,
      setVisibleChartLines,
    ],
  );

  // called when user changes an expiration date for a specific trade date
  const onChangeExpiry = useCallback(
    (event: SelectChangeEvent<typeof selectedExpirations>) => {
      const updatedExpirations = event.target.value;

      setSelectedExpirations(
        typeof updatedExpirations === 'string'
          ? updatedExpirations.split(',')
          : updatedExpirations,
      );
    },
    [setSelectedExpirations],
  );

  const isExpiryDisabled = useCallback(
    (tradeDate: Dayjs, expiry: string) =>
      volSkewSelectedDatedGreeks.some(
        (datedGreeks: DatedGreeks) =>
          datedGreeks.expiryDate === expiry &&
          datedGreeks.tradeDate.isSame(tradeDate, 'day'),
      ),
    [volSkewSelectedDatedGreeks],
  );

  const onTradeDateChange = useCallback(
    async (newValue: Dayjs | null) => {
      if (newValue !== null) {
        const formattedDate: string = newValue.format('YYYY-MM-DD');
        // fetch the relevant greeks based on the selected date
        const rawData: RawGreeksDataMap | null = newValue.isSame(
          getCurrentDate(),
          'day',
        )
          ? await getCurrentGreeksData(selectedSym)
          : await getDailyGreeksData(formattedDate, selectedSym);
        if (rawData) {
          const updatedExpirations: string[] = Object.keys(rawData);

          // update expirations list
          setExpirations(updatedExpirations);
          // update selected expirations to be only the first available expiration by default
          const firstExpiry = updatedExpirations.find(
            (expiry) => !isExpiryDisabled(newValue, expiry),
          );
          setSelectedExpirations(firstExpiry ? [firstExpiry] : []);
          // update currently selected raw greeks data map
          setSelectedRawData(rawData);

          // update selected date
          setSelectedDate(newValue);
        }
      }
    },
    [
      getCurrentGreeksData,
      getDailyGreeksData,
      isExpiryDisabled,
      selectedSym,
      setExpirations,
      setSelectedDate,
      setSelectedExpirations,
      setSelectedRawData,
    ],
  );

  const onStatsLookbackChange = (newStats: RawStatsData) => {
    let finalData: VolSkew[] = [];
    let updatedSelectedDatedGreeks: DatedGreeks[] = [];

    volSkewSelectedDatedGreeks.forEach((dg: DatedGreeks) => {
      const rawGreeks: RawGreeksDataMap = dg.greeksMap;

      if (rawGreeks) {
        const expiry = dg.expiryDate;
        const strikeMap = rawGreeks[Number(expiry)];
        const statsMap = getStatsFromExp(newStats, rawGreeks, dg.expiryDate);

        const transformedVolSkewData: VolSkew[] = extractVolSkewData(
          strikeMap,
          dg.key,
          statsMap,
        );

        if (visibleChartLines.includes(dg.key)) {
          finalData = mergeObjectLists(
            finalData,
            transformedVolSkewData,
            getVolSkewKeyFromLabel(selectedDisplayType),
          );
        }

        updatedSelectedDatedGreeks = [
          ...updatedSelectedDatedGreeks,
          { ...dg, statsMap },
        ];
      }
    });

    setVolSkewSelectedDatedGreeks(updatedSelectedDatedGreeks);
    setVolSkewData(finalData);
  };

  const reachedMaximumDates =
    volSkewSelectedDatedGreeks?.length === MAX_TIME_FRAMES + 1;

  const tooManyExpirationsSelected =
    selectedExpirations?.length + volSkewSelectedDatedGreeks?.length >
    MAX_TIME_FRAMES + 1;

  const isSelectedDateValid = useMemo(() => {
    return (
      selectedDate &&
      selectedDate.isValid() &&
      isBusinessDay(selectedDate) &&
      selectedDate.isAfter(prevBusinessDay(MIN_QUERY_DATE), 'day') &&
      selectedDate.isBefore(nextBusinessDay(maxQueryDate), 'day')
    );
  }, [maxQueryDate, selectedDate]);

  const datePickerHelpText = useMemo(() => {
    if (reachedMaximumDates) {
      return 'Maximum number of dates used';
    } else if (error) {
      return 'Failed to fetch data for selected date. Try again!';
    } else if (tooManyExpirationsSelected) {
      return 'Too many expirations selected. A maximum of 6 time frames is allowed for better visuals.';
    } else if (selectedDate.isBefore(MIN_QUERY_DATE, 'day')) {
      return `No data available for ${selectedDate.format(
        'YYYY-MM-DD',
      )}. Try another date!`;
    }
    return null;
  }, [error, reachedMaximumDates, selectedDate, tooManyExpirationsSelected]);

  return (
    <Stack sx={{ textAlign: 'left', justifyContent: 'flex-start' }} spacing={5}>
      <ButtonGroup
        aria-label="outlined secondary button group"
        sx={{ alignSelf: 'left', flexWrap: 'wrap' }}
      >
        {Object.values(StrikeDisplayType).map((type: StrikeDisplayType) => (
          <Tooltip
            key={type}
            children={
              <Button
                key={type}
                sx={{
                  backgroundColor:
                    selectedDisplayType === type
                      ? alpha(theme.palette.secondary.main, 0.25)
                      : 'transparent',
                  '&:hover': {
                    color: alpha(theme.palette.secondary.main, 1),
                    borderColor: alpha(theme.palette.secondary.main, 1),
                  },
                  fontSize: isMobile ? 12 : 14,
                  textTransform: 'none',
                  width: '33.3%',
                }}
                onClick={() => {
                  onChangeXDisplayType(type);
                }}
              >
                {type}
              </Button>
            }
            PopperProps={{
              sx: {
                zIndex: 10001,
                maxWidth: 160,
                '& .MuiTooltip-tooltip': {
                  fontSize: '12px',
                },
              },
            }}
            title={SKEW_VIEW_TOOLTIPS[type]}
          />
        ))}
      </ButtonGroup>
      <Box
        display="flex"
        justifyContent="space-between"
        maxWidth="100%"
        alignItems="center"
      >
        <FormControlLabel
          sx={{
            marginLeft: 0,
          }}
          control={
            <Switch
              sx={{
                '& .MuiSwitch-track': {
                  opacity: 0.25,
                  backgroundColor: theme.palette.success.main,
                },
                '&.Mui-checked .MuiSwitch-track': {
                  opacity: 1,
                },
              }}
              color="success"
              checked={showVolSkewIVStats}
              onChange={(_e, checked: boolean) =>
                setShowVolSkewIVStats(checked)
              }
            />
          }
          label="Statistics"
          labelPlacement="start"
        />
        <Button
          variant="contained"
          size="small"
          sx={{
            height: '32px',
            fontSize: isMobile ? 12 : 14,
            textTransform: 'capitalize',
          }}
          onClick={onResetToDefault}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: theme.palette.getContrastText(
                  theme.palette.background.default,
                ),
              }}
            />
          ) : (
            'Reset to Default'
          )}
        </Button>
      </Box>

      {showVolSkewIVStats && (
        <StatsLookbackSettings onLookbackChange={onStatsLookbackChange} />
      )}

      <Typography sx={{ fontSize: isMobile ? 12 : 14, marginBottom: '4px' }}>
        Add Additional Trading Time Frames to Compare Skews
      </Typography>
      <Stack direction="row" justifyContent="space-between" gap={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['day']}
            label="From Date"
            value={selectedDate}
            disabled={reachedMaximumDates}
            onChange={onTradeDateChange}
            shouldDisableDate={(date: Dayjs) =>
              !isBusinessDay(date) ||
              date.isBefore(MIN_QUERY_DATE, 'day') ||
              !isMarketOpenOnDate(date)
            }
            format="YYYY-MM-DD"
            minDate={MIN_QUERY_DATE}
            maxDate={maxQueryDate}
            slotProps={{
              ...defaultSlotProps,
              textField: {
                ...defaultSlotProps.textField,
                style: {
                  width: '150px',
                  marginBottom: '10px',
                  fontSize: isMobile ? 12 : 14,
                },
                helperText: datePickerHelpText,
                error: error != null,
              },
            }}
          />
        </LocalizationProvider>
        <FormControl
          sx={{
            width: 120,
          }}
          size="small"
        >
          <InputLabel id="expiry-date-select-label">Expiration</InputLabel>
          <Select
            labelId="expiry-date-select-label"
            id="expiry-date-select"
            multiple
            disabled={loading}
            open={expirySelectorOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={onChangeExpiry}
            value={selectedExpirations}
            size="small"
            input={
              <OutlinedInput
                label="Expiration"
                sx={{
                  svg: {
                    color: alpha(theme.palette.primary.main, 0.5),
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha(theme.palette.primary.main, 0.5),
                  },
                }}
              />
            }
            renderValue={(selected: string[]) =>
              selected
                .map(
                  (expiration: string) =>
                    `${convertToDaysTillExpiry(
                      selectedDate.valueOf(),
                      Number(expiration),
                    )} DTE`,
                )
                .join(', ')
            }
            MenuProps={{
              sx: {
                zIndex: 10001,
                '& .MuiSelect-icon': {
                  color: theme.palette.text.primary,
                },
              },
              PaperProps: {
                style: {
                  width: '150px',
                  maxHeight: '250px',
                },
              },
            }}
          >
            {loading ? (
              <MenuItem value="">
                <CircularProgress />
              </MenuItem>
            ) : (
              expirations.map((utcTime: string) => {
                return (
                  <MenuItem
                    key={utcTime}
                    value={utcTime}
                    sx={{
                      padding: '10px',
                    }}
                    disabled={isExpiryDisabled(selectedDate, utcTime)}
                  >
                    <Checkbox
                      checked={selectedExpirations.indexOf(utcTime) > -1}
                      style={{
                        padding: '0px 10px 0px 0px',
                      }}
                    />
                    <ListItemText
                      primary={`${getExpirationLabel(utcTime)}`}
                      secondary={`${convertToDaysTillExpiry(
                        selectedDate.valueOf(),
                        Number(utcTime),
                      )} DTE`}
                      primaryTypographyProps={{
                        fontSize: '13px',
                      }}
                    />
                  </MenuItem>
                );
              })
            )}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          size="small"
          disabled={
            loading ||
            error != null ||
            !isSelectedDateValid ||
            reachedMaximumDates ||
            selectedExpirations?.length === 0 ||
            tooManyExpirationsSelected
          }
          sx={{
            width: '35px',
            height: '37px',
            marginLeft: '10px',
            fontFamily: 'Satoshi Complete',
            fontSize: 14,
            textTransform: 'capitalize',
          }}
          onClick={onAddNewTimeFrame}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: theme.palette.getContrastText(
                  theme.palette.background.default,
                ),
              }}
            />
          ) : (
            'Add'
          )}
        </Button>
      </Stack>

      {volSkewSelectedDatedGreeks.length > 0 ? (
        <Divider
          style={{
            borderColor: theme.palette.getContrastText(
              theme.palette.background.default,
            ),
          }}
        />
      ) : null}

      <List
        style={{
          marginTop: '5px',
        }}
      >
        {volSkewSelectedDatedGreeks.map((datedGreeks: DatedGreeks) => (
          <TimeFramePill
            key={datedGreeks.key}
            identifier={datedGreeks.key}
            onDelete={onDeleteTimeFrame}
            visibleLines={visibleChartLines}
            onToggleLineVisiblity={onToggleLineVisiblity}
            icon={<ShowChartIcon />}
            bgColor={datedGreeks.color}
            expirationDateUtc={datedGreeks.expiryDate}
            date={datedGreeks.tradeDate}
          />
        ))}
      </List>
    </Stack>
  );
};

export const VolSkewChartSettings = ({ selectedSym }: IVolSettingsProps) => {
  const isExternalMenuOpen = useRecoilValue(volSkewDTESelectorState);
  const isMobile = useRecoilValue(isMobileState);
  return (
    <SettingsPopout
      title="Vol Skew Settings"
      popperID="vol-skew-controls"
      placement="bottom-end"
      externalMenuOpen={isExternalMenuOpen}
      sx={{
        width: isMobile ? '350px' : '430px',
      }}
    >
      <VolSkewControls selectedSym={selectedSym} />
    </SettingsPopout>
  );
};

import { useCallback, useMemo } from 'react';
import { ProductType, UserDetailsPayload } from 'types';
import {
  checkPermissions,
  getCachedToken,
  isAdmin,
  isBBEnvAvailable,
  isValidTokenOverrideTimeframe,
} from '../../util';
import {
  hasInternalDashAccessState,
  productAccessState,
  userDetailsState,
} from '../../states';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import useLog from '../useLog';
import useUserDetails from '../user/useUserDetails';
import { OH_TOKEN } from 'config/user';

const useAuth = () => {
  const navigate = useNavigate();
  const products = useRecoilValue(productAccessState);
  const { initUserDetails } = useUserDetails();
  const userDetails = useRecoilValue(userDetailsState);
  const isInternal = useRecoilValue(hasInternalDashAccessState);

  const { logError, fetchAPIWithLog } = useLog('useAuth');

  const productsWithAccess = useMemo(() => new Set(products), [products]);

  const hasAccessToProduct = (product: ProductType | null) => {
    if (userDetails == null && product === ProductType.INTEGRATIONS) {
      return false;
    }

    return (
      product == null ||
      productsWithAccess.has(product) ||
      isValidTokenOverrideTimeframe()
    );
  };

  const getLogin = async (username: string, password: string) => {
    return await fetchAPIWithLog(`v1/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
    });
  };

  const setTokensAndUserDetails = useCallback(
    (token: string | null, payload: UserDetailsPayload | null) => {
      if (token == null || payload == null) {
        setToken(null);
        initUserDetails(undefined);
        return;
      }
      setToken(token);
      payload.watchlists = payload.watchlists ?? [];
      initUserDetails(payload);
    },
    [initUserDetails],
  );

  const validateCachedToken = useCallback(async () => {
    const sgToken = getCachedToken();
    if (isBBEnvAvailable()) {
      return;
    }
    const data = await fetchAPIWithLog(`v1/sgMyself`, {
      headers: { Authorization: `Bearer ${sgToken}` },
    });

    if (data?.code != null) {
      return setTokensAndUserDetails(null, null);
    } else if (data?.error != null) {
      if (data.error.name === 'TokenExpiredError') {
        // Silently log the error, but no need to confuse end-users with this information.
        logError(data.error, 'validateCached');
      }
      // If this failed to fetch, then abort.  The request to the endpoint never actually went through
      // sometimes will see 'TypeError: Failed to fetch'
      if (data.error.message?.includes('Failed to fetch')) {
        // fetchApi will automatically retry if this error occurs
        // in the exceedingly rare scenario in which it happens again, just return
        // never force logout a user if this error is the cause of the fetch fail
        return;
      }
      return setTokensAndUserDetails(null, null);
    }
    const { hasAccess, errorMessage } = checkPermissions(data);
    if (!hasAccess) {
      if (errorMessage != null) {
        logError(errorMessage, 'validateCachedToken');
      }
      setTokensAndUserDetails(null, null);
      return navigate('/login');
    }
    setTokensAndUserDetails(sgToken, data);
  }, [setTokensAndUserDetails, logError, navigate]);

  const userIsAdmin = useMemo(() => isAdmin(userDetails), [userDetails]);

  const getBbgSGToken = useCallback(async (oauthParams: any) => {
    return await fetchAPIWithLog(`bbg/login`, {
      method: 'POST',
      body: JSON.stringify({ oauthParams: oauthParams }),
    });
  }, []);

  const canViewCompass = isInternal;

  const setToken = (token: string | null) => {
    if (token === OH_TOKEN) {
      debugger;
      throw new Error('Setting token to OH Token!');
    }
    if (token == null) {
      localStorage.removeItem('sgToken');
    } else {
      localStorage.setItem('sgToken', token);
    }
  };

  return {
    hasAccessToProduct,
    getLogin,
    setTokensAndUserDetails,
    validateCachedToken,
    productsWithAccess,
    userIsAdmin,
    getBbgSGToken,
    canViewCompass,
    setToken,
  };
};

export default useAuth;

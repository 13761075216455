import { DEFAULT_BIAXIAL_ZOOM_CONFIG, DEFAULT_ZOOM_CONFIG } from 'config';
import {
  BiaxialChartZoomConfig,
  Bounds,
  BrushZoomConfig,
  ChartZoomConfig,
} from 'types';
import * as d3 from 'd3';
import { saveAs } from 'file-saver';
import html2canvas, { Options } from 'html2canvas';
import { SetterOrUpdater } from 'recoil';
import { ReferenceArea } from 'recharts';

// Zoom
export const getAxisYDomain = (
  from: number,
  to: number,
  yFields: string[],
  xField: string,
  offset: number,
  initialData?: any[],
) => {
  const refData = (initialData || []).filter(
    (d) => d[xField] >= from && d[xField] <= to,
  );
  if (refData.length > 0) {
    const allValues: number[] = refData.flatMap((item) =>
      yFields.map((key) => item[key]).filter((value) => value != null),
    );
    const minValue = allValues.reduce(
      (min, value) => (value < min ? value : min),
      Number.POSITIVE_INFINITY,
    );
    const maxValue = allValues.reduce(
      (max, value) => (value > max ? value : max),
      Number.NEGATIVE_INFINITY,
    );
    return [minValue - offset, maxValue + offset];
  } else {
    return [from, to];
  }
};

export const isZoomConfigEqDefault = (
  zoomConfig: BiaxialChartZoomConfig | ChartZoomConfig,
  overrideDefault?: any,
): boolean => {
  const originalDefault =
    'top2' in zoomConfig ? DEFAULT_BIAXIAL_ZOOM_CONFIG : DEFAULT_ZOOM_CONFIG;
  return Object.entries(overrideDefault ?? originalDefault)
    .filter(
      ([key, _]) => !['data', 'refAreaLeft', 'refAreaRight'].includes(key),
    )
    .some(([key, value]) =>
      'top2' in zoomConfig
        ? zoomConfig[key as keyof BiaxialChartZoomConfig] !== value
        : zoomConfig[key as keyof ChartZoomConfig] !== value,
    );
};

function getBounds(strikes: number[], zoomConfig: Bounds) {
  const [min, max] = d3.extent(strikes) as [number, number];
  let left = parseFloat(`${zoomConfig.left}`);
  let right = parseFloat(`${zoomConfig.right}`);
  return {
    left: isNaN(right) ? min : left,
    right: isNaN(right) ? max : right,
  };
}

function getTickRange(left: number, right: number, step: number): number[] {
  const [min, max] = left < right ? [left, right] : [right, left];
  const start = Math.ceil(min / step) * step;
  const end = Math.ceil(max / step) * step;
  return d3.range(start, end, step);
}

export const STRIKE_TICK_CONFIG: Map<number, number> = new Map([
  [800, 100],
  [400, 50],
  [200, 25],
  [50, 10],
  [25, 5],
  [15, 2],
  [5, 1],
  [2, 0.25],
  [1, 0.1],
  [0.1, 0.02],
]);

export const DAY_IN_MS = 86400000; // Number of milliseconds in a day
const WEEK_IN_MS = 604800000; // Number of milliseconds in a week
const MONTH_IN_MS = 2629800000; // Average number of milliseconds in a month (30.44 days)
const YEAR_IN_MS = 365 * DAY_IN_MS;

export const TIMESTAMP_TICK_CONFIG = new Map([
  [YEAR_IN_MS * 2, MONTH_IN_MS * 4], // >= 2 years, step is 4 months
  [MONTH_IN_MS * 6, MONTH_IN_MS], // >= 6 months, step is 1 month
  [MONTH_IN_MS * 3, WEEK_IN_MS * 2], // >= 3 months, step is 2 weeks
  [MONTH_IN_MS, DAY_IN_MS * 5], // >= 1 month, step is 5 days
  [WEEK_IN_MS * 2, DAY_IN_MS * 2], // >= 2 weeks, step is 2 days
  [WEEK_IN_MS, DAY_IN_MS], // >= 1 week, step is 1 day
  [DAY_IN_MS, DAY_IN_MS], // >= 1 day, step is 1 day
]);

export const DTE_TICK_CONFIG = new Map([
  [730, 90], // >= 2 years, step is 1 quarter (approx.)
  [365, 30], // >= 1 year, step is 1 month (approx.)
  [180, 14], // >= half a year, step is 2 weeks (approx.)
  [90, 7], // >= 3 months, step is 1 week
  [30, 2], // >= 1 month, step is 2 days
  [1, 1], // >= 1 day, step is 1 day
]);

export const MONEYNESS_TICK_CONFIG = new Map([
  [0.5, 0.1], // >= 50% (0.5), step is 10% (0.1)
  [0.2, 0.05], // >= 20% (0.2), step is 5% (0.05)
  [0.1, 0.02], // >= 10% (0.1), step is 2% (0.02)
  [0.05, 0.01], // >= 5% (0.05), step is 1% (0.01)
  [0.01, 0.005], // >= 2% (0.02), step is 0.5% (0.005)
]);

export const DELTA_TICK_CONFIG = new Map([
  [0.5, 0.1], // >= 50% (0.5), step is 10% (0.1)
  [0.2, 0.05], // >= 20% (0.2), step is 5% (0.05)
  [0.05, 0.02], // >= 5% (0.05), step is 2% (0.02)
  [0.01, 0.01], // >= 1% (0.01), step is 1% (0.01)
]);

export const getTicks = (
  values: number[],
  zoomConfig: Bounds,
  tickConfig: Map<number, number>,
) => {
  const { left, right } = getBounds(values, zoomConfig);
  for (const [threshold, step] of tickConfig.entries()) {
    if (right - left >= threshold) {
      return getTickRange(left, right, step);
    }
  }
  return undefined; // let recharts use default ticks algorithm
};

export const getTicksBrushed = (
  values: number[],
  zoomConfig: BrushZoomConfig,
  tickConfig: Map<number, number>,
) => {
  const { leftIdx: left, rightIdx: right } = zoomConfig;
  if (left != null && right != null && values != null) {
    for (const [threshold, step] of tickConfig.entries()) {
      const leftVal = values[left];
      const rightVal = values[right];
      if (Math.abs(rightVal - leftVal) >= threshold) {
        return getTickRange(leftVal, rightVal, step);
      }
    }
  }
  return undefined; // let recharts use default ticks algorithm
};

export const updateBrushZoomConfig = (
  oldConfig: BrushZoomConfig,
  newData: any[],
  setBrushZoomConfig: SetterOrUpdater<BrushZoomConfig>,
  initialRightIdx?: number,
  initialLeftIdx?: number,
  reset?: boolean,
) => {
  if (
    reset ||
    oldConfig.data == null ||
    oldConfig.data.length === 0 ||
    (oldConfig.leftIdx === 0 &&
      oldConfig.rightIdx === oldConfig.data.length - 1)
  ) {
    setBrushZoomConfig((prev) => ({
      ...prev,
      leftIdx: initialLeftIdx ?? 0,
      rightIdx: initialRightIdx ?? newData.length - 1,
      data: newData,
    }));
    return;
  }

  const oldLength = oldConfig.data.length;
  const newLength = newData.length;

  let newleftIdx = Math.round(
    ((oldConfig.leftIdx ?? 0) / oldLength) * newLength,
  );
  let newrightIdx = Math.round(
    ((oldConfig.rightIdx ?? oldLength - 1) / oldLength) * newLength,
  );

  // Ensure the new indices are within bounds
  newleftIdx = Math.max(0, Math.min(newleftIdx, newLength - 1));
  newrightIdx = Math.max(0, Math.min(newrightIdx, newLength - 1));

  setBrushZoomConfig((prev) => ({
    ...prev,
    leftIdx: newleftIdx,
    rightIdx: newrightIdx,
    data: newData,
  }));
};

export const downloadChartAsPng = async (
  elementRef: React.RefObject<HTMLDivElement>,
  fileName: string,
  options?: Pick<Options, 'backgroundColor'>,
): Promise<void> => {
  if (elementRef.current) {
    try {
      const canvas = await html2canvas(elementRef.current, options);
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          saveAs(blob, `${fileName}.png`);
        }
      });
    } catch (error) {
      console.error(`Error generating ${fileName} PNG`, error);
    }
  }
};

export const getZoomConfigRefArea = (
  zoomConfig: BrushZoomConfig | ChartZoomConfig | BiaxialChartZoomConfig,
  yAxisId?: string,
) =>
  zoomConfig.refAreaLeft !== '' && zoomConfig.refAreaRight !== '' ? (
    <ReferenceArea
      {...(yAxisId ? { yAxisId } : {})}
      x1={zoomConfig.refAreaLeft}
      x2={zoomConfig.refAreaRight}
      strokeOpacity={0.3}
      isFront
    />
  ) : null;

import { useRecoilState, useRecoilValue } from 'recoil';
import { SxProps } from '@mui/material';
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import { chartExpandedState, isMobileState } from '../../states';
import { ChartSizingButton } from './ChartSizingButton';

interface FullScreenButtonProps {
  setOpenSettings?: React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps;
  withText?: boolean;
  onClick?: () => void;
}

export const FullScreenButton = ({
  setOpenSettings,
  sx,
  withText,
  onClick,
}: FullScreenButtonProps) => {
  const [chartExpanded, setChartExpanded] = useRecoilState(chartExpandedState);
  const isMobile = useRecoilValue(isMobileState);
  if (isMobile) {
    return null;
  }

  return (
    <ChartSizingButton
      onClick={() => {
        if (setOpenSettings) {
          setOpenSettings(!chartExpanded);
        }
        setChartExpanded(!chartExpanded);
        onClick != null && onClick();
      }}
      icon={
        chartExpanded ? <FullscreenExitSharpIcon /> : <FullscreenSharpIcon />
      }
      title={chartExpanded ? 'Close Full Screen' : 'Open Full Screen'}
      withText={withText}
      buttonSx={sx}
    />
  );
};
